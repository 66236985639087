import Grid from "@mui/material/Grid2"
import Typography from "@mui/material/Typography"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import TableHead from "@mui/material/TableHead"
import TableContainer from "@mui/material/TableContainer"
import { Box, useTheme, useMediaQuery } from "@mui/material"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  getWorkspaces,
  selectWorkspaces,
  selectWorkspacesStatus,
  WorkspacesStatus,
} from "../../app/slices/workspaces-slice"
import {
  getDashboard,
} from "../../app/slices/reports-slice"
import WorkspaceRow from "../../components/workspace-table/workspace-table-row"
import { useEffect } from "react"
import DashboardSmall from "./dashboard-small"
import ListingsValueCard from "./cards/listings-value"
import MainCard from "../../components/cards/MainCard"
import { gridSpacing } from "../../store/constant"
import TotalGcrCard from "./cards/total-gcr"

const Dashboard = () => {
  const dispatch = useAppDispatch()

  const workspacesStatus = useAppSelector(selectWorkspacesStatus)
  const theme = useTheme()

  const isSmallOrMedium = useMediaQuery(theme.breakpoints.between("xs", "md"))
  useEffect(() => {
    dispatch(getDashboard())
  }, [dispatch])

  const workspaces = useAppSelector(selectWorkspaces)
  useEffect(() => {
    if (workspacesStatus === WorkspacesStatus.initial) {
      dispatch(getWorkspaces())
    }
  }, [dispatch, workspacesStatus])



  if (isSmallOrMedium) {
    return <DashboardSmall />
  }
  return (
    <Grid container spacing={gridSpacing}>
      <Grid size={{ xs: 12, md: 6, lg: 6 }}>
        <ListingsValueCard />
      </Grid>
      <Grid size={{ xs: 12, md: 6, lg: 6 }}>
        <TotalGcrCard />
      </Grid>

      <Grid size={{ xs: 12, md: 6, lg: 12 }} container spacing={1}>
        <MainCard>
          <Grid container spacing={gridSpacing}>
            <Grid size={12}>
              <Grid
                container
                sx={{
                  alignContent: "center",
                  justifyContent: "space-between",
                }}
              >
                <Grid>
                  <Typography variant="h4">Property Workspaces</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid size={12}>
              <Box
                sx={{ width: "100%", display: "table", tableLayout: "fixed" }}
              >
                <TableContainer>
                  <Table stickyHeader sx={{ whiteSpace: "nowrap" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Address</TableCell>
                        <TableCell>Seller/s</TableCell>
                        <TableCell>Agent</TableCell>
                        <TableCell>Asking Price</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Offers</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {workspaces.map(workspace => (
                        <WorkspaceRow
                          key={workspace.workspace.id}
                          workspace={workspace.workspace}
                          workflow={workspace.workflow}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
          </Grid>
        </MainCard>
      </Grid>
    </Grid>
  )
}

export default Dashboard
