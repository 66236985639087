import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../store"
import MenuItem from "./menu-item"
import dashboard from "./dashboard"
import other from "./other"
import settings from "./settings"
// Types
export interface IMenuState {
  openedItem: string
  menuItems: MenuItem[]
  openedComponent: string
  openedHorizontalItem: string | null
  isDashboardDrawerOpened: boolean
  isComponentDrawerOpened: boolean
}

// Initial state
const initialState: IMenuState = {
  openedItem: "dashboard",
  menuItems: [dashboard, other, settings],
  openedComponent: "buttons",
  openedHorizontalItem: null,
  isDashboardDrawerOpened: true,
  isComponentDrawerOpened: true,
}

// Slice
export const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    setDrawerOpen: (state, action: PayloadAction<boolean>) => {
      state.isDashboardDrawerOpened = action.payload
    },
    setActiveItem: (state, action: PayloadAction<string>) => {
      state.openedItem = action.payload
    },
    setComponentDrawerOpen: (state, action: PayloadAction<boolean>) => {
      state.isComponentDrawerOpened = action.payload
    },
    setOpenedComponent: (state, action: PayloadAction<string>) => {
      state.openedComponent = action.payload
    },
    setOpenedHorizontalItem: (state, action: PayloadAction<string | null>) => {
      state.openedHorizontalItem = action.payload
    },
  },
})

// Actions
export const {
  setDrawerOpen,
  setActiveItem,
  setComponentDrawerOpen,
  setOpenedComponent,
  setOpenedHorizontalItem,
} = menuSlice.actions

// Selectors
export const selectMenu = (state: RootState): IMenuState => state.menu
export const selectMenuItems = (state: RootState): MenuItem[] =>
  state.menu.menuItems
export const selectIsDrawerOpen = (state: RootState): boolean =>
  state.menu.isDashboardDrawerOpened
export const selectActiveItem = (state: RootState): string =>
  state.menu.openedItem
export const selectOpenedComponent = (state: RootState): string =>
  state.menu.openedComponent
export const selectIsComponentDrawerOpen = (state: RootState): boolean =>
  state.menu.isComponentDrawerOpened
export const selectOpenedHorizontalItem = (state: RootState): string | null =>
  state.menu.openedHorizontalItem

export default menuSlice.reducer
