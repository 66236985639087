import { FC, Fragment } from "react"
import {
  Grid,
  Typography,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  List,
  ListItem,
  ListItemText,
} from "@mui/material"
import { Notes } from "../../features/agent-appointment/components/form-wrapper"
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined"

interface SellerAndPropertyProps {
  onCommunitySchemeChange: (value: string) => void
}

const SellerAndProperty: FC<SellerAndPropertyProps> = ({
  onCommunitySchemeChange,
}) => {
  const notes = [
    {
      title: "Warning to Buyer",
      items: [
        "This statement contains important legal and other information about the property offered for sale...",
      ],
    },
  ]

  const statementItems = [
    "Flooding or other natural hazard history",
    "Structural soundness of the building or pest infestation",
    "Current or historical use of the property",
    "Current or past building or development approvals for the property",
    "Limits imposed by planning laws on the use of the land",
    "Services that are or may be connected to the property",
    "The presence of asbestos within buildings or improvements on the property",
  ]

  return (
    <Fragment>
      <Grid item>
        <Notes notes={notes} />
        <List sx={{ "& .MuiListItem-root": { padding: 0 } }}>
          <Typography variant="body1" sx={{ mb: 1 }}>
            This statement does not include information about:
          </Typography>
          {statementItems.map((item, index) => (
            <ListItem
              key={index}
              sx={{ display: "flex", alignItems: "center", gap: 1, padding: 0 }}
            >
              <KeyboardArrowRightOutlinedIcon fontSize="medium" />
              <ListItemText primary={item} />
            </ListItem>
          ))}
          <Typography variant="caption">
            You are encouraged to make your own inquiries...
          </Typography>
        </List>

        <form>
          <Grid container spacing={1} my={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Name</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Seller Name"
                size="small"
                fullWidth
                variant="filled"
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} my={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Address</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Street Address 1"
                size="small"
                fullWidth
                variant="filled"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Street Address 2"
                size="small"
                fullWidth
                variant="filled"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Lot on plan description"
                size="small"
                fullWidth
                variant="filled"
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} my={2}>
            <Grid item xs={12}>
              <Typography variant="body1">
                Is the property part of a community titles scheme or a BUGTA
                scheme?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <RadioGroup
                row
                onChange={e => onCommunitySchemeChange(e.target.value)}
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Fragment>
  )
}

export default SellerAndProperty
