import { useAppSelector } from "../../../app/hooks"
import Timeline from "../../timeline/timeline"
import Grid from "@mui/material/Grid2"
import WorkflowAction from "./workflow-action"
import WorkspaceHeader from "./workspace-header"
import { selectWorkflow } from "../../../app/slices/workspaces-slice"
import ParticipantsList from "./participants-list"
import WorkspaceDocuments from "./workspace-documents"
import { gridSpacing } from "../../../store/constant"
import MainCard from "../../../components/cards/MainCard"
const WorkspacePageLarge = () => {
  const workflow = useAppSelector(selectWorkflow)

  return (
    <Grid container spacing={gridSpacing}>
      <Grid size={12}>
        <WorkspaceHeader />
      </Grid>
      <Grid size={12}>
        {workflow === undefined ? <></> : <Timeline workflow={workflow} />}
      </Grid>
      <Grid size={9}>
        <WorkflowAction />
      </Grid>
      <Grid size={3}>
        <Grid container spacing={gridSpacing}>
          <Grid size={12}>
            <MainCard title="Participants">
              <ParticipantsList />
            </MainCard>
          </Grid>
          <Grid size={12}>
            <MainCard title="Documents">
              <WorkspaceDocuments />
            </MainCard>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default WorkspacePageLarge
