// material-ui
import { Box, SxProps } from "@mui/material"
import { useTheme } from "@mui/material/styles"

// project imports

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

interface ILogoProps {
  width?: number | string;
  sx?: SxProps;
}

// ==============================|| LOGO SVG ||============================== //

const Logo = ({ width = 196, sx }: ILogoProps) => {
  const theme = useTheme()

  return (
    <Box sx={sx}>
      <svg
        version="1.2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 500 98"
        width={width}
        style={{ display: 'block' }}
      >
        <title>landconnex-full-logo-svg</title>
        <defs>
          <linearGradient
            id="g1"
            x2="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="matrix(55.659,0,0,54.916,14.671,21.542)"
          >
            <stop offset="0" stopColor={theme.palette.info.dark} />
            <stop offset="1" stopColor={theme.palette.info.main} />
          </linearGradient>
        </defs>
        <style>
          {`.s0 { fill: url(#g1) }
      .s1 { fill: ${theme.palette.info.dark} }`}
        </style>
        <g id="SvgjsG1017">
          <g id="Page-1">
            <g id="Artboard">
              <g id="X-Symbols-08">
                <path
                  id="Shape"
                  className="s0"
                  d="m28.5 21.5l-13.8 13.7 11.5 11.4 4.1-4-7.5-7.4 5.7-5.6 19.7 19.4-3.2 3.2 4 4 7.3-7.2zm30.3 29.9l-4.1 4 7.5 7.4-5.7 5.6-19.6-19.4 3.2-3.2-4.1-4-7.3 7.1v0.1l27.8 27.4 13.8-13.6zm-30.2 17l-5.7-5.6 7.5-7.4-4.1-4.1-11.5 11.5 13.8 13.6 11.5-11.4-4-4zm41.8-33.3l-13.8-13.6-11.5 11.4 4 4.1 7.5-7.5 5.7 5.6-7.5 7.5 4.1 4z"
                />  
              </g>
            </g>
          </g>
        </g>
        <g id="SvgjsG1018">
          <path
            fillRule="evenodd"
            className="s1"
            d="m89.5 68.7v-41.3h9.3v32.7h19.2v8.6zm61.2 0l-2.4-7.1h-16.2l-2.3 7.1h-9.6l14.6-41.3h11.4l14.6 41.3zm-5.2-15.4l-5.3-16.2-5.4 16.2zm43.7 15.4l-16.6-26.2v26.2h-9v-41.3h10.2l16.6 26.2v-26.2h9v41.3zm15.3 0v-41.3h16.9c12.7 0 21.1 9 21.1 20.4 0 11.7-8.7 20.9-21.6 20.9zm16.4-32.8h-7v24.2h7.3c6.7 0 11.9-5.2 11.9-12.2 0-6.8-5.2-12-12.2-12zm46.6-0.7c-7 0-12.4 5.6-12.4 12.7 0 7.3 5.4 12.9 12.7 12.9 5 0 8.9-2.7 11.2-6.7l7.3 5.1c-3.9 6.5-10 10.3-18.4 10.3-12.8 0-22.3-9.4-22.3-21.6 0-12 9.5-21.4 22-21.4 8.2 0 14.6 3.4 18.6 10.3l-7.5 5.2c-2.4-4-6-6.8-11.2-6.8zm43.4 34.3c-12.1 0-21.9-9.6-21.9-21.5 0-11.9 9.8-21.5 21.9-21.5 12.1 0 21.9 9.6 21.9 21.5 0 11.9-9.8 21.5-21.9 21.5zm12.4-21.5c0-7.1-5.6-12.8-12.4-12.8-6.8 0-12.4 5.7-12.4 12.8 0 7.1 5.6 12.8 12.4 12.8 6.8 0 12.4-5.7 12.4-12.8zm39.2 20.7l-16.6-26.2v26.2h-9v-41.3h10.2l16.7 26.2v-26.2h8.9v41.3zm41 0l-16.7-26.2v26.2h-9v-41.3h10.2l16.7 26.2v-26.2h9v41.3zm15.2 0v-41.3h30.3v8.4h-20.9v7.8h19.3v8.2h-19.3v8.4h20.9v8.5zm59.8 0l-8.7-13.7-8.5 13.7h-10.4l13.9-20.8-13.5-20.5h10.9l8.3 13.2 8.2-13.2h10.5l-13.6 20.3 13.8 21z"
          />
        </g>
      </svg>
    </Box>
  )
}

export default Logo
