import "./App.css"
import { Provider } from "react-redux"
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom"
import { store } from "./app/store"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import "dayjs/locale/en-au"
import WorkspacePage from "./features/workspace/workspace-page"
import Login from "./features/login"
import AuthCallback from "./features/auth-callback/auth-callback"
import SilentCallback from "./features/auth/silent-callback"
import CreateWorkspacePage from "./features/create-workspace/create-workspace-page"
import WorkspacesPage from "./features/workspaces/workspaces-page"
import ViewInvoice from "./features/view-invoice/view-invoice"
import AcceptInvitation from "./features/accept-invitation/accept-invitation"
import ViewDocument from "./features/view-document/view-document"
// import { loadStripe } from "@stripe/stripe-js"
import Questionnaire from "./features/questionnaire"
import OpenHouse from "./features/open-house/open-house"
import OnboardingPage from "./features/customer-onboarding/on-boarding-page"
import OnBoardingComplete from "./features/customer-onboarding/on-boarding-complete"
import Dashboard from "./features/dashboard/dashboard"
import OfferPage from "./features/offer/offer-page"
import Test2 from "./test2"
import { selectIsDashboardViewer, selectIsTeamAdministrator } from "./app/slices/session-slice"
import { selectTeam } from "./app/slices/team-slice"
import AcceptTeamInvitation from "./features/accept-team-invitation/accept-team-invitation"
import CompletePayment from "./features/settings/components/complete-payment"
import MyWorkspaces from "./features/workspaces/my-workspaces"
import MainLayout from "./layout/main-layout"
import ThemeCustomization from "./themes"
import MyDetails from "./features/settings/components/my-details"
import SettingsPage from "./features/settings/settings-page"
import LandingPage from "./features/landing/landing-page"
const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <MainLayout />,
      children: [
        {
          path: "/",
          element: <LandingPage />,
        },
        {
          path: "/create-workspace",
          element: <CreateWorkspacePage />,
    
          id: "create-workspaces",
        },
        {
          path: "/workspaces",
          element: <WorkspacesPage />,

          id: "workspaces",
        },
        {
          path: "/workspaces/:workspaceId",
          element: <WorkspacePage />,
          id: "view-workspace",
        },
        {
          path: "/dashboard",
          element: <Dashboard />,
          loader: () => {
            const state = store.getState()
            const isDashboardViewer = selectIsDashboardViewer(state)
            if (!isDashboardViewer) {
              throw new Error("Unauthorized")
            }
            return null
          },
          errorElement: <Navigate to="/workspaces" />,
        },
        {
          path: "/settings",
          element: <MyDetails />,
          id: "settings",
        },
        {
          path: "/settings/team",
          element: <SettingsPage />,
          id: "team",
        },
        {
          path: "/workspaces/:workspaceId/offers/:offerId",
          element: <OfferPage />,
          id: "view-offer",
        },
        {
          path: "/workspaces/:workspaceId/document/:documentName",
          element: <ViewDocument />,
          id: "view-document",
        },
        {
          path: "/auth-callback",
          element: <AuthCallback />,
        },
      ],
    },
    { path: "/test2", element: <Test2 /> },
    { path: "login", element: <Login /> },
   
    {
      path: "/dashboard-old",
      element: <Dashboard />,
      loader: () => {
        const state = store.getState()
        const isTeamAdmin = selectIsTeamAdministrator(state)
        if (!isTeamAdmin) {
          throw new Error("Unauthorized")
        }
        return null
      },
      errorElement: <Navigate to="/workspaces" />,
    },
    { path: "/questionnaire", element: <Questionnaire /> },

   
    {
      path: "/silent-callback",
      element: <SilentCallback />,
    },
    // {
    //   path: "/subscriptions",
    //   element: <CreateSubscription stripePromise={stripePromise!} />,
    // },
    // {
    //   path: "/subscriptions/complete",
    //   element: <CreateSubscriptionSuccess />,
    // },
    // {
    //   path: "/",
    //   element: <LandingPage />,
    //   id: "landingPage",
    // },
    {
      path: "on-boarding",
      element: <OnboardingPage />,
      loader: () => {
        const state = store.getState()
        const team = selectTeam(state)
        if (team != null) {
          throw new Error("Cannot ob-board if already in a team")
        }
        return null
      },
      errorElement: <Navigate to="/workspaces" />,
      // children: [
      //   {
      //     path: "",
      //     element: <Navigate to={"payment-details"} />,
      //   },
      //   {
      //     path: "payment-details",
      //     id: "payment-details",
      //     element: <PaymentDetails />,
      //   },
      // ],
    },
    {
      path: "on-boarding-complete",
      element: <OnBoardingComplete />,
    },

    {
      path: "/my-workspaces",
      element: <MyWorkspaces />,

      id: "my-workspaces",
    },

    {
      path: "open-house/:workspaceId",
      element: <OpenHouse />,
    },
    {
      path: "/workspaces/:workspaceId/invoice",
      element: <ViewInvoice />,
    },
    {
      path: "/workspaces-old/:workspaceId",
      element: <WorkspacePage />,
      id: "view-workspace-old",
    },
    
    {
      path: "/accept-invitation/:invitation",
      element: <AcceptInvitation />,
      id: "accept-invitation",
    },
    {
      path: "/accept-team-invitation/:invitationId",
      element: <AcceptTeamInvitation />,
      id: "accept-team-invitation",
    },

    {
      path: "/settings/setup-intent/:setupIntentId",
      element: <CompletePayment />,
      id: "setup-intent",
    },
  ],
  // {
  //   future: {
  //     v7_normalizeFormMethod: true,
  //   },
  // },
)

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-au">
      <Provider store={store}>
        <ThemeCustomization>
          <RouterProvider router={router} />
        </ThemeCustomization>
      </Provider>
    </LocalizationProvider>
  )
}

export default App
