import {
  CardHeader,
  Card,
  FormControlLabel,
  Radio,
  RadioGroup,
  CardContent,
  Stack,
  debounce,
  Box,
} from "@mui/material"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { FC, useEffect } from "react"
import {
  AgentAppointment,
  AgentAppointmentCommissionPaymentTermEnum,
  AgentAppointmentCommissionTypeEnum,
} from "../../../landconnex-api-client"
import { useForm, Controller } from "react-hook-form"
import FormInputText from "../../../components/form-input-text"
import { Notes } from "./form-wrapper"
import { useParams } from "react-router-dom"
import { useAppSelector, useAppDispatch } from "../../../app/hooks"
import {
  selectAgentAppointment,
  updateAgentAppointment,
} from "../../../app/slices/agent-appointment-slice"
import { zodResolver } from "@hookform/resolvers/zod"
import React from "react"
import FormInputNumeric from "../../../components/form-input-numeric"
import FormInputPercentage from "../../../components/form-input-percentage"
import { agentAppointmentSchema } from "../../../utils/validation-schemas"

const Commission: FC = () => {
  const agentAppointment = useAppSelector(selectAgentAppointment)
  const params = useParams()
  const dispatch = useAppDispatch()
  const workspaceId = Number(params.workspaceId)
  const schema = agentAppointmentSchema

  const agentAppointmentForm = useForm<AgentAppointment>({
    defaultValues: agentAppointment,
    resolver: zodResolver(schema),
    mode: "onChange",
  })

  const {
    control,
    watch,
    formState: { errors, isDirty },
    reset,
  } = agentAppointmentForm

  const formValues = watch()
  const selectedPaymentTerm = watch("commissionPaymentTerm")

  const debouncedSave = React.useCallback(
    debounce(async (data: AgentAppointment) => {
      dispatch(
        updateAgentAppointment({
          workspaceId: workspaceId,
          agentAppointment: data,
        }),
      )
      reset(data, { keepErrors: true })
    }, 1000),
    [dispatch, workspaceId],
  )

  useEffect(() => {
    if (isDirty) {
      debouncedSave(formValues)
    }
  }, [formValues, isDirty])

  const notes = [
    {
      title: "To the client:",
      items: [
        "The commission is negotiable. It must be written as a percentage or dollar amount.",
        "Make sure you understand when commission is payable. If you choose ‘Other’ and the contract does not settle, the agent may still seek commission.",
      ],
    },
    {
      title: "To the agent:",
      items: [
        "You should ensure that commission is clearly expressed and the client fully understands the likely amount and when it is payable.",
        "Refer to section 104 and 105 of the Property Occupations Act 2014",
      ],
    },
  ]

  return (
    <form>
      <Card sx={{ border: "none" }}>
        <CardHeader title="Commission" inheritlayout="true" />
        <CardContent>
          <Notes notes={notes}></Notes>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1">
                The client and the agent agree that the commission including GST
                payable for the service to be performed by the agent is:
              </Typography>
            </Grid>
            <Grid item xs={3}>
              {agentAppointment?.commissionType ===
              AgentAppointmentCommissionTypeEnum.Fixed ? (
                <FormInputNumeric
                  control={control}
                  name="commissionAmount"
                  label="Commission Amount"
                  required
                  error={errors.commissionAmount}
                />
              ) : (
                <FormInputPercentage
                  control={control}
                  name="commissionAmount"
                  label="Commission Percentage"
                  required
                  error={errors.commissionAmount}
                />
                // <Controller
                //   control={control}
                //   name="commissionAmount"
                //   render={({ field }) => (
                //     <NumericFormat
                //       {...field}
                //       customInput={TextField}
                //       value={field.value}
                //       variant="filled"
                //       suffix="%"
                //       size="small"
                //       fixedDecimalScale={true}
                //       allowNegative={false}
                //       error={!!errors.commissionAmount}
                //       helperText={errors.commissionAmount?.message}
                //       aria-invalid={errors.commissionAmount ? true : false}
                //       label="Commission Percentage"
                //     />
                //   )}
                // />
              )}
            </Grid>
            <Grid item xs={9}>
              <Controller
                control={control}
                name="commissionType"
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <Stack direction="row" spacing={1}>
                      <FormControlLabel
                        value={AgentAppointmentCommissionTypeEnum.Fixed}
                        control={<Radio />}
                        label="Fixed"
                      />
                      <FormControlLabel
                        value={AgentAppointmentCommissionTypeEnum.Percentage}
                        control={<Radio />}
                        label="Percentage"
                      />
                    </Stack>
                  </RadioGroup>
                )}
              ></Controller>
            </Grid>

            <Grid item xs={12}>
              <Typography
                className="roboto-bold"
                sx={{ color: "#c20029", fontSize: "18px" }}
              >
                When commission is payable
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="commissionPaymentTerm"
                render={({ field }) => (
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    {...field}
                  >
                    <FormControlLabel
                      value={
                        AgentAppointmentCommissionPaymentTermEnum.Settlement
                      }
                      control={<Radio />}
                      label="For sales, including auctions, commission is payable if a contract is entered into and settlement of the contract occurs."
                    />
                    <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                      <FormControlLabel
                        value={AgentAppointmentCommissionPaymentTermEnum.Other}
                        control={<Radio />}
                        label="Other:"
                      />
                      {selectedPaymentTerm ===
                        AgentAppointmentCommissionPaymentTermEnum.Other && (
                        <FormInputText
                          control={control}
                          name="commissionPaymentTermInstructions"
                          label="Other Commission Terms"
                          minRows={5}
                          multiline
                        />
                      )}
                    </Box>
                  </RadioGroup>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography mb={1}>
                Your agent may either complete this section or attach annexures
                of commission amounts. In either case, a description must be
                written here.
              </Typography>
              <FormInputText
                control={control}
                label="for all other types of appointments"
                name="commissionPaymentTermOtherAppointments"
                minRows={5}
                multiline
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </form>
  )
}

export default Commission
