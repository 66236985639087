import {
  IconButton,
  ListItem,
  ListItemText,
  Theme,
  SxProps,
  Typography,
  Stack,
  Box,
} from "@mui/material"

import { Participant } from "../landconnex-api-client"
import PhoneIcon from "@mui/icons-material/Phone"
import EmailIcon from "@mui/icons-material/Email"
import { Fragment } from "react"
import { fullName } from "../helpers/helpers"
import RoleChip from "./role-chip"

export interface ParticipantListItemProps {
  participant: Participant
  sx?: SxProps<Theme>
}
const ParticipantListItem: React.FC<ParticipantListItemProps> = ({
  participant,
  sx,
}) => {
  return (
    <ListItem
      sx={{
        pl: 1,
        py: 2,
        ...sx,
      }}
      key={participant.id}
      // secondaryAction={
      //   participant.firstName &&
      //   participant.lastName && (
      //     <Fragment>
      //       <RoleChip role={participant.role!} />
      //       <IconButton
      //         onClick={() => {
      //           window.open(`tel:${participant.phone}`, "_blank")
      //         }}
      //       >
      //         <PhoneIcon />
      //       </IconButton>
      //       <IconButton
      //         onClick={() => {
      //           window.open(`mailto:${participant.email}`, "_blank")
      //         }}
      //       >
      //         <EmailIcon />
      //       </IconButton>
      //     </Fragment>
      //   )
      // }
    >
      <ListItemText
        primary={<Fragment>
            <Typography component="span" variant="h5">
              {fullName(participant)}
            </Typography>
            
          </Fragment>
        }
        secondary={
          <Stack
            component="span"
            direction="column"
            gap={0}
            sx={{ wrap: "nowrap" }}
          >
            <Typography component="span" variant="body1">
              {participant.phone}
            </Typography>
            <Typography component="span" variant="body1">
              {participant.email}
            </Typography>
          </Stack>
        }
        sx={{ flexWrap: "wrap" }}
      />
      {participant.firstName && participant.lastName && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <RoleChip role={participant.role!} />
          <Box sx={{ alignSelf: "center" }}>
            <IconButton
              onClick={() => {
                window.open(`tel:${participant.phone}`, "_blank")
              }}
            >
              <PhoneIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                window.open(`mailto:${participant.email}`, "_blank")
              }}
            >
              <EmailIcon />
            </IconButton>
          </Box>
        </Box>
      )}
    </ListItem>
  )
}

// function getRoleDescription(role: WorkspaceRoleEnum): string {
//   switch (role) {
//     case WorkspaceRoleEnum.Seller:
//       return "Seller"
//     case WorkspaceRoleEnum.SellerAgent:
//       return "Seller Agent"
//     case WorkspaceRoleEnum.SellerSolicitor:
//       return "Seller Solicitor"
//     default:
//       return "Participant"
//   }
// }

export default ParticipantListItem
