import { memo, useMemo } from "react"

import useMediaQuery from "@mui/material/useMediaQuery"
import Chip from "@mui/material/Chip"
import Drawer from "@mui/material/Drawer"
import Stack from "@mui/material/Stack"
import Box from "@mui/material/Box"

// third party
import PerfectScrollbar from "react-perfect-scrollbar"

// project imports
import MenuList from "../MenuList"
import LogoSection from "../logo-section"

import { drawerWidth } from "../../constants"
import MiniDrawerStyled from "./MiniDrawerStyled"
import { useTheme } from "@mui/material/styles"
import { useMenu } from "../../app/features/menu/useMenu"
// import { handlerDrawerOpen, useGetMenuMaster } from 'api/menu';

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = () => {
  const theme = useTheme()
  const downMD = useMediaQuery(theme.breakpoints.down("md"))
  const { handleDrawerOpen, isDrawerOpen } = useMenu()

  // const { miniDrawer, mode } = useConfig();

  const logo = useMemo(
    () => (
      <Box sx={{ display: "flex", p: 2 }}>
        <LogoSection />
      </Box>
    ),
    [],
  )

  const drawer = useMemo(() => {
    const drawerContent = (
      <>
        {/* <MenuCard /> */}
        <Stack direction="row" sx={{ justifyContent: "center", mb: 2 }}>
          <Chip
            label={import.meta.env.VITE_APP_VERSION}
            size="small"
            color="default"
          />
        </Stack>
      </>
    )

    let drawerSX = {
      paddingLeft: "0px",
      paddingRight: "0px",
      marginTop: "20px",
    }
    if (isDrawerOpen)
      drawerSX = { paddingLeft: "16px", paddingRight: "16px", marginTop: "0px" }

    return (
      <>
        {downMD ? (
          <Box sx={drawerSX}>
            <MenuList />
            {isDrawerOpen && drawerContent}
          </Box>
        ) : (
          <PerfectScrollbar
            style={{ height: "calc(100vh - 88px)", ...drawerSX }}
          >
            <MenuList />
            {isDrawerOpen && drawerContent}
          </PerfectScrollbar>
        )}
      </>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downMD, isDrawerOpen])

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { md: 0 }, width: { xs: "auto", md: drawerWidth } }}
      aria-label="mailbox folders"
    >
      {downMD || isDrawerOpen ? (
        <Drawer
          variant={downMD ? "temporary" : "persistent"}
          anchor="left"
          open={isDrawerOpen}
          onClose={() => handleDrawerOpen(!isDrawerOpen)}
          sx={{
            "& .MuiDrawer-paper": {
              mt: downMD ? 0 : 11,
              zIndex: 1099,
              width: drawerWidth,
              bgcolor: "background.default",
              color: "text.primary",
              borderRight: "none",
            },
          }}
          ModalProps={{ keepMounted: true }}
          color="inherit"
        >
          {downMD && logo}
          {drawer}
        </Drawer>
      ) : (
        <MiniDrawerStyled variant="permanent" open={isDrawerOpen}>
          {logo}
          {drawer}
        </MiniDrawerStyled>
      )}
    </Box>
  )
}

export default memo(Sidebar)
