import React, { FC, useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import {
  Autocomplete,
  Card,
  CardContent,
  CardHeader,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
  debounce,
} from "@mui/material"
import AddressLabel from "../../../components/address-label"
import FormInputText from "../../../components/form-input-text"

import type { Property } from "../../../landconnex-api-client"
import { Notes } from "./form-wrapper"
import { useAppSelector, useAppDispatch } from "../../../app/hooks"

import { putProperty, selectProperty } from "../../../app/slices/property-slice"
import { selectWorkspace } from "../../../app/slices/workspaces-slice"
import { zodResolver } from "@hookform/resolvers/zod"
import { propertySchema } from "../../../utils/validation-schemas"
import FormInputSelect from "../../../components/form-input-select"

export type OnPropertyChanged = (property: Property) => void

const Property: FC = () => {
  const dispatch = useAppDispatch()
  const workspace = useAppSelector(selectWorkspace)
  const property = useAppSelector(selectProperty)

  const schema = propertySchema

  const propertyFormMethods = useForm<Property>({
    defaultValues: property!,
    resolver: zodResolver(schema),
    mode: "onChange",
  })

  const {
    control,
    watch,
    reset,
    formState: { isDirty, errors },
  } = propertyFormMethods

  const formValues = watch()

  const debouncedSave = React.useCallback(
    debounce(async (data: Property) => {
      dispatch(
        putProperty({
          workspaceId: workspace!.workspace!.id,
          property: data,
        }),
      )
      reset(data, { keepErrors: true })
    }, 1000),
    [dispatch, workspace!.workspace!.id],
  )

  const presentUseOptions = ["Vacant land", "Residential", "Farming", "Other"]
  useEffect(() => {
    if (isDirty) {
      debouncedSave(formValues)
    }
  }, [formValues, isDirty])

  const notes = [
    {
      title: "Note:",
      items: [
        "Please provide details of the property, land, or business as appropriate.",
        "Annexures detailing multiple properties may be attached if required.",
      ],
    },
  ]
  const planTypeOptions = [
    {
      label: "Building Unit Plan of Amalgamation",
      value: "BPA",
    },
    {
      label: "Building Unit Plan of Resubdivision",
      value: "BRP",
    },
    {
      label: "Building Unit Plan",
      value: "BUP",
    },
    {
      label: "Crown Plan",
      value: "CP",
    },
    {
      label: "Group Title Plan of Resubdivision",
      value: "GRP",
    },
    {
      label: "Group Title Plan of Amalgamation",
      value: "GTA",
    },
    {
      label: "Group Titles Plan",
      value: "GTP",
    },
    {
      label: "Lower Building Units Plan",
      value: "LB",
    },
    {
      label: "Registered Plan",
      value: "RP",
    },
    {
      label: "Leasehold Building Units Plan (South Bank)",
      value: "SBA",
    },

    {
      label: "Leasehold Building Units Plan (South Bank)",
      value: "SBP",
    },
    {
      label: "Survey Plan",
      value: "SP",
    },
    {
      label: "Stratum Plan of Amalgamation (South Bank)",
      value: "SPA",
    },
    {
      label: "Stratum Plan of Subdivision (South Bank)",
      value: "SPS",
    },
    {
      label: "Leasehold Building Units Plan (South Bank)",
      value: "SRP",
    },
    {
      label: "Leasehold Building Units Plan (South Bank)",
      value: "SRP",
    },

    {
      label: "Stratum Plan (South Bank)",
      value: "SSP",
    },
    {
      label: "Upper Building Units Plan",
      value: "UB",
    },
  ]
  return (
    <Card sx={{ border: "none" }}>
      <CardHeader
        title="Details of property or business that is to be sold, let, purchased, or managed"
        inheritlayout="true"
      />
      <CardContent>
        <Notes notes={notes} />
        <form>
          <Paper>
            <AddressLabel property={property!} />
          </Paper>

          <Typography variant="h6" my={2}>
            Description
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={6} sm={2}>
              <FormInputText
                control={control}
                name="lot"
                label="Lot"
                required
                error={errors.lot}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <FormInputSelect
                control={control}
                name="planType"
                label="Plan Type"
                
                
              >
                {planTypeOptions.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    ({option.value}) {option.label}
                  </MenuItem>
                ))}
              </FormInputSelect>
              {/* <Controller
                name="planType"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    freeSolo
                    options={planTypeOptions}
                    onChange={(_, value) => {
                      if (typeof value === "string") {
                        field.onChange(value)
                      } else {
                        field.onChange(value!.value)
                      }
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>
                        ({option.value}) {option.label}
                      </li>
                    )}
                    renderInput={params => (
                      <TextField
                        {...params}
                        size="small"
                        variant="filled"
                        label="Plan Type"
                        value={field.value}
                      />
                    )}
                  />
                )}
              /> */}
              {/* <FormInputText
                control={control}
                name="planType"
                label="Plan Type"
                required
                error={errors.planType}
              /> */}
            </Grid>
            <Grid item xs={6} sm={2}>
              <FormInputText
                control={control}
                name="plan"
                label="Plan"
                required
                error={errors.plan}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormInputText
                control={control}
                name="titleReference"
                label="Title Reference"
                required
                error={errors.titleReference}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              
              <Controller
                name="presentUse"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    freeSolo
                    options={presentUseOptions}
                    onChange={(_, value) => field.onChange(value)}
                    renderInput={params => (
                      <TextField
                        {...params}
                        size="small"
                        
                        label="Present Use"
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  )
}

export default Property
