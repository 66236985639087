import { TableCell, TableRow, useTheme } from "@mui/material"
import { FC } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useAppDispatch } from "../../app/hooks"
import { getDocuments } from "../../app/slices/documents-slice"
import { getParticipants } from "../../app/slices/participants-slice"
import { getProperty } from "../../app/slices/property-slice"
import { getWorkspace, getWorkflow, WorkspaceDetails } from "../../app/slices/workspaces-slice"
import { singleLineFullAddress, fullName, formatCurrency } from "../../helpers/helpers"

import StatusIndicator from "../status-indicator"

const WorkspaceRow: FC<WorkspaceDetails> = ({ workspace }) => {
    const dispatch = useAppDispatch()
    const theme = useTheme()
    const navigate = useNavigate()
    return (
      <TableRow
        hover
        onClick={() => {
          dispatch(getWorkspace(workspace.id))
          dispatch(getDocuments(workspace.id))
          dispatch(getParticipants(workspace.id))
          dispatch(getWorkflow(workspace.id))
          dispatch(getProperty(workspace.id))
          navigate(`/workspaces/${workspace.id}`)
        }}
        sx={{ cursor: "pointer" }}
      >
        <TableCell>
          <Link
            to={`/workspaces/${workspace.id}`}
            style={{ textDecoration: "underline", color: theme.palette.info.main }}
          >
            {singleLineFullAddress(workspace.property)}
          </Link>
        </TableCell>
        <TableCell>
          {workspace.sellers.map(seller => fullName(seller)).join(", ")}
        </TableCell>
        <TableCell>{fullName(workspace.agent)}</TableCell>
        <TableCell>{formatCurrency(workspace.price)}</TableCell>
        <TableCell>
          <StatusIndicator
            subState={workspace.state}
          />
        </TableCell>
        <TableCell>{workspace.offers?.length ?? 0}</TableCell>
      </TableRow>
    )
  }

export default WorkspaceRow
