import React, { useEffect } from "react"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Radio from "@mui/material/Radio"
import { Controller, useForm } from "react-hook-form"
import { Contract } from "../../../landconnex-api-client"
import { Card, CardContent, CardHeader, debounce } from "@mui/material"
import { useAppSelector, useAppDispatch } from "../../../app/hooks"
import { selectWorkspace } from "../../../app/slices/workspaces-slice"
import { putContract, selectContract } from "../../../app/slices/contract-slice"

const GstWithholding: React.FC = () => {
  const dispatch = useAppDispatch()
  const contract = useAppSelector(selectContract)
  const workspace = useAppSelector(selectWorkspace)

  const formMethods = useForm<Contract>({
    defaultValues: contract,
    mode: "onChange",
  })

  const {
    control,
    watch,
    reset,
    formState: { isDirty },
  } = formMethods

  const formValues = watch()

  const debouncedSave = React.useCallback(
    debounce(async (data: Contract) => {
      dispatch(
        putContract({
          workspaceId: workspace!.workspace!.id,
          contract: data,
        }),
      )
      reset(data, { keepErrors: true })
    }, 1000),
    [dispatch, workspace!.workspace!.id],
  )

  useEffect(() => {
    if (isDirty) {
      debouncedSave(formValues)
    }
  }, [formValues, isDirty])

  return (
    <Card sx={{ border: "none" }}>
      <CardHeader title="GST Withholding" inheritlayout="true" />
      <CardContent>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">
                The Seller gives notice to the Buyer in accordance with section
                14-255(1)(a) of the Withholding Law that:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="gstPaymentRequired"
                control={control}
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    defaultValue={field.value ? "true" : "false"}
                    onChange={({ target }) =>
                      field.onChange(target.value === "true")
                    }
                  >
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="The Buyer is not required to make a payment under section 14-250 of the Withholding Law in relation to the supply of the Property."
                    />
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="The Buyer is required to make a payment under section 14-250 of the Withholding Law in relation to the supply of the Property. Under section 14-255(1) of the Withholding Law, the Seller is required to give further details prior to settlement."
                    />
                  </RadioGroup>
                )}
              />
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  )
}

export default GstWithholding
