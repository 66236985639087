import { FC, Fragment } from "react";

import { Controller, FieldError } from "react-hook-form";
import { Checkbox, FormControlLabel, FormHelperText } from "@mui/material";
import { FormInputProps } from "./events";


export interface FormInputCheckboxProps extends FormInputProps {
  error?: FieldError
}
const FormInputCheckbox: FC<FormInputCheckboxProps> = ({
  name,
  label,
  control,
  error,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      
      render={({ field }) => (
        <Fragment>
          <FormControlLabel
            control={
              <Checkbox
              checked={field.value ? field.value : false}
              onChange={field.onChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label={label}
          />
          {error && <FormHelperText error>{error.message}</FormHelperText>}
        </Fragment>
      )}
    />
  )
}

export default FormInputCheckbox;
