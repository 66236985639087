import { FC, useEffect } from "react"
import {
  Typography,
  useTheme,
  Card,
  CardContent,
  CardHeader,
  Paper,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material"
import { Controller, useForm } from "react-hook-form"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { useParams } from "react-router-dom"
import {
  getSchedule,
  selectSchedule,
  updateSchedule,
} from "../../../app/slices/schedule-slice"
import { Schedule, ScheduleF1Enum, ScheduleG1Enum } from "../../../landconnex-api-client"
import FormInputCheckbox from "../../../components/form-input-checkbox"

const ScheduleForm: FC = () => {
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const params = useParams()
  const workspaceId = Number(params.workspaceId)
  const schedule = useAppSelector(selectSchedule)

  const {
    control,
    reset,
    formState: { isDirty },
    watch,
  } = useForm<Schedule>({
    defaultValues: schedule || {
      a1: false,
      b1: false,
      b2: false,
      c1: false,
      c2: false,
      d1: false,
      e1: false,
      e2: false,
      f1: ScheduleF1Enum.NoAdvice,
      g1: ScheduleG1Enum.Authorises,
      h1: false,
    },
  })

  const formValues = watch()

  useEffect(() => {
    if (isDirty) {
      dispatch(updateSchedule({ workspaceId, schedule: formValues }))
      reset(formValues)
    }
  }, [formValues, isDirty])

  useEffect(() => {
    dispatch(getSchedule(workspaceId))
  }, [workspaceId, dispatch])

  const SectionTitle: FC<{ children: React.ReactNode }> = ({ children }) => (
    <Typography
      variant="h6"
      color={theme.palette.primary.main}
      sx={{
        fontWeight: 600,
        mb: 2,
        mt: 1,
      }}
    >
      {children}
    </Typography>
  )

  const FormSection: FC<{ children: React.ReactNode }> = ({ children }) => (
    <Paper
      elevation={0}
      sx={{
        p: 3,
        mb: 3,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 2,
      }}
    >
      {children}
    </Paper>
  )

  return (
    <Card component="form">
      <CardHeader title="Schedule" inheritlayout="true" />
      <CardContent
        sx={{
          p: 4,
          "& .MuiFormControlLabel-root": {
            ml: 0,
            my: 1,
            alignItems: "flex-start",
          },
          "& .MuiCheckbox-root": {
            pt: 0,
          },
        }}
      >
        <Typography
          variant="body1"
          color={theme.palette.text.secondary}
          sx={{ mb: 4, fontStyle: "italic" }}
        >
          This Schedule forms an integral part of the Agency Appointment
          Agreement (the "Agreement") entered into between the Agent and the
          Seller, for the purpose of disclosing certain information to the other
          party that are supplementary to those terms set forth in the
          Agreement.
        </Typography>

        <Typography
          variant="body1"
          color={theme.palette.text.secondary}
          sx={{ mb: 4, fontStyle: "italic" }}
        >
          Please tick all the statements that apply to this agreement. The
          schedule is added as a standard annexure to the Agent Appointment
          Agreement.
        </Typography>
        <FormSection>
          <SectionTitle>
            A. Conflict of Duty or Interest Regulation 18
          </SectionTitle>
          <FormInputCheckbox
            label="The Agent discloses that, in accepting this appointment, that the Agent's duty or interests do not conflict with the Client's interests."
            name="a1"
            control={control}
          />
        </FormSection>

        <FormSection>
          <SectionTitle>
            B. Verification of Ownership Regulation 19
          </SectionTitle>
          <FormInputCheckbox
            label="The Client represents and warrants that they are the registered owner of the Property listed in this Appointment."
            name="b1"
            control={control}
          />
          <FormInputCheckbox
            label="The Agent confirms that they have verified the identity of the owner, ensuring that both the owner's name and the legal description of the Property listed in this Appointment corresponds with the information recorded on the title's registry."
            name="b2"
            control={control}
          />
        </FormSection>

        <FormSection>
          <SectionTitle>
            C. Verification of Facts Material to the Sale of Property Regulation
            20
          </SectionTitle>
          <FormInputCheckbox
            label="The Client represents and warrants that to the best of their knowledge, they have disclosed to the Agent all facts material to the Sale of the Property."
            name="c1"
            control={control}
          />
          <FormInputCheckbox
            label="The Client represents and warrants that they will retain the services of a Solicitor to investigate and compile a list of facts material to the sale of the Property and hereby directs their solicitor to disclose those facts to the Agent, in writing, prior to the listing going live on the market."
            name="c2"
            control={control}
          />
        </FormSection>

        <FormSection>
          <SectionTitle>
            D. Schedule of Fees and Charges Regulation 21
          </SectionTitle>
          <FormInputCheckbox
            label="The Client confirms that it has not retained an Agent, prior to this appointment that is currently in force, to sell the Property."
            name="d1"
            control={control}
          />
        </FormSection>

        <FormSection>
          <SectionTitle>
            E. Agent to act in accordance with Clients instructions Regulation
            22
          </SectionTitle>
          <FormInputCheckbox
            label="The Client represents and warrants that they have reviewed the contents of this Appointment and acknowledges that its contents correctly reflect the entirety of the instructions provided to the Agent."
            name="e1"
            control={control}
          />
          <FormInputCheckbox
            label="The Client commits to providing written instructions to the Agent in the event of any changes at any point throughout the duration of the Appointment."
            name="e2"
            control={control}
          />
        </FormSection>

        <FormSection>
          <SectionTitle>
            F. Representation of Price of Property Section 215
          </SectionTitle>
          <Typography
            variant="body1"
            sx={{
              mb: 2,
              color: theme.palette.text.secondary,
              fontWeight: 500,
            }}
          >
            Has the Client requested pricing information from the agent about
            the potential sale price of the residential property, regardless of
            whether it will be sold at auction?
          </Typography>
          {/* <FormInputCheckbox
            label="No - the Client understands that they will not get any advice from the Agent about the market value of the Property unless they later give the Agent written instructions to do so."
            name="f1"
            control={control}
          />
          <FormInputCheckbox 
            label="Yes" 
            name="f2" 
            control={control} 
          /> */}

          <Controller
            control={control}
            name="f1"
            render={({ field }) => (
              <RadioGroup
                {...field}
                aria-labelledby="pricing-information-options"
                name="pricingInformationOption"
              >
                <FormControlLabel
                  value={ScheduleF1Enum.NoAdvice}
                  control={<Radio />}
                  label="No - the Client understands that they will not get any advice from the Agent about the market value of the Property unless they later give the Agent written instructions to do so."
                />
                <FormControlLabel
                  value={ScheduleF1Enum.NotProvided}
                  control={<Radio />}
                  label="Yes - The Client has asked the Agent for a comparative market analysis (CMA)  and the agent has decided NOT to provide one to the Client; or"
                />
                <FormControlLabel
                  value={ScheduleF1Enum.Provided}
                  control={<Radio />}
                  label="Yes - The Client has received a copy of a comparative market analysis (CMA) or a written explanation detailing how the real estate agent determined the property’s market value (Market Information)."
                />
              </RadioGroup>
            )}
          />
        </FormSection>

        <FormSection>
          <SectionTitle>
            G. Not to disclose Reserve or other Price Section 216
          </SectionTitle>
          <Typography
            variant="body1"
            sx={{
              mb: 2,
              color: theme.palette.text.secondary,
              fontWeight: 500,
            }}
          >
            If the Property is sold by auction or marketed without a price, the
            Agent is prohibited from providing a price guide or CMA to a Buyer.
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            If the Property is being marketed with a price, the Client:
          </Typography>

          <Controller
            control={control}
            name="g1"
            render={({ field }) => (
              <RadioGroup {...field} aria-labelledby="g" name="g">
                <FormControlLabel
                  value={ScheduleG1Enum.Authorises}
                  control={<Radio />}
                  label="authorises the Agent to provide the Buyer with the Market Information; or"
                />
                <FormControlLabel
                  value={ScheduleG1Enum.DoesNotAuthorise}
                  control={<Radio />}
                  label="does not authorise the Agent to provide the Buyer with the Market Information."
                />
              </RadioGroup>
            )}
          />
        </FormSection>

        <FormSection>
          <SectionTitle>H. Consent to Privacy Notice</SectionTitle>
          <FormInputCheckbox
            label="The Client acknowledges that they have, if needed, received and completed a Privacy Notice and Consent from the Agent, either attached to this Appointment or available on the Agent's website."
            name="h1"
            control={control}
          />
        </FormSection>
      </CardContent>
    </Card>
  )
}

export default ScheduleForm
