import Grid from "@mui/material/Grid"
import React, { useEffect } from "react"
import Typography from "@mui/material/Typography"
import {
  Participant,
  ParticipantInvitationStatusEnum,
  ParticipantParticipantTypeEnum,
  ParticipantRoleEnum,
} from "../../../landconnex-api-client"
import {
  Card,
  CardContent,
  CardHeader,
  debounce,
  useTheme,
} from "@mui/material"
import { useForm } from "react-hook-form"
import ParticipantNameForm from "../../../components/participant-name-form"
import FormInputText from "../../../components/form-input-text"
import ContactDetails from "../../../components/contact-details"
import AddressForm from "../../../components/address-form"
import { useAppSelector, useAppDispatch } from "../../../app/hooks"
import {
  putOfferParticipant,
  selectOffer,
} from "../../../app/slices/offers-slice"
import { selectWorkspace } from "../../../app/slices/workspaces-slice"
import { selectParticipants } from "../../../app/slices/participants-slice"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"
import { Properties } from "../../../helpers/helpers"

const BuyersSolicitor: React.FC = () => {
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const workspace = useAppSelector(selectWorkspace)
  const participants = useAppSelector(selectParticipants)
  const offer = useAppSelector(selectOffer)
  const disableField = ["sellerAgent"].includes(workspace!.workspace!.role!)

  const buyerSolicitor = participants.find(
    participant => participant.role === "buyerSolicitor",
  )
  const australianPostcodeSchema = z.string().refine(
    (postcode: any) => {
      const cleaned = postcode.replace(/\s/g, "")
      return /^\d{4}$/.test(cleaned)
    },
    { message: "Invalid Australian postcode - must be 4 digits" },
  )
  const australianPhoneSchema = z.string().refine(
    (phone: any) => {
      const cleaned = phone.replace(/[^\d]/g, "")

      return (
        /^04\d{8}$/.test(cleaned) ||
        /^0[23578]\d{8}$/.test(cleaned) ||
        /^\+61[45]\d{8}$/.test(cleaned) ||
        /^\+61[23578]\d{8}$/.test(cleaned)
      )
    },
    { message: "Invalid Australian phone number" },
  )
  const schema = z.object<Properties<Participant>>({
    id: z.number(),
    firstName: z
      .string()
      .min(2, { message: "Name must be at least 2 characters" })
      .max(50, { message: "Name cannot exceed 50 characters" }),
    lastName: z
      .string()
      .min(2, { message: "Name must be at least 2 characters" })
      .max(50, { message: "Name cannot exceed 50 characters" }),
    participantType: z.nativeEnum(ParticipantParticipantTypeEnum),
    externalReference: z.string().optional(),
    role: z.nativeEnum(ParticipantRoleEnum),
    licenceeNumber: z.string().optional(),
    licenceeExpiryDate: z.string().optional(),
    tradingName: z.string().optional(),
    createdAt: z.string().optional(),
    createdBy: z.string().optional(),
    modifiedAt: z.string().optional(),
    modifiedBy: z.string().optional(),
    invitationStatus: z.nativeEnum(ParticipantInvitationStatusEnum),
    middleNames: z.string().optional(),
    streetAddress1: z.string().optional(),
    streetAddress2: z.string().optional(),
    locality: z.string().optional(),
    stateOrTerritory: z.string().optional(),
    postCode: australianPostcodeSchema,
    country: z.string().optional(),
    phone: australianPhoneSchema,
    mobilePhone: australianPhoneSchema.optional(),
    countryCode: z.string().optional(),
    email: z.string().email({ message: "Invalid email address" }),
    organisationName: z.string().optional(),
    abn: z.string().optional(),
    acn: z.string().optional(),
    registeredForGst: z.boolean().optional(),
  })

  const detailsForm = useForm<Participant>({
    resolver: zodResolver(schema),
    mode: "onChange",
    defaultValues: buyerSolicitor,
  })

  const {
    control,
    watch,
    formState: { errors, isDirty },
    setValue,
    reset,
  } = detailsForm

  const formValues = watch()

  const handleOnParticipantChanged = (data: Participant) => {
    try {
      dispatch(
        putOfferParticipant({
          workspaceId: workspace!.workspace!.id!,
          offerId: offer!.id!,
          participant: data,
        }),
      )
      reset(data, { keepErrors: true })
    } catch (serverError) {
      console.error("Autosave failed", serverError)
    }
  }

  const debouncedAutoSave = React.useCallback(
    debounce(async (data: Participant) => {
      handleOnParticipantChanged(data)
    }, 1000),
    [dispatch, workspace!.workspace!.id],
  )
  useEffect(() => {
    if (isDirty) {
      debouncedAutoSave(formValues)
    }
  }, [formValues, isDirty, debouncedAutoSave])

  return (
    <form>
      <Card sx={{ border: "none" }}>
        <CardHeader title="Buyers' Solicitor's Details" inheritlayout="true" />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" color={theme.palette.text.secondary}>
                Name
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormInputText
                control={control}
                name="solicitorName"
                label="Solicitor Name"
                required
                disabled={disableField}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <ParticipantNameForm
                control={control}
                participantType={"organisation"}
                disabled={disableField}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" color={theme.palette.text.secondary}>
                Contact Details
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <ContactDetails
                control={control}
                disabled={disableField}
                errors={errors}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" color={theme.palette.text.secondary}>
                Address
              </Typography>
            </Grid>
            <AddressForm
              control={control}
              address={buyerSolicitor!}
              setValue={setValue}
              disabled={disableField}
              errors={errors}
            />
          </Grid>
        </CardContent>
      </Card>
    </form>
  )
}

export default BuyersSolicitor
