import { useEffect, useRef, useState } from "react"
import { Link, matchPath, useLocation } from "react-router-dom"

// material-ui
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import Avatar from "@mui/material/Avatar"
import ButtonBase from "@mui/material/ButtonBase"
import Chip from "@mui/material/Chip"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"

// project imports
import { useMenu } from "../../../app/features/menu/useMenu"
import { borderRadius } from "../../../constants"
import MenuItem from "../../../app/features/menu/menu-item"

// assets
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord"
import { Icon } from "@tabler/icons-react"
import iconNameMapper from "../../../utils/icon-name-mapper"

interface INavItemProps {
  item: MenuItem
  level: number
  isParents?: boolean
  setSelectedID?: () => void
}

export const NavItem = ({
  item,
  level,
  isParents = false,
  setSelectedID,
}: INavItemProps): JSX.Element => {
  const theme = useTheme()
  const downMD = useMediaQuery(theme.breakpoints.down("md"))
  const ref = useRef<HTMLParagraphElement>(null)

  const { pathname } = useLocation()
  const { isDrawerOpen, handleDrawerOpen } = useMenu()
  const isSelected = !!matchPath({ path: item.url!, end: false }, pathname)

  const [hoverStatus, setHover] = useState(false)

  const compareSize = () => {
    const compare = ref.current
      ? ref.current.scrollWidth > ref.current.clientWidth
      : false
    setHover(compare)
  }

  useEffect(() => {
    compareSize()
    window.addEventListener("resize", compareSize)
    return () => {
      window.removeEventListener("resize", compareSize)
    }
  }, [])

  let Icon: Icon | null = null
  Icon = iconNameMapper(item?.icon)

  const itemIcon = item?.icon ? (
    Icon && (
      <Icon
        stroke={1.5}
        size={isDrawerOpen ? "20px" : "24px"}
        style={{ ...(isParents && { fontSize: 20, stroke: "1.5" }) }}
      />
    )
  ) : (
    <FiberManualRecordIcon
      sx={{ width: isSelected ? 8 : 6, height: isSelected ? 8 : 6 }}
      fontSize={level > 0 ? "inherit" : "medium"}
    />
  )

  let itemTarget = "_self"
  if (item.target) {
    itemTarget = "_blank"
  }

  const itemHandler = () => {
    if (downMD) handleDrawerOpen(false)

    if (isParents && setSelectedID) {
      setSelectedID()
    }
  }

  const iconSelectedColor = "secondary.main"

  return (
    <>
      <ListItemButton
        component={Link}
        to={item.url ?? ""}
        target={itemTarget}
        disabled={item.disabled}
        disableRipple={!isDrawerOpen}
        sx={{
          zIndex: 1201,
          borderRadius: `${borderRadius}px`,
          mb: 0.5,
          ...(isDrawerOpen && level !== 1 && { ml: `${level * 18}px` }),
          ...(!isDrawerOpen && { pl: 1.25 }),
          ...(isDrawerOpen &&
            level === 1 && {
              "&:hover": {
                bgcolor: "secondary.light",
              },
              "&.Mui-selected": {
                bgcolor: "secondary.light",
                color: iconSelectedColor,
                "&:hover": {
                  color: iconSelectedColor,
                  bgcolor: "secondary.light",
                },
              },
            }),
          ...((!isDrawerOpen || level !== 1) && {
            py: level === 1 ? 0 : 1,
            "&:hover": {
              bgcolor: "transparent",
            },
            "&.Mui-selected": {
              "&:hover": {
                bgcolor: "transparent",
              },
              bgcolor: "transparent",
            },
          }),
        }}
        selected={isSelected}
        onClick={itemHandler}
      >
        <ButtonBase
          aria-label="theme-icon"
          sx={{ borderRadius: `${borderRadius}px` }}
          disableRipple={isDrawerOpen}
        >
          <ListItemIcon
            sx={{
              minWidth: level === 1 ? 36 : 18,
              color: isSelected ? iconSelectedColor : "text.primary",
              ...(!isDrawerOpen &&
                level === 1 && {
                  borderRadius: `${borderRadius}px`,
                  width: 46,
                  height: 46,
                  alignItems: "center",
                  justifyContent: "center",
                  "&:hover": {
                    bgcolor: "secondary.light",
                  },
                  ...(isSelected && {
                    bgcolor: "secondary.light",
                    "&:hover": {
                      bgcolor: "secondary.light",
                    },
                  }),
                }),
            }}
          >
            {itemIcon}
          </ListItemIcon>
        </ButtonBase>

        {(isDrawerOpen || (!isDrawerOpen && level !== 1)) && (
          <Tooltip title={item.title} disableHoverListener={!hoverStatus}>
            <ListItemText
              primary={
                <Typography
                  ref={ref}
                  noWrap
                  variant={isSelected ? "h5" : "body1"}
                  color="inherit"
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: 102,
                  }}
                >
                  {item.title}
                </Typography>
              }
              secondary={
                item.caption && (
                  <Typography
                    variant="caption"
                    gutterBottom
                    sx={{
                      display: "block",
                      ...theme.typography.subMenuCaption,
                    }}
                  >
                    {item.caption}
                  </Typography>
                )
              }
            />
          </Tooltip>
        )}

        {isDrawerOpen && item.chip && (
          <Chip
            color={item.chip.color}
            variant={item.chip.variant}
            size={item.chip.size}
            label={item.chip.label}
            avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
          />
        )}
      </ListItemButton>
    </>
  )
}

export default NavItem
