import { FC, Fragment } from "react"
import {
  Grid,
  Typography,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from "@mui/material"
import { Notes } from "../../features/agent-appointment/components/form-wrapper"
import { DatePicker } from "@mui/x-date-pickers"

export interface RatesAndServicesProps {}

const RatesAndServices: FC<RatesAndServicesProps> = () => {
  const notes = [
    {
      title: "Warning to Buyer",
      items: [
        "The amount of charges imposed on you may be different to the amount imposed on the seller.",
      ],
    },
  ]

  return (
    <Fragment>
      <Grid item>
        <Notes notes={notes} />
        <form>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} my={2}>
              <Typography variant="h6">Rates</Typography>
              <Typography variant="h6" mb={4}>
                Whichever of the following applies—
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  borderBottom: "1px solid silver",
                  paddingBottom: 3,
                  gap: 2,
                }}
              >
                <Typography variant="body1">
                  The total amount payable* for all rates and charges (without
                  any discount) for the property as stated in the most recent
                  rate notice is:
                </Typography>

                <Grid item xs={12} sm={4}>
                  <TextField
                    label={"Insert amount of rates"}
                    size="small"
                    fullWidth
                    autoComplete="false"
                    variant="filled"
                  />
                </Grid>

                <Typography variant="body1">Insert Date Range</Typography>

                <Grid container spacing={1}>
                  <Grid item xs={12} sm={4}>
                    <DatePicker
                      slotProps={{
                        textField: {
                          size: "small",
                          fullWidth: true,
                          variant: "filled",
                          label: "Start date",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <DatePicker
                      slotProps={{
                        textField: {
                          size: "small",
                          fullWidth: true,
                          variant: "filled",
                          label: "End date",
                        },
                      }}
                    />
                  </Grid>
                </Grid>

                <RadioGroup>
                  <Typography variant="body1">OR</Typography>

                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="The property is currently a rates exempt lot.**"
                  />
                  <Typography variant="body1">OR</Typography>

                  <FormControlLabel
                    value="no"
                    control={<Radio />}
                    label="The property is not rates exempt but no separate assessment of rates
is issued by a local government for the property."
                  />
                </RadioGroup>
                <Typography variant="caption">
                  * Concessions: A local government may grant a concession for
                  rates. The concession will not pass to you as buyer unless you
                  meet the criteria in section 120 of the Local Government
                  Regulation 2012 or section 112 of the City of Brisbane
                  Regulation 2012.
                </Typography>
                <Typography variant="caption">
                  ** An exemption for rates applies to particular entities. The
                  exemption will not pass to you as buyer unless you meet the
                  criteria in section 93 of the Local Government Act 2009 or
                  section 95 of the City of Brisbane Act 2010.
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} my={2}>
              <Typography variant="h6">Water</Typography>
              <Typography variant="h6" mb={4}>
                Whichever of the following applies—
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  borderBottom: "1px solid silver",
                  paddingBottom: 3,
                  gap: 2,
                }}
              >
                <Typography variant="body1">
                  The total amount payable as charges for water services for the
                  property as indicated in the most recent water services
                  notice* is:
                </Typography>

                <Grid item xs={12} sm={4}>
                  <TextField
                    label={"Insert amount of rates"}
                    size="small"
                    fullWidth
                    autoComplete="false"
                    variant="filled"
                  />
                </Grid>

                <Typography variant="body1">Insert Date Range</Typography>

                <Grid container spacing={1}>
                  <Grid item xs={12} sm={4}>
                    <DatePicker
                      slotProps={{
                        textField: {
                          size: "small",
                          fullWidth: true,
                          variant: "filled",
                          label: "Start date",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <DatePicker
                      slotProps={{
                        textField: {
                          size: "small",
                          fullWidth: true,
                          variant: "filled",
                          label: "End date",
                        },
                      }}
                    />
                  </Grid>
                </Grid>

                <Typography variant="body1">OR</Typography>

                <Typography variant="body1">
                  There is no separate water services notice issued for the lot;
                  however, an estimate of the total amount payable for water
                  services is:
                </Typography>

                <Grid item xs={12} sm={4}>
                  <TextField
                    label={"Insert amount of rates"}
                    size="small"
                    fullWidth
                    autoComplete="false"
                    variant="filled"
                  />
                </Grid>

                <Typography variant="body1">Insert Date Range</Typography>

                <Grid container spacing={1}>
                  <Grid item xs={12} sm={4}>
                    <DatePicker
                      slotProps={{
                        textField: {
                          size: "small",
                          fullWidth: true,
                          variant: "filled",
                          label: "Start date",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <DatePicker
                      slotProps={{
                        textField: {
                          size: "small",
                          fullWidth: true,
                          variant: "filled",
                          label: "End date",
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                <Typography variant="caption">
                  * A water services notices means a notice of water charges
                  issued by a water service provider under the Water Supply
                  (Safety and Reliability) Act 2008.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Fragment>
  )
}

export default RatesAndServices
