import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { authCallbackState, AuthCallbackStatus } from "./auth-callback-slice"
import { Box, CircularProgress, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { withAuthenticationRequired } from "react-oidc-context"
import RedirectToLogin from "../../components/redirect-to-login"
import { assertUser } from "./thunks"

const AuthCallback = () => {
  const state = useAppSelector(authCallbackState)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    switch (state.status) {
      case AuthCallbackStatus.initial:
        dispatch(assertUser())
        break
      case AuthCallbackStatus.ready:
        if (state.isNewUser) {
          navigate("/settings", { relative: "route" })
        } else {
          navigate("/workspaces", { relative: "route" })
        }
        break
      default:
        break
    }
    return
  }, [state.status, dispatch])
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <CircularProgress />
      <Typography variant="h6" style={{ marginTop: "16px" }}>
        Working on it :-) Please wait...
      </Typography>
    </Box>
  )
}

export default withAuthenticationRequired(AuthCallback, {
  OnRedirecting: () => <RedirectToLogin />,
})
