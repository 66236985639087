import {
  Box,
  OutlinedInput,
  InputAdornment,
  Grid,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material"
import { Fragment, useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import {
  getWorkspaces,
  selectWorkspaces,
  selectWorkspacesStatus,
  WorkspacesStatus,
} from "../../../app/slices/workspaces-slice"
import WorkspaceSummary from "./workspace-sumary"
import { Settings, PersonAdd } from "@mui/icons-material"
import SearchIcon from "@mui/icons-material/Search"
import { useNavigate } from "react-router-dom"
import { selectIsWorkspaceReader } from "../../../app/slices/session-slice"

const WorkspacesSmallMedium = () => {
  const [searchQuery, setSearchQuery] = useState("")
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const workspaces = useAppSelector(selectWorkspaces)
  const workspacesStatus = useAppSelector(selectWorkspacesStatus)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const filteredWorkspaces = workspaces.filter(workspace =>
    workspace.workspace.property?.streetAddress1
      ?.toLowerCase()
      .includes(searchQuery.toLowerCase()),
  )

  useEffect(() => {
    if (workspaces.length === 0) {
      dispatch(getWorkspaces())
    }
  }, [dispatch])

  const workspaceData = searchQuery ? filteredWorkspaces : workspaces
  const isWorkspaceViewer = useAppSelector(selectIsWorkspaceReader)
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleSettingsClick = () => {
    navigate("/settings")
    handleMenuClose()
  }

  const handleCreateAccountClick = () => {
    navigate("/on-boarding")
    handleMenuClose()
  }

  if (workspacesStatus === WorkspacesStatus.loading) {
    return (
      <Fragment>
        <Box
          sx={{
            width: { xs: "100%", md: "500px" },
            mb: 2,
            justifySelf: "center",
          }}
        >
          <CircularProgress size="100px" />
        </Box>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Box
        sx={{
          width: { xs: "100%", md: "500px" },
          mb: 2,
          justifySelf: "center",
          position: "relative",
        }}
      >
        <OutlinedInput
          placeholder="Search by anything..."
          value={searchQuery}
          onChange={handleSearchChange}
          autoFocus
          sx={{
            width: "100%",
            height: "auto",
            borderRadius: "5px",
            backgroundColor: "#fff",
          }}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
        />
        <IconButton
          onClick={handleMenuOpen}
          sx={{
            position: "fixed",
            top: 16,
            right: 16,
            color: "white",
          }}
          aria-label="settings"
          aria-controls="settings-menu"
          aria-haspopup="true"
        >
          <Settings />
        </IconButton>
        <Menu
          id="settings-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem onClick={handleSettingsClick}>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </MenuItem>
          {!isWorkspaceViewer && (
            <MenuItem onClick={handleCreateAccountClick}>
              <ListItemIcon>
                <PersonAdd fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Create Account" />
            </MenuItem>
          )}
        </Menu>
      </Box>
      <Box sx={{ position: "relative", overflow: "hidden" }}>
        {workspaceData.length > 0 ? (
          <Grid container spacing={2}>
            {workspaceData.map(workspace => (
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
                key={`ws-${workspace.workspace.id}`}
              >
                <WorkspaceSummary
                  workspaceSummary={workspace!.workspace!}
                  workflow={workspace.workflow}
                  isCardView={true}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Box
            sx={{
              maxWidth: 600,
              mx: "auto",
            }}
          >
            <Card
              sx={{
                padding: "15px",
                height: 300,
                textAlign: "center",
                alignItems: "center",
                alignContent: "center",
              }}
            >
              <CardHeader title={workspaces.length > 0 && `No results found`} />
              <CardContent>
                {workspaces.length > 0 ? (
                  <Typography variant="body1" color="textSecondary">
                    Please try a different search query.
                  </Typography>
                ) : (
                  <Typography variant="body1" color="textSecondary">
                    You have no workspaces.
                  </Typography>
                  // <CircularProgress size="100px" />
                )}
              </CardContent>
            </Card>
          </Box>
        )}
      </Box>
    </Fragment>
  )
}

export default WorkspacesSmallMedium
