import { FC, ReactNode, useEffect, useRef, useState } from "react"
import {
  AddAnnexureDocumentTypeEnum,
  Annexure,
} from "../../../landconnex-api-client"
import {
  Box,
  IconButton,
  Typography,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Card,
  CardContent,
  CardHeader,
} from "@mui/material"
import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  PointerSensor,
  useDroppable,
} from "@dnd-kit/core"
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable"
import { arrayMove } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined"
import { useAppSelector, useAppDispatch } from "../../../app/hooks"
import {
  addAnnexure,
  deleteAnnexure,
  getContractAnnexures,
  selectAnnexures,
  updateAnnexurePosition,
} from "../../../app/slices/contract-slice"
import { selectWorkspace } from "../../../app/slices/workspaces-slice"
export type OnAnnexureUpload = (file: File) => void
export type OnAnnexureDelete = (annexureId: number) => void
export type OnAnnexureUpdate = (annexureId: number, position: number) => void

export interface AnnexuresProps {
  documents: Annexure[]
  onAnnexureDelete: OnAnnexureDelete
  onAnnexureUpload: OnAnnexureUpload
  onAnnexureUpdate: OnAnnexureUpdate
}

interface DraggableItemProps {
  id: string
  isLoading: boolean
  children: (listeners: any) => ReactNode
}

const DraggableItem: FC<DraggableItemProps> = ({ id, isLoading, children }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: isDragging ? undefined : transition,
    opacity: isDragging || isLoading ? 0.5 : 1,
    zIndex: isDragging || isLoading ? 1 : "auto",
    position: "relative" as const,
  }

  return (
    <Box ref={setNodeRef} style={style} {...attributes}>
      {children(listeners)}
      {isLoading && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            bgcolor: "rgba(255, 255, 255, 0.7)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 2,
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  )
}

const DroppableArea = ({ children }: { children: React.ReactNode }) => {
  const { setNodeRef } = useDroppable({ id: "droppable" })
  return (
    <Box
      ref={setNodeRef}
      sx={{ display: "flex", flexDirection: "column", gap: 2 }}
    >
      {children}
    </Box>
  )
}

const Annexures: FC = ({}) => {
  const dispatch = useAppDispatch()
  const workspace = useAppSelector(selectWorkspace)
  const annexures = useAppSelector(selectAnnexures)
  const disableField = ["buyer", "buyerSolicitor"].includes(
    workspace!.workspace?.role!,
  )

  const [localDocuments, setLocalDocuments] = useState<Annexure[]>(
    annexures || [],
  )
  const [isUploading] = useState<boolean>(false)
  const [isDeleting, setIsDeleting] = useState<number | null>(null)
  const [confirmDeleteId, setConfirmDeleteId] = useState<number | null>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const sensors = useSensors(useSensor(PointerSensor))

  useEffect(() => {
    setLocalDocuments(annexures || [])
    setIsDeleting(null)
  }, [annexures])

  useEffect(() => {
    dispatch(getContractAnnexures(workspace!.workspace!.id!))
  }, [workspace])

  const handleAnnexureUpload = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const files = event.target.files
    if (files) {
      const newAnnexure: Annexure = {
        id: Date.now(),
        document: {
          name: files[0].name,
          size: Math.round(files[0].size / 1024),
        },
        order: localDocuments.length,
      }

      setLocalDocuments(prev => [...prev, newAnnexure])

      dispatch(
        addAnnexure({
          workspaceId: workspace!.workspace!.id!,
          documentName: files[0].name,
          documentType: AddAnnexureDocumentTypeEnum.Annexure,
          file: files[0],
        }),
      )
    }
  }

  const handleDragEnd = (event: any) => {
    const { active, over } = event
    if (!over || active.id === over.id) return

    const oldIndex = localDocuments.findIndex(
      doc => String(doc.id) === active.id,
    )
    const newIndex = localDocuments.findIndex(doc => String(doc.id) === over.id)

    if (oldIndex !== -1 && newIndex !== -1) {
      const updatedDocuments = arrayMove(
        localDocuments,
        oldIndex,
        newIndex,
      ).map((doc, index) => ({
        ...doc,
        order: index,
      }))

      setLocalDocuments(updatedDocuments)

      dispatch(
        updateAnnexurePosition({
          workspaceId: workspace!.workspace!.id!,
          annexureId: updatedDocuments[newIndex].id,
          position: newIndex,
        }),
      )
    }
  }

  const handleDeleteClick = (id: number) => {
    setConfirmDeleteId(id) // Show confirmation dialog
  }

  const confirmDelete = () => {
    if (confirmDeleteId !== null) {
      setIsDeleting(confirmDeleteId)
      dispatch(
        deleteAnnexure({
          workspaceId: workspace!.workspace!.id!,
          annexureId: confirmDeleteId,
        }),
      )
      setConfirmDeleteId(null)
    }
  }

  const cancelDelete = () => {
    setConfirmDeleteId(null)
  }

  return (
    <Card sx={{ border: "none" }}>
      <CardHeader title="Upload Annexures" inheritlayout="true" />
      <CardContent>
        <Box
          sx={{
            pointerEvents: isUploading ? "none" : "",
            opacity: isUploading ? ".5" : 1,
          }}
        >
          <Box
            sx={{
              position: "relative",
              zIndex: 1,
              display: "flex",
              gap: 2,
              justifyContent: "end",
            }}
          >
            {isUploading && (
              <Box
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress size={30} />
              </Box>
            )}
            <Box
              sx={{
                width: 80,
                mb: 2,
              }}
            >
              <Button
                color="success"
                disabled={isUploading || disableField}
                onClick={() => {
                  fileInputRef.current?.click()
                }}
              >
                Upload
              </Button>
            </Box>
          </Box>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={localDocuments.map(doc => String(doc.id))}
              strategy={verticalListSortingStrategy}
            >
              <DroppableArea>
                {localDocuments.map(document => (
                  <DraggableItem
                    key={document.id}
                    id={String(document.id)}
                    isLoading={isDeleting === document.id}
                  >
                    {listeners => (
                      <Box
                        sx={{
                          padding: 2,
                          borderRadius: 2,
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          position: "relative",
                          flex: 1,
                          boxShadow:
                            "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
                          bgcolor: "#f1f6f6",
                        }}
                      >
                        <Box
                          {...listeners}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            flex: 1,
                          }}
                        >
                          <Typography>{document.document.name}</Typography>
                          <Typography>{document.document.size} kb.</Typography>
                        </Box>
                        <IconButton
                          onClick={() => handleDeleteClick(document.id)}
                          sx={{ pointerEvents: "auto" }}
                          disabled={disableField}
                        >
                          <DeleteForeverOutlinedIcon
                            fontSize="large"
                            color="error"
                          />
                        </IconButton>
                      </Box>
                    )}
                  </DraggableItem>
                ))}
              </DroppableArea>
            </SortableContext>
          </DndContext>
        </Box>
        <input
          type="file"
          style={{ display: "none" }}
          ref={fileInputRef}
          onChange={handleAnnexureUpload}
          accept="application/pdf"
          multiple={false}
        />

        {/* Confirmation Dialog */}
        <Dialog open={confirmDeleteId !== null} onClose={cancelDelete}>
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            <Typography>Are you sure you want to delete this item?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={cancelDelete} color="primary">
              Cancel
            </Button>
            <Button onClick={confirmDelete} color="secondary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </CardContent>
    </Card>
  )
}

export default Annexures
