import React, { FC, Fragment, useEffect, useState } from "react"
import {
  Grid,
  Typography,
  IconButton,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useTheme,
  Box,
  debounce,
} from "@mui/material"
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined"
import {
  Participant,
  ParticipantParticipantTypeEnum,
} from "../../../landconnex-api-client"
import { useForm } from "react-hook-form"
import ConfirmationDialog from "../../../components/confirmation-dialog"
import ContactDetails from "../../../components/contact-details"
import ParticipantNameForm from "../../../components/participant-name-form"
import AddressForm from "../../../components/address-form"
import {
  deleteParticipant,
  postParticipant,
  putParticipant,
} from "../../../app/slices/participants-slice"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { selectWorkspace } from "../../../app/slices/workspaces-slice"
import { zodResolver } from "@hookform/resolvers/zod"
import { participantSchema } from "../../../utils/validation-schemas"
import { gridSpacing } from "../../../constants"
export interface ClientDetailProps {
  client: Participant
  index: number
}

const ClientDetail: FC<ClientDetailProps> = ({ client, index }) => {
  const dispatch = useAppDispatch()
  const workspace = useAppSelector(selectWorkspace)
  const [localClient, setLocalClient] = useState(client)
  const [confirmationDialogState, setConfirmationDialogState] = useState<
    number[]
  >([])
  const theme = useTheme()
  const participantForm = useForm<Participant>({
    resolver: zodResolver(participantSchema),
    mode: "onChange",
    defaultValues: client,
  })

  const {
    control,
    watch,
    formState: { errors, isDirty, defaultValues },
    setValue,
    reset,
  } = participantForm

  const formValues = watch()

  const saveParticipant = (data: Participant) => {
    try {
      if (data.id == null) {
        dispatch(
          postParticipant({
            workspaceId: workspace!.workspace!.id,
            participant: data,
          }),
        )
      } else {
        dispatch(
          putParticipant({
            workspaceId: workspace!.workspace!.id,
            participant: data,
          }),
        )
      }
      reset(data, { keepErrors: true })
    } catch (serverError) {
      console.error("Autosave failed", serverError)
    }
  }

  const debouncedAutoSave = React.useCallback(
    debounce(async (data: Participant) => {
      saveParticipant(data)
    }, 1000),
    [dispatch, workspace!.workspace!.id],
  )
  useEffect(() => {
    if (isDirty) {
      debouncedAutoSave(formValues)
    }
  }, [formValues, isDirty, debouncedAutoSave])

  const handleDelete = () => {
    dispatch(
      deleteParticipant({
        workspaceId: workspace!.workspace!.id,
        participant: localClient,
      }),
    )
  }

  const handleChanged = (data: Participant) => {
    if (data.id == null) {
      dispatch(
        postParticipant({
          workspaceId: workspace!.workspace!.id,
          participant: data,
        }),
      )
    } else {
      dispatch(
        putParticipant({
          workspaceId: workspace!.workspace!.id,
          participant: data,
        }),
      )
    }
  }
  return (
    <Fragment>
      <Grid key={localClient.id} item xs={12} sm={12}>
        <ConfirmationDialog
          open={confirmationDialogState.includes(localClient.id!)}
          message={`Remove ${
            defaultValues?.organisationName ||
            (defaultValues?.firstName && defaultValues?.lastName
              ? `${defaultValues?.firstName} ${defaultValues?.lastName}`
              : `Client ${index + 1}`)
          } from the contract?`}
          title="Remove client?"
          onClose={result => {
            if (result) {
              handleDelete()
            }
            setConfirmationDialogState([])
          }}
        />
      </Grid>

      <Grid item>
        <form>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12} sm={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6">
                  {`${
                    client.organisationName ||
                    (client.firstName && client.lastName
                      ? `${client.firstName} ${client.lastName}`
                      : `Client ${index + 1}`)
                  }`}
                </Typography>
                <IconButton
                  color="primary"
                  onClick={() => setConfirmationDialogState([localClient.id!])}
                >
                  <DeleteForeverOutlinedIcon fontSize="large" color="error" />
                </IconButton>
              </Box>
              <Divider />
            </Grid>

            {/* Select client type */}
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <InputLabel size="small" id="clientTypeLabel">
                  Client Type
                </InputLabel>
                <Select
                  labelId="clientTypeLabel"
                  name="participantType"
                  label="Client Type"
                  value={localClient.participantType}
                  onChange={event => {
                    const updatedClient = {
                      ...localClient,
                      participantType: event.target
                        .value as ParticipantParticipantTypeEnum,
                    }
                    setLocalClient(updatedClient) // Update local client state
                    handleChanged(updatedClient) // Call onChanged with updated client
                  }}
                >
                  {Object.values(ParticipantParticipantTypeEnum).map(type => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Participant name form */}
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" color={theme.palette.text.secondary}>
                Name
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <ParticipantNameForm
                control={control}
                captureMiddleName={true}
                captureGst={true}
                participantType={localClient.participantType!}
                errors={errors}
              />
            </Grid>

            {/* Contact details */}
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" color={theme.palette.text.secondary}>
                Contact Details
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <ContactDetails control={control} errors={errors} />
            </Grid>

            {/* Address form */}
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" color={theme.palette.text.secondary}>
                Address
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <AddressForm
                control={control}
                address={localClient}
                setValue={setValue}
                errors={errors}
              />
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Fragment>
  )
}

export default ClientDetail
