import { createRoot } from "react-dom/client"
import App from "./App"
import "./index.css"
import { AuthProvider, AuthProviderProps } from "react-oidc-context"
import { User } from "oidc-client-ts"
import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"
import { CssBaseline, StyledEngineProvider } from "@mui/material"
import { appConfig } from "./constants"

const oidcConfig: AuthProviderProps = {
  authority: appConfig.authority,
  client_id: appConfig.clientId,
  redirect_uri: appConfig.redirectUri,
  automaticSilentRenew: true,
  silent_redirect_uri: `${window.location.origin}/silent-callback`,
  monitorSession: true,
  loadUserInfo: true,
  onSigninCallback: (_user: User | void): void => {
    window.history.replaceState({}, document.title, window.location.pathname)
  },
}

const container = document.getElementById("root")

if (container) {
  const root = createRoot(container)

  root.render(
    // <React.StrictMode>

    <StyledEngineProvider injectFirst>
      <AuthProvider {...oidcConfig}>
        <CssBaseline />
        <App />
      </AuthProvider>
    </StyledEngineProvider>,
    // </React.StrictMode>,
  )
} else {
  throw new Error(
    "Root element with ID 'root' was not found in the document. Ensure there is a corresponding HTML element with the ID 'root' in your HTML file.",
  )
}
