import Grid from "@mui/material/Grid"
import Button from "@mui/material/Button"
import React, { useEffect } from "react"
import { Participant } from "../../../landconnex-api-client"
import BuyerDetail from "./buyer-detail"
import { useAppSelector, useAppDispatch } from "../../../app/hooks"
import { Box, Paper } from "@mui/material"
import AddOutlinedIcon from "@mui/icons-material/AddOutlined"
import {
  deleteOfferParticipant,
  getOfferParticipants,
  postOfferParticipant,
  putOfferParticipant,
  selectOffer,
  selectOfferParticipants,
} from "../../../app/slices/offers-slice"
import { selectWorkspace } from "../../../app/slices/workspaces-slice"
import { useParams } from "react-router-dom"
import { gridSpacing } from "../../../constants"

export type OnAddBuyer = () => void

const BuyersDetails: React.FC = () => {
  const dispatch = useAppDispatch()
  const workspace = useAppSelector(selectWorkspace)
  const offer = useAppSelector(selectOffer)
  const offerParticipants = useAppSelector(selectOfferParticipants)
  const params = useParams()
  const offerId = parseInt(params.offerId!)
  const workspaceId = parseInt(params.workspaceId!)

  function handleOnAddBuyer(): void {
    dispatch(
      postOfferParticipant({
        workspaceId: workspace!.workspace!.id!,
        offerId: offer!.id!,
        participant: { role: "buyer" },
      }),
    )
  }

  function handleOnBuyerRemoved(participant: Participant): void {
    dispatch(
      deleteOfferParticipant({
        workspaceId: workspace!.workspace!.id!,
        offerId: offer!.id!,
        participantId: participant.id!,
      }),
    )
  }

  function handleOnBuyersDetailsChanged(participant: Participant): void {
    dispatch(
      putOfferParticipant({
        participant: participant,
        workspaceId: workspace!.workspace!.id!,
        offerId: offer!.id!,
      }),
    )
  }

  useEffect(() => {
    dispatch(
      getOfferParticipants({ workspaceId: workspaceId, offerId: offerId }),
    )
  }, [workspaceId, offerId])

  const buyers = offerParticipants.filter(p => p.role === "buyer")
  return (
    <Paper sx={{ p: gridSpacing }}>
      {buyers.map((buyer, index) => (
        <BuyerDetail
          key={buyer.id}
          buyer={buyer}
          onChanged={handleOnBuyersDetailsChanged}
          onDelete={handleOnBuyerRemoved}
          index={index}
        />
      ))}

      <Grid container item spacing={gridSpacing} justifyContent={"center"}>
        <Box sx={{ width: "100px", mt: 5 }}>
          <Button
            color="success"
            onClick={handleOnAddBuyer}
            startIcon={<AddOutlinedIcon />}
          >
            client
          </Button>
        </Box>
      </Grid>
    </Paper>
  )
}

export default BuyersDetails
