import { useAppDispatch, useAppSelector } from "../../app/hooks"

import React, { useEffect, useState } from "react"

import { Box, Paper, Tab, Tabs } from "@mui/material"
import SellerDetails from "./components/seller-details"
import { getOffer, selectOffer } from "../../app/slices/offers-slice"
import { selectWorkspace } from "../../app/slices/workspaces-slice"
import Encumbrances from "./components/encumbrances"
import Finance from "./components/finance"
import Price from "./components/price"
import Property from "./components/property"
import SpecialConditions from "./components/special-conditions"
import BuyersDetails from "./components/buyers-details"
import SellerSolicitor from "./components/seller-solicitor"
import BuyersSolicitor from "./components/buyers-solicitor"
import PoolSafety from "./components/pool-safety"
import SafetyAlarmsAndSwitches from "./components/safety-alarms-and-switches"
import NeighbourhoodDisputes from "./components/neighbourhood-disputes"
import Annexures from "./components/annexures"
import Review from "./components/review"
import { getContract } from "../../app/slices/contract-slice"
import { useParams } from "react-router-dom"
import DepositStep from "./components/deposit"
import { TabDefinition } from "../../components/tabs"
import CustomTabPanel from "../../components/tabs/custom-tab-panel"
import Gst from "./components/gst"
import Settlement from "./components/settlement"

const sellerSolicitorTabs: TabDefinition[] = [
  { label: "Sellers", value: 0, view: <SellerDetails /> },
  { label: "Buyers", value: 1, view: <BuyersDetails /> },
  { label: "Price", value: 2, view: <Price /> },
  { label: "Solicitor", value: 3, view: <SellerSolicitor /> },
  { label: "Deposit", value: 4, view: <DepositStep /> },
  { label: "Finance", value: 5, view: <Finance /> },
  { label: "Buyers Solicitor", value: 6, view: <BuyersSolicitor /> },
  { label: "Property", value: 7, view: <Property /> },
  { label: "Encumbrances", value: 8, view: <Encumbrances /> },
  { label: "Special Conditions", value: 9, view: <SpecialConditions /> },
  { label: "Pool Safety", value: 10, view: <PoolSafety /> },
  { label: "Safety Switches and Alarms", value: 11, view: <SafetyAlarmsAndSwitches /> },
  { label: "Neighbourhood Disputes", value: 12, view: <NeighbourhoodDisputes /> },
  { label: "Annexures", value: 13, view: <Annexures /> },
  { label: "Review", value: 14, view: <Review /> },
]

const sellerSolicitorPreparationTabs: TabDefinition[] = [
  { label: "Sellers", value: 0, view: <SellerDetails /> },
  { label: "Solicitor", value: 1, view: <SellerSolicitor /> },
  { label: "GST", value: 2, view: <Gst /> },
  { label: "Deposit", value: 3, view: <DepositStep /> },
  { label: "Property", value: 4, view: <Property /> },
  { label: "Encumbrances", value: 5, view: <Encumbrances /> },
  { label: "Special Conditions", value: 6, view: <SpecialConditions /> },
  { label: "Pool Safety", value: 7, view: <PoolSafety /> },
  { label: "Safety Switches and Alarms", value: 8, view: <SafetyAlarmsAndSwitches /> },
  { label: "Neighbourhood Disputes", value: 9, view: <NeighbourhoodDisputes /> },
  { label: "Annexures", value: 10, view: <Annexures /> },
  { label: "Review", value: 11, view: <Review /> },
]


const buyerSolicitorTabs: TabDefinition[] = [
  { label: "Buyers", value: 0, view: <BuyersDetails /> },
  { label: "Price", value: 1, view: <Price /> },
  { label: "Finance", value: 2, view: <Finance /> },
  { label: "Buyers Solicitor", value: 3, view: <BuyersSolicitor /> },
  { label: "Property", value: 4, view: <Property /> },
  { label: "Encumbrances", value: 5, view: <Encumbrances /> },
  { label: "Special Conditions", value: 6, view: <SpecialConditions /> },
  { label: "Pool Safety", value: 7, view: <PoolSafety /> },
  { label: "Safety Switches and Alarms", value: 8, view: <SafetyAlarmsAndSwitches /> },
  { label: "Neighbourhood Disputes", value: 9, view: <NeighbourhoodDisputes /> },
  { label: "Annexures", value: 10, view: <Annexures /> },
  { label: "Settlement", value: 11, view: <Settlement /> },
  { label: "Review", value: 12, view: <Review /> },
]


const sellerAgentTabs: TabDefinition[] = [
  { label: "Buyers", value: 0, view: <BuyersDetails /> },
  { label: "Price", value: 1, view: <Price /> },
  { label: "Deposit", value: 2, view: <DepositStep /> },
  { label: "Finance", value: 3, view: <Finance /> },
  { label: "Special Conditions", value: 4, view: <SpecialConditions /> },
  { label: "Settlement", value: 5, view: <Settlement /> },
  { label: "Review", value: 6, view: <Review /> },
]



const ContractStepperPage: React.FC = () => {
  const dispatch = useAppDispatch()

  const offer = useAppSelector(selectOffer)
  const workspace = useAppSelector(selectWorkspace)
  const [tab, setTab] = useState<number>(0)
  const params = useParams()
  const workspaceId = params.workspaceId
    ? parseInt(params.workspaceId)
    : undefined

  const offerId = params.offerId ? parseInt(params.offerId) : undefined

  useEffect(() => {
    if (workspace != undefined) {
      dispatch(getContract(workspace!.workspace!.id))
    }
  }, [dispatch, workspace])

  useEffect(() => {
    if (offerId != offer?.id) {
      dispatch(getOffer({ offerId: offerId!, workspaceId: workspaceId! }))
    }
  }, [dispatch, offerId, offer])



  let tabs: TabDefinition[] = []
  // let title: string
  let initialStep: string = ""
  if (workspace != undefined) {
    switch (workspace!.workspace?.role) {
      case "seller":
      case "sellerSolicitor":
        if (!offer) {
          tabs = sellerSolicitorPreparationTabs
          // title = "Contract and Disclosures"
          initialStep = "sellers"
        } else {
          tabs = sellerSolicitorTabs
          // title = "Contract"
          initialStep = "sellers"
        }
        break

      case "sellerAgent":
        tabs = sellerAgentTabs
        // title = "Offer"
        initialStep = "buyers"
        break

      case "buyer":
      case "buyerSolicitor":
        tabs = buyerSolicitorTabs
        // title = "Contract and Disclosures"
        initialStep = "buyers"
        break

      default:
        initialStep = initialStep
    }
  }

  return (
    <Paper sx={{ padding: 2 }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tab} onChange={(_, newValue) => setTab(newValue)}
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            '& .MuiTabs-scrollButtons': {
              color: 'primary.main',
            },
          }}
        >
          {tabs.map(tab => (
            <Tab key={tab.value} label={tab.label} />
          ))}
        </Tabs>
      </Box>
      
      {tabs.map(tabDetails => (
        <CustomTabPanel value={tab} index={tabDetails.value} sx={{ p: 0, borderRadius: 3 }}>
          {tabDetails.view}
        </CustomTabPanel>
      ))}
     
    </Paper>
  )
}

export default ContractStepperPage
