import { Button } from "@mui/material"
import Grid from "@mui/material/Grid2"
import React from "react"
import {
  ParticipantParticipantTypeEnum,
  ParticipantRoleEnum,
} from "../../../landconnex-api-client"
import ClientDetail from "./client-detail"
import { Notes } from "./form-wrapper"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import AddOutlinedIcon from "@mui/icons-material/AddOutlined"
import {
  postParticipant,
  selectParticipants,
} from "../../../app/slices/participants-slice"
import { selectWorkspace } from "../../../app/slices/workspaces-slice"
export type OnParticipantAdded = () => void

const ClientDetails: React.FC = () => {
  const participants = useAppSelector(selectParticipants)
  const workspace = useAppSelector(selectWorkspace)
  const dispatch = useAppDispatch()
  const clients = participants.filter(
    p => p.role === ParticipantRoleEnum.Seller,
  )

  const notes = [
    {
      title: "Note:",
      items: [
        "The client is the person or entity appointing the agent to provide the services. This may be the owner (or authorised representative of the owner) of the land, property or business that is to be sold or may be a prospective buyer seeking to purchase land or a property. If a company, it is recommended that you undertake an ASIC search to determine the correct signatory. If the sale is subject to an enduring power of attorney or an estate, then the correct documentation from the representative is required prior to signing. If the person signing does not have authority, this could jeopardise your right to commission and attract significant penalties at law. Seek legal advice if required.",
        "Fields marked with * are required.",
        "Annexures detailing additional clients may be attached if required.",
      ],
    },
  ]

  const handleAddClient = () => {
    dispatch(
      postParticipant({
        workspaceId: workspace!.workspace!.id,
        participant: {
          role: ParticipantRoleEnum.Seller,
          participantType: ParticipantParticipantTypeEnum.Individual,
        },
      }),
    )
  }

  return (
    <Grid container spacing={2}>
      <Grid size={12} padding={2}>
        <Notes notes={notes} />
      </Grid>
      <Grid >
        <Button
          size="small"
          variant="contained"
          onClick={() => handleAddClient()}
          startIcon={<AddOutlinedIcon />}
        >
          add client
        </Button>
      </Grid>
      {clients.map((client, index) => (
        <Grid size={12} padding={2}>
          <ClientDetail key={client.id} client={client} index={index} />
        </Grid>
      ))}
    </Grid>
  )
}

export default ClientDetails
