import { FC, Fragment } from "react"
import {
  Grid,
  Typography,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material"
import { Notes } from "../../features/agent-appointment/components/form-wrapper"

interface CommunityTitlesProps {
  disabled: boolean
}
const CommunityTitles: FC<CommunityTitlesProps> = ({ disabled }) => {
  const notes = [
    {
      title: "Warning to Buyer",
      items: [
        "If the property is part of a community titles scheme or a BUGTA scheme and you purchase the property, you will become a member of the body corporate for the scheme with the right to participate in significant decisions about the scheme and you will be required to pay contributions towards the body corporate’s expenses in managing the scheme. You will also be required to comply with the by-laws. By-laws will regulate your use of common property and the lot.",
      ],
    },
  ]

  return (
    <Fragment>
      <Grid
        item
        sx={{
          pointerEvents: disabled ? "none" : "auto",
          opacity: disabled ? 0.6 : 1,
        }}
      >
        <Notes notes={notes} />
        <Typography variant="subtitle2">
          For more information about living in a body corporate and your rights
          and obligations, contact the Office of the Commissioner for Body
          Corporate and Community Management.
        </Typography>
        <form>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} my={2}>
              <Typography variant="h6" mb={4}>
                Body Corporate and Community Management Act 1997
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  borderBottom: "1px solid silver",
                  paddingBottom: 3,
                  gap: 2,
                }}
              >
                <Box>
                  The property is included in a community titles scheme.
                  <br />
                  <Typography variant="caption">
                    (If Yes, complete the information below)
                  </Typography>
                </Box>

                <RadioGroup row>
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                  <FormControlLabel
                    value="idk"
                    control={<Radio />}
                    label="I Don't Know"
                  />
                </RadioGroup>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} my={2}>
              <Typography variant="h6" mb={4}>
                Community Management Statement
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  borderBottom: "1px solid silver",
                  paddingBottom: 3,
                  gap: 2,
                }}
              >
                <Typography variant="body1">
                  A copy of the most recent community management statement for
                  the scheme as recorded under the Land Title Act 1994 or
                  another Act is given to the buyer
                </Typography>

                <RadioGroup row>
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                </RadioGroup>
                <Typography variant="caption">
                  Note—If the property is part of a community titles scheme, the
                  community management statement for the scheme contains
                  important information about the rights and obligations of
                  owners of lots in the scheme including matters such as lot
                  entitlements, by-laws and exclusive use areas.
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} my={2}>
              <Typography variant="h6" mb={4}>
                Body Corporate Certificate
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  borderBottom: "1px solid silver",
                  paddingBottom: 3,
                  gap: 2,
                }}
              >
                <Typography variant="body1">
                  A copy of a body corporate certificate for the lot under the
                  Body Corporate and Community Management Act 1997, section
                  205(4) is given to the buyer.
                </Typography>

                <RadioGroup row>
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                  <FormControlLabel
                    value="idk"
                    control={<Radio />}
                    label="I Don't Know"
                  />
                </RadioGroup>

                <Typography variant="body1">
                  If No— An explanatory statement is given to the buyer that
                  states:
                </Typography>

                <RadioGroup row>
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                </RadioGroup>

                <Typography variant="subtitle2">
                  » a copy of a body corporate certificate for the lot is not
                  attached; and
                </Typography>

                <Typography variant="subtitle2">
                  » the reasons under section 6 of the Property Law Regulation
                  2024 why the seller has not been able to obtain a copy of the
                  body corporate certificate for the lot.
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} my={2}>
              <Typography variant="h6" mb={4}>
                Statutory Warranties
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  borderBottom: "1px solid silver",
                  paddingBottom: 3,
                  gap: 2,
                }}
              >
                <Typography variant="body1">
                  Statutory Warranties—If you enter into a contract, you will
                  have implied warranties under the Body Corporate and Community
                  Management Act 1997 relating to matters such as latent or
                  patent defects in common property or body corporate assets;
                  any actual, expected or contingent financial liabilities that
                  are not part of the normal operating costs; and any
                  circumstances in relation to the affairs of the body corporate
                  that will materially prejudice you as owner of the property.
                  There will be further disclosure about warranties in the
                  contract.
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} my={2}>
              <Typography variant="h6" mb={4}>
                Building Units and Group Titles Act 1980
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  borderBottom: "1px solid silver",
                  paddingBottom: 3,
                  gap: 2,
                }}
              >
                <Box>
                  The property is included in a BUGTA scheme
                  <br />
                  <Typography variant="caption">
                    (If Yes, complete the information below)
                  </Typography>
                </Box>

                <RadioGroup row>
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                  <FormControlLabel
                    value="idk"
                    control={<Radio />}
                    label="I Don't Know"
                  />
                </RadioGroup>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} my={2}>
              <Typography variant="h6" mb={4}>
                Body Corporate Certificate
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  borderBottom: "1px solid silver",
                  paddingBottom: 3,
                  gap: 2,
                }}
              >
                <Typography variant="body1">
                  A copy of a body corporate certificate for the lot under the
                  Building Units and Group Titles Act 1980, section 40AA(1) is
                  given to the buyer.
                </Typography>

                <RadioGroup row>
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                  <FormControlLabel
                    value="idk"
                    control={<Radio />}
                    label="I Don't Know"
                  />
                </RadioGroup>

                <Typography variant="body1">
                  If No— An explanatory statement is given to the buyer that
                  states:
                </Typography>

                <RadioGroup row>
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                </RadioGroup>

                <Typography variant="subtitle2">
                  » a copy of a body corporate certificate for the lot is not
                  attached; and
                </Typography>

                <Typography variant="subtitle2">
                  » the reasons under section 7 of the Property Law Regulation
                  2024 why the seller has not been able to obtain a copy of the
                  body corporate certificate for the lot.
                </Typography>

                <Typography variant="caption">
                  Note—If the property is part of a BUGTA scheme, you will be
                  subject to by-laws approved by the body corporate and other
                  by-laws that regulate your use of the property and common
                  property.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Fragment>
  )
}

export default CommunityTitles
