import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
  CircularProgress,
} from "@mui/material"
import { FC, Fragment, useState } from "react"
import ParticipantNameForm from "../../../components/participant-name-form"
import ContactDetails from "../../../components/contact-details"
import { User } from "../../../landconnex-api-client"
import { FormProvider, useForm } from "react-hook-form"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"

import FormInputText from "../../../components/form-input-text"
import { selectIsWorkspaceCreator } from "../../../app/slices/session-slice"
import FormInputDatePicker from "../../../components/form-input-date-picker"
import FormInputCheckbox from "../../../components/form-input-checkbox"
import { updateUser } from "../../../app/slices/team-members-slice"

export interface EditMemberProps {
  user: User
  onClose: () => void
}

const EditMember: FC<EditMemberProps> = ({ user, onClose }) => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"))
  const isMedium = useMediaQuery(theme.breakpoints.between("sm", "md"))
  const isWorkspaceCreator = useAppSelector(selectIsWorkspaceCreator)

  const formMethods = useForm<User>({
    defaultValues: user,
  })

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = formMethods

  const handleMyDetailsFormSubmit = (data: User) => {
    dispatch(updateUser(data))
    reset(data)
    onClose()
  }
  const [isLoading] = useState(false)

  const renderContent = () => {
    return (
      <Paper sx={{ padding: { lg: 3, md: 2, sm: 1 } }}>

          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(handleMyDetailsFormSubmit)}>
              <Grid item xs={12} sm={12}>
                <Typography
                  variant="h6"
                  color={theme.palette.text.secondary}
                  my={1}
                >
                  Name
                </Typography>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <ParticipantNameForm
                    control={control}
                    captureMiddleName={true}
                    participantType="individual"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography
                  variant="h6"
                  color={theme.palette.text.secondary}
                  my={1}
                >
                  Contact Information
                </Typography>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <ContactDetails control={control} errors={errors} />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                  <Typography
                    variant="h6"
                    color={theme.palette.text.secondary}
                    my={1}
                  >
                    Company Information
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormInputText
                    name="organisationName"
                    required
                    label="Company Name"
                    control={control}
                    error={errors.organisationName}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormInputText
                    name="tradingName"
                    required
                    label="Trading Name"
                    control={control}
                    error={errors.tradingName}
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <FormInputText
                    name="abn"
                    required
                    label="ABN"
                    control={control}
                    error={errors.abn}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <FormInputText
                    name="acn"
                    required
                    label="ACN"
                    control={control}
                    error={errors.acn}
                  />
                </Grid>
                {isWorkspaceCreator && (
                  <Fragment>
                    <Grid item xs={12} sm={6}>
                      <FormInputText
                        name="licenceeNumber"
                        required
                        label="Licencee Number"
                        control={control}
                        error={errors.licenceeNumber}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormInputDatePicker
                        name="licenceeExpiryDate"
                        required
                        label="Licencee Expiry Date"
                        control={control}
                        error={errors.licenceeExpiryDate}
                      />
                    </Grid>
                  </Fragment>
                )}
                <Grid item xs={12}>
                  <FormInputCheckbox
                    control={control}
                    name="registeredForGst"
                    label="Registered for GST?"
                  />
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                  <Typography
                    variant="h6"
                    color={theme.palette.text.secondary}
                    mb={1}
                    mt={2}
                  >
                    Address
                  </Typography>
                </Grid>
                {/* <AddressForm
                  control={control}
                  address={user}
                  setValue={(field, value) => setValue(field, value)}
                  errors={errors}
                /> */}
              </Grid>
              <Box width={120} mx="auto" mt="30px">
                <Button
                  type="submit"
                  size="large"
                  disabled={isLoading}
                  startIcon={
                    isLoading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : null
                  }
                >
                  {isLoading ? "Saving..." : "Save"}
                </Button>
              </Box>
            </form>
          </FormProvider>
       
      </Paper>
    )
  }

  if (isSmall || isMedium) {
    return (
      <Box sx={{ padding: 0, backgroundColor: "#fff" }}>{renderContent()}</Box>
    )
  }

  return renderContent()
}

export default EditMember
