import { useMemo } from 'react';

// material-ui
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';

// project imports
// import useConfig from 'hooks/useConfig';
import Palette from './palette';
import Typography from './typography';
import customShadows from './shadows';
import componentStyleOverrides from './compStyleOverride';

// import componentStyleOverrides from './compStyleOverride';

declare module "@mui/material/styles" {
  interface Palette {
    custom: {
      agentAppointment: string
      contractPreparation: string
      offerNegotiation: string
    },
    orange: {
      light: string
      main: string
      dark: string
    },
  }

  interface PaletteOptions {
    custom?: {
      agentAppointment: string
      contractPreparation: string
      offerNegotiation: string
    },
    orange?: {
      light: string
      main: string
      dark: string
    },
    dark?: {
      light: string
      main: string
      dark: string
      800: string
      900: string
    },
  }
}

declare module '@mui/material/styles/createTypography' {
  interface Typography {
    commonAvatar: React.CSSProperties;
    smallAvatar: React.CSSProperties;
    mediumAvatar: React.CSSProperties;
    largeAvatar: React.CSSProperties;
    menuCaption: React.CSSProperties;
    subMenuCaption: React.CSSProperties;
  }

  interface TypographyOptions {
    commonAvatar?: React.CSSProperties;
    smallAvatar?: React.CSSProperties;
    mediumAvatar?: React.CSSProperties;
    largeAvatar?: React.CSSProperties;
    menuCaption?: React.CSSProperties;
    subMenuCaption?: React.CSSProperties;
  }
}

const ThemeCustomization = ({ children }: { children: React.ReactNode }) => {
  // const { borderRadius, fontFamily, mode, outlinedFilled, presetColor } = useConfig();
  const borderRadius = 12;
  const fontFamily = 'Roboto';
  const mode = 'light';
  const outlinedFilled = 'filled';
  const presetColor = 'default';

  const theme = useMemo(() => Palette(mode, presetColor), [mode, presetColor]);

  const themeTypography = useMemo(() => Typography(theme, borderRadius, fontFamily), [theme, borderRadius, fontFamily]);
  const themeCustomShadows = useMemo(() => customShadows(mode, theme), [mode, theme]);

  const themeOptions = useMemo(
    () => ({
      
      palette: theme.palette,
      mixins: {
        toolbar: {
          minHeight: '48px',
          padding: '16px',
          '@media (min-width: 600px)': {
            minHeight: '48px'
          }
        }
      },
      typography: themeTypography,
      customShadows: themeCustomShadows
    }),
    [theme, themeCustomShadows, themeTypography]
  );

  const themes = createTheme(themeOptions);
  themes.components = useMemo(() => componentStyleOverrides(themes, borderRadius, outlinedFilled), [themes, borderRadius, outlinedFilled]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes}>
        <CssBaseline enableColorScheme />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default ThemeCustomization;
