import { useEffect } from "react"
import { useAuth } from "react-oidc-context"

const SilentCallback = () => {
  const auth = useAuth()

  useEffect(() => {
    // This page is loaded in an iframe during silent refresh
    // We don't need to do anything here as the auth library will handle the token refresh
    if (window.parent !== window) {
        
      // We're in the silent refresh iframe
      auth.signinSilent().catch((error: Error) => {
        console.error("Silent refresh callback error:", error)
      })
    }
  }, [auth])

  return null // This component doesn't need to render anything
}

export default SilentCallback 