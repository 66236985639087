import {
  Autocomplete,
  Box,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material"
import { useAppSelector } from "../../app/hooks"
import { User } from "../../landconnex-api-client"
import { Fragment } from "react/jsx-runtime"
import {
  selectTeamMembers,
  TeamMembersStatus,
  selectStatus,
} from "../../app/slices/team-members-slice"
import { useEffect, useState } from "react"
import { selectIsAgent, selectTokenInfo, selectIsAgentAdministrator } from "../../app/slices/session-slice"

export interface SelectAgentProps {
  selectedAgent: User | null
  onAgentSelected: (agent: User | null) => void
}
const SelectAgent = ({ selectedAgent, onAgentSelected }: SelectAgentProps) => {
  const teamMembers = useAppSelector(selectTeamMembers)
  const status = useAppSelector(selectStatus)
  const [agents, setAgents] = useState<User[]>([])
  const tokenInfo = useAppSelector(selectTokenInfo)
  const isAgent = useAppSelector(selectIsAgent)
  const isAgentAdministrator = useAppSelector(selectIsAgentAdministrator)
  const handleAgentChange = (_event: any, agent: User | null): void => {
    onAgentSelected(agent)
  }
  useEffect(() => {
    if (isAgent) {
      const user = teamMembers.find(member => member.sub === tokenInfo!.sub)!
      setAgents([user])
      handleAgentChange(null, user)
    } else {
      const filteredMembers = teamMembers.filter(
        member => member.firstName !== "undefined",
      )
      setAgents(filteredMembers)
    }
  }, [teamMembers])

  if (
    status === TeamMembersStatus.loading ||
    status === TeamMembersStatus.initial
  ) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
        <CircularProgress size={24} />
      </Box>
    )
  }
  if (status === TeamMembersStatus.error) {
    return <pre>{JSON.stringify(status, null, 2)}</pre>
  }
  return (
    <Fragment>
      <Typography variant="body1">Agent</Typography>

      <Box sx={{ display: "flex", margin: "15px 0" }}>
        {!isAgentAdministrator && (
          <Typography variant="body1">
            {selectedAgent?.firstName} {selectedAgent?.lastName}
          </Typography>
        )}
        {isAgentAdministrator && (
          <Autocomplete
            disablePortal
            id="agent"
            fullWidth
            value={selectedAgent}
            onChange={handleAgentChange}
            options={agents}
            getOptionLabel={option => `${option.firstName} ${option.lastName}`}
            isOptionEqualToValue={(option, value) => option.sub === value.sub}
            renderInput={params => (
              <TextField
                {...params}
                label="Select Agent"
                
                // error={!!agentError}
                // helperText={agentError}
              />
            )}
          />
        )}
      </Box>
    </Fragment>
  )
}

export default SelectAgent
