import { FC, Fragment } from "react"
import { Grid, Typography, useTheme, TextField } from "@mui/material"
import { Notes } from "../../features/agent-appointment/components/form-wrapper"

export interface BuyerSignatureProps {}

const BuyerSignature: FC<BuyerSignatureProps> = () => {
  const theme = useTheme()

  const notes = [
    {
      title: "To the client:",
      items: [
        "By signing this disclosure statement the buyer acknowledges receipt of this disclosure statement before entering into a contract with the seller for the sale of the lot.",
      ],
    },
  ]

  return (
    <Fragment>
      <Grid item>
        <Notes notes={notes} />
        <form>
          <Grid container spacing={1}>
            {/* Participant name form */}
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" color={theme.palette.text.secondary}>
                Buyer
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={"Signature Of Buyer"}
                size="small"
                fullWidth
                autoComplete="false"
                variant="filled"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={"Signature Of Buyer"}
                size="small"
                fullWidth
                autoComplete="false"
                variant="filled"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label={"Name Of Buyer"}
                size="small"
                fullWidth
                autoComplete="false"
                variant="filled"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={"Name Of Buyer"}
                size="small"
                fullWidth
                autoComplete="false"
                variant="filled"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label={"Date"}
                size="small"
                fullWidth
                autoComplete="false"
                variant="filled"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={"Date"}
                size="small"
                fullWidth
                autoComplete="false"
                variant="filled"
              />
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Fragment>
  )
}

export default BuyerSignature
