import {  List } from "@mui/material"
import { useAppSelector } from "../../../app/hooks"
import { selectParticipants } from "../../../app/slices/participants-slice"
import ParticipantListItem from "../../../components/participant-list-item"
const ParticipantsList = () => {
  const participants = useAppSelector(selectParticipants)
  return (
    <List>
      {participants.map(participant => (
        <ParticipantListItem key={participant.id} participant={participant} />
      ))}
    </List>
  )
}
export default ParticipantsList
// import { Box, Typography, styled, Link } from "@mui/material"
// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
// } from "@mui/material"
// import { Participant } from "../../../landconnex-api-client"
// import { convertCamelCase } from "../../../helpers/helpers"

// interface ParticipantListProps {
//   participants: Participant[]
//   currentUserEmail: string
//   currentUserRole?: string
//   roles: Array<string>
//   simplifiedLayout?: boolean
// }

// const ParticipantList = ({
//   participants,
//   currentUserEmail,
//   currentUserRole,
//   roles,
//   simplifiedLayout = false,
// }: ParticipantListProps) => {
//   const roleTitles: Record<string, string> = {
//     sellerAgent: "Agent",
//     seller: "Seller",
//     sellerSolicitor: "Seller Solicitor",
//     buyer: "Buyer",
//     buyerSolicitor: "Buyer Solicitor",
//   }

//   const filteredParticipants = participants.filter(participant => {
//     return (
//       roles.includes(participant.role!) &&
//       participant.firstName &&
//       participant.phone &&
//       participant.email !== currentUserEmail &&
//       participant.role !== currentUserRole
//     )
//   })

//   return (
//     <Box>
//       {simplifiedLayout && (
//         <Typography variant="h6" >
//           Participants
//         </Typography>
//       )}

//       {simplifiedLayout ? (
//         filteredParticipants.map((participant, index) => {
//           const title = roleTitles[participant.role!] || ""

//           return (
//             <Box
//               key={index}
//               sx={{
//                 margin: "10px 0",
//                 padding: "5px 15px",
//                 borderRadius: "10px",
//               }}
//             >
//               <Box
//                 sx={{
//                   display: "flex",
//                   flexDirection: "column",
//                   alignSelf: "center",
//                 }}
//               >
//                 {title && participant.firstName ? `${title} - ` : title}
//                 {`${participant.firstName} ${participant.lastName}`}
//                 <Box>
//                   Invitation:
//                   <Typography
//                     component="span"
//                     sx={{
//                       marginLeft: 1,
//                       color:
//                         participant.invitationStatus === "accepted"
//                           ? "green"
//                           : "orange",
//                     }}
//                   >
//                     {convertCamelCase(participant.invitationStatus || "")}
//                   </Typography>
//                 </Box>
//                 <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
//                   <StyledLink
//                     href={`tel:${participant.phone}`}
//                     sx={{
//                       color: "#014597",
//                       textDecoration: "none",
//                     }}
//                   >
//                     {participant.phone}
//                   </StyledLink>
//                   <Box
//                     sx={{
//                       width: 3,
//                       height: 3,
//                       borderRadius: "50%",
//                       backgroundColor: "#868686",
//                     }}
//                   />
//                   <StyledLink
//                     href={`mailto:${participant.email}`}
//                     sx={{
//                       color: "#014597",
//                       textDecoration: "none",
//                       overflow: "hidden",
//                       textOverflow: "ellipsis",
//                       whiteSpace: "nowrap",
//                     }}
//                   >
//                     {participant.email}
//                   </StyledLink>
//                 </Box>
//               </Box>
//             </Box>
//           )
//         })
//       ) : (
//         <TableContainer>
//           <Table>
//             <TableHead>
//               <TableRow>
//                 <TableCell>Name</TableCell>
//                 <TableCell>Invitation</TableCell>
//                 <TableCell>Contact Information</TableCell>
//               </TableRow>
//             </TableHead>

//             <TableBody>
//               {filteredParticipants.map((participant, index) => {
//                 const title = roleTitles[participant.role!] || ""

//                 return (
//                   <TableRow key={index}>
//                     <TableCell>
//                       <Box sx={{ textAlign: "center" }}>
//                         {participant.firstName} {participant.lastName}
//                         <Typography
//                           variant="body2"
//                           sx={{ fontStyle: "italic" }}
//                         >
//                           {title}
//                         </Typography>
//                       </Box>
//                     </TableCell>

//                     <TableCell
//                       sx={{
//                         color:
//                           participant.invitationStatus === "accepted"
//                             ? "green"
//                             : "orange",
//                       }}
//                     >
//                       {convertCamelCase(participant.invitationStatus || "")}
//                     </TableCell>

//                     <TableCell>
//                       <Box sx={{ display: "flex", flexDirection: "column" }}>
//                         <Link
//                           href={`tel:${participant.phone}`}
//                           sx={{
//                             color: "#014597",
//                             textDecoration: "none",
//                           }}
//                         >
//                           {participant.phone}
//                         </Link>
//                         <Link
//                           href={`mailto:${participant.email}`}
//                           sx={{
//                             color: "#014597",
//                             textDecoration: "none",
//                           }}
//                         >
//                           {participant.email}
//                         </Link>
//                       </Box>
//                     </TableCell>
//                   </TableRow>
//                 )
//               })}
//             </TableBody>
//           </Table>
//         </TableContainer>
//       )}
//     </Box>
//   )
// }

// const StyledLink = styled("a")(({ theme }) => ({
//   ...theme.typography.body2,
//   color: "silver",
// }))

// export default ParticipantList
