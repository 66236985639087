import React, { FC, useEffect } from "react"
import { useForm } from "react-hook-form"
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  FormGroup,
  Grid,
  Typography,
  useTheme,
  debounce,
} from "@mui/material"
import FormInputCheckbox from "../../../components/form-input-checkbox"
import FormInputText from "../../../components/form-input-text"
import FormInputDatePicker from "../../../components/form-input-date-picker"
import {
  AgentAppointment,
  Licencee,
} from "../../../landconnex-api-client"

import { Notes } from "./form-wrapper"
import { useParams } from "react-router-dom"
import { useAppSelector, useAppDispatch } from "../../../app/hooks"

import {
  getLicencee,
  updateLicencee,
  selectAgentAppointment,
  selectLicencee,
  updateAgentAppointment,
} from "../../../app/slices/agent-appointment-slice"
import { zodResolver } from "@hookform/resolvers/zod"

import { agentAppointmentSchema } from "../../../utils/validation-schemas"
import { gridSpacing } from "../../../constants"
import AddressForm from "../../contact-details/address-form"

const LicenceeDetails: FC = () => {
  const agentAppointment = useAppSelector(selectAgentAppointment)

  const licencee = useAppSelector(selectLicencee)

  const params = useParams()
  const dispatch = useAppDispatch()
  const workspaceId = Number(params.workspaceId)
  const theme = useTheme()

  const aaSchema = agentAppointmentSchema

  const agentAppointmentForm = useForm<AgentAppointment>({
    defaultValues: agentAppointment,
    resolver: zodResolver(aaSchema),
    mode: "onChange",
  })

  const licenceeAgentForm = useForm<Licencee>({
    defaultValues: licencee,

    mode: "onChange",
  })

  const {
    control: aaControl,
    watch: aaWatch,
    formState: { isDirty: aaIsDirty },
    reset: aaReset,
  } = agentAppointmentForm

  const {
    control: agentControl,
    setValue: agentSetValue,
    watch: agentWatch,
    formState: { errors: agentErrors, isDirty: agentIsDirty },
    reset: agentReset,
  } = licenceeAgentForm

  const aaFormValues = aaWatch()
  const agentFormValues = agentWatch()

  const debouncedAASave = React.useCallback(
    debounce(async (data: AgentAppointment) => {
      dispatch(
        updateAgentAppointment({
          workspaceId: workspaceId,
          agentAppointment: data,
        }),
      )
      aaReset(data, { keepErrors: true })
    }, 1000),
    [dispatch, workspaceId],
  )

  const debouncedAgentSave = React.useCallback(
    debounce(async (data: Licencee) => {
      dispatch(
        updateLicencee({
          workspaceId: workspaceId,
          licencee: data,
        }),
      )
      agentReset(data, { keepErrors: true })
    }, 1000),
    [dispatch, workspaceId],
  )

  useEffect(() => {
    dispatch(getLicencee(workspaceId))
  }, [dispatch])

  useEffect(() => {
    if (aaIsDirty) {
      debouncedAASave(aaFormValues)
    }
  }, [aaFormValues, aaIsDirty])

  useEffect(() => {
    if (agentIsDirty) {
      debouncedAgentSave(agentFormValues)
    }
  }, [agentFormValues, agentIsDirty])

  const notes = [
    {
      title: "Licensee Type:",
      items: [
        "More than one box may be ticked if appropriate.",
        "Annexures detailing conjuncting agents may be attached if required.",
      ],
    },
    {
      title: "Licensee Name:",
      items: [
        "Where a corporation licensee is to be appointed, state the corporation’s name and licence number.",
        "Where a sole trader is to be appointed, state the individual’s name and licence number.",
      ],
    },
  ]

  return (
    <Card sx={{ border: "none", padding: 0, margin: 0 }}>
      <CardHeader title="Licencee Details" inheritlayout="true" />
      <CardContent sx={{ padding: 0, margin: 0 }}>
        <Notes notes={notes} />
        <form>
          <FormGroup>
            <Box sx={{ display: "flex" }}>
              <FormInputCheckbox
                control={aaControl}
                name="isRealEstateAgent"
                label="Real estate agent"
              />
              <FormInputCheckbox
                control={aaControl}
                name="isLettingAgent"
                label="Resident Letting Agent"
              />
              <FormInputCheckbox
                control={aaControl}
                name="isPropertyAuctioneer"
                label="Property Auctioneer"
              />
            </Box>
          </FormGroup>
        </form>
        <form>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" color={theme.palette.text.secondary}>
                Licencee
              </Typography>
            </Grid>
          
            <Grid item xs={12}>
              <Typography variant="h5">Licencee</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" color={theme.palette.text.secondary}>Licence Holder</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormInputText
                control={agentControl}
                name="companyName"
                label="Company Name"
                error={agentErrors.companyName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormInputText
                control={agentControl}
                name="tradingName"
                label="Trading name"
                error={agentErrors.tradingName}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <FormInputText
                control={agentControl}
                label="ABN"
                name="abn"
                required
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <FormInputText
                control={agentControl}
                label="ACN"
                name="acn"
                required
              />
            </Grid>

            <Grid item xs={6} sm={3}>
              <FormInputText
                control={agentControl}
                label="Licence Number"
                name="number"
                required
                error={agentErrors.number}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <FormInputDatePicker
                control={agentControl}
                name={"expiryDate"}
                label={"Licence Expiry"}
                required
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography variant="h6" color={theme.palette.text.secondary}>
                Contact details
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormInputText
                control={agentControl}
                name="contactDetails.phone"
                label="Phone"
                error={agentErrors.contactDetails?.phone}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormInputText
                control={agentControl}
                name="contactDetails.mobilePhone"
                label="Mobile Phone"
                error={agentErrors.contactDetails?.mobilePhone}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormInputText
                control={agentControl}
                name="contactDetails.email"
                label="Email"
                error={agentErrors.contactDetails?.email}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" color={theme.palette.text.secondary}>
                Address
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <AddressForm contactDetails={agentFormValues} control={agentControl} errors={agentErrors} setValue={agentSetValue} />
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  )
}

export default LicenceeDetails
