import { agentsApi } from "../../api"
import { Schedule } from "../../landconnex-api-client"
import { createAppSlice } from "../createAppSlice"
import { Status } from "./status"

export interface ScheduleState {
  status: Status
  schedule: Schedule | undefined
}

const initialState: ScheduleState = {
  status: Status.initial,
  schedule: undefined,
}

export interface UpdateScheduleArgs {
  workspaceId: number
  schedule: Schedule
}

export const scheduleSlice = createAppSlice({
  name: "schedule",
  initialState: initialState,
  reducers: create => ({
    getSchedule: create.asyncThunk<Schedule, number>(
      async workspaceId => {
        const response =
          await agentsApi.getAgentAppointmentSchedule(workspaceId)
        return response.data!
      },
      {
        pending: state => {
          state.status = Status.loading
        },
        fulfilled: (state, action) => {
          state.status = Status.ready
          state.schedule = action.payload
        },
      },
    ),
    updateSchedule: create.asyncThunk<Schedule, UpdateScheduleArgs>(
      async ({ workspaceId, schedule }) => {
        await agentsApi.putAgentAppointmentSchedule(workspaceId, schedule)
        return schedule
      },
      {
        pending: state => {
          state.status = Status.loading
        },
        fulfilled: (state, action) => {
          state.status = Status.ready
          state.schedule = action.payload
        },
      },
    ),
  }),
  selectors: {
    selectSchedule: state => state.schedule,
    selectStatus: state => state.status,
  },
})

export const { getSchedule, updateSchedule } = scheduleSlice.actions
export const { selectSchedule, selectStatus } = scheduleSlice.selectors
