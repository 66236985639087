import PropTypes from 'prop-types';
import { createContext } from 'react';

// project imports
import defaultConfig from '../config';
import useLocalStorage from '../hooks/useLocalStorage';

// initial state
const initialState = {
  ...defaultConfig,
  onChangeFontFamily: (_fontFamily: string) => {},
  onChangeBorderRadius: (_event: React.ChangeEvent<{}>, _newValue: number) => {},
  onReset: () => {}
};

// ==============================|| CONFIG CONTEXT & PROVIDER ||============================== //

const ConfigContext = createContext(initialState);

const ConfigProvider = ({ children }: { children: React.ReactNode }) => {
  const [config, setConfig] = useLocalStorage('landconnex-config', {
    fontFamily: initialState.fontFamily,
    borderRadius: initialState.borderRadius
  });

  const onChangeFontFamily = (fontFamily: string) => {
    setConfig({
      ...config,
      fontFamily
    });
  };

  const onChangeBorderRadius = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setConfig({
      ...config,
      borderRadius: newValue
    });
  };

  const onReset = () => {
    setConfig({ ...defaultConfig });
  };

  return (
    <ConfigContext.Provider
      value={{
        ...config,
        onChangeFontFamily,
        onChangeBorderRadius,
        onReset
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
}

export { ConfigProvider, ConfigContext };

ConfigProvider.propTypes = { children: PropTypes.node };
