import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { FC, Fragment, useEffect } from "react"
import FeesData from "./fees-data"

import { Box, Card, CardContent, CardHeader, debounce } from "@mui/material"
import { useForm } from "react-hook-form"
import { AgentAppointment } from "../../../landconnex-api-client"

import FormInputDatePicker from "../../../components/form-input-date-picker"
import { useParams } from "react-router-dom"
import { useAppSelector, useAppDispatch } from "../../../app/hooks"

import FormInputText from "../../../components/form-input-text"
import FormInputNumeric from "../../../components/form-input-numeric"
import {
  selectAgentAppointment,
  updateAgentAppointment,
} from "../../../app/slices/agent-appointment-slice"
import { zodResolver } from "@hookform/resolvers/zod"
import React from "react"
import BenefitsData from "./benefits-data"
import { agentAppointmentSchema } from "../../../utils/validation-schemas"

const Authorisation: FC = () => {
  const agentAppointment = useAppSelector(selectAgentAppointment)
  const params = useParams()
  const dispatch = useAppDispatch()
  const workspaceId = Number(params.workspaceId)

  const aaSchema = agentAppointmentSchema

  // const benefitsSchema = z.object<Properties<BenefitToAgent>>({
  //   id: z.number(),
  //   service: z.string().optional(),
  //   source: z.string().optional(),
  //   estimatedAmount: z.number().optional(),
  // })

  const aaForm = useForm<AgentAppointment>({
    defaultValues: agentAppointment,
    resolver: zodResolver(aaSchema),
    mode: "onChange",
  })

  // const benefitsForm = useForm<BenefitToAgent>({
  //   resolver: zodResolver(benefitsSchema),
  //   mode: "onChange",
  // })

  const {
    control: aaControl,
    watch: aaWatch,
    formState: { errors: aaErrors, isDirty: aaIsDirty },
    reset: aaReset,
  } = aaForm

  // const {
  //   control: benefitControl,
  //   watch: benefitWatch,
  //   formState: { errors: benefitErrors, isDirty: benefitIsDirty },
  //   reset: benefitReset,
  // } = benefitsForm

  const aaFormValues = aaWatch()
  // const benefitFormValues = benefitWatch()

  const debouncedAASave = React.useCallback(
    debounce(async (data: AgentAppointment) => {
      dispatch(
        updateAgentAppointment({
          workspaceId: workspaceId,
          agentAppointment: data,
        }),
      )
      aaReset(data, { keepErrors: true })
    }, 1000),
    [dispatch, workspaceId],
  )

  // const debouncedBenefitSave = React.useCallback(
  //   debounce(async (data: BenefitToAgent) => {
  //     dispatch(
  //       updateBenefitToAgent({
  //         workspaceId: workspaceId,
  //         benefitToAgent: data,
  //       }),
  //     )
  //     benefitReset(data, { keepErrors: true })
  //   }, 1000),
  //   [dispatch, workspaceId],
  // )

  useEffect(() => {
    if (aaIsDirty) {
      debouncedAASave(aaFormValues)
    }
  }, [aaFormValues, aaIsDirty])

  // useEffect(() => {
  //   if (benefitIsDirty) {
  //     debouncedBenefitSave(benefitFormValues)
  //   }
  // }, [benefitFormValues, benefitIsDirty])

  return (
    <form>
      <Card sx={{ border: "none" }}>
        <CardHeader
          title="Authorisation to incurr fees, charges and expenses"
          inheritlayout="true"
        />
        <CardContent>
          <Box
            className="section-1"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              marginBottom: 4,
              borderBottom: "1px solid silver",
              paddingBottom: 5,
            }}
          >
            <Typography variant="h6" component="span" className="roboto-bold">
              Section 1 - Adveritising / Marketing
            </Typography>
            <Box>
              <Typography variant="body1">
                <strong>To the client</strong>
              </Typography>
              <Typography variant="body2">
                Your agent may either complete this section or attach annexures
                of marketing/ advertising activities. In either case, the
                authorised amount must be written here.
              </Typography>
            </Box>
            <Grid>
              <Fragment>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormInputText
                      control={aaControl}
                      name="marketingInstructions"
                      label="Description"
                      multiline
                      minRows={5}
                      error={aaErrors.marketingInstructions}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormInputNumeric
                      control={aaControl}
                      name="marketingAuthorisedAmount"
                      label="Authorised Amount"
                      error={aaErrors.marketingAuthorisedAmount}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormInputDatePicker
                      control={aaControl}
                      label="When Payable"
                      name="marketingCostsDueDate"
                      required
                    />
                  </Grid>
                </Grid>
              </Fragment>
            </Grid>
          </Box>

          <Box
            className="section-2"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              marginBottom: 4,
              borderBottom: "1px solid silver",
              paddingBottom: 5,
            }}
          >
            <Typography variant="h6" component="span" className="roboto-bold">
              Section 2 - Repairs and maintenance
            </Typography>
            <Box>
              <Typography variant="body2">
                <i>(if applicable)</i>
              </Typography>
              <Typography variant="h6">Residential Sales</Typography>
              <Typography variant="body2">
                The maximum value of repairs and maintenance to be paid by the
                agent without prior approval by the client is
              </Typography>
            </Box>
            <Grid container>
              <Grid item xs={12} sm={4}>
                <FormInputNumeric
                  name="repairsAndMaintenanceAuthorisedAmount"
                  label="Authorised Amount"
                  control={aaControl}
                  error={aaErrors.repairsAndMaintenanceAuthorisedAmount}
                />
              </Grid>
            </Grid>
            <Typography variant="h6">Property management</Typography>
            <Typography variant="body2">Routine repairs</Typography>
            <Typography variant="body2">
              The maximum value of repairs and maintenance to be paid by the
              agent without prior approval by the client is
            </Typography>
            <Grid container>
              <Grid item xs={12} sm={4}>
                <FormInputNumeric
                  name="repairsAndMaintenancePropertyAuthorisedAmount"
                  label="Authorised Amount"
                  control={aaControl}
                  error={aaErrors.repairsAndMaintenancePropertyAuthorisedAmount}
                />
              </Grid>
            </Grid>
          </Box>
          <Box
            className="section-3"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              marginBottom: 4,
              borderBottom: "1px solid silver",
              paddingBottom: 5,
            }}
          >
            <Typography variant="h6" component="span" className="roboto-bold">
              Section 3 - Fees
            </Typography>
            <Box>
              <Typography variant="body2">
                <i>
                  Description of fees incurred by the agent on behalf of the
                  client during the term of this agreement or as agreed in
                  writing after the date of this appointment
                  <br />
                  The agent may either complete this section or attach
                  annexures.
                </i>
              </Typography>
            </Box>
            <Grid>
              <FeesData />
            </Grid>
          </Box>

          <Box
            className="section-4"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Typography variant="h6" component="span" className="roboto-bold">
              Section 4 - Agent Benefits
            </Typography>
            <Box>
              <Typography variant="body1">
                Agent’s rebate, discount, commission or benefit incurred in the
                provision of or performance of the service
              </Typography>
            </Box>
            <Grid>
              <BenefitsData />
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </form>
  )
}

export default Authorisation
