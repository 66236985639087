import React, { Fragment, useState } from "react"
import { Container, Paper, Typography } from "@mui/material"
import KonveiStepper from "../components/konvei-stepper"
import SellerAndProperty from "./components/seller-and-property"
import SellerSignature from "./components/seller-signature"
import BuyerSignature from "./components/buyer-signature"
import TitleDetails from "./components/title-details"
import LandUse from "./components/land-use"
import BuildingAndStructure from "./components/building-and-structure"
import RatesAndServices from "./components/rates-and-services"
import CommunityTitles from "./components/community-titles"

const Test2: React.FC = () => {
  const [communityScheme, setCommunityScheme] = useState<string>("")
  const [step, setStep] = useState<string>(
    "Part 1 – Seller and property details",
  )
  const steps = [
    "Part 1 – Seller and property details",
    "Part 2 – Title details, encumbrances and residential tenancy or rooming accommodation agreement",
    "Part 3 – Land use, planning and environment",
    "Part 4 – Buildings and structures",
    "Part 5 – Rates and services",
    "Part 6 – Community titles schemes and BUGTA schemes",
    "Signatures – SELLER",
    "Signatures – BUYER",
  ]

  function onStepChanged(step: string): void {
    setStep(step)
  }

  return (
    <Container sx={{ padding: { md: 5, xs: 0 }, margin: "0 auto" }}>
      <Paper sx={{ padding: 1, boxShadow: "0 1px 15px 0 rgba(0, 0, 0, 0.1)" }}>
        <Typography variant="h6">
          Current Task: Complete the Seller disclosure statement
        </Typography>

        <Fragment>
          <Typography
            variant="h6"
            sx={{ color: "primary.main", textTransform: "capitalize" }}
          >
            {step}
          </Typography>
          <KonveiStepper
            steps={steps}
            activeStep={step}
            onStepChanged={onStepChanged}
            isCompressed={true}
            top={0}
          />

          {step === "Part 1 – Seller and property details" && (
            <SellerAndProperty onCommunitySchemeChange={setCommunityScheme} />
          )}
          {step ===
            "Part 2 – Title details, encumbrances and residential tenancy or rooming accommodation agreement" && (
            <TitleDetails />
          )}
          {step === "Part 3 – Land use, planning and environment" && (
            <LandUse />
          )}
          {step === "Part 4 – Buildings and structures" && (
            <BuildingAndStructure />
          )}
          {step === "Part 5 – Rates and services" && <RatesAndServices />}
          {step === "Part 6 – Community titles schemes and BUGTA schemes" && (
            <CommunityTitles disabled={communityScheme === "no"} />
          )}
          {step === "Signatures – SELLER" && <SellerSignature />}
          {step === "Signatures – BUYER" && <BuyerSignature />}
        </Fragment>
      </Paper>
    </Container>
  )
}

export default Test2
