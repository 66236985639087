import { FC, useEffect, useState } from "react"
import {
  Box,
  Typography,
  Alert,
  FormControl,
  TextField,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Container,
  CircularProgress,
  Stack,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
} from "@mui/material"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"

import {
  getWorkflow,
  selectWorkflow,
  selectWorkspace,
  cancelSellerSolicitorInvitation,
} from "../../../../app/slices/workspaces-slice"
import { inviteSellerSolicitor } from "../../../../app/slices/contract-slice"

export enum ActingForEnum {
  Buyer,
  Seller,
}

export interface SelectSolicitorArgs {
  actingFor: ActingForEnum
  offerId?: number
}

// interface Solicitor {
//   id: number
//   name: string
//   email: string
//   firm: string
// }

const SelectSolicitor: FC<SelectSolicitorArgs> = ({ actingFor }) => {
  const dispatch = useAppDispatch()
  const [email, setEmail] = useState("")
  const [success, setSuccess] = useState(false)
  const [invitedEmail, setInvitedEmail] = useState("")
  const [emailError, setEmailError] = useState("")
  const workflow = useAppSelector(selectWorkflow)

  const workspace = useAppSelector(selectWorkspace)

  const handleInvite = () => {
    if (validateEmail(email)) {
      if (actingFor == ActingForEnum.Seller) {
        dispatch(
          inviteSellerSolicitor({
            workspaceId: workspace!.workspace!.id,
            invitation: {
              emailAddress: email,
            },
          }),
        )
      } else if (actingFor == ActingForEnum.Buyer) {
        // dispatch(
        //   inviteBuyerSolicitor({
        //     workspaceId: workspace!.workspace!.id,
        //     emailAddress: email,
        //     offerId: 2,
        //   }),
        // )
      }

      setSuccess(true)
      setInvitedEmail(email)
      setEmail("")
      setEmailError("")
    } else {
      setEmailError("Please enter a valid email address.")
    }
  }

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return re.test(String(email).toLowerCase())
  }

  // const handleSolicitorSelect = (solicitor: Solicitor) => {
  //   setEmail(solicitor.email)
  //   setSelectedSolicitorId(solicitor.id)
  //   if (success) setSuccess(false)
  //   if (emailError) setEmailError("")
  // }
  useEffect(() => {
    dispatch(getWorkflow(workspace!.workspace!.id))
  }, [dispatch])

  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const handleCancelInvitation = () => {
    setShowConfirmDialog(false)
    dispatch(cancelSellerSolicitorInvitation(workspace!.workspace!.id))
  }

  return (
    <Container>
      <Card>
        <CardHeader title={`Appoint a Solicitor`} />
        <CardContent sx={{ padding: { xs: "15px", lg: "30px" } }}>
          {(workflow?.subState ==
            "contractPreparationAwaitingSellerSolicitorSelection" ||
            workflow?.subState ==
              "agentAppointmentAwaitingSellerAdmission") && (
            <Box>
              {/* <Typography sx={{ textAlign: "justify", marginBottom: 3 }}>
                Hello username, <br />
                Thank you for using Ray White for the successful sale of your
                property.
              </Typography> 
              <p>
                <strong>Next step:</strong> Invite a solicitor to prepare your
                contract.
              </p>*/}
              <Typography variant="body1" gutterBottom>
                Solicitors are conveyancing experts who have been trained in
                property law and will ensure your interests are protected
                throughout the sale. They will help you:
              </Typography>
              <List sx={{ listStyleType: "disc", pl: 2 }}>
                <ListItem sx={{ display: "list-item", padding: "0 0 0 10px" }}>
                  <Typography variant="body1">
                    Ensure you meet all legal disclosure requirements to the
                    buyer
                  </Typography>
                </ListItem>
                <ListItem sx={{ display: "list-item", padding: "0 0 0 10px" }}>
                  <Typography variant="body1">
                    Check all documents are properly prepared and signed
                  </Typography>
                </ListItem>
              </List>

              {success && (
                <Alert severity="success" sx={{ marginBottom: 2 }}>
                  Solicitor invitation was successfully sent to {invitedEmail}.
                </Alert>
              )}
              <Box sx={{ width: "100%", my: 3 }}>
                <FormControl sx={{ minWidth: 300 }}>
                  <TextField
                    label="Solicitor Email"
                    value={email}
                    onChange={e => {
                      setEmail(e.target.value)
                      if (success) setSuccess(false)
                      if (emailError) setEmailError("")
                    }}
                    fullWidth
                    variant="filled"
                    error={Boolean(emailError)}
                    helperText={emailError}
                  />
                </FormControl>
              </Box>
            </Box>
          )}
          {workflow?.subState ==
            "contractPreparationAwaitingSellerSolicitorAdmission" && (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "100px",
                }}
              >
                <Stack direction="column" spacing={2} alignItems="center">
                  <CircularProgress />
                  <Typography variant="body1" gutterBottom align="center">
                    Waiting for solicitor to join the workspace...
                  </Typography>
                </Stack>
              </Box>
            </Box>
          )}
        </CardContent>
        <CardActions sx={{ justifyContent: "center" }}>
          {workflow?.subState ==
            "contractPreparationAwaitingSellerSolicitorSelection" && (
            <Box sx={{ width: 120 }}>
              <Button onClick={handleInvite} variant="contained" fullWidth>
                Invite
              </Button>
            </Box>
          )}
          {workflow?.subState ==
            "contractPreparationAwaitingSellerSolicitorAdmission" && (
            <Box sx={{ width: 220 }}>
              <Button
                onClick={() => setShowConfirmDialog(true)}
                variant="contained"
                fullWidth
              >
                Cancel invitation
              </Button>
            </Box>
          )}
        </CardActions>
      </Card>
      <Dialog
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
      >
        <DialogTitle>Cancel invitation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to cancel the invitation? You will need to
            invite a new solicitor.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirmDialog(false)}>Cancel</Button>
          <Button onClick={handleCancelInvitation} variant="contained">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}

export default SelectSolicitor
