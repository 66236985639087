import { FC } from "react"

import { Controller, FieldError } from "react-hook-form"
import { TextField } from "@mui/material"
import { PatternFormat } from "react-number-format"
import { FormInputProps } from "./events"

export interface FormInputPatternProps extends FormInputProps {
  format: string
  type?: "text" | "tel" | "password" | undefined
  disabled?: boolean
  error?: FieldError
}

const FormInputPattern: FC<FormInputPatternProps> = ({
  name,
  label,
  control,
  format,
  type,
  required,
  disabled,
  error,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <PatternFormat
          onChange={field.onChange}
          format={format}
          allowEmptyFormatting
          required={required}
          customInput={TextField}
          value={field.value}
          type={type}
          label={label}
          helperText={error?.message}
          fullWidth
          error={!!error}
          aria-invalid={!!error}
          autoComplete="false"
          key={name}
          
          disabled={disabled}
          InputLabelProps={{ shrink: !!field.value }}
        />
      )}
    />
  )
}

export default FormInputPattern
