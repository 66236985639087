import { useAppSelector } from "../app/hooks"
import { selectWorkspace } from "../app/slices/workspaces-slice"
import { useEffect, useState } from "react"

const useBranding = () => {
  const [primaryColour, setPrimaryColour] = useState<string>("#fff")
  const [secondaryColour, setSecondaryColour] = useState<string>("#fff")
  const [logo, setLogo] = useState<string>("")

  const workspace = useAppSelector(selectWorkspace)

  useEffect(() => {
    if (workspace && workspace.branding) {
      switch (workspace.workspace.role) {
        case "seller":
        case "sellerAgent":
        case "buyer":
          const branding1 = workspace.branding.find(
            b => b.companyType === "realEstateAgency",
          )
          setPrimaryColour(branding1?.primaryColor! || "#fff")
          setSecondaryColour(branding1?.secondaryColor! || "#fff")
          setLogo(branding1?.logo! || "")
          break
        case "sellerSolicitor":
        case "buyerSolicitor":
          const branding2 = workspace.branding.find(
            b => b.companyType === "solicitor",
          )
          setPrimaryColour(branding2?.primaryColor! || "#fff")
          setSecondaryColour(branding2?.secondaryColor! || "#fff")
          setLogo(branding2?.logo! || "")
          break
        default:
          setPrimaryColour("#fff")
          setSecondaryColour("#fff")
      }
    }
  }, [workspace])

  return {
    primaryColour,
    secondaryColour,
    logo,
  }
}

export default useBranding
