import {
  IconButton,
  ListItem,
  ListItemText,
  Theme,
  SxProps,
  Typography,
  Stack,
  Box,
} from "@mui/material"

import { Fragment } from "react"
import { fullName } from "../../../helpers/helpers"
import { Delete, Edit } from "@mui/icons-material"
import { User } from "../../../landconnex-api-client"

export interface MemberListItemProps {
  participant: User
  sx?: SxProps<Theme>
  openDeleteModal: (sub: string) => void
  openMemberDetailsModal: ( member: User) => void
}
const ParticipantListItem: React.FC<MemberListItemProps> = ({
  participant,
  sx,
  openDeleteModal,
  openMemberDetailsModal,
}) => {
  return (
    <ListItem
      sx={{
        ...sx,
      }}
      key={participant.abn}
    >
      <ListItemText
        primary={<Fragment>{fullName(participant)}</Fragment>}
        secondary={
          <Stack
            component="span"
            direction="column"
            gap={0}
            sx={{ wrap: "nowrap" }}
          >
            <Typography component="span" variant="caption">
              {participant.phone}
            </Typography>
            <Typography component="span" variant="caption">
              {participant.email}
            </Typography>
          </Stack>
        }
        sx={{ flexWrap: "wrap" }}
      />
      {participant.firstName && participant.lastName && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Box sx={{ alignSelf: "center" }}>
            <IconButton
              onClick={() => openMemberDetailsModal( participant)}
            >
              <Edit />
            </IconButton>
            <IconButton
              onClick={e => {
                e.stopPropagation()
                openDeleteModal(participant.sub!)
              }}
            >
              <Delete />
            </IconButton>
          </Box>
        </Box>
      )}
    </ListItem>
  )
}

// function getRoleDescription(role: WorkspaceRoleEnum): string {
//   switch (role) {
//     case WorkspaceRoleEnum.Seller:
//       return "Seller"
//     case WorkspaceRoleEnum.SellerAgent:
//       return "Seller Agent"
//     case WorkspaceRoleEnum.SellerSolicitor:
//       return "Seller Solicitor"
//     default:
//       return "Participant"
//   }
// }

export default ParticipantListItem
