import {
  Box,
  Button,
  
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
  CircularProgress,
  
} from "@mui/material"
import { FC, Fragment, useEffect } from "react"
import ParticipantNameForm from "../../../components/participant-name-form"
import ContactDetails from "../../../components/contact-details"
import AddressForm from "../../../components/address-form"
import { Participant } from "../../../landconnex-api-client"
import {  useForm } from "react-hook-form"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import Grid from "@mui/material/Grid2"

import {
  getMyDetails,
  MyDetailsStatus,
  selectMyDetails,
  updateMyDetails,
} from "../../../app/slices/my-details-slice"
import FormInputText from "../../../components/form-input-text"
import { selectIsWorkspaceCreator } from "../../../app/slices/session-slice"
import FormInputDatePicker from "../../../components/form-input-date-picker"
import FormInputCheckbox from "../../../components/form-input-checkbox"
import { zodResolver } from "@hookform/resolvers/zod"
import { participantSchema } from "../../../utils/validation-schemas"
import { CheckCircle } from "@mui/icons-material"
import { gridSpacing } from "../../../constants"



const MyDetails: FC = () => {
  const theme = useTheme()
  const myDetails = useAppSelector(selectMyDetails)
  const { status } = useAppSelector(state => state.myDetails)
  const dispatch = useAppDispatch()
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"))
  const isMedium = useMediaQuery(theme.breakpoints.between("sm", "md"))
  const { isNewUser } = useAppSelector(state => state.authCallback)
  const isWorkspaceCreator = useAppSelector(selectIsWorkspaceCreator)

  const isLoading = status === MyDetailsStatus.loading

  useEffect(() => {
    dispatch(getMyDetails())
  }, [dispatch])


  const formMethods = useForm<Participant>({
    defaultValues: myDetails == undefined ? {
      id: 0,
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      mobilePhone: "",
      organisationName: "",
      tradingName: "",
      abn: "",
      acn: "",
      licenceeNumber: "",
      licenceeExpiryDate: "",
      registeredForGst: false,
      streetAddress1: "",
      streetAddress2: "",
      locality: "",
      stateOrTerritory: "",
      postCode: "",
      country: "",
      middleNames: "",
      
    
    
      
    } : myDetails,
    mode: "onChange",
    resolver: zodResolver(participantSchema),
  })

  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty },
    setValue,
    reset,
  } = formMethods

  useEffect(() => {
    if (myDetails) {
      reset(myDetails)
    }
  }, [myDetails, reset])

  const handleMyDetailsFormSubmit = (data: Participant) => {
    if (isValid) {
      dispatch(updateMyDetails(data))
      reset(data)
    }
  }

  const renderContent = () => {
    return (
      <Paper sx={{ padding: { lg: 3, md: 2, sm: 1 } }}>
        <Grid component="form" onSubmit={handleSubmit(handleMyDetailsFormSubmit)} container spacing={gridSpacing}>
        {isNewUser && (
          <Grid size={12}>
            <Typography variant="h6" my={2}>
              Welcome to LandConnex! Please fill in your details to get started.
            </Typography>
          </Grid>
        )}
        {myDetails && (
          <Fragment>
              <Grid size={12}>
                <Typography variant="h6" my={2}>
                  Name & Contact
                </Typography>
              </Grid>

              <Grid container spacing={gridSpacing}>
                <Grid size={12}>
                  <ParticipantNameForm
                    control={control}
                    captureMiddleName={false}
                    errors={errors}
                    participantType="individual"
                  />
                </Grid>
                <Grid size={12}>
                  <ContactDetails control={control} errors={errors} />
                </Grid>
              </Grid>
             
              <Grid container spacing={gridSpacing}>
                <Grid size={12}>
                  <Typography variant="h6" my={2}>
                    Company
                  </Typography>
                </Grid>
                <Grid size={{lg:4, md:4, sm:12}}>
                  <FormInputText
                    name="organisationName"
                    label="Company Name"
                    control={control}
                    error={errors.organisationName}
                  />
                </Grid>
                <Grid size={{lg:4, md:4, sm:12}}>
                  <FormInputText
                    name="tradingName"
                    required
                    label="Trading Name"
                    control={control}
                    error={errors.tradingName}
                  />
                </Grid>

                <Grid size={{lg:2, md:2, sm:12}}>
                  <FormInputText
                    name="abn"
                    required
                    label="ABN"
                    control={control}
                    error={errors.abn}
                  />
                </Grid>
                <Grid size={{lg:2, md:2, sm:12}}>
                  <FormInputText
                    name="acn"
                    required
                    label="ACN"
                    control={control}
                    error={errors.acn}
                  />
                </Grid>
                {isWorkspaceCreator && (
                  <Fragment>
                    <Grid size={{lg:6, md:6, sm:12}}>
                      <FormInputText
                        name="licenceeNumber"
                        required
                        label="Licencee Number"
                        control={control}
                        error={errors.licenceeNumber}
                      />
                    </Grid>
                    <Grid size={{lg:6, md:6, sm:12}}>
                      <FormInputDatePicker
                        name="licenceeExpiryDate"
                        required
                        label="Licencee Expiry Date"
                        control={control}
                        error={errors.licenceeExpiryDate}
                      />
                    </Grid>
                  </Fragment>
                )}
                <Grid size={{lg:12, md:12, sm:12}}>
                  <FormInputCheckbox
                    control={control}
                    name="registeredForGst"
                    label="Registered for GST?"
                    error={errors.registeredForGst}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={gridSpacing}>
                <Grid size={{lg:12, md:12, sm:12}}>
                  <Typography variant="h6" mb={1} mt={2}>
                    Address
                  </Typography>
                </Grid>
                <AddressForm
                  control={control}
                  address={myDetails!}
                  setValue={(field, value) => setValue(field, value)}
                  errors={errors}
                />
              </Grid>
              <Box width={120} mx="auto" mt="30px">
                <Button
                  size="large"
                  disabled={isLoading}
                  onClick={() => {
                    handleSubmit(data => {
                      if (isValid) {
                        handleMyDetailsFormSubmit(data)
                      }
                    })()
                  }}
                  startIcon={
                    isLoading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : null
                  }
                  endIcon={
                    !isDirty ? (
                      <CheckCircle  />
                    ) : null
                  }
                >
                  {isLoading ? "Saving..." : "Save"}
                </Button>
              </Box>
            </Fragment>
        )}
        </Grid>
      </Paper>
    )
  }

  if (isSmall || isMedium) {
    return (
      <Box sx={{ padding: 0, backgroundColor: "#fff" }}>{renderContent()}</Box>
    )
  }

  return renderContent()
}

export default MyDetails
