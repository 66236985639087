import { useEffect, useState } from "react"
import { matchPath, useLocation } from "react-router-dom"
import { useTheme } from "@mui/material/styles"
import Divider from "@mui/material/Divider"
import List from "@mui/material/List"
import Typography from "@mui/material/Typography"
import { NavItem } from "../NavItem"
import { useMenu } from "../../../app/features/menu/useMenu"
import MenuItem from "../../../app/features/menu/menu-item"
import NavCollapse from "../NavCollapse"

interface INavGroupProps {
  item: MenuItem
  lastItem?: number | null
  remItems?: Array<MenuItem>
  lastItemId?: string
  selectedID?: string
  setSelectedID: (id: string) => void
}

const NavGroup = ({
  item,
  lastItem,
  remItems = [],
  lastItemId,
  setSelectedID,
}: INavGroupProps): JSX.Element => {
  const theme = useTheme()
  const { pathname } = useLocation()
  const { isDrawerOpen } = useMenu()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [currentItem, setCurrentItem] = useState<MenuItem>(item)

  const openMini = Boolean(anchorEl)

  useEffect(() => {
    if (lastItem) {
      if (item.id === lastItemId) {
        const localItem = { ...item }
        const elements = remItems.map(ele => ele.children)
        localItem.children = elements.flat(1) as MenuItem[]
        setCurrentItem(localItem)
      } else {
        setCurrentItem(item)
      }
    }
  }, [item, lastItem, remItems, lastItemId])

  const checkOpenForParent = (child: MenuItem[], id: string): void => {
    child.forEach(ele => {
      if (ele.children?.length) {
        checkOpenForParent(ele.children, currentItem.id)
      }
      if (
        ele?.url &&
        !!matchPath({ path: ele?.link ?? ele.url, end: true }, pathname)
      ) {
        setSelectedID(id)
      }
    })
  }

  const checkSelectedOnload = (data: MenuItem): void => {
    const children = data.children ?? []
    children.forEach(itemCheck => {
      if (itemCheck?.children?.length) {
        checkOpenForParent(itemCheck.children, currentItem.id)
      }
      if (
        itemCheck?.url &&
        !!matchPath(
          { path: itemCheck?.link ?? itemCheck.url, end: true },
          pathname,
        )
      ) {
        setSelectedID(currentItem.id)
      }
    })

    if (
      data?.url &&
      !!matchPath({ path: data?.link ?? data.url, end: true }, pathname)
    ) {
      setSelectedID(currentItem.id)
    }
  }

  // keep selected-menu on page load and use for horizontal menu close on change routes
  useEffect(() => {
    checkSelectedOnload(currentItem)
    if (openMini) setAnchorEl(null)
  }, [pathname, currentItem, openMini])


  // menu list collapse & items
  const items = currentItem.children?.map(menu => {
    switch (menu?.type) {
      case "collapse":
        return (
          <NavCollapse
            key={menu.id}
            menu={menu}
            level={1}
            parentId={currentItem.id}
          />
        )
      case "item":
        return <NavItem key={menu.id} item={menu} level={1} />
      default:
        return (
          <Typography key={menu?.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        )
    }
  })

  return (
    <>
      <List
        disablePadding={!isDrawerOpen}
        subheader={
          currentItem.title &&
          isDrawerOpen && (
            <Typography
              variant="caption"
              gutterBottom
              sx={{ display: "block", ...theme.typography.menuCaption }}
            >
              {currentItem.title}
              {currentItem.caption && (
                <Typography
                  variant="caption"
                  gutterBottom
                  sx={{ display: "block", ...theme.typography.subMenuCaption }}
                >
                  {currentItem.caption}
                </Typography>
              )}
            </Typography>
          )
        }
      >
        {items}
      </List>

      {/* group divider */}
      {isDrawerOpen && <Divider sx={{ mt: 0.25, mb: 1.25 }} />}
    </>
  )
}

export default NavGroup
