import {
  Button,
  useTheme,
  useMediaQuery,
  Stack,
  Fab,
  Box,
  CardActions,
  Typography,
  Card,
  CardContent,
  CardHeader,
} from "@mui/material"
import { Add as AddIcon } from "@mui/icons-material"
import OfferTable from "../../components/offer/offer-table"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  getOffers,
  postOffer,
  selectOffers,
} from "../../app/slices/offers-slice"
import { useEffect } from "react"

import OfferCard from "../../components/offer/offer-card"
import { useNavigate } from "react-router-dom"
import { selectWorkspace } from "../../app/slices/workspaces-slice"
import MainCard from "../../components/cards/MainCard"

const Offers = () => {
  const offers = useAppSelector(selectOffers)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"))
  const isMedium = useMediaQuery(theme.breakpoints.between("sm", "md"))
  const isLarge = useMediaQuery(theme.breakpoints.up("md"))
  const workspace = useAppSelector(selectWorkspace)

  useEffect(() => {
    dispatch(getOffers(workspace?.workspace.id!))
  }, [dispatch])

  // const getOfferStatus = (
  //   offerId: number,
  // ): WorkflowOffersInnerStateEnum | undefined => {
  //   const offerState = workflow?.offers.find(offer => offer.offerId === offerId)
  //   return offerState?.state
  // }

  if (isSmall || isMedium) {
    return (
      <Box>
        <Stack direction="column" spacing={1}>
          {offers.length > 0 ? (
            offers.map(offer => (
              <OfferCard key={offer.offer.id} offerId={offer.offer.id} />
            ))
          ) : (
            <Card>
              <CardHeader title="No Offers" />
              <CardContent sx={{ padding: "15px" }}>
                <Typography variant="body1" color="textSecondary">
                  No offers available.
                </Typography>
              </CardContent>
            </Card>
          )}
        </Stack>
        <Fab
          color="primary"
          aria-label="add"
          sx={{
            position: "fixed",
            bottom: 72,
            right: 16,
            zIndex: 1000,
          }}
        >
          <AddIcon />
        </Fab>
      </Box>
    )
  }
  if (isLarge) {
    const title = (
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h4">Offers</Typography>
        <Typography variant="body2">
          {offers.length > 1
            ? `${offers.length} offers`
            : `${offers.length} offer`}
        </Typography>
      </Stack>
    )
    return (
      <MainCard title={title}>
        <OfferTable />
        <CardActions
          sx={{
            padding: 1,
            display: "flex",
            justifyContent: "center",
            my: 2,
          }}
        >
          {workspace?.workspace.role === "sellerAgent" && (
            <Box width={170}>
              <Button
                color="success"
                variant="contained"
                onClick={() => {
                  dispatch(
                    postOffer({
                      workspaceId: workspace?.workspace?.id!,
                      offer: {
                        id: 0,
                        hasEncumbrances: false,
                        hasPool: false,
                        hasPoolCertificate: false,
                        hasSafetySwitches: false,
                        hasSmokeAlarms: false,
                        hasNeighbourhoodDisputes: false,
                        hasTenant: false,
                        status: "",
                        hasSellerSolicitor: false,
                      },
                    }),
                  )
                    .unwrap()
                    .then(contract => {
                      navigate(`offers/${contract.id}`)
                    })
                }}
              >
                Create New Offer
              </Button>
            </Box>
          )}
        </CardActions>
      </MainCard>
    )
  }
}

export default Offers
