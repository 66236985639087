import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  DialogTitle,
  DialogContentText,
  DialogContent,
  Dialog,
  Typography,
  DialogActions,
  useTheme,
  Stack,
  Container,
} from "@mui/material"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import Grid from "@mui/material/Grid2"
import { useEffect, useState } from "react"
import SearchForProperty from "./search-for-property"
import SelectAgent from "./select-agent"
import { useNavigate } from "react-router-dom"
import {
  Property,
  TitleSearchByLotPlan,
  User,
  ValidAddressDetails,
  WorkspaceSummaryNew,
} from "../../landconnex-api-client"

import {
  initialise,
  selectIsWorkspaceCreator,
  selectSessionStatus,
  selectTeamName,
  SessionStatus,
} from "../../app/slices/session-slice"
import {
  getWorkspace,
  postWorkspace,
  selectWorkspaceStatus,
  WorkspacesStatus,
} from "../../app/slices/workspaces-slice"
import {
  getTeamMembers,
  selectTeamMembers,
} from "../../app/slices/team-members-slice"
import MainCard from "../../components/cards/MainCard"
import createWorkspace from "/create-workspace.svg"
import { gridSpacing } from "../../constants"

const CreateWorkspacePage = () => {
  const dispatch = useAppDispatch()
  const teamName = useAppSelector(selectTeamName)
  const [agent, setAgent] = useState<User | null>(null)
  const [address, setAddress] = useState<ValidAddressDetails | null>(null)
  const [title, setTitle] = useState<TitleSearchByLotPlan | null>(null)
  const workspaceStatus = useAppSelector(selectWorkspaceStatus)
  const teamMembers = useAppSelector(selectTeamMembers)
  const isWorkspaceCreator = useAppSelector(selectIsWorkspaceCreator)
  const sessionStatus = useAppSelector(selectSessionStatus)
  const [missingTitleError, setMissingTitleError] = useState(false)
  const theme = useTheme()
  const navigate = useNavigate()

  useEffect(() => {
    if (sessionStatus == SessionStatus.initial) {
      dispatch(initialise())
    }
  }, [dispatch, sessionStatus])

  useEffect(() => {
    if (!teamMembers || teamMembers.length === 0) {
      dispatch(getTeamMembers())
    }
  }, [teamMembers, dispatch])

  const handleAgentSelected = (agent: User | null) => {
    setAgent(agent)
  }

  const handleAddressSelected = (address: ValidAddressDetails | null) => {
    setAddress(address)
  }

  const handleTitleSelected = (title: TitleSearchByLotPlan | null) => {
    setTitle(title)
  }

  const handleCreateWorkspace = () => {
    if (!title) {
      setMissingTitleError(true)
      return
    }

    const streetAddress1 = `${address?.address?.roadNumber?.first} ${address?.address?.road?.name} ${address?.address?.road?.typeCode}`
    const property: Property = {
      locality: address?.address?.locality,
      lga: address?.localGovernmentArea?.name,
      postCode: address?.address?.postcode,
      stateOrTerritory: address?.address?.state,
      streetAddress1: streetAddress1,
      titleReference: title?.titleReference,
      lot: title?.lotNumber,
      plan: title?.planNumber,
    }

    dispatch(
      postWorkspace({
        agentId: agent?.sub!,
        property: property,
      }),
    ).then(action => {
      setAgent(null)
      setAddress(null)
      setTitle(null)

      const w = action.payload as WorkspaceSummaryNew
      dispatch(getWorkspace(w.id))

      navigate(`/workspaces/${w.id}`)
    })
  }

  if (!isWorkspaceCreator) {
    return (
      <Container>
        <Card
          sx={{
            margin: "15px 0",
            boxShadow: "0 1px 15px 0 rgba(0, 0, 0, 0.1)",
          }}
        >
          <CardHeader title="Unathorized" />
          <CardContent sx={{ textAlign: "center" }}>
            <Typography variant="h5">
              You are not authorized to create a workspace.
            </Typography>
            <Typography variant="body1">
              If you do not you have a business account, you can create one by
              onboading.
            </Typography>
          </CardContent>
          <CardActions
            sx={{ flexDirection: "column", my: 5, textAlign: "center" }}
          >
            <Box sx={{ width: 280, margin: "0 auto 10px auto" }}>
              <Button
                onClick={() => navigate("/on-boarding")}
                
                variant="contained"
                size="large"
              >
                Create a business account
              </Button>
            </Box>
            <Typography variant="caption">
              If your company does not have an LandConnex account, please contact your administrator to be added to the workspace creator role.
            </Typography>
   
          </CardActions>
        </Card>
      </Container>
    )
  }
  // return (
  //   <Container>
  //     <Paper>
  //       <Grid
  //         container
  //         sx={{
  //           height: "100vh",
  //           overflow: "auto",
  //         }}
  //       >
  //         <Grid
  //           size={4}
  //           sx={{
  //             p: 2,
  //           }}
  //           bgcolor={theme.palette.info.light}
  //         >
  //           <Stack
  //             direction="column"
  //             justifyContent="flex-start"
  //             alignItems="center"
  //             height="100%"
  //           >
  //             <Logo width="100%" />

  //             <img width="80%" src={createWorkspace} alt="create workspace" />
  //             <Typography color={theme.palette.info.contrastText} variant="h1">
  //               Fast. Simple. Legally Compliant.
  //             </Typography>
  //             <Typography color={theme.palette.info.contrastText} variant="h2">
  //               Stress free property transaction management
  //             </Typography>
  //             <p></p>
  //             <List sx={{ width: "100%" }}>
  //               <ListItem sx={{ display: "flex", flexDirection: "column" }}>
  //                 <ListItemText>
  //                   <Typography
  //                     color={theme.palette.info.contrastText}
  //                     variant="h6"
  //                   >
  //                     Effortless agent appointment generation
  //                   </Typography>
  //                 </ListItemText>
  //                 <ListItemText>
  //                   <Typography
  //                     color={theme.palette.info.contrastText}
  //                     variant="h6"
  //                   >
  //                     Collaborate with the Seller Solicitor for Contract
  //                     Preparation
  //                   </Typography>
  //                 </ListItemText>
  //                 <ListItemText>
  //                   <Typography
  //                     color={theme.palette.info.contrastText}
  //                     variant="h6"
  //                   >
  //                     Manage buyer offers and negotiations
  //                   </Typography>
  //                 </ListItemText>
  //               </ListItem>
  //             </List>
  //           </Stack>
  //         </Grid>
  //         <Grid size={8} bgcolor={theme.palette.info.dark}>
  //           <Box sx={{ position: "absolute", top: 16, right: 16 }}>
  //             <IconButton
  //               onClick={() => navigate(-1)}
  //               sx={{ color: theme.palette.info.contrastText }}
  //             >
  //               <Close />
  //             </IconButton>
  //           </Box>
  //           <Box
  //             sx={{
  //               display: "flex",
  //               flexDirection: "column",
  //               alignItems: "center",
  //               justifyContent: "center",
  //               height: "100%",
  //               p: 4,
  //             }}
  //           >
  //             <MainCard
  //               sx={{
  //                 width: "100%",
  //                 backgroundColor: theme.palette.info.main,
  //                 color: theme.palette.info.contrastText,
  //                 maxWidth: "50%",
  //               }}
  //             >
  //               <Typography
  //                 color={theme.palette.info.contrastText}
  //                 variant="h1"
  //               >
  //                 Create a new workspace
  //               </Typography>

  //               <CardContent>
  //                 <SearchForProperty
  //                   selectedAddress={address}
  //                   onAddressSelected={handleAddressSelected}
  //                   selectedTitle={title}
  //                   onTitleSelected={handleTitleSelected}
  //                   missingTitleError={missingTitleError}
  //                 />
  //                 <SelectAgent
  //                   selectedAgent={agent}
  //                   onAgentSelected={handleAgentSelected}
  //                 />
  //                 <Typography variant="body1">
  //                   <b>Note:</b> Upon workspace creation, the title for the
  //                   property will be fetched from the Land Registry and added to
  //                   the workspace ($21.56).
  //                 </Typography>
  //               </CardContent>
  //               {missingTitleError && (
  //                 <Box sx={{ textAlign: "center" }}>
  //                   <Typography color="error" mt={2}>
  //                     Please select a title before creating the workspace.
  //                   </Typography>
  //                 </Box>
  //               )}
  //               <CardActions sx={{ justifyContent: "center" }}>
  //                 {workspaceStatus == WorkspacesStatus.loading && (
  //                   <Box sx={{ justifyItems: "center" }} mb={3} maxWidth={200}>
  //                     <CircularProgress />
  //                     <Typography ml={2}>Creating workspace...</Typography>
  //                   </Box>
  //                 )}
  //                 {workspaceStatus !== WorkspacesStatus.loading && (
  //                   <Button
  //                     size="large"
  //                     variant="contained"
  //                     onClick={() => handleCreateWorkspace()}
  //                     disabled={agent && address ? false : true}
  //                     sx={{
  //                       backgroundColor: theme.palette.info.light,
  //                       color: theme.palette.info.contrastText,
  //                     }}
  //                   >
  //                     Create Workspace
  //                   </Button>
  //                 )}
  //               </CardActions>
  //             </MainCard>
  //           </Box>
  //         </Grid>
  //       </Grid>
  //     </Paper>
  //   </Container>
  // )

  return (
    <Container>
      <MainCard
        title="Create a new workspace"
        content={false}
        sx={{
          borderWidth: "1px",
          borderColor: theme.palette.grey[300],
          borderStyle: "solid",
          padding: 0,
        }}
      >
        <CardContent sx={{ padding: 0 , paddingBottom: 0}}>
          <Grid container>
            <Grid size={4} sx={{ bgcolor: theme.palette.grey[200] }}>
              <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                height="100%"
              >
                <img width="80%" src={createWorkspace} alt="create workspace" />
                <Typography variant="h6" color={theme.palette.grey[600]}>
                  Fast. Simple. Legally Compliant.
                </Typography>
                <Typography variant="h6" color={theme.palette.grey[600]}>
                  Stress free property transaction management
                </Typography>
              </Stack>
            </Grid>
            <Grid size={8} sx={{ padding: gridSpacing }}>
              <Grid container spacing={gridSpacing}>
                <Grid size={12}>
                  <SearchForProperty
                    selectedAddress={address}
                    onAddressSelected={handleAddressSelected}
                    selectedTitle={title}
                    onTitleSelected={handleTitleSelected}
                    missingTitleError={missingTitleError}
                  />
                </Grid>
                <Grid size={12}>
                  <SelectAgent
                    selectedAgent={agent}
                    onAgentSelected={handleAgentSelected}
                  />
                </Grid>
              </Grid>
              {missingTitleError && (
                <Box sx={{ textAlign: "center" }}>
                  <Typography color="error" mt={2}>
                    Please select a title before creating the workspace.
                  </Typography>
                </Box>
              )}
              <Grid  mt={2}>
                {workspaceStatus == WorkspacesStatus.loading && (
                  <Box sx={{ justifyItems: "center" }} mb={3} maxWidth={200}>
                    <CircularProgress />
                    <Typography ml={2}>Creating workspace...</Typography>
                  </Box>
                )}
                {workspaceStatus !== WorkspacesStatus.loading && (
                  <Button
                    size="large"
                    variant="contained"
                    onClick={() => handleCreateWorkspace()}
                    disabled={agent && address ? false : true}
                  >
                    Create Workspace
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </MainCard>
   

      <Dialog
        open={teamName === undefined}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogTitle>Account Required</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please create an account to create a workspace.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => navigate("/workspaces")}>
            Cancel
          </Button>
          <Button variant="contained" onClick={() => navigate("/on-boarding")}>
            Create Account
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}

export default CreateWorkspacePage
