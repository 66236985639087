import Grid from "@mui/material/Grid"
import React, { useEffect } from "react"

import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { Contract } from "../../../landconnex-api-client"
import { Controller, useForm } from "react-hook-form"
import { Autocomplete, debounce, Paper, TextField } from "@mui/material"
import { selectWorkspace } from "../../../app/slices/workspaces-slice"
import {
  getContract,
  putContract,
  selectContract,
} from "../../../app/slices/contract-slice"
import { useParams } from "react-router-dom"
import {
  getOffer,
  putOffer,
  selectOffer,
} from "../../../app/slices/offers-slice"
import { gridSpacing } from "../../../constants"
const financeDateOptions = [
  "7 days from Contract Date",
  "14 days from Contract Date",
  "21 days from Contract Date",
  "30 days from Contract Date",
]
const financierOptions = ["buyer's choice"]
const financeAmountOptions = ["sufficient to complete"]

const Finance: React.FC = () => {
  const dispatch = useAppDispatch()
  const params = useParams()
  const workspaceId = Number(params.workspaceId)
  const offerId = Number(params.offerId)

  const workspace = useAppSelector(selectWorkspace)
  let contract: Contract | undefined
  if (offerId) {
    contract = useAppSelector(selectOffer)
  } else {
    contract = useAppSelector(selectContract)
  }

  const formMethods = useForm<Contract>({
    mode: "onChange",
    defaultValues: contract,
  })
  const {
    control,
    reset,
    watch,
    formState: { isDirty },
  } = formMethods

  const formValues = watch()

  const debouncedSave = React.useCallback(
    debounce(async (data: Contract) => {
      if (offerId) {
        dispatch(
          putOffer({
            workspaceId: workspace!.workspace!.id,
            offer: data,
          }),
        )
      } else {
        dispatch(
          putContract({
            workspaceId: workspace!.workspace!.id,
            contract: data,
          }),
        )
      }
      reset(data, { keepErrors: true })
    }, 1000),
    [dispatch, workspaceId],
  )

  useEffect(() => {
    if (isDirty) {
      debouncedSave(formValues)
    }
  }, [formValues, isDirty])

  useEffect(() => {
    if (offerId) {
      dispatch(getOffer({ workspaceId, offerId }))
    } else {
      dispatch(getContract(workspaceId))
    }
  }, [workspaceId, offerId, dispatch])

  return (
    <Paper sx={{ p: gridSpacing }}>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={3} sm={3}>
          <Controller
            name="financeAmount"
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                freeSolo
                options={financeAmountOptions}
                onChange={(_, value) => field.onChange(value)}
                renderInput={params => (
                  <TextField {...params} label="Finance Amount" />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={9} sm={9}>
          <Controller
            name="financier"
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                freeSolo
                options={financierOptions}
                onChange={(_, value) => field.onChange(value)}
                renderInput={params => (
                  <TextField {...params} label="Financier" />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={3} sm={3}>
          <Controller
            name="financeDate"
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                freeSolo
                options={financeDateOptions}
                onChange={(_, value) => field.onChange(value)}
                renderInput={params => (
                  <TextField {...params} label="Finance Due" />
                )}
              />
            )}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}
export default Finance
