import React, { useEffect, useState } from "react"
import {
  Box,
  Typography,
  CircularProgress,
  ListItem,
  List,
  ListItemText,
  ListItemIcon,
  AlertTitle,
  Alert,
} from "@mui/material"
import Grid from "@mui/material/Grid2"
import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"
import { useDebounceValue } from "usehooks-ts"
import { useAppDispatch, useAppSelector } from "../../app/hooks"

import {
  SearchByLotAndPlanPlanTypeEnum,
  TitleSearchByLotPlan,
  ValidAddressDetails,
} from "../../landconnex-api-client"

import {
  searchForTitleByLotAndPlan,
  selectSearchByLotAndPlan,
  selectSearchByLotAndPlanStatus,
  selectValidateAddressStatus,
  validateAddress,
} from "../../app/slices/titles-slice"
import LocationOnIcon from "@mui/icons-material/LocationOn"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { selectValidateAddress } from "../../app/slices/titles-slice"
import { Status } from "../../app/slices/status"
import { gridSpacing } from "../../constants"

export interface SearchForPropertyProps {
  selectedAddress: ValidAddressDetails | null
  onAddressSelected: (address: ValidAddressDetails | null) => void
  onTitleSelected: (title: TitleSearchByLotPlan | null) => void
  selectedTitle: TitleSearchByLotPlan | null
  missingTitleError?: boolean
}

const SearchForProperty: React.FC<SearchForPropertyProps> = ({
  // selectedAddress,
  onAddressSelected,
  onTitleSelected,
  selectedTitle,
  missingTitleError,
}) => {
  const dispatch = useAppDispatch()

  const validateAddressResults = useAppSelector(selectValidateAddress)
  // const titleSummaryStatus = useAppSelector(selectTitleSummaryStatus)

  const validateAddressStatus = useAppSelector(selectValidateAddressStatus)
  const searchByLotAndPlanResults = useAppSelector(selectSearchByLotAndPlan)
  const searchByLotAndPlanStatus = useAppSelector(
    selectSearchByLotAndPlanStatus,
  )
  // const addressDetails = useAppSelector(selectAddressDetails)
  // const addressSearchStatus = useAppSelector(selectSearchAddressStatus)
  // const getAddressDetailsStatus = useAppSelector(selectGetAddressDetailsStatus)
  const [searchTerm, setSearchTerm] = useState<string>("")
  const [selectedAddress, setSelectedAddress] =
    useState<ValidAddressDetails | null>(null)
  const [debouncedAddress] = useDebounceValue(searchTerm, 1000)

  useEffect(() => {
    if (debouncedAddress) {
      dispatch(validateAddress(debouncedAddress))
      // dispatch(lookupAddress(debouncedAddress))
    }
  }, [debouncedAddress, dispatch])

  // useEffect(() => {
  //   if (addressDetails) {
  //     dispatch(validateAddress(addressDetails.pid))
  //     onAddressSelected(addressDetails)
  //   }
  // }, [addressDetails, dispatch])

  // const handleAddressChange = (
  //   _: unknown,
  //   newValue: FlattenedAddress | null,
  // ) => {
  //   if (newValue) {
  //     setSelectedValue(newValue)
  //     onTitleSelected(null)
  //     dispatch(getAddressDetails(newValue.pid!))
  //   }
  // }

  const handleTitleSelected = (title: TitleSearchByLotPlan) => {
    onTitleSelected(title)
  }

  const handleAddressSelected = (address: ValidAddressDetails) => {
    setSelectedAddress(address)
    onAddressSelected(address)

    const validPlanTypes = [
      "BPA",
      "BRP",
      "BUP",
      "CP",
      "GRP",
      "GTA",
      "GTP",
      "LB",
      "MCP",
      "MPP",
      "MSP",
      "RP",
      "SBA",
      "SBP",
      "SP",
      "SPA",
      "SPS",
      "SRP",
      "SSP",
      "UB",
      "RP",
    ]

    const planTypeMatch = address.parcel?.plan!.match(/^([A-Za-z]+)(\d+)$/)
    let planType = planTypeMatch?.[1] || ""
    let planNumber = planTypeMatch?.[2] || ""

    if (!validPlanTypes.includes(planType)) {
      planType = "CP"
      planNumber = address.parcel?.plan!
    }

    dispatch(
      searchForTitleByLotAndPlan({
        lotNumber: address.parcel?.lot!,
        planNumber: planNumber,
        planType: planType as SearchByLotAndPlanPlanTypeEnum,
      }),
    )
  }

  return (
    <Grid container spacing={gridSpacing}>
      <Grid size={12}>
        <Typography variant="h6">
          Property
        </Typography>
      </Grid>
      <Grid size={12}>
        <Typography variant="body1">
          Enter the address of the property, then select the matching address
          from the dropdown.
        </Typography>
      </Grid>
      <Grid size={12}>
      <Box sx={{ display: "flex", margin: "15px 0", alignItems: "center" }}>
        <Autocomplete
          fullWidth
          autoComplete
          onChange={(_, value) => {
            handleAddressSelected(value!)
          }}
          value={selectedAddress}
          filterOptions={x => x}
          isOptionEqualToValue={(option, value) =>
            option.parcel?.lot === value.parcel?.lot &&
            option.parcel?.plan === value.parcel?.plan
          }
          options={validateAddressResults}
          onInputChange={(_, value) => setSearchTerm(value)}
          getOptionKey={option => {
            const key = option.metaData?.find(x => x.name === "key")
            return key?.value!
          }}
          getOptionLabel={option => {
            return (
              option.metaData?.find(x => x.name === "FullAddressString")
                ?.value || ""
            )
          }}
          renderOption={(props, option) => {
            const { key, ...optionProps } = props

            const address = option.metaData?.find(
              x => x.name === "FullAddressString",
            )

            return (
              <li key={key} {...optionProps}>
                <Grid container sx={{ alignItems: "center" }}>
                  <Grid size={44}>
                    <LocationOnIcon sx={{ color: "text.secondary" }} />
                  </Grid>
                  <Grid  >
                    {address?.value}
                    {option.localGovernmentArea?.name ? (
                      <Typography
                        variant="body2"
                        sx={{ color: "text.secondary" }}
                      >
                        {option.localGovernmentArea?.name}
                      </Typography>
                    ) : null}
                    {option.parcel?.lot ? (
                      <Typography
                        variant="body2"
                        sx={{ color: "text.secondary" }}
                      >
                        Lot {option.parcel?.lot} on {option.parcel?.plan}
                      </Typography>
                    ) : null}
                  </Grid>
                </Grid>
              </li>
            )
          }}
          // renderOption={(props, option) => (
          //   <Box>
          //     <Typography>
          //       Address:{" "}
          //       {
          //         option.metaData?.find(x => x.name === "FullAddressString")
          //           ?.value
          //       }
          //     </Typography>
          //     <Typography>LGA: {option.localGovernmentArea?.name}</Typography>
          //     <Typography>
          //       Lot/Plan: {option.parcel?.lot} on {option.parcel?.plan}
          //     </Typography>
          //   </Box>
          // )}
          renderInput={params => (
            <TextField
              {...params}
              fullWidth
              label="Address"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {validateAddressStatus === Status.loading && (
                      <CircularProgress color="inherit" size={24} />
                    )}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </Box>
      {searchByLotAndPlanStatus === Status.loading && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress />
          <Typography ml={2}>
            Searching title registry. This can take up to 30 seconds...
          </Typography>
        </Box>
      )}
      </Grid>

      {searchByLotAndPlanResults &&
        searchByLotAndPlanStatus === Status.ready && (
            <Grid size={12}>
              <Typography variant="body1">
              Select the title that matches the property being sold by the
              seller/s.
            </Typography>

            <List>
              {searchByLotAndPlanResults?.map((title, index) => (
                <ListItem
                  key={index}
                  sx={{
                    outline:
                      selectedTitle?.titleReference === title.titleReference
                        ? "2px solid lightblue"
                        : missingTitleError
                          ? "1px solid red"
                          : "1px solid #ccc",
                    borderRadius: "8px",
                    margin: "8px 0",
                    padding: "12px",
                    cursor: "pointer",
                    transition: "background-color 0.3s, box-shadow 0.3s",
                    backgroundColor:
                      selectedTitle?.titleReference === title.titleReference
                        ? "#f0f8ff"
                        : "#fff",
                    "&:hover": {
                      backgroundColor: "#f5f5f5",
                      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                    },
                  }}
                  onClick={() => handleTitleSelected(title)}
                >
                  <ListItemText
                    primary={
                      selectedAddress?.metaData?.find(
                        x => x.name === "FullAddressString",
                      )?.value
                    }
                    secondary={
                      <>
                        {title.titleDescription !== "FEE SIMPLE" && (
                          <Alert severity="warning">
                            <AlertTitle>Comatose Title</AlertTitle>A 'COMATOSE'
                            title refers to the title containing the land
                            description underlying a building unit development.
                            If for some reason the building unit ceases to exist
                            (e.g. fire, redevelopment etc.) the underlying
                            parcel description becomes the current land
                            description.
                          </Alert>
                        )}
                        <Typography
                          component="span"
                          variant="subtitle2"
                          display="block"
                        >
                          Title: {title.titleReference}
                        </Typography>
                        <Typography
                          component="span"
                          variant="subtitle2"
                          display="block"
                        >
                          Lot on Plan: {title.lotNumber} on {title.planNumber}
                        </Typography>
                        <Typography
                          component="span"
                          variant="subtitle2"
                          display="block"
                        >
                          Title Description: {title.titleDescription}
                        </Typography>
                      </>
                    }
                  />
                  {selectedTitle?.titleReference === title.titleReference && (
                    <ListItemIcon>
                      <CheckCircleIcon color="primary" />
                    </ListItemIcon>
                  )}
                </ListItem>
              ))}
            </List>
          </Grid>
        )}
    </Grid>
  )
}

export default SearchForProperty
