import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import Avatar from "@mui/material/Avatar"
import Box from "@mui/material/Box"
import LogoSection from "../logo-section"
import { useMenu } from "../../app/features/menu/useMenu"
import { Menu } from "@mui/icons-material"
import ProfileSection from "./ProfileSection"

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
  const theme = useTheme()
  const downMD = useMediaQuery(theme.breakpoints.down("md"))

  const { isDrawerOpen, handleDrawerOpen } = useMenu()

  const drawerOpen = isDrawerOpen

  return (
    <>
      {/* logo & toggler button */}
      <Box sx={{ width: downMD ? "auto" : 228, display: "flex" }}>
        <Box
          component="span"
          sx={{ display: { xs: "none", md: "block" }, flexGrow: 1 }}
        >
          <LogoSection />
        </Box>
        <Avatar
          variant="rounded"
          sx={{
            ...theme.typography.commonAvatar,
            ...theme.typography.mediumAvatar,
            overflow: "hidden",
            transition: "all .2s ease-in-out",
            bgcolor: "secondary.light",
            color: "secondary.dark",
            "&:hover": {
              bgcolor: "secondary.dark",
              color: "secondary.light",
            },
          }}
          onClick={() => handleDrawerOpen(!drawerOpen)}
          color="inherit"
        >
          <Menu />
        </Avatar>
      </Box>

      {/* header search */}
      {/* <SearchSection /> */}
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />

      {/* notification */}
      {/* <NotificationSection /> */}

      {/* profile */}
      <ProfileSection />
    </>
  )
}

export default Header
