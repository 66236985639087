import { selectWorkspace } from "../../../app/slices/workspaces-slice"
import { useAppSelector } from "../../../app/hooks"
import { selectWorkflow } from "../../../app/slices/workspaces-slice"
import AgentAppointmentPage from "../../agent-appointment/agent-appointment-page"
import ContractStepperPage from "../../contract-stepper/contract-stepper-page"
import Grid from "@mui/material/Grid2"
import Offers from "../../offers"
import AwaitingTaskCompletion from "../../../components/awaiting-task-completion"
import SelectSolicitor from "./actions/select-solicitor"
import { ActingForEnum } from "./actions/select-solicitor"
import AgentAppointmentAwaitingSigning from "../../agent-appointment/agent-appointment-awating-signing"
import { Fragment } from "react/jsx-runtime"

const WorkflowAction = () => {
  const workspace = useAppSelector(selectWorkspace)
  const workflow = useAppSelector(selectWorkflow)

  if (workspace == null || workspace.workspace == null) {
    return <></>
  }

  switch (workflow?.subState) {
    case "agentAppointmentPreparing":
      if (workspace?.workspace!.role == "sellerAgent") {
        return <AgentAppointmentPage />
      }
      return <>Nothing to do</>
    case "agentAppointmentAwaitingSigning":
      return <AgentAppointmentAwaitingSigning />
    case "agentAppointmentAwaitingSellerAdmission":
      if (workspace?.workspace!.role == "sellerAgent") {
        return (
          <Fragment>
            <SelectSolicitor actingFor={ActingForEnum.Seller} />
            <Offers />
          </Fragment>
        )
      }
      return (
        <AwaitingTaskCompletion>
          Waiting for sellers to join the workspace
        </AwaitingTaskCompletion>
      )
    case "contractPreparationAwaitingSellerSolicitorSelection":
      if (workspace?.workspace!.role == "sellerAgent") {
        return (
          <Grid container spacing={1}>
            <Grid size={12}>
              <SelectSolicitor actingFor={ActingForEnum.Seller} />
            </Grid>
            <Grid size={12}>
              <Offers />
            </Grid>
          </Grid>
        )
      }
      if (workspace?.workspace!.role == "seller") {
        return <SelectSolicitor actingFor={ActingForEnum.Seller} />
      }
      return (
        <AwaitingTaskCompletion>
          Waiting for buyer solicitor to be selected
        </AwaitingTaskCompletion>
      )
    case "contractPreparationAwaitingSellerSolicitorAdmission":
      return (
        <Fragment>
          <SelectSolicitor actingFor={ActingForEnum.Seller} />
          <Offers />
        </Fragment>
      )

    case "contractPreparationPreparing":
      if (
        workspace?.workspace!.role == "sellerAgent" ||
        workspace?.workspace!.role == "seller"
      ) {
        return <Offers />
      }
      if (workspace?.workspace!.role == "sellerSolicitor") {
        return <ContractStepperPage />
      }
      return (
        <AwaitingTaskCompletion>
          Waiting for seller solicitor to be selected
        </AwaitingTaskCompletion>
      )
    case "acceptingOffers":
      return (
        <Fragment>
          <Offers />
       
        </Fragment>
      )

    case "contractSigned":
      return <Offers />
    default:
      return <></>
  }

  return <></>
}

export default WorkflowAction
