import { FC, Fragment, useState } from "react"
import {
  Typography,
  Grid,
  Box,
  IconButton,
  OutlinedInput,
  InputAdornment,
  Button,
  Divider,
} from "@mui/material"
import KonveiModal from "../../../components/modal/konvei-modal"
import SearchIcon from "@mui/icons-material/Search"
import SortByAlphaOutlinedIcon from "@mui/icons-material/SortByAlphaOutlined"
import { User } from "../../../landconnex-api-client"
import { useAppDispatch } from "../../../app/hooks"

import MemberListItem from "./member-list-item"
import { removeUserFromTeam } from "../../../app/slices/team-members-slice"
import EditMember from "./edit-member"

interface MembersListProps {
  members: Partial<User>[]
  handleDeleteMember: (email: string) => void
  handleUpdateMember: (updatedMember: Partial<User>) => void
}

const MembersList: FC<MembersListProps> = ({ members = [] }) => {
  const dispatch = useAppDispatch()
  const [deleteSub, setDeleteSub] = useState<string>("")
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>("")
  const [sortAsc, setSortAsc] = useState<boolean>(true)
  const [selectedMember, setSelectedMember] = useState<Partial<User> | null>(
    null,
  )

  const openDeleteModal = (sub: string) => {
    setDeleteSub(sub)
    setModalOpen(true)
  }

  const closeDeleteModal = () => {
    setDeleteSub("")
    setModalOpen(false)
  }

  const confirmDelete = () => {
    dispatch(removeUserFromTeam(deleteSub)).then(_ => {
      closeDeleteModal()
    })
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }

  const filteredMembers = members.filter(member =>
    member.firstName?.toLowerCase().includes(searchValue.toLowerCase()),
  )

  const toggleSorting = () => {
    setSortAsc(prevSortAsc => !prevSortAsc)
  }

  const sortedMembers = filteredMembers.sort((a, b) => {
    if (sortAsc) {
      return a.firstName!.localeCompare(b.firstName!)
    } else {
      return b.firstName!.localeCompare(a.firstName!)
    }
  })

  const openMemberDetailsModal = (member: Partial<User>) => {
    setSelectedMember(member)

    setModalOpen(true)
  }

  const renderDeleteConfirmation = () => (
    <Box sx={{ p: 5 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Confirm Deletion
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Are you sure you want to remove this member?
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2, gap: 3 }}>
        <Button color="inherit" onClick={closeDeleteModal}>
          Cancel
        </Button>
        <Button onClick={confirmDelete}>Delete</Button>
      </Box>
    </Box>
  )

  return (
    <Fragment>
      <OutlinedInput
        id="input-search-header"
        value={searchValue}
        size="small"
        onChange={handleSearchChange}
        placeholder="Search Member"
        sx={{ width: { xs: "100%", md: "500px" }, borderRadius: "5px" }}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
      />
      <IconButton onClick={toggleSorting}>
        <SortByAlphaOutlinedIcon />
      </IconButton>

      <Grid container spacing={1}>
        {sortedMembers.map((member, index) => (
          <Grid item xs={12} key={index}>
            <MemberListItem
              participant={member}
              openDeleteModal={openDeleteModal}
              openMemberDetailsModal={openMemberDetailsModal}
            />
            <Divider />
          </Grid>
        ))}
      </Grid>

      <KonveiModal open={modalOpen} onClose={closeDeleteModal}>
        {deleteSub
          ? renderDeleteConfirmation()
          : selectedMember && (
              <EditMember user={selectedMember} onClose={closeDeleteModal} />
            )}
      </KonveiModal>
    </Fragment>
  )
}

export default MembersList
