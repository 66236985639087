import TextField from "@mui/material/TextField"
import { FC } from "react"
import { FieldError } from "react-hook-form"
import { NumericFormat } from "react-number-format"

export interface CurrencyFieldProps {
  amount?: number
  label: string
  required?: boolean
  onChange?: (amount: number | undefined) => void
  disabled?: boolean
  error?: FieldError | undefined
}
const CurrencyField: FC<CurrencyFieldProps> = ({
  amount,
  required,
  label,
  onChange,
  disabled = false,
  error,
}) => {
  let val
  if (amount) {
    val = amount / 100
    val = val.toFixed(2)
  }
  return (
    <NumericFormat
      value={val}
      customInput={TextField}
      prefix="$ "
      
      fixedDecimalScale
      label={label}
      required={required}
      fullWidth
      decimalScale={2}
      disabled={disabled}
      onValueChange={values => {
        values.floatValue
        let t = values.floatValue! * 100
        onChange!(Math.round(t))
      }}
      thousandSeparator
      helperText={error?.message}
      error={!!error}
      aria-invalid={error ? true : false}
    />
  )
}

export default CurrencyField
