import { Breadcrumbs, Box, AppBar, Toolbar, Container } from "@mui/material"
import { Outlet } from "react-router-dom"
import Footer from "../footer"
import { borderRadius } from "../../constants"
import MainContentStyled from "./MainContentStyled"
import Sidebar from "../sidebar"
import Header from "../header"
const MainLayout = () => {
  // const theme = useTheme()
  // const downMD = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <Box sx={{ display: "flex" }}>
      {/* header */}
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{ bgcolor: "background.default" }}
      >
        <Toolbar sx={{ p: 2 }}><Header /></Toolbar>
      </AppBar>

      {/* menu / drawer */}
      <Sidebar />

      {/* main content */}
      <MainContentStyled {...{ borderRadius, open: true }}>
        <Box
          sx={{
            ...{ px: { xs: 0 } },
            minHeight: "calc(100vh - 128px)",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* breadcrumb */}
          <Breadcrumbs />
          <Container maxWidth="xl">
            <Outlet />
          </Container>
          <Footer />
        </Box>
      </MainContentStyled>
    </Box>
  )
}

export default MainLayout
