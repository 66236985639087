import { z } from "zod"
import {
  AgentAppointment,
  AgentAppointmentAppointmentTermTypeEnum,
  AgentAppointmentAppointmentTypeEnum,
  AgentAppointmentCommissionPaymentTermEnum,
  AgentAppointmentCommissionTypeEnum,
  Contract,
  Fee,
  OnBoardingCompanyTypeEnum,
  OnBoarding,
  Participant,
  ParticipantInvitationStatusEnum,
  ParticipantParticipantTypeEnum,
  ParticipantRoleEnum,
  Property,
  Schedule,
  ScheduleF1Enum,
  Team,
  TeamCompanyTypeEnum,
  Address,
  Name,
  NameTypeEnum,
  ContactDetails,
  ScheduleG1Enum,
} from "../landconnex-api-client"
import { Properties } from "../helpers/helpers"

const abnValidator = z.string().refine(
  abn => {
    // Remove any non-digit characters and ensure it's 11 digits
    const cleanAbn = abn.replace(/\D/g, "")
    if (cleanAbn.length !== 11) return false

    // Weights for each position
    const weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19]

    // Subtract 1 from first digit
    const digits = cleanAbn.split("").map((d, i) => {
      const num = parseInt(d)
      return i === 0 ? num - 1 : num
    })

    // Calculate weighted sum
    const sum = digits.reduce((acc, digit, index) => {
      return acc + digit * weights[index]
    }, 0)

    // Valid if sum is divisible by 89
    return sum % 89 === 0
  },
  { message: "Invalid ABN" },
)

const acnValidator = z.string().refine(
  acn => {
    // Remove any non-digit characters
    const cleanAcn = acn.replace(/\D/g, "")

    // Check length is 9 digits
    if (cleanAcn.length !== 9) return false

    // Convert to array of numbers
    const digits = cleanAcn.split("").map(d => parseInt(d))

    // Get check digit (last digit)
    const checkDigit = digits[8]

    // Weights for each position
    const weights = [8, 7, 6, 5, 4, 3, 2, 1]

    // Calculate sum of digit * weight products
    const sum = digits.slice(0, 8).reduce((acc, digit, index) => {
      return acc + digit * weights[index]
    }, 0)

    // Calculate remainder when divided by 10
    const remainder = sum % 10

    // Calculate complement (what needs to be added to remainder to get to 10)
    const complement = (10 - remainder) % 10

    // Valid if complement equals check digit
    return complement === checkDigit
  },
  { message: "Invalid ACN" },
)

const australianPostcodeSchema = z.string().refine(
  (postcode: any) => {
    // Remove any whitespace and check it's exactly 4 digits
    const cleaned = postcode.replace(/\s/g, "")
    return /^\d{4}$/.test(cleaned)
  },
  { message: "Invalid Australian postcode - must be 4 digits" },
)
const australianPhoneSchema = z.string().refine(
  (phone: any) => {
    const cleaned = phone.replace(/[^\d]/g, "")

    return (
      /^04\d{8}$/.test(cleaned) ||
      /^0[23578]\d{8}$/.test(cleaned) ||
      /^\+61[45]\d{8}$/.test(cleaned) ||
      /^\+61[23578]\d{8}$/.test(cleaned)
    )
  },
  { message: "Invalid Australian phone number" },
)
const participantSchema = z
  .object<Properties<Participant>>({
    id: z.number().optional(),
    firstName: z
      .string()
      .min(2, { message: "Name must be at least 2 characters" })
      .max(50, { message: "Name cannot exceed 50 characters" }),
    lastName: z
      .string()
      .min(2, { message: "Name must be at least 2 characters" })
      .max(50, { message: "Name cannot exceed 50 characters" }),
    participantType: z.nativeEnum(ParticipantParticipantTypeEnum).optional(),
    externalReference: z.string().optional(),
    role: z.nativeEnum(ParticipantRoleEnum).optional(),
    licenceeNumber: z.string().optional(),
    licenceeExpiryDate: z.string().optional(),
    tradingName: z.string().optional(),
    createdAt: z.string().optional(),
    createdBy: z.string().optional(),
    modifiedAt: z.string().optional(),
    modifiedBy: z.string().optional(),
    invitationStatus: z.nativeEnum(ParticipantInvitationStatusEnum).optional(),
    middleNames: z.string().optional(),
    streetAddress1: z
      .string()
      .min(1, { message: "Street Address 1 is required" }),
    streetAddress2: z.string().optional(),
    locality: z.string().min(1, { message: "Locality is required" }),
    stateOrTerritory: z
      .string()
      .min(1, { message: "State or Territory is required" }),
    postCode: australianPostcodeSchema,
    country: z.string().optional(),
    phone: australianPhoneSchema,
    mobilePhone: australianPhoneSchema,
    countryCode: z.string().optional(),
    email: z.string().email({ message: "Invalid email address" }),
    organisationName: z
      .string()
      .min(1, { message: "Organisation Name is required" }),
    abn: abnValidator,

    acn: acnValidator,
    registeredForGst: z.boolean(),
  })
  .superRefine(({ role, licenceeNumber }, refinementContext) => {
    if (role === ParticipantRoleEnum.SellerAgent && !licenceeNumber) {
      refinementContext.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Licencee Number is required",
        path: ["licenceeNumber"],
      })
    }
    if (licenceeNumber && licenceeNumber.length > 7) {
      refinementContext.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Licencee Number cannot exceed 7 characters",
        path: ["licenceeNumber"],
      })
    }
  })

const agentAppointmentSchema = z.object<Properties<AgentAppointment>>({
  appointmentIsAuction: z.boolean(),
  appointmentIsLeasing: z.boolean(),
  appointmentIsLetting: z.boolean(),
  appointmentIsOther: z.boolean(),
  appointmentIsOtherDescription: z.string().optional(),
  appointmentIsPurchase: z.boolean(),
  appointmentIsSale: z.boolean(),
  appointmentTermEnd: z.string().optional(),
  appointmentTermStart: z.string().optional(),
  appointmentTermType: z.nativeEnum(AgentAppointmentAppointmentTermTypeEnum),
  appointmentType: z.nativeEnum(AgentAppointmentAppointmentTypeEnum),
  auctionDate: z.string().optional(),
  commissionAmount: z.number().optional(),
  commissionInstructions: z.string().optional(),
  commissionPaymentTerm: z.nativeEnum(
    AgentAppointmentCommissionPaymentTermEnum,
  ),
  commissionPaymentTermInstructions: z.string().optional(),
  commissionPaymentTermOtherAppointments: z.string().optional(),
  commissionType: z.nativeEnum(AgentAppointmentCommissionTypeEnum),
  id: z.number().optional(),
  instructions: z.string().optional(),
  isLettingAgent: z.boolean(),
  isPropertyAuctioneer: z.boolean(),
  isRealEstateAgent: z.boolean(),
  isSoleOrExclusiveContinuation: z.boolean(),
  licenceeExpiryDate: z.string().optional(),
  licenceeName: z.string().optional(),
  licenceeNumber: z.string().optional(),
  marketingAuthorisedAmount: z.number().optional(),
  marketingCostsDueDate: z.string().optional(),
  marketingInstructions: z.string().optional(),
  priceLetting: z.number().optional(),
  priceListLow: z.number().optional(),
  priceListHigh: z.number().optional(),
  priceMarketingRangeLow: z.number().optional(),
  priceMarketingRangeHigh: z.number().optional(),
  priceReserve: z.number().optional(),
  repairsAndMaintenanceAuthorisedAmount: z.number().optional(),
  repairsAndMaintenancePropertyAuthorisedAmount: z.number().optional(),
  workspaceId: z.number().optional(),
  tradingName: z.string().optional(),
})

const propertySchema = z.object<Properties<Property>>({
  area: z.string().min(1, { message: "Area is required" }),
  holdingType: z.string().min(1, { message: "Holding Type is required" }),
  planType: z.string().min(1, { message: "Plan Type is required" }),
  isBuiltOn: z.boolean().optional(),
  lga: z.string().min(2, { message: "must be at least 2 characters" }),
  locality: z.string().min(1, { message: "Locality is required" }),
  lot: z.string().min(1, { message: "Lot is required" }),
  plan: z.string().min(1, { message: "Plan is required" }),
  postCode: z.string().min(1, { message: "Post Code is required" }),
  presentUse: z.string().min(1, { message: "Present Use is required" }),
  stateOrTerritory: z
    .string()
    .min(1, { message: "State or Territory is required" }),
  streetAddress1: z
    .string()
    .min(1, { message: "Street Address 1 is required" }),
  streetAddress2: z.string().optional(),
  titleReference: z.string().min(2, { message: "Title Reference is required" }),
})

const contractSchema = z.object<Properties<Contract>>({
  depositHolderBank: z.string().min(1, "Deposit Holder Bank is required"),
  depositHolderBsb: z
    .string()
    .min(6, "Deposit Holder BSB must be 6 characters")
    .max(6, "Deposit Holder BSB must be 6 characters"),
  depositHolderAccountName: z
    .string()
    .min(1, "Deposit Holder Account Name is required"),
  depositHolderAccountNumber: z
    .string()
    .min(1, "Deposit Holder Account Number is required"),
  depositHolderName: z.string().min(1, "Deposit Holder Name is required"),
  account: z.string().min(1, "Account is required"),
  bank: z.string().min(1, "Bank is required"),
  bsb: z.string().min(6, "BSB is required").max(6, "BSB is required"),
  buildingAndPestInspection: z.string().optional(),
  buyerRegisteredForGst: z.boolean().optional(),
  createdAt: z.string().optional(),
  createdBy: z.string().optional(),
  id: z.number(),
  defaultInterestRate: z.string().optional(),
  depositBalanceDue: z.string().optional(),
  depositBalance: z.number().optional(),
  depositHolder: z.string().optional(),
  depositInitial: z.number().optional(),
  depositInitialDue: z.string().optional(),
  encumbrances: z.string().optional(),
  excludedFixtures: z.string().optional(),
  externalReference: z.string().optional(),
  financeAmount: z.string().optional(),
  gstPaymentRequired: z.boolean().optional(),
  financeDate: z.string().optional(),
  financier: z.string().optional(),
  hasEncumbrances: z.boolean(),
  hasNeighbourhoodDisputes: z.boolean(),
  hasPool: z.boolean(),
  hasRtaAgreement: z.boolean(),
  lastRentIncreaseDate: z.string().optional(),
  hasPoolCertificate: z.boolean(),
  hasSafetySwitches: z.boolean(),
  hasSellerSolicitor: z.boolean(),
  hasSmokeAlarms: z.boolean(),
  hasTenant: z.boolean(),
  includedChattels: z.string().optional(),
  purchasePrice: z.number().optional(),
  buyerSpecialConditions: z.string().optional(),
  sellerSpecialConditions: z.string().optional(),
  status: z.string(),
  tenancyBond: z.number().optional(),
  tenancyRent: z.number().optional(),
  tenancyTenantName: z.string().optional(),
  tenancyTermAndOptions: z.string().optional(),
  tenancyTermEnd: z.string().optional(),
  tenancyTermStart: z.string().optional(),
  trustAccount: z.string().optional(),
  modifiedAt: z.string().optional(),
  modifiedBy: z.string().optional(),
  reference: z.string().optional(),
  settlementDate: z.string().optional(),
})

const feeSchema = z.object<Properties<Fee>>({
  id: z.number(),
  description: z.string().min(1, { message: "Description is required" }),
  whenPayable: z.string().min(1, { message: "When Payable is required" }),
  estimatedAmount: z
    .number()
    .min(1, { message: "Estimated Amount is required" }),
})

const benefitSchema = z.object({
  id: z.number(),
  service: z.string().min(1, { message: "Service is required" }),
  source: z.string().min(1, { message: "Source is required" }),
  estimatedAmount: z
    .number()
    .min(1, { message: "Estimated Amount is required" }),
})

const scheduleSchema = z.object<Properties<Schedule>>({
  a1: z.boolean(),
  b1: z.boolean(),
  b2: z.boolean(),
  c1: z.boolean(),
  c2: z.boolean(),
  d1: z.boolean(),
  e1: z.boolean(),
  e2: z.boolean(),
  f1: z.nativeEnum(ScheduleF1Enum),
  g1: z.nativeEnum(ScheduleG1Enum),
  h1: z.boolean(),
})

const nameSchema = z.object<Properties<Name>>({
  firstName: z.string().min(1, { message: "First Name is required" }),
  lastName: z.string().min(1, { message: "Last Name is required" }),
  middleName: z.string().optional(),
  organisationName: z.string().optional(),
  type: z.nativeEnum(NameTypeEnum).optional(),
})

const addressSchema = z.object<Properties<Address>>({
  streetAddress1: z
    .string()
    .min(1, { message: "Street Address 1 is required" }),
  streetAddress2: z.string().optional(),
  locality: z.string().min(1, { message: "Locality is required" }),
  stateOrTerritory: z
    .string()
    .min(1, { message: "State or Territory is required" }),
  postCode: australianPostcodeSchema,
  country: z.string().optional(),
})

const contactDetailsSchema = z.object<Properties<ContactDetails>>({
  name: nameSchema,
  address: addressSchema,
  phone: australianPhoneSchema,
  mobilePhone: australianPhoneSchema,
  email: z.string().email({ message: "Invalid email address" }),
})

const onboardingSchema = z.object<Properties<OnBoarding>>({
  companyName: z.string().min(1, { message: "Company Name is required" }),
  companyType: z.nativeEnum(OnBoardingCompanyTypeEnum),
  abn: abnValidator,
  acn: acnValidator,
  physicalAddress: addressSchema,
  primaryContactName: nameSchema,
  email: z.string().email({ message: "Invalid email address" }),
  phone: australianPhoneSchema,
  licenceeExpiryDate: z.string().optional(),
  licenceeNumber: z.string().optional(),
  mobilePhone: australianPhoneSchema,
  registeredForGst: z.boolean(),
})

const teamSchema = z.object<Properties<Team>>({
  abn: abnValidator,
  acn: acnValidator,
  primaryColour: z.string().optional(),
  websiteUrl: z.string().optional(),
  companyType: z.nativeEnum(TeamCompanyTypeEnum),
  primaryContact: contactDetailsSchema,
  id: z.number(),
  name: z.string().min(1, { message: "Name is required" }),

  secondaryColour: z.string().optional(),
  registeredForGst: z.boolean().optional(),
  customerID: z.string().optional(),
})

export {
  participantSchema,
  australianPostcodeSchema,
  australianPhoneSchema,
  agentAppointmentSchema,
  propertySchema,
  contractSchema,
  feeSchema,
  benefitSchema,
  scheduleSchema,
  teamSchema,
  onboardingSchema,
}
