import { IconButton, ListItem, ListItemText } from "@mui/material"
import { AnnexureDocument } from "../landconnex-api-client"
import { Fragment } from "react/jsx-runtime"
import { Download as DownloadIcon } from "@mui/icons-material"
import { useTheme } from "@mui/material/styles"
import { Link } from "react-router-dom"
export interface DocumentListItemProps {
  document: AnnexureDocument
  workspaceId: number
}
const DocumentListItem = ({
  document: annexure,
  workspaceId,
}: DocumentListItemProps) => {
  const theme = useTheme()

  const handleDownload = () => {
    fetch(`/v3/workspaces/${workspaceId}/document/${annexure.name}`)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement("a")
        a.href = url
        a.download = annexure.name!
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
      })
  }

  return (
    <ListItem
      secondaryAction={
        <Fragment>
          <IconButton id="basic-button" onClick={handleDownload}>
            <DownloadIcon />
          </IconButton>
        </Fragment>
      }
    >
      <ListItemText>
        <Link
          to={`/workspaces/${workspaceId}/document/${annexure.name}`}
          style={{
            textDecoration: "underline",
            color: theme.palette.info.main,
          }}
        >
          {annexure.name}
        </Link>
      </ListItemText>
    </ListItem>
  )
}

export default DocumentListItem
