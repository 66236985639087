import {
  Address,
  Licencee,
  PhysicalAustralianAddress,
} from "../../landconnex-api-client"
import Grid from "@mui/material/Grid2"
import { gridSpacing } from "../../constants"
import { Control, FieldErrors, UseFormSetValue } from "react-hook-form"
import FormInputText from "../../components/form-input-text"
import AddressLookup from "../../components/address-lookup"
import FormInputSelect from "../../components/form-input-select"
import { MenuItem } from "@mui/material"
export interface AddressFormProps {
  contactDetails: Licencee
  control: Control<Licencee>
  errors: FieldErrors<Licencee>
  setValue: UseFormSetValue<Licencee>
}
const AddressForm = ({ control, errors, setValue }: AddressFormProps) => {
  const onAddressSelected = (
    _address: PhysicalAustralianAddress,
    add: Address,
  ) => {
    setValue("contactDetails.address", add, { shouldDirty: true })
  }
  const stateOrTerritoryOptions = [
    "NSW",
    "VIC",
    "QLD",
    "SA",
    "WA",
    "TAS",
    "ACT",
    "NT",
  ]
  return (
    <Grid container spacing={gridSpacing}>
      <Grid size={12}>
        <AddressLookup onAddressSelected={onAddressSelected}></AddressLookup>
      </Grid>
      <Grid size={12}>
        <FormInputText
          control={control}
          name="contactDetails.address.streetAddress1"
          label="Street Address 1"
          error={errors.contactDetails?.address?.streetAddress1}
        />
      </Grid>
      <Grid size={12}>
        <FormInputText
          control={control}
          name="contactDetails.address.streetAddress2"
          label="Street Address 2"
          error={errors.contactDetails?.address?.streetAddress2}
        />
      </Grid>
      <Grid size={4}>
        <FormInputText
          control={control}
          name="contactDetails.address.locality"
          label="Locality"
        />
      </Grid>
      <Grid size={4}>
        <FormInputSelect
          control={control}
          name="contactDetails.address.stateOrTerritory"
          label="State or Territory"
        >
          {stateOrTerritoryOptions.map(option => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </FormInputSelect>
      </Grid>
      <Grid size={4}>
        <FormInputText
          control={control}
          name="contactDetails.address.postCode"
          label="Postal Code"
        />
      </Grid>
    </Grid>
  )
}

export default AddressForm
