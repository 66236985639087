import {
  Typography,
  useTheme,
  TextField,
  MenuItem,
  Select,
  debounce,
  Paper,
} from "@mui/material"
import Grid from "@mui/material/Grid2"
import React, { useEffect } from "react"
import { useForm, Controller } from "react-hook-form"
import {
  Contract,
  WorkflowStateEnum,
  type Property,
} from "../../../landconnex-api-client"
import { useAppSelector, useAppDispatch } from "../../../app/hooks"
import FormInputText from "../../../components/form-input-text"
import {
  selectWorkflow,
  selectWorkspace,
} from "../../../app/slices/workspaces-slice"
import {
  getProperty,
  putProperty,
  selectProperty,
} from "../../../app/slices/property-slice"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  contractSchema,
  propertySchema,
} from "../../../utils/validation-schemas"
import {
  getContract,
  putContract,
  selectContract,
} from "../../../app/slices/contract-slice"
import { gridSpacing } from "../../../constants"
import { singleLineFullAddress } from "../../../helpers/helpers"
const presentUseOptions = [
  "Vacant land",
  "Dwelling",
  "Multi Unit",
  "Flats",
  "Guest House Private Hotel",
  "Farming",
  "Industrial",
  "Commercial",
  "Other",
]

interface BuiltOnType {
  isBuiltOn: string
  description: string
}
const isBuiltOnOptions = Array<BuiltOnType>()

isBuiltOnOptions.push({ description: "Built On", isBuiltOn: "true" })
isBuiltOnOptions.push({ description: "Vacant", isBuiltOn: "false" })
const holdingTypeOptions = ["freehold", "leasehold"]

const PropertyStep: React.FC = ({}) => {
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const property = useAppSelector(selectProperty)
  const workspace = useAppSelector(selectWorkspace)
  const workflow = useAppSelector(selectWorkflow)
  const contract = useAppSelector(selectContract)
  const disableField = ["buyer", "buyerSolicitor"].includes(
    workspace!.workspace?.role!,
  )

  const contractForm = useForm<Contract>({
    defaultValues: contract,
    resolver: zodResolver(contractSchema),
    mode: "onChange",
  })
  useEffect(() => {
    if (workflow?.state === WorkflowStateEnum.ContractPreparation) {
      dispatch(getContract(workspace!.workspace!.id))
    }
  }, [workflow?.state])

  const {
    control: contractControl,
    watch: contractWatch,
    reset: contractReset,
    formState: { isDirty: contractIsDirty, errors: contractErrors },
  } = contractForm

  const propertyForm = useForm<Property>({
    defaultValues: property,
    resolver: zodResolver(propertySchema),
    mode: "onChange",
  })

  const {
    control: propertyControl,
    watch: propertyWatch,
    reset: propertyReset,
    formState: { isDirty, errors },
  } = propertyForm

  const formValues = propertyWatch()
  const contractValues = contractWatch()

  const debouncedSave = React.useCallback(
    debounce(async (data: Property) => {
      dispatch(
        putProperty({
          workspaceId: workspace!.workspace!.id,
          property: data,
        }),
      )
      propertyReset(data, { keepErrors: true })
    }, 1000),
    [dispatch, workspace!.workspace!.id],
  )

  const debouncedContractSave = React.useCallback(
    debounce(async (data: Contract) => {
      dispatch(
        putContract({ contract: data, workspaceId: workspace!.workspace!.id! }),
      )
      contractReset(data, { keepErrors: true })
    }, 1000),
    [dispatch, workspace!.workspace!.id],
  )

  useEffect(() => {
    if (isDirty) {
      debouncedSave(formValues)
    }
  }, [formValues, isDirty])

  useEffect(() => {
    if (contractIsDirty) {
      debouncedContractSave(contractValues)
    }
  }, [contractValues, contractIsDirty])

  useEffect(() => {
    dispatch(getProperty(workspace!.workspace!.id!))
  }, [dispatch])

  const onPropertyChangedSubmit = (data: Property) => {
    dispatch(
      putProperty({ workspaceId: workspace!.workspace!.id!, property: data }),
    )
  }

  return (
    <Paper sx={{ p: 2 }}>
      <Grid component="form" container size={12} spacing={gridSpacing}>
        <Grid size={{ xs: 12 }}>
          <Paper sx={{ p: 2, backgroundColor: theme.palette.grey[100] }}>
            <Typography variant="h3" color={theme.palette.text.secondary}>
              Property Address:{" "}
              {workspace?.property && singleLineFullAddress(workspace.property)}
            </Typography>
          </Paper>
        </Grid>

        <Grid size={{ xs: 12 }}>
          <Grid container spacing={gridSpacing} marginTop={2}>
            <Grid size={{ xs: 6, sm: 4 }}>
              <FormInputText
                control={propertyControl}
                name="lot"
                label="Lot"
                required
                error={errors.lot}
              />
            </Grid>
            <Grid size={{ xs: 6, sm: 4 }}>
              <FormInputText
                control={propertyControl}
                name="plan"
                label="Plan"
                required
                disabled={disableField}
                error={errors.plan}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 4 }}>
              <FormInputText
                control={propertyControl}
                name="titleReference"
                label="Title Reference"
                required
                disabled={disableField}
                error={errors.titleReference}
              />
            </Grid>
            <Grid size={{ xs: 12 }}>
              <Typography variant="h5" color={theme.palette.text.secondary}>
                Description
              </Typography>
            </Grid>
            <Grid size={{ xs: 12 }}>
              <Typography variant="body1">
                Is the land built on or vacant?
              </Typography>
            </Grid>
            <Grid size={{ xs: 12 }}>
              <Controller
                control={propertyControl}
                name="isBuiltOn"
                render={() => (
                  <Select
                    variant="outlined"
                    fullWidth
                    label="Is Built On"
                    disabled={disableField}
                    defaultValue={
                      workspace!.property!.isBuiltOn ? "true" : "false"
                    }
                    onChange={({ target }) => {
                      const prop = { ...workspace!.property }
                      prop.isBuiltOn = target.value == "true"
                      onPropertyChangedSubmit(prop)
                    }}
                  >
                    {isBuiltOnOptions.map(option => (
                      <MenuItem
                        key={option.description}
                        value={option.isBuiltOn}
                      >
                        {option.description}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Grid>
            <Grid size={{ xs: 12 }}>
              <Typography variant="body1">What is the holding type?</Typography>
            </Grid>
            <Grid size={{ xs: 12 }}>
              <Controller
                control={propertyControl}
                name="holdingType"
                render={({ field }) => (
                  <Select
                    variant="outlined"
                    fullWidth
                    label="Holding Type"
                    disabled={disableField}
                    {...field}
                  >
                    {holdingTypeOptions.map(option => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 2, lg: 6 }}>
              <Controller
                control={propertyControl}
                name="area"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="Area"
                    variant="outlined"
                    fullWidth
                    disabled={disableField}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 12, lg: 6 }}>
              <Controller
                control={propertyControl}
                name="presentUse"
                render={({ field }) => (
                  <Select
                    variant="outlined"
                    fullWidth
                    {...field}
                    label="Present Use"
                    disabled={disableField}
                    error={!!errors.presentUse}
                  >
                    {presentUseOptions.map(option => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 12, lg: 6 }}>
              <Controller
                name="lga"
                control={propertyControl}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="Local Government"
                    variant="outlined"
                    required
                    fullWidth
                    disabled={disableField}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Grid container spacing={gridSpacing} >
            <Grid size={{ xs: 12 }}>
              <Typography variant="h5" color={theme.palette.text.secondary}>
                Fixtures and Chattels
              </Typography>
            </Grid>
            <Grid size={{ xs: 12 }}>
              <FormInputText
                control={contractControl}
                name="includedChattels"
                label="Included Chattels"
                multiline
                minRows={3}
                required
                disabled={disableField}
                error={contractErrors.includedChattels}
              />
            </Grid>
            <Grid size={{ xs: 12 }}>
              <FormInputText
                control={contractControl}
                name="excludedFixtures"
                label="Excluded Fixtures"
                multiline
                minRows={3}
                required
                disabled={disableField}
                error={contractErrors.excludedFixtures}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default PropertyStep
