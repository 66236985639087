import { AxiosError } from "axios"
import { teamMembersApi, usersApi } from "../../api"
import { createAppSlice } from "../../app/createAppSlice"
import { TeamInvitation, User } from "../../landconnex-api-client"
import { Status } from "./status"

export enum TeamMembersStatus {
  initial,
  loading,
  ready,
  error,
}

interface TeamMembersState {
  status: TeamMembersStatus
  acceptTeamInvitationStatus: Status
  members: Array<User>
  teamInvitations: Array<TeamInvitation>
  error: string | null
}

const initialState: TeamMembersState = {
  status: TeamMembersStatus.initial,
  acceptTeamInvitationStatus: Status.initial,
  members: [],
  teamInvitations: [],
  error: null,
}

export const teamMembersSlice = createAppSlice({
  name: "teamMembers",
  initialState,
  reducers: create => ({
    getTeamMembers: create.asyncThunk(
      async () => {
        const response = await teamMembersApi.getUsers()

        return response.data
      },
      {
        pending: state => {
          state.status = TeamMembersStatus.loading
        },
        fulfilled: (state, action) => {
          state.members = action.payload.users!
          state.status = TeamMembersStatus.ready
        },
        rejected: state => {
          state.status = TeamMembersStatus.error
        },
      },
    ),
    getTeamInvitations: create.asyncThunk(
      async () => {
        const response = await teamMembersApi.getTeamInvitations()

        return response.data.items
      },
      {
        pending: state => {
          state.status = TeamMembersStatus.loading
        },
        fulfilled: (state, action) => {
          state.status = TeamMembersStatus.ready
          state.teamInvitations = action.payload
        },
        rejected: state => {
          state.status = TeamMembersStatus.error
        },
      },
    ),
    cancelInvitation: create.asyncThunk<void, number>(
      async invitationId => {
        await teamMembersApi.cancelInvitation(invitationId)
      },
      {
        pending: state => {
          state.status = TeamMembersStatus.loading
        },
        fulfilled: state => {
          state.status = TeamMembersStatus.ready
        },
        rejected: state => {
          state.status = TeamMembersStatus.error
        },
      },
    ),
    inviteUserToTeam: create.asyncThunk<void, string>(
      async (emailAddress, { rejectWithValue }) => {
        try {
          await teamMembersApi.inviteUserToTeam(emailAddress)
        } catch (error) {
          if (error instanceof AxiosError) {
            return rejectWithValue(error as AxiosError)
          }
        }
      },
      {
        pending: state => {
          state.status = TeamMembersStatus.loading
        },
        fulfilled: (state) => {
          state.status = TeamMembersStatus.ready
        },
        rejected: (state, action) => {
          state.status = TeamMembersStatus.error
          if (action.payload instanceof AxiosError) {
            if (action.payload.response?.status === 403) {
              state.error = "User already exists"
            } else {
              state.error = "An unknown error occurred"
            }
          }
        },
      },
    ),
    removeUserFromTeam: create.asyncThunk<void, string>(
      async sub => {
        await teamMembersApi.removeUserFromTeam(sub)
      },
      {
        pending: state => {
          state.status = TeamMembersStatus.loading
        },
        fulfilled: state => {
          state.status = TeamMembersStatus.ready
        },
        rejected: state => {
          state.status = TeamMembersStatus.error
        },
      },
    ),
    updateUser: create.asyncThunk<void, User>(async (user: User) => {
      await teamMembersApi.updateUser(user.sub!, {
        user: user,
      })
    }),
    clearError: create.reducer<void>(state => {
      state.error = null
    }),
    // clearError: state => {
    //   state.error = null
    // },
    acceptTeamInvitation: create.asyncThunk<void, number>(
      async (invitationId, { rejectWithValue }) => {
        try {
          await usersApi.assertUser()
          const response =
            await teamMembersApi.acceptTeamInvitation(invitationId)

          if (response.status === 404) {
            return rejectWithValue("Invitation not found")
          }

          return response.data
        } catch (error) {
          if (error instanceof AxiosError) {
            return rejectWithValue(error)
          }
        }
      },
      {
        pending: state => {
          state.acceptTeamInvitationStatus = Status.loading
        },
        fulfilled: state => {
          state.status = TeamMembersStatus.ready
          state.acceptTeamInvitationStatus = Status.ready
        },
        rejected: (state, action) => {
          state.acceptTeamInvitationStatus = Status.error
          if (action.payload instanceof AxiosError) {
            if (action.payload.response?.status === 404) {
              state.error = "Invitation not found"
            } else {
              state.error = "An unknown error occurred"
            }
          }
        },
      },
    ),
  }),

  selectors: {
    selectStatus: settings => settings.status,
    selectTeamMembers: settings => settings.members,
    selectTeamInvitations: settings => settings.teamInvitations,
    selectError: settings => settings.error,
    selectAcceptTeamInvitationStatus: settings => settings.acceptTeamInvitationStatus,
  },
})

export const {
  getTeamMembers,
  getTeamInvitations,
  cancelInvitation,
  inviteUserToTeam,
  updateUser,
  removeUserFromTeam,
  acceptTeamInvitation,
  clearError,
} = teamMembersSlice.actions

export const {
  selectStatus,
  selectTeamInvitations,
  selectTeamMembers,
  selectError,
  selectAcceptTeamInvitationStatus,
  
} = teamMembersSlice.selectors
