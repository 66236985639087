import CircularProgress from "@mui/material/CircularProgress"
import { FC, useEffect, useRef, useCallback } from "react"
import PDFViewer from "../../../components/pdf-viewer"
import Grid from "@mui/material/Grid"
import Button from "@mui/material/Button"
import { useNavigate, useParams } from "react-router-dom"
import { useAppSelector, useAppDispatch } from "../../../app/hooks"
import {
  AgentAppointmentStatus,
  getAgentAppointmentPdf,
  selectAgentAppointmentPdf,
  selectAgentAppointmentPdfStatus,
  selectAgentAppointmentStatus,
  selectValidationErrors,
  sendAgentAppointmentForSigning,
  validateAgentAppointment,
} from "../../../app/slices/agent-appointment-slice"
import {
  Typography,
  Card,
  CardContent,
  CardActions,
  Box,
  CardHeader,
} from "@mui/material"
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined"
interface GenerateProps {
  onStepChanged: (step: string) => void
}

const Generate: FC<GenerateProps> = ({ onStepChanged }) => {
  const status = useAppSelector(selectAgentAppointmentStatus)
  const pdf = useAppSelector(selectAgentAppointmentPdf)
  const pdfStatus = useAppSelector(selectAgentAppointmentPdfStatus)
  const validationErrors = useAppSelector(selectValidationErrors)
  const params = useParams()
  const dispatch = useAppDispatch()
  const workspaceId = Number(params.workspaceId)
  const navigate = useNavigate()
  const pdfViewerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    dispatch(getAgentAppointmentPdf(workspaceId))
  }, [pdfStatus, dispatch, workspaceId])

  useEffect(() => {
    dispatch(validateAgentAppointment(workspaceId))
  }, [dispatch, workspaceId])

  const handleOnSendAgentAppointmentForSigning = useCallback(async () => {
    try {
      const success = await dispatch(
        sendAgentAppointmentForSigning(workspaceId),
      )
      if (success) {
        setTimeout(() => {
          navigate(`/workspaces/${workspaceId}`)
        }, 1500)
      } else {
        console.error("Failed to send agent appointment")
      }
    } catch (error) {
      console.error("Error occurred while sending agent appointment:", error)
    }
  }, [dispatch, workspaceId, navigate])

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: pdfViewerRef.current?.offsetTop || 0,
      behavior: "smooth",
    })
  }, [])

  if (!pdf) {
    return (
      <Grid
        container
        sx={{
          height: "100%",
          py: 3,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CircularProgress size={100} />
      </Grid>
    )
  }

  return (
    <>
    
      {status === AgentAppointmentStatus.sentForSigning && (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, py: 3 }}>
          <CircularProgress size={40} />
          <Typography>Preparing the document for signing</Typography>
        </Box>
      )}
      {status === AgentAppointmentStatus.error && (
        <Card>
          <CardHeader title="Please fix the errors and try again." />
          <CardContent>
            <Typography mb={2}>
              The agent appointment has the following errors:
            </Typography>
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              {validationErrors.map(error => (
                <Box
                  key={error.reason}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    width: { xs: "100%", md: "48%", lg: "30%" },
                    cursor: "pointer",
                    transition: "all 0.2s ease-in-out",
                    "&:hover": {
                      gap: 1.5,
                      background: "#f8f8f8",
                    },
                  }}
                >
                  <KeyboardArrowRightOutlinedIcon fontSize="small" />
                  <Box
                    onClick={() => onStepChanged(error.step)}
                    sx={{
                      my: 0.5,
                      color: "#c20029",
                    }}
                  >
                    {error.reason}
                  </Box>
                </Box>
              ))}
            </Box>
          </CardContent>
        </Card>
      )}
      {status === AgentAppointmentStatus.ready && (
        <Card>
          <CardContent>
            <Typography>
              Your agent appointment is ready to be signed.
            </Typography>
            <CardActions sx={{ justifyContent: "center" }}>
              <Box width={150}>
                <Button color="success">Manually Sign</Button>
              </Box>
              <Box width={150}>
                <Button
                  color="success"
                  onClick={handleOnSendAgentAppointmentForSigning}
                >
                  E-sign
                </Button>
              </Box>
            </CardActions>
          </CardContent>
        </Card>
      )}

      <Box ref={pdfViewerRef}>
        <PDFViewer url={pdf!} />
      </Box>

      <Box sx={{ margin: "0 auto 15px auto", width: "150px" }}>
        <Button onClick={scrollToTop} color="primary">
          Scroll to Top
        </Button>
      </Box>
    </>
  )
}

export default Generate
