import { useEffect, useRef, useState } from "react"
import { matchPath, useLocation } from "react-router-dom"

// material-ui
import { useTheme } from "@mui/material/styles"
import Collapse from "@mui/material/Collapse"
import List from "@mui/material/List"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"

// project imports
import { NavItem } from "../NavItem"
import { useMenu } from "../../../app/features/menu/useMenu"
import MenuItem from "../../../app/features/menu/menu-item"

// assets
import {
  IconBrandChrome,
  IconChevronDown,
  IconDashboard,
  IconKey,
  IconChevronUp,
  IconHelp,
  IconLayoutList,
  IconPlus,
  Icon,
  IconChevronRight,
} from "@tabler/icons-react"
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord"

interface INavCollapseProps {
  menu: MenuItem
  level: number
  parentId: string
}

const NavCollapse = ({
  menu,
  level,
  parentId,
}: INavCollapseProps): JSX.Element => {
  const theme = useTheme()
  const ref = useRef<HTMLDivElement>(null)

  const { isDrawerOpen } = useMenu()

  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState<string | null>(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClickMini = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null)
    if (isDrawerOpen) {
      setOpen(prev => !prev)
      setSelected(selected ? null : menu.id)
    } else {
      setAnchorEl(event?.currentTarget)
    }
  }

  const openMini = Boolean(anchorEl)

  const handleClosePopper = () => {
    setOpen(false)
    if (!openMini && !menu.url) {
      setSelected(null)
    }
    setAnchorEl(null)
  }

  const { pathname } = useLocation()

  const checkOpenForParent = (child: MenuItem[], id: string) => {
    child.forEach(item => {
      if (item.url === pathname) {
        setOpen(true)
        setSelected(id)
      }
    })
  }

  // menu collapse for sub-levels
  useEffect(() => {
    setOpen(() => false)
    if (openMini) {
      setAnchorEl(null)
    }
    setSelected(null)

    if (menu.children) {
      menu.children.forEach(item => {
        if (item.children?.length) {
          checkOpenForParent(item.children, menu.id)
        }
        if (
          item.link &&
          !!matchPath({ path: item?.link, end: false }, pathname)
        ) {
          setSelected(menu.id)
          setOpen(() => true)
        }
        if (item.url === pathname) {
          setSelected(menu.id)
          setOpen(() => true)
        }
      })
    }
  }, [pathname, menu.children, menu.id, openMini])

  const [hoverStatus, setHover] = useState(false)

  const compareSize = () => {
    const compare =
      ref.current && ref.current.scrollWidth > ref.current.clientWidth
    setHover(compare ?? false)
  }

  useEffect(() => {
    compareSize()
    window.addEventListener("resize", compareSize)
    return () => {
      window.removeEventListener("resize", compareSize)
    }
  }, [])

  useEffect(() => {
    if (menu.url === pathname) {
      setSelected(menu.id)
      setAnchorEl(null)
      setOpen(true)
    }
  }, [pathname, menu])

  // menu collapse & items
  const menus = menu.children?.map(item => {
    switch (item.type) {
      case "collapse":
        return (
          <NavCollapse
            key={item.id}
            menu={item}
            level={level + 1}
            parentId={parentId}
          />
        )
      case "item":
        return <NavItem key={item.id} item={item} level={level + 1} />
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        )
    }
  })

  const isSelected = selected === menu.id

  let Icon: Icon | null = null

  switch (menu?.icon) {
    case "IconDashboard":
      Icon = IconDashboard
      break
    case "IconKey":
      Icon = IconKey
      break
    case "IconBrandChrome":
      Icon = IconBrandChrome
      break
    case "IconHelp":
      Icon = IconHelp
      break
    case "IconLayoutList":
      Icon = IconLayoutList
      break
    case "IconPlus":
      Icon = IconPlus
      break
    default:
      break
  }
  const menuIcon = menu.icon ? (
    Icon && <Icon size={isDrawerOpen ? "20px" : "24px"} />
  ) : (
    <FiberManualRecordIcon
      sx={{
        width: isSelected ? 8 : 6,
        height: isSelected ? 8 : 6,
      }}
      fontSize={level > 0 ? "inherit" : "medium"}
    />
  )

  const collapseIcon = isDrawerOpen ? (
    <IconChevronUp
      stroke={1.5}
      size="16px"
      style={{ marginTop: "auto", marginBottom: "auto" }}
    />
  ) : (
    <IconChevronRight
      stroke={1.5}
      size="16px"
      style={{ marginTop: "auto", marginBottom: "auto" }}
    />
  )

  const iconSelectedColor = "secondary.main"
  const borderRadius = 8 // Default value since we removed useConfig

  return (
    <>
      <ListItemButton
        sx={{
          zIndex: 1201,
          borderRadius: `${borderRadius}px`,
          mb: 0.5,
          ...(isDrawerOpen && level !== 1 && { ml: `${level * 18}px` }),
          ...(!isDrawerOpen && { pl: 1.25 }),
          ...(isDrawerOpen &&
            level === 1 && {
              "&:hover": { bgcolor: "secondary.light" },
              "&.Mui-selected": {
                bgcolor: "secondary.light",
                color: iconSelectedColor,
                "&:hover": {
                  color: iconSelectedColor,
                  bgcolor: "secondary.light",
                },
              },
            }),
          ...((!isDrawerOpen || level !== 1) && {
            py: level === 1 ? 0 : 1,
            "&:hover": { bgcolor: "transparent" },
            "&.Mui-selected": {
              "&:hover": { bgcolor: "transparent" },
              bgcolor: "transparent",
            },
          }),
        }}
        selected={isSelected}
        {...(!isDrawerOpen && {
          onMouseEnter: handleClickMini,
          onMouseLeave: handleClosePopper,
        })}
        onClick={handleClickMini}
      >
        {menuIcon && (
          <ListItemIcon
            sx={{
              minWidth: level === 1 ? 36 : 18,
              color: isSelected ? iconSelectedColor : "text.primary",
              ...(!isDrawerOpen &&
                level === 1 && {
                  borderRadius: `${borderRadius}px`,
                  width: 46,
                  height: 46,
                  alignItems: "center",
                  justifyContent: "center",
                  "&:hover": {
                    bgcolor: "secondary.light",
                  },
                  ...(isSelected && {
                    bgcolor: "secondary.light",
                    "&:hover": {
                      bgcolor: "secondary.light",
                    },
                  }),
                }),
            }}
          >
            {menuIcon}
          </ListItemIcon>
        )}
        {(isDrawerOpen || (!isDrawerOpen && level !== 1)) && (
          <Tooltip title={menu.title} disableHoverListener={!hoverStatus}>
            <ListItemText
              primary={
                <Typography
                  ref={ref}
                  noWrap
                  variant={isSelected ? "h5" : "body1"}
                  color="inherit"
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: 120,
                  }}
                >
                  {menu.title}
                </Typography>
              }
              secondary={
                menu.caption && (
                  <Typography
                    variant="caption"
                    gutterBottom
                    sx={{
                      display: "block",
                      ...theme.typography.subMenuCaption,
                    }}
                  >
                    {menu.caption}
                  </Typography>
                )
              }
            />
          </Tooltip>
        )}

        {openMini || open ? (
          collapseIcon
        ) : (
          <IconChevronDown
            stroke={1.5}
            size="16px"
            style={{ marginTop: "auto", marginBottom: "auto" }}
          />
        )}
      </ListItemButton>

      {isDrawerOpen && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          {open && (
            <List
              disablePadding
              sx={{
                position: "relative",
                "&:after": {
                  content: "''",
                  position: "absolute",
                  left: "25px",
                  top: 0,
                  height: "100%",
                  width: "1px",
                  opacity: 1,
                  bgcolor: "primary.light",
                },
              }}
            >
              {menus}
            </List>
          )}
        </Collapse>
      )}
    </>
  )
}

export default NavCollapse
