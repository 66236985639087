import { teamMembersApi, teamsApi } from "../../api"
import { createAppSlice } from "../createAppSlice"
import {
  Team,
  TeamInvitation,
  ReferralPartnersItemsInner,
  CompleteOnBoardingRequest,
  OnBoarding,
  User,
  SetupIntent,
  TrustAccount,
  Licencee,
} from "../../landconnex-api-client"

interface ReferralPartner extends ReferralPartnersItemsInner {
  emailAddress: string
}

export enum TeamStatus {
  initial,
  loading,
  creatingCompany,
  ready,
  error,
}

interface TeamState {
  status: TeamStatus
  team: Team | null
  licencee: Licencee | null
  trustAccount: TrustAccount | null
  teamMembers: User[]
  teamInvitations: TeamInvitation[]
  referralPartners: ReferralPartner[]
  currentReferralPartner: ReferralPartner | null
  logo: File | null

  clientSecretID: string | null
  setupIntentID: string | null
  setupIntent: SetupIntent | null
}

const initialState: TeamState = {
  status: TeamStatus.initial,
  team: null,
  licencee: null,
  trustAccount: null,
  teamMembers: [],
  teamInvitations: [],
  referralPartners: [],
  currentReferralPartner: null,
  logo: null,

  clientSecretID: null,
  setupIntentID: null,
  setupIntent: null,
}

export const teamSlice = createAppSlice({
  name: "team",
  initialState,
  reducers: create => ({
    getLicencee: create.asyncThunk(
      async () => {
        const result = await teamsApi.getLicencee()
        return result.data
      },
      {
        pending: state => {
          state.status = TeamStatus.loading
        },
        fulfilled: (state, action) => {
          state.status = TeamStatus.ready
          state.licencee = action.payload.licence
        },
        rejected: state => {
          state.status = TeamStatus.error
        },
      },
      
    ),
    putLicencee: create.asyncThunk(
      async (licencee: Licencee) => {
        await teamsApi.putLicencee(licencee)
      },
      {
        pending: state => {
          state.status = TeamStatus.loading
        },
        fulfilled: (state) => {
          state.status = TeamStatus.ready
        },
        rejected: state => {
          state.status = TeamStatus.error
        },
      },
      
    ),
    getTrustAccount: create.asyncThunk(
      async () => {
        const result = await teamsApi.getTrustAccount()
        return result.data
      },
      {
        pending: state => {
          state.status = TeamStatus.loading
        },
        fulfilled: (state, action) => {
          state.status = TeamStatus.ready
          state.trustAccount = action.payload
        },
        rejected: state => {
          state.status = TeamStatus.error
        },
      },
      
    ),
    putTrustAccount: create.asyncThunk(
      async (trustAccount: TrustAccount) => {
        await teamsApi.putTrustAccount(trustAccount)
      },
      {
        pending: state => { 
          state.status = TeamStatus.loading
        },
        fulfilled: (state) => {
          state.status = TeamStatus.ready
        },
        rejected: state => {
          state.status = TeamStatus.error 
        },
      },
    ),
    
    startOnBoarding: create.asyncThunk(
      async (onBoarding: OnBoarding) => {
        const result = await teamsApi.startOnBoarding(onBoarding)
        return result.data
      },
      {
        pending: state => {
          state.status = TeamStatus.creatingCompany
        },
        fulfilled: (state, action) => {
          state.status = TeamStatus.ready
          state.clientSecretID = action.payload.clientSecretID
          state.setupIntentID = action.payload.setupIntentID
        },
        rejected: state => {
          state.status = TeamStatus.error
        },
      },
    ),
    completeOnBoarding: create.asyncThunk(
      async (request: CompleteOnBoardingRequest) => {
        await teamsApi.completeOnBoarding(request)
      },
      {
        pending: state => {
          state.status = TeamStatus.loading
        },
        fulfilled: state => {
          state.status = TeamStatus.ready
          state.clientSecretID = null
          state.setupIntentID = null
        },
        rejected: state => {
          state.status = TeamStatus.error
        },
      },
    ),
    getLicenceDetails: create.asyncThunk(
      async () => {
        const result = await teamsApi.getLicencee()
        return result.data
      },
      {
        pending: state => {
          state.status = TeamStatus.loading
        },
        fulfilled: (state, action) => {
          state.status = TeamStatus.ready
          state.licencee = action.payload.licence
        },
        rejected: state => {
          state.status = TeamStatus.error
        },
      },
    ),
    getTeam: create.asyncThunk(
      async () => {
        const result = await teamsApi.getTeam()
        // if (result.status === 404) {
        //   return Promise.reject("Team not found")
        // }
        return result.data
      },
      {
        pending: state => {
          state.status = TeamStatus.loading
        },
        fulfilled: (state, action) => {
          state.status = TeamStatus.ready

          if (!action.payload) {
            state.team = null
            return
          }

          action.payload.abn = action.payload.abn ?? "" 
          action.payload.acn = action.payload.acn ?? ""
          action.payload.name = action.payload.name ?? ""
          action.payload.primaryColour = action.payload.primaryColour ?? ""
          action.payload.secondaryColour = action.payload.secondaryColour ?? ""
          action.payload.registeredForGst = action.payload.registeredForGst ?? false
          action.payload.websiteUrl = action.payload.websiteUrl ?? ""
          
          if (!action.payload.primaryContact) {
           
            action.payload.primaryContact = {
              name: { firstName: "", lastName: "", middleName: "", organisationName: ""},
              email: "",
              phone: "",
              mobilePhone: "",

              address: {
                streetAddress1: "",
                streetAddress2: "",
                locality: "",
                stateOrTerritory: "",
                postCode: "",
                country: "",

              },
            }
          }
          
          state.team = action.payload
        },
        
      },
    ),
    updateTeam: create.asyncThunk(
      async (team: Team) => {
        await teamsApi.putTeam(team)
        return team
      },
      {
        pending: state => {
          state.status = TeamStatus.loading
        },
        fulfilled: (state, action) => {
          state.status = TeamStatus.ready
          state.team = action.payload
        },
        rejected: state => {
          state.status = TeamStatus.error
        },
      },
    ),
    uploadTeamLogo: create.asyncThunk<void, File>(
      async (file: File) => {
        await teamsApi.putTeamLogo(file)
      },
      {
        pending: state => {
          state.status = TeamStatus.loading
        },
        fulfilled: state => {
          state.status = TeamStatus.ready
        },
        rejected: state => {
          state.status = TeamStatus.error
        },
      },
    ),
    getReferralPartners: create.asyncThunk(
      async () => {
        const result = await teamsApi.getReferralPartners()
        return result.data
      },
      {
        pending: state => {
          state.status = TeamStatus.loading
        },
        fulfilled: (state, action) => {
          state.status = TeamStatus.ready
          state.referralPartners = (action.payload.items ||
            []) as ReferralPartner[]
        },
        rejected: state => {
          state.status = TeamStatus.error
        },
      },
    ),
    updateUser: create.asyncThunk<void, User>(async (user: User) => {
      await teamMembersApi.updateUser(user.sub!, {
        user: user,
      })
    }),
    createSetupIntent: create.asyncThunk(
      async () => {
        const result = await teamsApi.getSetupIntent()
        return result.data
      },
      {
        pending: state => {
          state.status = TeamStatus.loading
        },
        fulfilled: (state, action) => {
          state.status = TeamStatus.ready
          state.clientSecretID = action.payload.clientSecretID
          state.setupIntentID = action.payload.setupIntentID
          state.setupIntent = action.payload
        },
        rejected: state => {
          state.status = TeamStatus.error
        },
      },
    ),
  }),

  selectors: {
    selectTeam: settings => settings.team,
    selectTeamStatus: settings => settings.status,
    selectLicencee: settings => settings.licencee,
    selectTrustAccount: settings => settings.trustAccount,
    selectTeamMembers: settings => settings.teamMembers,

    selectTeamInvitations: settings => settings.teamInvitations,
    selectReferralPartners: settings => settings.referralPartners,
    selectCurrentReferralPartner: settings => settings.currentReferralPartner,
    selectLogo: settings => settings.logo,
    selectClientSecretID: settings => settings.clientSecretID,
    selectSetupIntentID: settings => settings.setupIntentID,
    selectSetupIntent: settings => settings.setupIntent,
  },
})

export const {
  getTeam,
  getLicencee,
  putLicencee,
  getTrustAccount,
  putTrustAccount,
  getLicenceDetails,
  updateTeam,
  uploadTeamLogo,
  getReferralPartners,
  startOnBoarding,
  completeOnBoarding,
  createSetupIntent,
} = teamSlice.actions

export const {
  selectTeam,
  selectTeamStatus,
  selectLicencee,
  selectTrustAccount,
  selectTeamMembers,
  selectTeamInvitations,
  selectReferralPartners,
  selectCurrentReferralPartner,
  selectLogo,
  selectClientSecretID,
  selectSetupIntent,
  selectSetupIntentID,
} = teamSlice.selectors
