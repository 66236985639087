// assets

import MenuItem from './menu-item';

const other:MenuItem = {
  id: 'sample-docs-roadmap',
  title: 'Workspaces',
  type: 'group',
  children: [
    {
      id: 'workspaces',
      title: 'Workspaces',
      type: 'item',
      url: '/workspaces',
      icon: "IconLayoutList",
      breadcrumbs: false
    },
    {
      id: 'new-workspace',
      title: 'New',
      type: 'item',
      url: '/create-workspace',
      icon: "IconPlus",
      external: false,
      
    }
  ]
};

export default other;
