import { Button, Card, CardActions, CardContent, CardHeader, Typography } from "@mui/material"
import Grid from "@mui/material/Grid2"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import {
  getOfferParticipants,
} from "../../../../app/slices/offers-slice"
import { selectWorkflow } from "../../../../app/slices/workspaces-slice"
import { useParams } from "react-router-dom"
import {  useEffect } from "react"
import FormInputText  from "../../../../components/form-input-text"

import { useForm } from "react-hook-form"
const SelectBuyerSolicitor = () => {
  const params = useParams()
  const offerId = Number(params.offerId)
  const workspaceId = Number(params.workspaceId)
  const workflow = useAppSelector(selectWorkflow)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(
      getOfferParticipants({ offerId: offerId, workspaceId: workspaceId }),
    )
  }, [dispatch, offerId, workspaceId])

  const { control } = useForm<{email: string}>()


  const inviteBuyerSolicitor = <Grid container spacing={1}>
    <Grid size={12}>
    <Typography variant="body1">
        Invite a solicitor to act for the buyer in this offer.
    </Typography>
    </Grid>
    <Grid size={6}>
      <FormInputText  label="Email" name="email" control={control} />
    </Grid>
    
  </Grid>


  return (
    <div>
      <Card>
        <CardHeader title="Select Buyer Solicitor" />
        <CardContent>
            {workflow?.offers[0].buyerSolicitorInvited == false && inviteBuyerSolicitor}
        </CardContent>
        <CardActions>
          {workflow?.offers[0].buyerSolicitorInvited == false && <Button  color="primary">Invite</Button>}
        </CardActions>
      </Card>
    </div>
  )
}

export default SelectBuyerSolicitor
