import { FC, useEffect, useState } from "react"
import { OnAgentAppointmentChanged } from "../../../components/events"
import {
  AgentAppointment,
  AgentAppointmentAppointmentTypeEnum,
} from "../../../landconnex-api-client"
import { useParams } from "react-router-dom"
import { useAppSelector, useAppDispatch } from "../../../app/hooks"
import {
  Box,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  CardContent,
  Card,
  CardHeader,
  debounce,
} from "@mui/material"
import { Notes } from "./form-wrapper"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import {
  selectAgentAppointment,
  updateAgentAppointment,
} from "../../../app/slices/agent-appointment-slice"
import { zodResolver } from "@hookform/resolvers/zod"
import React from "react"
import { agentAppointmentSchema } from "../../../utils/validation-schemas"

export interface PropertySaleProps {
  agent: AgentAppointment
  onAgentChanged: OnAgentAppointmentChanged
}
const PropertySale: FC = () => {
  const params = useParams()
  const dispatch = useAppDispatch()
  const workspaceId = Number(params.workspaceId)
  const agentAppointment = useAppSelector(selectAgentAppointment)
  const [selectedOption, setSelectedOption] = useState(
    agentAppointment!.isSoleOrExclusiveContinuation?.toString() || "false",
  )

  const schema = agentAppointmentSchema

  const agentAppointmentForm = useForm<AgentAppointment>({
    defaultValues: agentAppointment,
    resolver: zodResolver(schema),
    mode: "onChange",
  })

  const {
    control,
    watch,
    formState: { isDirty },
    reset,
  } = agentAppointmentForm

  const formValues = watch()

  const debouncedSave = React.useCallback(
    debounce(async (data: AgentAppointment) => {
      dispatch(
        updateAgentAppointment({
          workspaceId: workspaceId,
          agentAppointment: data,
        }),
      )
      reset(data, { keepErrors: true })
    }, 1000),
    [dispatch, workspaceId],
  )

  useEffect(() => {
    if (isDirty) {
      debouncedSave(formValues)
    }
  }, [formValues, isDirty])

  useEffect(() => {
    setSelectedOption(
      agentAppointment!.isSoleOrExclusiveContinuation?.toString() || "false",
    )
  }, [agentAppointment!.isSoleOrExclusiveContinuation])

  const handleRadioChange = (event: any) => {
    const newValue = event.currentTarget.value === "true"
    const u = { ...agentAppointment }
    u.isSoleOrExclusiveContinuation = newValue
    onSubmit(u)
    setSelectedOption(newValue.toString())
  }

  const notes = [
    {
      title: "To the client:",
      items: [
        "You may appoint an agent to sell a property or land on the basis of an open listing, or a sole agency, or exclusive agency. The following information explains the circumstances under which you will, and won’t, have to pay a commission to the agent if the property is sold during their term of appointment.",
      ],
    },
  ]

  const onSubmit: SubmitHandler<AgentAppointment> = data => {
    dispatch(
      updateAgentAppointment({
        workspaceId: workspaceId,
        agentAppointment: data,
      }),
    )
  }

  return (
    <form>
      <Card sx={{ border: "none" }}>
        <CardHeader
          title="Property Sales: open listing, sole agency or exclusive agency"
          inheritlayout="true"
        />
        <CardContent>
          <Notes notes={notes} />

          <Grid item xs={12}>
            <Controller
              control={control}
              name="appointmentType"
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <FormControlLabel
                        value={AgentAppointmentAppointmentTypeEnum.Open}
                        control={<Radio />}
                        label={
                          <Typography variant="h6">Open listing</Typography>
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <List
                        sx={{
                          padding: 0,
                          marginBottom: 5,
                          paddingLeft: "30px",
                          listStyleType: "none",
                          "& li": {
                            position: "relative",
                            paddingLeft: "1.5em",
                          },
                          "& li::before": {
                            content: '"✔"',
                            position: "absolute",
                            left: 0,
                            fontSize: "1em",
                            color: "green",
                          },
                        }}
                      >
                        <ListItem sx={{ padding: 0, display: "list-item" }}>
                          <ListItemText primary="You appoint the agent to sell the property but you retain a right to appoint other agents on similar terms, without penalty or extra commission." />
                        </ListItem>
                        <ListItem sx={{ padding: 0, display: "list-item" }}>
                          <ListItemText primary="No end date required." />
                        </ListItem>
                        <ListItem sx={{ padding: 0, display: "list-item" }}>
                          <ListItemText primary=" Appointment can be ended by either you or the agent at anytime by giving written notice." />
                        </ListItem>
                      </List>
                      <Box>
                        <Typography
                          className="roboto-bold"
                          sx={{ color: "#c20029", fontSize: "18px" }}
                        >
                          When you must pay the agent
                        </Typography>

                        <List
                          sx={{
                            padding: 0,
                            paddingLeft: "30px",
                            listStyleType: "disc",
                            "& li::marker": {
                              fontSize: ".8em",
                            },
                          }}
                        >
                          <ListItem
                            sx={{ paddingLeft: 0, display: "list-item" }}
                          >
                            <ListItemText primary="The agent is entitled to the agreed commission if the agent is the effective cause of sale." />
                          </ListItem>
                        </List>
                      </Box>

                      <Box>
                        <Typography
                          className="roboto-bold"
                          sx={{ color: "#c20029", fontSize: "18px" }}
                        >
                          When you don’t have to pay the agent
                        </Typography>

                        <List
                          sx={{
                            padding: 0,
                            paddingLeft: "30px",
                            listStyleType: "disc",
                            "& li::marker": {
                              fontSize: ".8em",
                            },
                          }}
                        >
                          <ListItem
                            sx={{ paddingLeft: 0, display: "list-item" }}
                          >
                            <ListItemText primary="If the client sells the property privately and the agent is not the effective cause of sale i.e.: purchaser did not contact the agent, did not attend open house inspections etc." />
                          </ListItem>
                        </List>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        margin: "15px 0",
                      }}
                    >
                      <Divider></Divider>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        value={AgentAppointmentAppointmentTypeEnum.Sole}
                        control={<Radio />}
                        label={
                          <Typography variant="h6">Sole agency</Typography>
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Typography
                          className="roboto-bold"
                          sx={{
                            color: "#c20029",
                            fontSize: "18px",
                            marginTop: 1.5,
                          }}
                        >
                          When you must pay the agent
                        </Typography>

                        <List
                          sx={{
                            padding: 0,
                            paddingLeft: "30px",
                            listStyleType: "disc",
                            "& li::marker": {
                              fontSize: ".8em",
                            },
                          }}
                        >
                          <ListItem
                            sx={{ paddingLeft: 0, display: "list-item" }}
                          >
                            <ListItemText primary="If you appoint a new agent during an existing agent’s sole agreement term and the property is sold during that term, you may have to pay:" />
                            <List
                              sx={{
                                padding: 0,
                                paddingLeft: "20px", // Indentation for sublist
                                listStyleType: "circle", // Different bullet style for sublist
                                "& li::marker": {
                                  fontSize: ".8em",
                                },
                              }}
                            >
                              <ListItem
                                sx={{
                                  paddingLeft: 0,
                                  display: "list-item",
                                }}
                              >
                                <ListItemText primary="A commission to each agent (two commissions)" />
                              </ListItem>
                              <ListItem
                                sx={{
                                  paddingLeft: 0,
                                  display: "list-item",
                                }}
                              >
                                <ListItemText primary="Damages for breach of contract arising under the existing agent’s appointment" />
                              </ListItem>
                            </List>
                          </ListItem>
                        </List>
                      </Box>

                      <Box>
                        <Typography
                          className="roboto-bold"
                          sx={{ color: "#c20029", fontSize: "18px" }}
                        >
                          When you don’t have to pay the agent
                        </Typography>

                        <List
                          sx={{
                            padding: 0,
                            paddingLeft: "30px",
                            listStyleType: "disc",
                            "& li::marker": {
                              fontSize: ".8em",
                            },
                          }}
                        >
                          <ListItem
                            sx={{ paddingLeft: 0, display: "list-item" }}
                          >
                            <ListItemText primary="If the client sells the property privately and the agent is not the effective cause of sale i.e.: purchaser did not contact the agent, did not attend open house inspections etc." />
                          </ListItem>
                        </List>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        margin: "15px 0",
                      }}
                    >
                      <Divider></Divider>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        value={AgentAppointmentAppointmentTypeEnum.Exclusive}
                        control={<Radio />}
                        label={
                          <Typography variant="h6">Exclusive agency</Typography>
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Typography
                          className="roboto-bold"
                          sx={{
                            color: "#c20029",
                            fontSize: "18px",
                            marginTop: 1.5,
                          }}
                        >
                          When you must pay the agent
                        </Typography>

                        <List
                          sx={{
                            padding: 0,
                            paddingLeft: "30px",
                            listStyleType: "disc",
                            "& li::marker": {
                              fontSize: ".8em",
                            },
                          }}
                        >
                          <ListItem
                            sx={{
                              paddingLeft: 0,
                              paddingBottom: 0.5,
                              display: "list-item",
                            }}
                          >
                            <ListItemText primary="The client will pay the appointed agent whether this agent, any other agent, or person (including the client themselves) sells the property during the term of the appointment." />
                          </ListItem>
                          <ListItem
                            sx={{
                              paddingLeft: 0,
                              paddingBottom: 0.5,
                              display: "list-item",
                            }}
                          >
                            <ListItemText primary="If the client sells the property, after the exclusive appointment expires and if the agent was the effective cause of sale (introduced the buyer to the property) the agent may be entitled to commission." />
                          </ListItem>
                        </List>
                      </Box>
                    </Grid>
                  </Grid>
                </RadioGroup>
              )}
            ></Controller>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              margin: "15px 0",
            }}
          >
            <Divider></Divider>
          </Grid>

          <Grid item xs={12} sx={{ display: "flex" }}>
            <RadioGroup
              aria-labelledby="continuation"
              value={selectedOption}
              onChange={event => {
                const u = { ...agentAppointment }
                u.isSoleOrExclusiveContinuation =
                  event.currentTarget.value == "true"
                onSubmit(u)
                handleRadioChange(event)
              }}
              name="radio-buttons-group"
              sx={{ flexDirection: "row" }}
            >
              <FormControlLabel
                value="true"
                control={<Radio />}
                label="Agree"
              />
              <FormControlLabel
                value="false"
                control={<Radio />}
                label="Do Not Agree"
              />
            </RadioGroup>
          </Grid>

          <Grid item xs={12} sx={{ display: "flex" }}>
            <Box>
              At the end of the sole or exclusive agency, the parties{" "}
              {selectedOption === "true" ? (
                <strong>
                  <i>agree</i>
                </strong>
              ) : (
                <strong>
                  <i>do not agree</i>
                </strong>
              )}{" "}
              that the appointment will continue as an open listing.
            </Box>
          </Grid>
        </CardContent>
      </Card>
    </form>
  )
}

export default PropertySale
