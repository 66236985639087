import { FC } from "react";

import { Controller } from "react-hook-form";
import { FormControl, Select } from "@mui/material";
import { FormInputProps } from "./events";

export interface FormInputSelectProps extends FormInputProps {
  children?: React.ReactElement[];
}
const FormInputSelect: FC<FormInputSelectProps> = ({
  name,
  label,
  control,
  required,
  children = [],
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <FormControl fullWidth>
          <Select
            labelId="clientTypeLabel"
            
            name={field.name}
            label={label}
            required={required}
            defaultValue={field.value ?? ""}
            error={fieldState.error ? true : false}
            aria-invalid={fieldState.error ? true : false}
            onChange={field.onChange}
            
          >
            {children}
          </Select>
        </FormControl>
      )}
    />
  );
};

export default FormInputSelect;
