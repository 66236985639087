import { Fragment } from "react/jsx-runtime"
import AwaitingTaskCompletion from "../../components/awaiting-task-completion"
import { Box, Button, Card, CardActions, CardContent } from "@mui/material"
import { Typography } from "@mui/material"
import { useAppSelector } from "../../app/hooks"
import { useAppDispatch } from "../../app/hooks"
import { uploadSignedAgentAppointment } from "../../app/slices/agent-appointment-slice"
import { selectWorkspace } from "../../app/slices/workspaces-slice"

const AgentAppointmentAwaitingSigning = () => {
  const dispatch = useAppDispatch()
  const workspace = useAppSelector(selectWorkspace)

  function handleUploadSignedAgentAppointment(signedAgentAppointment: File) {
    dispatch(
      uploadSignedAgentAppointment({
        workspaceId: workspace!.workspace.id,
        signedAgentAppointment,
      }),
    )
  }

  return (
    <Fragment>
      <AwaitingTaskCompletion>
        <Fragment>
          <Typography variant="h6">Awaiting signing</Typography>
          <Card sx={{ marginTop: 2, maxWidth: "400px" }}>
            <CardContent>
              <Typography variant="h6">
                Upload a signed copy of the agent appointment
              </Typography>
              <Typography variant="body1">
                If the agent appointment has been manually signed, please upload
                a scanned copy of the document.
              </Typography>
            </CardContent>
            <CardActions>
              <Box width={100} margin="0 auto">
                <Button variant="contained" color="primary" component="label">
                  Upload
                  <input
                    type="file"
                    hidden
                    accept=".pdf"
                    onChange={e => {
                      if (e.target.files && e.target.files[0]) {
                        handleUploadSignedAgentAppointment(e.target.files[0])
                      }
                    }}
                  />
                </Button>
              </Box>
            </CardActions>
          </Card>
        </Fragment>
      </AwaitingTaskCompletion>
    </Fragment>
  )
}

export default AgentAppointmentAwaitingSigning
