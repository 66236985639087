/* tslint:disable */
/* eslint-disable */
/**
 * LandConnex API
 * LandConnex API
 *
 * The version of the OpenAPI document: 3.58.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface ActivityLog
 */
export interface ActivityLog {
    /**
     * 
     * @type {number}
     * @memberof ActivityLog
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ActivityLog
     */
    'created': string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLog
     */
    'summary': string;
}
/**
 * 
 * @export
 * @interface ActivityLogResponse
 */
export interface ActivityLogResponse {
    /**
     * 
     * @type {Array<ActivityLog>}
     * @memberof ActivityLogResponse
     */
    'items'?: Array<ActivityLog>;
}
/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'streetAddress1': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'streetAddress2'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'locality'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'stateOrTerritory': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'postCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'country'?: string;
}
/**
 * 
 * @export
 * @interface AgentAppointment
 */
export interface AgentAppointment {
    /**
     * 
     * @type {number}
     * @memberof AgentAppointment
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AgentAppointment
     */
    'isRealEstateAgent'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentAppointment
     */
    'isLettingAgent'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentAppointment
     */
    'isPropertyAuctioneer'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentAppointment
     */
    'tradingName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentAppointment
     */
    'licenceeName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentAppointment
     */
    'licenceeNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentAppointment
     */
    'licenceeExpiryDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AgentAppointment
     */
    'appointmentIsSale'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentAppointment
     */
    'appointmentIsPurchase'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentAppointment
     */
    'appointmentIsLetting'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentAppointment
     */
    'appointmentIsLeasing'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentAppointment
     */
    'appointmentIsAuction'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentAppointment
     */
    'appointmentIsOther'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentAppointment
     */
    'appointmentIsOtherDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentAppointment
     */
    'auctionDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentAppointment
     */
    'appointmentTermStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentAppointment
     */
    'appointmentTermEnd'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentAppointment
     */
    'appointmentTermType'?: AgentAppointmentAppointmentTermTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof AgentAppointment
     */
    'priceReserve'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentAppointment
     */
    'priceListLow'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentAppointment
     */
    'priceListHigh'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentAppointment
     */
    'priceLetting'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentAppointment
     */
    'priceMarketingRangeLow'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentAppointment
     */
    'priceMarketingRangeHigh'?: number;
    /**
     * 
     * @type {string}
     * @memberof AgentAppointment
     */
    'instructions'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentAppointment
     */
    'appointmentType'?: AgentAppointmentAppointmentTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof AgentAppointment
     */
    'isSoleOrExclusiveContinuation'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentAppointment
     */
    'commissionInstructions'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentAppointment
     */
    'commissionType'?: AgentAppointmentCommissionTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof AgentAppointment
     */
    'commissionAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof AgentAppointment
     */
    'commissionPaymentTerm'?: AgentAppointmentCommissionPaymentTermEnum;
    /**
     * 
     * @type {string}
     * @memberof AgentAppointment
     */
    'commissionPaymentTermOtherAppointments'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentAppointment
     */
    'commissionPaymentTermInstructions'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentAppointment
     */
    'marketingInstructions'?: string;
    /**
     * 
     * @type {number}
     * @memberof AgentAppointment
     */
    'marketingAuthorisedAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentAppointment
     */
    'repairsAndMaintenanceAuthorisedAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentAppointment
     */
    'repairsAndMaintenancePropertyAuthorisedAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof AgentAppointment
     */
    'marketingCostsDueDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof AgentAppointment
     */
    'workspaceId'?: number;
}

export const AgentAppointmentAppointmentTermTypeEnum = {
    Single: 'single',
    Continuing: 'continuing'
} as const;

export type AgentAppointmentAppointmentTermTypeEnum = typeof AgentAppointmentAppointmentTermTypeEnum[keyof typeof AgentAppointmentAppointmentTermTypeEnum];
export const AgentAppointmentAppointmentTypeEnum = {
    Open: 'open',
    Sole: 'sole',
    Exclusive: 'exclusive'
} as const;

export type AgentAppointmentAppointmentTypeEnum = typeof AgentAppointmentAppointmentTypeEnum[keyof typeof AgentAppointmentAppointmentTypeEnum];
export const AgentAppointmentCommissionTypeEnum = {
    Fixed: 'fixed',
    Percentage: 'percentage'
} as const;

export type AgentAppointmentCommissionTypeEnum = typeof AgentAppointmentCommissionTypeEnum[keyof typeof AgentAppointmentCommissionTypeEnum];
export const AgentAppointmentCommissionPaymentTermEnum = {
    Settlement: 'settlement',
    Other: 'other'
} as const;

export type AgentAppointmentCommissionPaymentTermEnum = typeof AgentAppointmentCommissionPaymentTermEnum[keyof typeof AgentAppointmentCommissionPaymentTermEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const AgentAppointmentState = {
    AgentAppointmentPreparing: 'agentAppointmentPreparing',
    AgentAppointmentAwaitingSigning: 'agentAppointmentAwaitingSigning',
    AgentAppointmentSigned: 'agentAppointmentSigned',
    AgentAppointmentDeclined: 'agentAppointmentDeclined',
    AgentAppointmentAwaitingSellerAdmission: 'agentAppointmentAwaitingSellerAdmission',
    AgentAppointmentComplete: 'agentAppointmentComplete'
} as const;

export type AgentAppointmentState = typeof AgentAppointmentState[keyof typeof AgentAppointmentState];


/**
 * 
 * @export
 * @interface AgentAppointmentStatus
 */
export interface AgentAppointmentStatus {
    /**
     * 
     * @type {string}
     * @memberof AgentAppointmentStatus
     */
    'status': string;
    /**
     * 
     * @type {Array<AgentAppointmentStatusRecipientsInner>}
     * @memberof AgentAppointmentStatus
     */
    'recipients'?: Array<AgentAppointmentStatusRecipientsInner>;
}
/**
 * 
 * @export
 * @interface AgentAppointmentStatusRecipientsInner
 */
export interface AgentAppointmentStatusRecipientsInner {
    /**
     * 
     * @type {string}
     * @memberof AgentAppointmentStatusRecipientsInner
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentAppointmentStatusRecipientsInner
     */
    'statusDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentAppointmentStatusRecipientsInner
     */
    'status'?: AgentAppointmentStatusRecipientsInnerStatusEnum;
}

export const AgentAppointmentStatusRecipientsInnerStatusEnum = {
    Created: 'created',
    Pending: 'pending',
    Sent: 'sent',
    Failed: 'failed',
    Completed: 'completed',
    Declined: 'declined'
} as const;

export type AgentAppointmentStatusRecipientsInnerStatusEnum = typeof AgentAppointmentStatusRecipientsInnerStatusEnum[keyof typeof AgentAppointmentStatusRecipientsInnerStatusEnum];

/**
 * 
 * @export
 * @interface AgentAppointmentValidationErrors
 */
export interface AgentAppointmentValidationErrors {
    /**
     * 
     * @type {Array<SendAgentAppointmentForSigning400ResponseItemsInner>}
     * @memberof AgentAppointmentValidationErrors
     */
    'items'?: Array<SendAgentAppointmentForSigning400ResponseItemsInner>;
}
/**
 * 
 * @export
 * @interface Annexure
 */
export interface Annexure {
    /**
     * 
     * @type {number}
     * @memberof Annexure
     */
    'id': number;
    /**
     * 
     * @type {AnnexureDocument}
     * @memberof Annexure
     */
    'document': AnnexureDocument;
    /**
     * 
     * @type {number}
     * @memberof Annexure
     */
    'order': number;
}
/**
 * 
 * @export
 * @interface AnnexureDocument
 */
export interface AnnexureDocument {
    /**
     * 
     * @type {string}
     * @memberof AnnexureDocument
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof AnnexureDocument
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnexureDocument
     */
    'documentType'?: AnnexureDocumentDocumentTypeEnum;
}

export const AnnexureDocumentDocumentTypeEnum = {
    AgentAppointment: 'AgentAppointment',
    Annexure: 'Annexure',
    ContractOfSale: 'ContractOfSale',
    Title: 'Title'
} as const;

export type AnnexureDocumentDocumentTypeEnum = typeof AnnexureDocumentDocumentTypeEnum[keyof typeof AnnexureDocumentDocumentTypeEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const AppointmentTermType = {
    Single: 'single',
    Continuing: 'continuing'
} as const;

export type AppointmentTermType = typeof AppointmentTermType[keyof typeof AppointmentTermType];


/**
 * 
 * @export
 * @enum {string}
 */

export const AppointmentType = {
    Open: 'open',
    Sole: 'sole',
    Exclusive: 'exclusive'
} as const;

export type AppointmentType = typeof AppointmentType[keyof typeof AppointmentType];


/**
 * 
 * @export
 * @interface AssertUser200Response
 */
export interface AssertUser200Response {
    /**
     * 
     * @type {boolean}
     * @memberof AssertUser200Response
     */
    'newUser': boolean;
}
/**
 * 
 * @export
 * @interface BenefitToAgent
 */
export interface BenefitToAgent {
    /**
     * 
     * @type {number}
     * @memberof BenefitToAgent
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof BenefitToAgent
     */
    'service'?: string;
    /**
     * 
     * @type {string}
     * @memberof BenefitToAgent
     */
    'source'?: string;
    /**
     * 
     * @type {string}
     * @memberof BenefitToAgent
     */
    'estimatedAmount'?: string;
}
/**
 * 
 * @export
 * @interface BenefitToAgents
 */
export interface BenefitToAgents {
    /**
     * 
     * @type {Array<BenefitToAgent>}
     * @memberof BenefitToAgents
     */
    'items'?: Array<BenefitToAgent>;
}
/**
 * 
 * @export
 * @interface Branding
 */
export interface Branding {
    /**
     * 
     * @type {string}
     * @memberof Branding
     */
    'logo': string;
    /**
     * 
     * @type {string}
     * @memberof Branding
     */
    'primaryColor': string;
    /**
     * 
     * @type {string}
     * @memberof Branding
     */
    'secondaryColor': string;
    /**
     * 
     * @type {string}
     * @memberof Branding
     */
    'companyType': BrandingCompanyTypeEnum;
}

export const BrandingCompanyTypeEnum = {
    Solicitor: 'solicitor',
    RealEstateAgency: 'realEstateAgency'
} as const;

export type BrandingCompanyTypeEnum = typeof BrandingCompanyTypeEnum[keyof typeof BrandingCompanyTypeEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const CommissionType = {
    Fixed: 'fixed',
    Percentage: 'percentage'
} as const;

export type CommissionType = typeof CommissionType[keyof typeof CommissionType];


/**
 * 
 * @export
 * @enum {string}
 */

export const CompanyType = {
    Solicitor: 'solicitor',
    RealEstateAgency: 'realEstateAgency'
} as const;

export type CompanyType = typeof CompanyType[keyof typeof CompanyType];


/**
 * 
 * @export
 * @interface CompleteOnBoardingRequest
 */
export interface CompleteOnBoardingRequest {
    /**
     * 
     * @type {string}
     * @memberof CompleteOnBoardingRequest
     */
    'setupIntentID': string;
}
/**
 * 
 * @export
 * @interface CompleteOnBoardingRequest1
 */
export interface CompleteOnBoardingRequest1 {
    /**
     * 
     * @type {string}
     * @memberof CompleteOnBoardingRequest1
     */
    'setupIntentID': string;
}
/**
 * 
 * @export
 * @interface ContactDetails
 */
export interface ContactDetails {
    /**
     * 
     * @type {Name}
     * @memberof ContactDetails
     */
    'name'?: Name;
    /**
     * 
     * @type {Address}
     * @memberof ContactDetails
     */
    'address'?: Address;
    /**
     * 
     * @type {string}
     * @memberof ContactDetails
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDetails
     */
    'mobilePhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDetails
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface ContactDetails1
 */
export interface ContactDetails1 {
    /**
     * 
     * @type {Name}
     * @memberof ContactDetails1
     */
    'name'?: Name;
    /**
     * 
     * @type {Address}
     * @memberof ContactDetails1
     */
    'address'?: Address;
    /**
     * 
     * @type {string}
     * @memberof ContactDetails1
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDetails1
     */
    'mobilePhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDetails1
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface Contract
 */
export interface Contract {
    /**
     * 
     * @type {number}
     * @memberof Contract
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'reference'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'externalReference'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'modifiedBy'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Contract
     */
    'hasRtaAgreement'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'lastRentIncreaseDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Contract
     */
    'gstPaymentRequired'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Contract
     */
    'hasEncumbrances': boolean;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'encumbrances'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Contract
     */
    'hasPool': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Contract
     */
    'hasPoolCertificate': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Contract
     */
    'hasSafetySwitches': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Contract
     */
    'hasSmokeAlarms': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Contract
     */
    'hasNeighbourhoodDisputes': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Contract
     */
    'hasTenant': boolean;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'depositHolderName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'depositHolderAccountName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'depositHolderBank'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'depositHolderBsb'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'depositHolderAccountNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'tenancyTenantName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'tenancyTermAndOptions'?: string;
    /**
     * 
     * @type {number}
     * @memberof Contract
     */
    'tenancyBond'?: number;
    /**
     * 
     * @type {number}
     * @memberof Contract
     */
    'tenancyRent'?: number;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'tenancyTermStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'tenancyTermEnd'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'excludedFixtures'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'includedChattels'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'sellerSpecialConditions'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'buyerSpecialConditions'?: string;
    /**
     * True if the seller is represented by a solicitor at the time of contract signing, false otherwise.
     * @type {boolean}
     * @memberof Contract
     */
    'hasSellerSolicitor': boolean;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'depositHolder'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'trustAccount'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'bank'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'bsb'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'account'?: string;
    /**
     * the total purchase amount in cents
     * @type {number}
     * @memberof Contract
     */
    'purchasePrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof Contract
     */
    'depositInitial'?: number;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'depositInitialDue'?: string;
    /**
     * 
     * @type {number}
     * @memberof Contract
     */
    'depositBalance'?: number;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'depositBalanceDue'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'defaultInterestRate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'financier'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'financeAmount'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'financeDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Contract
     */
    'buyerRegisteredForGst'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'buildingAndPestInspection'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'settlementDate'?: string;
}
/**
 * 
 * @export
 * @interface ContractAnnexuresResponse
 */
export interface ContractAnnexuresResponse {
    /**
     * 
     * @type {Array<Annexure>}
     * @memberof ContractAnnexuresResponse
     */
    'items': Array<Annexure>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ContractPreparationState = {
    ContractPreparationNotStarted: 'contractPreparationNotStarted',
    ContractPreparationAwaitingSellerSolicitorSelection: 'contractPreparationAwaitingSellerSolicitorSelection',
    ContractPreparationAwaitingSellerSolicitorAdmission: 'contractPreparationAwaitingSellerSolicitorAdmission',
    ContractPreparationPreparing: 'contractPreparationPreparing',
    ContractPreparationComplete: 'contractPreparationComplete'
} as const;

export type ContractPreparationState = typeof ContractPreparationState[keyof typeof ContractPreparationState];


/**
 * 
 * @export
 * @enum {string}
 */

export const ConveyanceState = {
    ConveyanceNotStarted: 'conveyanceNotStarted',
    ConveyanceInprogress: 'conveyanceInprogress'
} as const;

export type ConveyanceState = typeof ConveyanceState[keyof typeof ConveyanceState];


/**
 * 
 * @export
 * @interface CreateOnBoardingResponse
 */
export interface CreateOnBoardingResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateOnBoardingResponse
     */
    'setupIntentID': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOnBoardingResponse
     */
    'clientSecretID': string;
}
/**
 * 
 * @export
 * @interface Created
 */
export interface Created {
    /**
     * 
     * @type {string}
     * @memberof Created
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Created
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Created
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Created
     */
    'modifiedBy'?: string;
}
/**
 * 
 * @export
 * @interface Dashboard
 */
export interface Dashboard {
    /**
     * 
     * @type {Array<FinancialStatistic>}
     * @memberof Dashboard
     */
    'financialStatistics'?: Array<FinancialStatistic>;
}
/**
 * 
 * @export
 * @interface DocumentSummary
 */
export interface DocumentSummary {
    /**
     * 
     * @type {string}
     * @memberof DocumentSummary
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof DocumentSummary
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentSummary
     */
    'documentType'?: DocumentSummaryDocumentTypeEnum;
}

export const DocumentSummaryDocumentTypeEnum = {
    AgentAppointment: 'AgentAppointment',
    Annexure: 'Annexure',
    ContractOfSale: 'ContractOfSale',
    Title: 'Title'
} as const;

export type DocumentSummaryDocumentTypeEnum = typeof DocumentSummaryDocumentTypeEnum[keyof typeof DocumentSummaryDocumentTypeEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const DocumentType = {
    AgentAppointment: 'AgentAppointment',
    Annexure: 'Annexure',
    ContractOfSale: 'ContractOfSale',
    Title: 'Title'
} as const;

export type DocumentType = typeof DocumentType[keyof typeof DocumentType];


/**
 * 
 * @export
 * @interface Documents
 */
export interface Documents {
    /**
     * 
     * @type {Array<AnnexureDocument>}
     * @memberof Documents
     */
    'items'?: Array<AnnexureDocument>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ESigningStatus = {
    Created: 'created',
    Pending: 'pending',
    Sent: 'sent',
    Failed: 'failed',
    Completed: 'completed',
    Declined: 'declined'
} as const;

export type ESigningStatus = typeof ESigningStatus[keyof typeof ESigningStatus];


/**
 * 
 * @export
 * @interface EnvelopeChangedRequest
 */
export interface EnvelopeChangedRequest {
    /**
     * 
     * @type {string}
     * @memberof EnvelopeChangedRequest
     */
    'envelope_id': string;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeChangedRequest
     */
    'event': string;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeChangedRequest
     */
    'created'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeChangedRequest
     */
    'sent'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeChangedRequest
     */
    'voided'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeChangedRequest
     */
    'viewed'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeChangedRequest
     */
    'declined'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeChangedRequest
     */
    'completed'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeChangedRequest
     */
    'failed'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeChangedRequest
     */
    'recipient_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeChangedRequest
     */
    'certificate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeChangedRequest
     */
    'master'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeChangedRequest
     */
    'method'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeChangedRequest
     */
    'declined_reason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeChangedRequest
     */
    'diagnosis'?: string | null;
}
/**
 * 
 * @export
 * @interface EnvelopeUpdate
 */
export interface EnvelopeUpdate {
    /**
     * 
     * @type {string}
     * @memberof EnvelopeUpdate
     */
    'envelope_id': string;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeUpdate
     */
    'event': string;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeUpdate
     */
    'created'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeUpdate
     */
    'sent'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeUpdate
     */
    'voided'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeUpdate
     */
    'viewed'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeUpdate
     */
    'declined'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeUpdate
     */
    'completed'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeUpdate
     */
    'failed'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeUpdate
     */
    'recipient_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeUpdate
     */
    'certificate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeUpdate
     */
    'master'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeUpdate
     */
    'method'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeUpdate
     */
    'declined_reason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeUpdate
     */
    'diagnosis'?: string | null;
}
/**
 * 
 * @export
 * @interface Fee
 */
export interface Fee {
    /**
     * 
     * @type {number}
     * @memberof Fee
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Fee
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Fee
     */
    'whenPayable'?: string;
    /**
     * 
     * @type {number}
     * @memberof Fee
     */
    'estimatedAmount'?: number;
}
/**
 * 
 * @export
 * @interface Fees
 */
export interface Fees {
    /**
     * 
     * @type {Array<Fee>}
     * @memberof Fees
     */
    'items'?: Array<Fee>;
}
/**
 * 
 * @export
 * @interface FinancialStatistic
 */
export interface FinancialStatistic {
    /**
     * 
     * @type {string}
     * @memberof FinancialStatistic
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof FinancialStatistic
     */
    'settlementDate': string;
    /**
     * 
     * @type {number}
     * @memberof FinancialStatistic
     */
    'listingPrice': number;
    /**
     * 
     * @type {number}
     * @memberof FinancialStatistic
     */
    'minPurchasePrice': number;
    /**
     * 
     * @type {number}
     * @memberof FinancialStatistic
     */
    'maxPurchasePrice': number;
    /**
     * 
     * @type {number}
     * @memberof FinancialStatistic
     */
    'averagePurchasePrice': number;
    /**
     * 
     * @type {number}
     * @memberof FinancialStatistic
     */
    'commission': number;
    /**
     * 
     * @type {string}
     * @memberof FinancialStatistic
     */
    'commissionType': FinancialStatisticCommissionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FinancialStatistic
     */
    'agentSub': string;
    /**
     * 
     * @type {string}
     * @memberof FinancialStatistic
     */
    'agentName': string;
}

export const FinancialStatisticCommissionTypeEnum = {
    Fixed: 'fixed',
    Percentage: 'percentage'
} as const;

export type FinancialStatisticCommissionTypeEnum = typeof FinancialStatisticCommissionTypeEnum[keyof typeof FinancialStatisticCommissionTypeEnum];

/**
 * 
 * @export
 * @interface Flat
 */
export interface Flat {
    /**
     * 
     * @type {PhysicalAustralianAddressStructuredFlatType}
     * @memberof Flat
     */
    'type'?: PhysicalAustralianAddressStructuredFlatType;
    /**
     * 
     * @type {string}
     * @memberof Flat
     */
    'prefix'?: string;
    /**
     * 
     * @type {number}
     * @memberof Flat
     */
    'number'?: number;
    /**
     * 
     * @type {string}
     * @memberof Flat
     */
    'suffix'?: string;
}
/**
 * 
 * @export
 * @interface FlattenedAddress
 */
export interface FlattenedAddress {
    /**
     * The full address flattened to a single line. The primary use is for search results and for autocomplete.
     * @type {string}
     * @memberof FlattenedAddress
     */
    'sla': string;
    /**
     * The full address flattened to a single line. The primary use is for search results and for autocomplete.
     * @type {string}
     * @memberof FlattenedAddress
     */
    'ssla'?: string;
    /**
     * 
     * @type {FlattenedAddressHighlight}
     * @memberof FlattenedAddress
     */
    'highlight'?: FlattenedAddressHighlight;
    /**
     * strength of the match relative to the other matches
     * @type {number}
     * @memberof FlattenedAddress
     */
    'score': number;
    /**
     * The Persistent Identifier is unique to the real world feature this record represents.
     * @type {string}
     * @memberof FlattenedAddress
     */
    'pid': string;
}
/**
 * 
 * @export
 * @interface FlattenedAddressHighlight
 */
export interface FlattenedAddressHighlight {
    /**
     * The full address flattened to a single line with matching search tokens highlighted
     * @type {string}
     * @memberof FlattenedAddressHighlight
     */
    'sla': string;
    /**
     * The full address flattened to a single line with matching search tokens highlighted
     * @type {string}
     * @memberof FlattenedAddressHighlight
     */
    'ssla'?: string;
}
/**
 * 
 * @export
 * @interface FlattenedAddresses
 */
export interface FlattenedAddresses {
    /**
     * 
     * @type {Array<FlattenedAddress>}
     * @memberof FlattenedAddresses
     */
    'items'?: Array<FlattenedAddress>;
}
/**
 * 
 * @export
 * @interface Geocode
 */
export interface Geocode {
    /**
     * 
     * @type {string}
     * @memberof Geocode
     */
    'typeCode'?: string;
    /**
     * 
     * @type {number}
     * @memberof Geocode
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof Geocode
     */
    'longitude'?: number;
}
/**
 * 
 * @export
 * @interface GetAgent
 */
export interface GetAgent {
    /**
     * 
     * @type {GetAgentAppointment200ResponseAgentAppointment}
     * @memberof GetAgent
     */
    'agentAppointment': GetAgentAppointment200ResponseAgentAppointment;
}
/**
 * 
 * @export
 * @interface GetAgentAppointment200Response
 */
export interface GetAgentAppointment200Response {
    /**
     * 
     * @type {GetAgentAppointment200ResponseAgentAppointment}
     * @memberof GetAgentAppointment200Response
     */
    'agentAppointment': GetAgentAppointment200ResponseAgentAppointment;
}
/**
 * 
 * @export
 * @interface GetAgentAppointment200ResponseAgentAppointment
 */
export interface GetAgentAppointment200ResponseAgentAppointment {
    /**
     * 
     * @type {number}
     * @memberof GetAgentAppointment200ResponseAgentAppointment
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetAgentAppointment200ResponseAgentAppointment
     */
    'isRealEstateAgent'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetAgentAppointment200ResponseAgentAppointment
     */
    'isLettingAgent'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetAgentAppointment200ResponseAgentAppointment
     */
    'isPropertyAuctioneer'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetAgentAppointment200ResponseAgentAppointment
     */
    'tradingName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAgentAppointment200ResponseAgentAppointment
     */
    'licenceeName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAgentAppointment200ResponseAgentAppointment
     */
    'licenceeNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAgentAppointment200ResponseAgentAppointment
     */
    'licenceeExpiryDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetAgentAppointment200ResponseAgentAppointment
     */
    'appointmentIsSale'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetAgentAppointment200ResponseAgentAppointment
     */
    'appointmentIsPurchase'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetAgentAppointment200ResponseAgentAppointment
     */
    'appointmentIsLetting'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetAgentAppointment200ResponseAgentAppointment
     */
    'appointmentIsLeasing'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetAgentAppointment200ResponseAgentAppointment
     */
    'appointmentIsAuction'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetAgentAppointment200ResponseAgentAppointment
     */
    'appointmentIsOther'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetAgentAppointment200ResponseAgentAppointment
     */
    'appointmentIsOtherDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAgentAppointment200ResponseAgentAppointment
     */
    'auctionDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAgentAppointment200ResponseAgentAppointment
     */
    'appointmentTermStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAgentAppointment200ResponseAgentAppointment
     */
    'appointmentTermEnd'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAgentAppointment200ResponseAgentAppointment
     */
    'appointmentTermType'?: GetAgentAppointment200ResponseAgentAppointmentAppointmentTermTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof GetAgentAppointment200ResponseAgentAppointment
     */
    'priceReserve'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAgentAppointment200ResponseAgentAppointment
     */
    'priceListLow'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAgentAppointment200ResponseAgentAppointment
     */
    'priceListHigh'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAgentAppointment200ResponseAgentAppointment
     */
    'priceLetting'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAgentAppointment200ResponseAgentAppointment
     */
    'priceMarketingRangeLow'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAgentAppointment200ResponseAgentAppointment
     */
    'priceMarketingRangeHigh'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAgentAppointment200ResponseAgentAppointment
     */
    'instructions'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAgentAppointment200ResponseAgentAppointment
     */
    'appointmentType'?: GetAgentAppointment200ResponseAgentAppointmentAppointmentTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof GetAgentAppointment200ResponseAgentAppointment
     */
    'isSoleOrExclusiveContinuation'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetAgentAppointment200ResponseAgentAppointment
     */
    'commissionInstructions'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAgentAppointment200ResponseAgentAppointment
     */
    'commissionType'?: GetAgentAppointment200ResponseAgentAppointmentCommissionTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof GetAgentAppointment200ResponseAgentAppointment
     */
    'commissionAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAgentAppointment200ResponseAgentAppointment
     */
    'commissionPaymentTerm'?: GetAgentAppointment200ResponseAgentAppointmentCommissionPaymentTermEnum;
    /**
     * 
     * @type {string}
     * @memberof GetAgentAppointment200ResponseAgentAppointment
     */
    'commissionPaymentTermOtherAppointments'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAgentAppointment200ResponseAgentAppointment
     */
    'commissionPaymentTermInstructions'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAgentAppointment200ResponseAgentAppointment
     */
    'marketingInstructions'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAgentAppointment200ResponseAgentAppointment
     */
    'marketingAuthorisedAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAgentAppointment200ResponseAgentAppointment
     */
    'repairsAndMaintenanceAuthorisedAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAgentAppointment200ResponseAgentAppointment
     */
    'repairsAndMaintenancePropertyAuthorisedAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAgentAppointment200ResponseAgentAppointment
     */
    'marketingCostsDueDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAgentAppointment200ResponseAgentAppointment
     */
    'workspaceId'?: number;
}

export const GetAgentAppointment200ResponseAgentAppointmentAppointmentTermTypeEnum = {
    Single: 'single',
    Continuing: 'continuing'
} as const;

export type GetAgentAppointment200ResponseAgentAppointmentAppointmentTermTypeEnum = typeof GetAgentAppointment200ResponseAgentAppointmentAppointmentTermTypeEnum[keyof typeof GetAgentAppointment200ResponseAgentAppointmentAppointmentTermTypeEnum];
export const GetAgentAppointment200ResponseAgentAppointmentAppointmentTypeEnum = {
    Open: 'open',
    Sole: 'sole',
    Exclusive: 'exclusive'
} as const;

export type GetAgentAppointment200ResponseAgentAppointmentAppointmentTypeEnum = typeof GetAgentAppointment200ResponseAgentAppointmentAppointmentTypeEnum[keyof typeof GetAgentAppointment200ResponseAgentAppointmentAppointmentTypeEnum];
export const GetAgentAppointment200ResponseAgentAppointmentCommissionTypeEnum = {
    Fixed: 'fixed',
    Percentage: 'percentage'
} as const;

export type GetAgentAppointment200ResponseAgentAppointmentCommissionTypeEnum = typeof GetAgentAppointment200ResponseAgentAppointmentCommissionTypeEnum[keyof typeof GetAgentAppointment200ResponseAgentAppointmentCommissionTypeEnum];
export const GetAgentAppointment200ResponseAgentAppointmentCommissionPaymentTermEnum = {
    Settlement: 'settlement',
    Other: 'other'
} as const;

export type GetAgentAppointment200ResponseAgentAppointmentCommissionPaymentTermEnum = typeof GetAgentAppointment200ResponseAgentAppointmentCommissionPaymentTermEnum[keyof typeof GetAgentAppointment200ResponseAgentAppointmentCommissionPaymentTermEnum];

/**
 * 
 * @export
 * @interface GetAgentAppointmentAnnexuresResponse
 */
export interface GetAgentAppointmentAnnexuresResponse {
    /**
     * 
     * @type {Array<Annexure>}
     * @memberof GetAgentAppointmentAnnexuresResponse
     */
    'items'?: Array<Annexure>;
}
/**
 * 
 * @export
 * @interface GetAgentAppointmentLicencee200Response
 */
export interface GetAgentAppointmentLicencee200Response {
    /**
     * The trading name of the licencee
     * @type {string}
     * @memberof GetAgentAppointmentLicencee200Response
     */
    'tradingName'?: string;
    /**
     * The company name of the licencee
     * @type {string}
     * @memberof GetAgentAppointmentLicencee200Response
     */
    'companyName'?: string;
    /**
     * The ABN of the licencee
     * @type {string}
     * @memberof GetAgentAppointmentLicencee200Response
     */
    'abn'?: string;
    /**
     * The ACN of the licencee
     * @type {string}
     * @memberof GetAgentAppointmentLicencee200Response
     */
    'acn'?: string;
    /**
     * The name of the licencee
     * @type {string}
     * @memberof GetAgentAppointmentLicencee200Response
     */
    'name': string;
    /**
     * The number of the licencee
     * @type {string}
     * @memberof GetAgentAppointmentLicencee200Response
     */
    'number': string;
    /**
     * The expiry date of the licencee
     * @type {string}
     * @memberof GetAgentAppointmentLicencee200Response
     */
    'expiryDate': string;
    /**
     * 
     * @type {ContactDetails1}
     * @memberof GetAgentAppointmentLicencee200Response
     */
    'contactDetails'?: ContactDetails1;
}
/**
 * 
 * @export
 * @interface GetDocuments200Response
 */
export interface GetDocuments200Response {
    /**
     * 
     * @type {Array<AnnexureDocument>}
     * @memberof GetDocuments200Response
     */
    'items'?: Array<AnnexureDocument>;
}
/**
 * 
 * @export
 * @interface GetLicenceResponse
 */
export interface GetLicenceResponse {
    /**
     * 
     * @type {GetAgentAppointmentLicencee200Response}
     * @memberof GetLicenceResponse
     */
    'licence': GetAgentAppointmentLicencee200Response;
}
/**
 * 
 * @export
 * @interface GetMyDetailsResponse
 */
export interface GetMyDetailsResponse {
    /**
     * 
     * @type {User1}
     * @memberof GetMyDetailsResponse
     */
    'user': User1;
}
/**
 * 
 * @export
 * @interface GetTeamInvitationsResposne
 */
export interface GetTeamInvitationsResposne {
    /**
     * 
     * @type {Array<TeamInvitation>}
     * @memberof GetTeamInvitationsResposne
     */
    'items': Array<TeamInvitation>;
}
/**
 * 
 * @export
 * @interface GetTitle200Response
 */
export interface GetTitle200Response {
    /**
     * The unique reference number for the title
     * @type {string}
     * @memberof GetTitle200Response
     */
    'titleReference'?: string;
    /**
     * The date the title search was performed
     * @type {string}
     * @memberof GetTitle200Response
     */
    'searchDate'?: string;
    /**
     * The date the title was created
     * @type {string}
     * @memberof GetTitle200Response
     */
    'dateTitleCreated'?: string;
    /**
     * The request number for this search
     * @type {string}
     * @memberof GetTitle200Response
     */
    'requestNumber'?: string;
    /**
     * Reference to any previous title
     * @type {string}
     * @memberof GetTitle200Response
     */
    'previousTitle'?: string;
    /**
     * The lot number of the property
     * @type {string}
     * @memberof GetTitle200Response
     */
    'lotNumber'?: string;
    /**
     * The type of estate (e.g. Fee Simple)
     * @type {string}
     * @memberof GetTitle200Response
     */
    'estateType'?: string;
    /**
     * The plan number
     * @type {string}
     * @memberof GetTitle200Response
     */
    'planNumber'?: string;
    /**
     * The type of plan
     * @type {string}
     * @memberof GetTitle200Response
     */
    'planType'?: string;
    /**
     * Whether the owners/lessors are trustees
     * @type {boolean}
     * @memberof GetTitle200Response
     */
    'ownerOrLessorsIsTrustee'?: boolean;
    /**
     * The local government area
     * @type {string}
     * @memberof GetTitle200Response
     */
    'localGovernment'?: string;
    /**
     * Raw string of owner/lessor details
     * @type {string}
     * @memberof GetTitle200Response
     */
    'titleOwnerOrLessor'?: string;
    /**
     * List of parsed owners/lessors
     * @type {Array<GetTitle200ResponseOwnerOrLessorsInner>}
     * @memberof GetTitle200Response
     */
    'ownerOrLessors'?: Array<GetTitle200ResponseOwnerOrLessorsInner>;
}
/**
 * 
 * @export
 * @interface GetTitle200ResponseOwnerOrLessorsInner
 */
export interface GetTitle200ResponseOwnerOrLessorsInner {
    /**
     * Name of the owner/lessor
     * @type {string}
     * @memberof GetTitle200ResponseOwnerOrLessorsInner
     */
    'ownerName'?: string;
    /**
     * Their interest in the property
     * @type {string}
     * @memberof GetTitle200ResponseOwnerOrLessorsInner
     */
    'interest'?: string;
}
/**
 * 
 * @export
 * @interface GetTrustAccount200Response
 */
export interface GetTrustAccount200Response {
    /**
     * The name of the bank
     * @type {string}
     * @memberof GetTrustAccount200Response
     */
    'accountBankName': string;
    /**
     * The name of the trust account
     * @type {string}
     * @memberof GetTrustAccount200Response
     */
    'accountName': string;
    /**
     * The account number of the trust account
     * @type {string}
     * @memberof GetTrustAccount200Response
     */
    'accountNumber': string;
    /**
     * The BSB of the trust account
     * @type {string}
     * @memberof GetTrustAccount200Response
     */
    'accountBSB': string;
    /**
     * The name of the account holder
     * @type {string}
     * @memberof GetTrustAccount200Response
     */
    'accountHolderName': string;
}
/**
 * 
 * @export
 * @interface GetUsersResponse
 */
export interface GetUsersResponse {
    /**
     * 
     * @type {Array<User1>}
     * @memberof GetUsersResponse
     */
    'users'?: Array<User1>;
}
/**
 * 
 * @export
 * @interface Highlight
 */
export interface Highlight {
    /**
     * The full address flattened to a single line with matching search tokens highlighted
     * @type {string}
     * @memberof Highlight
     */
    'sla': string;
    /**
     * The full address flattened to a single line with matching search tokens highlighted
     * @type {string}
     * @memberof Highlight
     */
    'ssla'?: string;
}
/**
 * 
 * @export
 * @interface Invitation
 */
export interface Invitation {
    /**
     * 
     * @type {string}
     * @memberof Invitation
     */
    'emailAddress'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const InvitationStatus = {
    NotSent: 'notSent',
    Pending: 'pending',
    Accepted: 'accepted'
} as const;

export type InvitationStatus = typeof InvitationStatus[keyof typeof InvitationStatus];


/**
 * 
 * @export
 * @interface Invoice
 */
export interface Invoice {
    /**
     * 
     * @type {Array<InvoiceLineItem1>}
     * @memberof Invoice
     */
    'lineItems'?: Array<InvoiceLineItem1>;
}
/**
 * 
 * @export
 * @interface InvoiceLineItem
 */
export interface InvoiceLineItem {
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineItem
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineItem
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineItem
     */
    'quantity': number;
}
/**
 * 
 * @export
 * @interface InvoiceLineItem1
 */
export interface InvoiceLineItem1 {
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineItem1
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineItem1
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineItem1
     */
    'quantity': number;
}
/**
 * 
 * @export
 * @interface Licencee
 */
export interface Licencee {
    /**
     * The trading name of the licencee
     * @type {string}
     * @memberof Licencee
     */
    'tradingName'?: string;
    /**
     * The company name of the licencee
     * @type {string}
     * @memberof Licencee
     */
    'companyName'?: string;
    /**
     * The ABN of the licencee
     * @type {string}
     * @memberof Licencee
     */
    'abn'?: string;
    /**
     * The ACN of the licencee
     * @type {string}
     * @memberof Licencee
     */
    'acn'?: string;
    /**
     * The name of the licencee
     * @type {string}
     * @memberof Licencee
     */
    'name': string;
    /**
     * The number of the licencee
     * @type {string}
     * @memberof Licencee
     */
    'number': string;
    /**
     * The expiry date of the licencee
     * @type {string}
     * @memberof Licencee
     */
    'expiryDate': string;
    /**
     * 
     * @type {ContactDetails1}
     * @memberof Licencee
     */
    'contactDetails'?: ContactDetails1;
}
/**
 * 
 * @export
 * @interface LocalGovernmentArea
 */
export interface LocalGovernmentArea {
    /**
     * 
     * @type {string}
     * @memberof LocalGovernmentArea
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof LocalGovernmentArea
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface Locality
 */
export interface Locality {
    /**
     * 
     * @type {string}
     * @memberof Locality
     */
    'name'?: string;
    /**
     * 
     * @type {PhysicalAustralianAddressStructuredStreetType}
     * @memberof Locality
     */
    'class'?: PhysicalAustralianAddressStructuredStreetType;
}
/**
 * 
 * @export
 * @interface MetaData
 */
export interface MetaData {
    /**
     * 
     * @type {string}
     * @memberof MetaData
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof MetaData
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface Metadata
 */
export interface Metadata {
    /**
     * 
     * @type {string}
     * @memberof Metadata
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Metadata
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Metadata
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Metadata
     */
    'modifiedBy'?: string;
}
/**
 * 
 * @export
 * @interface Name
 */
export interface Name {
    /**
     * 
     * @type {string}
     * @memberof Name
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Name
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Name
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Name
     */
    'organisationName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Name
     */
    'type'?: NameTypeEnum;
}

export const NameTypeEnum = {
    Individual: 'individual',
    Organisation: 'organisation'
} as const;

export type NameTypeEnum = typeof NameTypeEnum[keyof typeof NameTypeEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const NameType = {
    Individual: 'individual',
    Organisation: 'organisation'
} as const;

export type NameType = typeof NameType[keyof typeof NameType];


/**
 * 
 * @export
 * @interface NewEntity
 */
export interface NewEntity {
    /**
     * 
     * @type {number}
     * @memberof NewEntity
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface OfferDetail
 */
export interface OfferDetail {
    /**
     * 
     * @type {Contract}
     * @memberof OfferDetail
     */
    'offer': Contract;
    /**
     * 
     * @type {Array<Participant1>}
     * @memberof OfferDetail
     */
    'participants': Array<Participant1>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const OfferNegotiationState = {
    OfferNegotiationNotStarted: 'offerNegotiationNotStarted',
    OfferNegotiationAcceptingOffers: 'offerNegotiationAcceptingOffers',
    OfferNegotiationComplete: 'offerNegotiationComplete'
} as const;

export type OfferNegotiationState = typeof OfferNegotiationState[keyof typeof OfferNegotiationState];


/**
 * 
 * @export
 * @enum {string}
 */

export const OfferState = {
    OfferCreated: 'OfferCreated',
    OfferBuyerSigningSent: 'OfferBuyerSigningSent',
    OfferBuyerSigningCompleted: 'OfferBuyerSigningCompleted',
    OfferBuyerSigningDeclined: 'OfferBuyerSigningDeclined',
    OfferSellerSigningSent: 'OfferSellerSigningSent',
    OfferSellerSigningCompleted: 'OfferSellerSigningCompleted',
    OfferSellerSigningDeclined: 'OfferSellerSigningDeclined',
    OfferCompletedSuccess: 'OfferCompletedSuccess',
    OfferCompletedDeclined: 'OfferCompletedDeclined'
} as const;

export type OfferState = typeof OfferState[keyof typeof OfferState];


/**
 * 
 * @export
 * @interface OfferStatus
 */
export interface OfferStatus {
    /**
     * 
     * @type {number}
     * @memberof OfferStatus
     */
    'offerId': number;
    /**
     * 
     * @type {string}
     * @memberof OfferStatus
     */
    'state': OfferStatusStateEnum;
    /**
     * 
     * @type {boolean}
     * @memberof OfferStatus
     */
    'buyerSolicitorInvited': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OfferStatus
     */
    'buyerInvited': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OfferStatus
     */
    'sharedWithSeller': boolean;
}

export const OfferStatusStateEnum = {
    OfferCreated: 'OfferCreated',
    OfferBuyerSigningSent: 'OfferBuyerSigningSent',
    OfferBuyerSigningCompleted: 'OfferBuyerSigningCompleted',
    OfferBuyerSigningDeclined: 'OfferBuyerSigningDeclined',
    OfferSellerSigningSent: 'OfferSellerSigningSent',
    OfferSellerSigningCompleted: 'OfferSellerSigningCompleted',
    OfferSellerSigningDeclined: 'OfferSellerSigningDeclined',
    OfferCompletedSuccess: 'OfferCompletedSuccess',
    OfferCompletedDeclined: 'OfferCompletedDeclined'
} as const;

export type OfferStatusStateEnum = typeof OfferStatusStateEnum[keyof typeof OfferStatusStateEnum];

/**
 * 
 * @export
 * @interface OfferSummary
 */
export interface OfferSummary {
    /**
     * 
     * @type {number}
     * @memberof OfferSummary
     */
    'offerId': number;
    /**
     * 
     * @type {string}
     * @memberof OfferSummary
     */
    'buyers'?: string;
    /**
     * 
     * @type {number}
     * @memberof OfferSummary
     */
    'purchasePrice'?: number;
    /**
     * 
     * @type {string}
     * @memberof OfferSummary
     */
    'settlementDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferSummary
     */
    'state': OfferSummaryStateEnum;
}

export const OfferSummaryStateEnum = {
    OfferCreated: 'OfferCreated',
    OfferBuyerSigningSent: 'OfferBuyerSigningSent',
    OfferBuyerSigningCompleted: 'OfferBuyerSigningCompleted',
    OfferBuyerSigningDeclined: 'OfferBuyerSigningDeclined',
    OfferSellerSigningSent: 'OfferSellerSigningSent',
    OfferSellerSigningCompleted: 'OfferSellerSigningCompleted',
    OfferSellerSigningDeclined: 'OfferSellerSigningDeclined',
    OfferCompletedSuccess: 'OfferCompletedSuccess',
    OfferCompletedDeclined: 'OfferCompletedDeclined'
} as const;

export type OfferSummaryStateEnum = typeof OfferSummaryStateEnum[keyof typeof OfferSummaryStateEnum];

/**
 * 
 * @export
 * @interface Offers
 */
export interface Offers {
    /**
     * 
     * @type {Array<OfferDetail>}
     * @memberof Offers
     */
    'items'?: Array<OfferDetail>;
}
/**
 * 
 * @export
 * @interface OnBoarding
 */
export interface OnBoarding {
    /**
     * 
     * @type {string}
     * @memberof OnBoarding
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof OnBoarding
     */
    'companyType': OnBoardingCompanyTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof OnBoarding
     */
    'abn': string;
    /**
     * 
     * @type {string}
     * @memberof OnBoarding
     */
    'acn'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OnBoarding
     */
    'registeredForGst': boolean;
    /**
     * 
     * @type {string}
     * @memberof OnBoarding
     */
    'licenceeNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof OnBoarding
     */
    'licenceeExpiryDate'?: string;
    /**
     * 
     * @type {Address}
     * @memberof OnBoarding
     */
    'physicalAddress': Address;
    /**
     * 
     * @type {Name}
     * @memberof OnBoarding
     */
    'primaryContactName': Name;
    /**
     * 
     * @type {string}
     * @memberof OnBoarding
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof OnBoarding
     */
    'mobilePhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof OnBoarding
     */
    'email': string;
}

export const OnBoardingCompanyTypeEnum = {
    Solicitor: 'solicitor',
    RealEstateAgency: 'realEstateAgency'
} as const;

export type OnBoardingCompanyTypeEnum = typeof OnBoardingCompanyTypeEnum[keyof typeof OnBoardingCompanyTypeEnum];

/**
 * 
 * @export
 * @interface OwnerOrLessor
 */
export interface OwnerOrLessor {
    /**
     * Name of the owner/lessor
     * @type {string}
     * @memberof OwnerOrLessor
     */
    'ownerName'?: string;
    /**
     * Their interest in the property
     * @type {string}
     * @memberof OwnerOrLessor
     */
    'interest'?: string;
}
/**
 * 
 * @export
 * @interface Parcel
 */
export interface Parcel {
    /**
     * 
     * @type {string}
     * @memberof Parcel
     */
    'lot'?: string;
    /**
     * 
     * @type {string}
     * @memberof Parcel
     */
    'plan'?: string;
}
/**
 * 
 * @export
 * @interface Participant
 */
export interface Participant {
    /**
     * 
     * @type {number}
     * @memberof Participant
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'externalReference'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'participantType'?: ParticipantParticipantTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'role'?: ParticipantRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'modifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'middleNames'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'organisationName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'streetAddress1'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'streetAddress2'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'locality'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'stateOrTerritory'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'postCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'mobilePhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'countryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'abn'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'acn'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Participant
     */
    'registeredForGst'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'licenceeNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'licenceeExpiryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'tradingName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'invitationStatus'?: ParticipantInvitationStatusEnum;
}

export const ParticipantParticipantTypeEnum = {
    Individual: 'individual',
    Organisation: 'organisation',
    Trust: 'trust'
} as const;

export type ParticipantParticipantTypeEnum = typeof ParticipantParticipantTypeEnum[keyof typeof ParticipantParticipantTypeEnum];
export const ParticipantRoleEnum = {
    Seller: 'seller',
    SellerAgent: 'sellerAgent',
    SellerSolicitor: 'sellerSolicitor',
    Buyer: 'buyer',
    BuyerSolicitor: 'buyerSolicitor',
    Licencee: 'licencee',
    TenancyAgent: 'tenancyAgent'
} as const;

export type ParticipantRoleEnum = typeof ParticipantRoleEnum[keyof typeof ParticipantRoleEnum];
export const ParticipantInvitationStatusEnum = {
    NotSent: 'notSent',
    Pending: 'pending',
    Accepted: 'accepted'
} as const;

export type ParticipantInvitationStatusEnum = typeof ParticipantInvitationStatusEnum[keyof typeof ParticipantInvitationStatusEnum];

/**
 * 
 * @export
 * @interface Participant1
 */
export interface Participant1 {
    /**
     * 
     * @type {number}
     * @memberof Participant1
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'externalReference'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'participantType'?: Participant1ParticipantTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'role'?: Participant1RoleEnum;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'modifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'middleNames'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'organisationName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'streetAddress1'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'streetAddress2'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'locality'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'stateOrTerritory'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'postCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'mobilePhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'countryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'abn'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'acn'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Participant1
     */
    'registeredForGst'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'licenceeNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'licenceeExpiryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'tradingName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'invitationStatus'?: Participant1InvitationStatusEnum;
}

export const Participant1ParticipantTypeEnum = {
    Individual: 'individual',
    Organisation: 'organisation',
    Trust: 'trust'
} as const;

export type Participant1ParticipantTypeEnum = typeof Participant1ParticipantTypeEnum[keyof typeof Participant1ParticipantTypeEnum];
export const Participant1RoleEnum = {
    Seller: 'seller',
    SellerAgent: 'sellerAgent',
    SellerSolicitor: 'sellerSolicitor',
    Buyer: 'buyer',
    BuyerSolicitor: 'buyerSolicitor',
    Licencee: 'licencee',
    TenancyAgent: 'tenancyAgent'
} as const;

export type Participant1RoleEnum = typeof Participant1RoleEnum[keyof typeof Participant1RoleEnum];
export const Participant1InvitationStatusEnum = {
    NotSent: 'notSent',
    Pending: 'pending',
    Accepted: 'accepted'
} as const;

export type Participant1InvitationStatusEnum = typeof Participant1InvitationStatusEnum[keyof typeof Participant1InvitationStatusEnum];

/**
 * 
 * @export
 * @interface Participants
 */
export interface Participants {
    /**
     * 
     * @type {Array<Participant1>}
     * @memberof Participants
     */
    'items'?: Array<Participant1>;
}
/**
 * A physical Australian address in structured and unstructured formats. If an address has a \"flat\" component (e.g. UNIT 12, 32 SOME ST...), then it will also have  the `ssla` and `smla` fields, which are shortened versions of `sla` and `mla`. 
 * @export
 * @interface PhysicalAustralianAddress
 */
export interface PhysicalAustralianAddress {
    /**
     * The Persistent Identifier is unique to the real world feature this record represents. 
     * @type {string}
     * @memberof PhysicalAustralianAddress
     */
    'pid': string;
    /**
     * 
     * @type {PhysicalAustralianAddressStructured}
     * @memberof PhysicalAustralianAddress
     */
    'structured': PhysicalAustralianAddressStructured;
    /**
     * The full address flattened to a single line.
     * @type {string}
     * @memberof PhysicalAustralianAddress
     */
    'sla': string;
    /**
     * The shortened version of the Single Line Address.
     * @type {string}
     * @memberof PhysicalAustralianAddress
     */
    'ssla'?: string;
    /**
     * The full address flattened to at most four lines.
     * @type {Array<string>}
     * @memberof PhysicalAustralianAddress
     */
    'mla': Array<string>;
    /**
     * The shortened version of the Multi Line Address.
     * @type {Array<string>}
     * @memberof PhysicalAustralianAddress
     */
    'smla'?: Array<string>;
}
/**
 * 
 * @export
 * @interface PhysicalAustralianAddressStructured
 */
export interface PhysicalAustralianAddressStructured {
    /**
     * 
     * @type {string}
     * @memberof PhysicalAustralianAddressStructured
     */
    'buildingName'?: string;
    /**
     * 
     * @type {PhysicalAustralianAddressStructuredLotNumber}
     * @memberof PhysicalAustralianAddressStructured
     */
    'lotNumber'?: PhysicalAustralianAddressStructuredLotNumber;
    /**
     * 
     * @type {PhysicalAustralianAddressStructuredFlat}
     * @memberof PhysicalAustralianAddressStructured
     */
    'flat'?: PhysicalAustralianAddressStructuredFlat;
    /**
     * 
     * @type {PhysicalAustralianAddressStructuredLotNumber}
     * @memberof PhysicalAustralianAddressStructured
     */
    'level'?: PhysicalAustralianAddressStructuredLotNumber;
    /**
     * 
     * @type {PhysicalAustralianAddressStructuredLotNumber}
     * @memberof PhysicalAustralianAddressStructured
     */
    'number'?: PhysicalAustralianAddressStructuredLotNumber;
    /**
     * 
     * @type {PhysicalAustralianAddressStructuredStreet}
     * @memberof PhysicalAustralianAddressStructured
     */
    'street'?: PhysicalAustralianAddressStructuredStreet;
    /**
     * 
     * @type {PhysicalAustralianAddressStructuredLocality}
     * @memberof PhysicalAustralianAddressStructured
     */
    'locality'?: PhysicalAustralianAddressStructuredLocality;
    /**
     * 
     * @type {PhysicalAustralianAddressStructuredState}
     * @memberof PhysicalAustralianAddressStructured
     */
    'state'?: PhysicalAustralianAddressStructuredState;
    /**
     * 
     * @type {string}
     * @memberof PhysicalAustralianAddressStructured
     */
    'postcode'?: string;
    /**
     * 
     * @type {number}
     * @memberof PhysicalAustralianAddressStructured
     */
    'confidence'?: number;
}
/**
 * 
 * @export
 * @interface PhysicalAustralianAddressStructuredFlat
 */
export interface PhysicalAustralianAddressStructuredFlat {
    /**
     * 
     * @type {PhysicalAustralianAddressStructuredFlatType}
     * @memberof PhysicalAustralianAddressStructuredFlat
     */
    'type'?: PhysicalAustralianAddressStructuredFlatType;
    /**
     * 
     * @type {string}
     * @memberof PhysicalAustralianAddressStructuredFlat
     */
    'prefix'?: string;
    /**
     * 
     * @type {number}
     * @memberof PhysicalAustralianAddressStructuredFlat
     */
    'number'?: number;
    /**
     * 
     * @type {string}
     * @memberof PhysicalAustralianAddressStructuredFlat
     */
    'suffix'?: string;
}
/**
 * 
 * @export
 * @interface PhysicalAustralianAddressStructuredFlatType
 */
export interface PhysicalAustralianAddressStructuredFlatType {
    /**
     * 
     * @type {string}
     * @memberof PhysicalAustralianAddressStructuredFlatType
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PhysicalAustralianAddressStructuredFlatType
     */
    'code'?: string;
}
/**
 * 
 * @export
 * @interface PhysicalAustralianAddressStructuredLocality
 */
export interface PhysicalAustralianAddressStructuredLocality {
    /**
     * 
     * @type {string}
     * @memberof PhysicalAustralianAddressStructuredLocality
     */
    'name'?: string;
    /**
     * 
     * @type {PhysicalAustralianAddressStructuredStreetType}
     * @memberof PhysicalAustralianAddressStructuredLocality
     */
    'class'?: PhysicalAustralianAddressStructuredStreetType;
}
/**
 * 
 * @export
 * @interface PhysicalAustralianAddressStructuredLotNumber
 */
export interface PhysicalAustralianAddressStructuredLotNumber {
    /**
     * 
     * @type {string}
     * @memberof PhysicalAustralianAddressStructuredLotNumber
     */
    'prefix'?: string;
    /**
     * 
     * @type {number}
     * @memberof PhysicalAustralianAddressStructuredLotNumber
     */
    'number'?: number;
    /**
     * 
     * @type {string}
     * @memberof PhysicalAustralianAddressStructuredLotNumber
     */
    'suffix'?: string;
}
/**
 * 
 * @export
 * @interface PhysicalAustralianAddressStructuredState
 */
export interface PhysicalAustralianAddressStructuredState {
    /**
     * 
     * @type {string}
     * @memberof PhysicalAustralianAddressStructuredState
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PhysicalAustralianAddressStructuredState
     */
    'abbreviation'?: string;
}
/**
 * 
 * @export
 * @interface PhysicalAustralianAddressStructuredStreet
 */
export interface PhysicalAustralianAddressStructuredStreet {
    /**
     * 
     * @type {string}
     * @memberof PhysicalAustralianAddressStructuredStreet
     */
    'name'?: string;
    /**
     * 
     * @type {PhysicalAustralianAddressStructuredStreetType}
     * @memberof PhysicalAustralianAddressStructuredStreet
     */
    'type'?: PhysicalAustralianAddressStructuredStreetType;
    /**
     * 
     * @type {PhysicalAustralianAddressStructuredStreetType}
     * @memberof PhysicalAustralianAddressStructuredStreet
     */
    'suffix'?: PhysicalAustralianAddressStructuredStreetType;
}
/**
 * 
 * @export
 * @interface PhysicalAustralianAddressStructuredStreetType
 */
export interface PhysicalAustralianAddressStructuredStreetType {
    /**
     * 
     * @type {string}
     * @memberof PhysicalAustralianAddressStructuredStreetType
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PhysicalAustralianAddressStructuredStreetType
     */
    'code'?: string;
}
/**
 * * BPA Building Unit Plan of Amalgamation  * BRP Building Unit Plan of Resubdivision  * BUP Building Unit Plan  * CP Crown Plan  * GRP Group Title Plan of Resubdivision  * GTA Group Title Plan of Amalgamation  * GTP Group Titles Plan  * LB Lower Building Units Plan  * MCP Mixed Community Plan  * MPP Mixed Precinct Plan  * MSP Mixed Stratum Plan  * RP Registered Plan  * SBA Leasehold Building Units Plan (South Bank)  * SBP Leasehold Building Units Plan (South Bank)  * SP Survey Plan  * SPA Stratum Plan of Amalgamation (South Bank)  * SPS Stratum Plan of Subdivision (South Bank)  * SRP Leasehold Building Units Plan (South Bank)  * SSP Stratum Plan (South Bank)  * UB Upper Building Units Plan 
 * @export
 * @enum {string}
 */

export const PlanType = {
    Bpa: 'BPA',
    Brp: 'BRP',
    Bup: 'BUP',
    Cp: 'CP',
    Grp: 'GRP',
    Gta: 'GTA',
    Gtp: 'GTP',
    Lb: 'LB',
    Mcp: 'MCP',
    Mpp: 'MPP',
    Msp: 'MSP',
    Rp: 'RP',
    Sba: 'SBA',
    Sbp: 'SBP',
    Sp: 'SP',
    Spa: 'SPA',
    Sps: 'SPS',
    Srp: 'SRP',
    Ssp: 'SSP',
    Ub: 'UB'
} as const;

export type PlanType = typeof PlanType[keyof typeof PlanType];


/**
 * 
 * @export
 * @interface PrefixNumberSuffix
 */
export interface PrefixNumberSuffix {
    /**
     * 
     * @type {string}
     * @memberof PrefixNumberSuffix
     */
    'prefix'?: string;
    /**
     * 
     * @type {number}
     * @memberof PrefixNumberSuffix
     */
    'number'?: number;
    /**
     * 
     * @type {string}
     * @memberof PrefixNumberSuffix
     */
    'suffix'?: string;
}
/**
 * 
 * @export
 * @interface Property
 */
export interface Property {
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    'streetAddress1'?: string;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    'streetAddress2'?: string;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    'locality'?: string;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    'stateOrTerritory'?: string;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    'postCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    'lot'?: string;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    'plan'?: string;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    'planType'?: string;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    'titleReference'?: string;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    'area'?: string;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    'presentUse'?: string;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    'lga'?: string;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    'holdingType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Property
     */
    'isBuiltOn'?: boolean;
}
/**
 * 
 * @export
 * @interface PropertyNew
 */
export interface PropertyNew {
    /**
     * 
     * @type {string}
     * @memberof PropertyNew
     */
    'lot'?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyNew
     */
    'plan'?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyNew
     */
    'planType'?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyNew
     */
    'titleReference'?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyNew
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyNew
     */
    'area'?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyNew
     */
    'presentUse'?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyNew
     */
    'lga'?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyNew
     */
    'holdingType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyNew
     */
    'isBuiltOn'?: boolean;
}
/**
 * 
 * @export
 * @interface PutContractRequest
 */
export interface PutContractRequest {
    /**
     * 
     * @type {Contract}
     * @memberof PutContractRequest
     */
    'contract'?: Contract;
}
/**
 * 
 * @export
 * @interface PutMyDetailsRequest
 */
export interface PutMyDetailsRequest {
    /**
     * 
     * @type {User1}
     * @memberof PutMyDetailsRequest
     */
    'user': User1;
}
/**
 * 
 * @export
 * @interface PutMyDetailsRequest1
 */
export interface PutMyDetailsRequest1 {
    /**
     * 
     * @type {Participant1}
     * @memberof PutMyDetailsRequest1
     */
    'user': Participant1;
}
/**
 * 
 * @export
 * @interface RecipientSigningStatus
 */
export interface RecipientSigningStatus {
    /**
     * 
     * @type {string}
     * @memberof RecipientSigningStatus
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientSigningStatus
     */
    'statusDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientSigningStatus
     */
    'status'?: RecipientSigningStatusStatusEnum;
}

export const RecipientSigningStatusStatusEnum = {
    Created: 'created',
    Pending: 'pending',
    Sent: 'sent',
    Failed: 'failed',
    Completed: 'completed',
    Declined: 'declined'
} as const;

export type RecipientSigningStatusStatusEnum = typeof RecipientSigningStatusStatusEnum[keyof typeof RecipientSigningStatusStatusEnum];

/**
 * 
 * @export
 * @interface ReferralPartner
 */
export interface ReferralPartner {
    /**
     * 
     * @type {Participant1}
     * @memberof ReferralPartner
     */
    'partner'?: Participant1;
    /**
     * 
     * @type {number}
     * @memberof ReferralPartner
     */
    'priority'?: number;
}
/**
 * 
 * @export
 * @interface ReferralPartners
 */
export interface ReferralPartners {
    /**
     * 
     * @type {Array<ReferralPartnersItemsInner>}
     * @memberof ReferralPartners
     */
    'items'?: Array<ReferralPartnersItemsInner>;
}
/**
 * 
 * @export
 * @interface ReferralPartnersItemsInner
 */
export interface ReferralPartnersItemsInner {
    /**
     * 
     * @type {Participant1}
     * @memberof ReferralPartnersItemsInner
     */
    'partner'?: Participant1;
    /**
     * 
     * @type {number}
     * @memberof ReferralPartnersItemsInner
     */
    'priority'?: number;
}
/**
 * 
 * @export
 * @interface Road
 */
export interface Road {
    /**
     * 
     * @type {string}
     * @memberof Road
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Road
     */
    'typeCode'?: string;
}
/**
 * 
 * @export
 * @interface RoadNumber
 */
export interface RoadNumber {
    /**
     * 
     * @type {string}
     * @memberof RoadNumber
     */
    'first'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Role = {
    Seller: 'seller',
    SellerAgent: 'sellerAgent',
    SellerSolicitor: 'sellerSolicitor',
    Buyer: 'buyer',
    BuyerSolicitor: 'buyerSolicitor',
    Licencee: 'licencee',
    TenancyAgent: 'tenancyAgent'
} as const;

export type Role = typeof Role[keyof typeof Role];


/**
 * 
 * @export
 * @interface Schedule
 */
export interface Schedule {
    /**
     * The Agent discloses that, in accepting this appointment, that the Agent’s duty or interests  do not conflict with the Client’s interests. 
     * @type {boolean}
     * @memberof Schedule
     */
    'a1': boolean;
    /**
     * The Client represents and warrants that they are the registered owner of the Property listed in this Appointment. 
     * @type {boolean}
     * @memberof Schedule
     */
    'b1': boolean;
    /**
     * The Agent confirms that they have verified the identity of the owner, ensuring that both the owner’s name and the  legal description of the Property listed in this Appointment corresponds with the information recorded on the title’s registry. 
     * @type {boolean}
     * @memberof Schedule
     */
    'b2': boolean;
    /**
     * The Client represents and warrants that to the best of their knowledge, they have disclosed to the Agent all facts material to the Sale of the Property. 
     * @type {boolean}
     * @memberof Schedule
     */
    'c1': boolean;
    /**
     * The Client represents and warrants that they will retain the services of a Solicitor to investigate and compile a list of facts material to the sale of the Property and hereby directs their solicitor to disclose those facts to the Agent, in writing, prior to the listing going live on the market. 
     * @type {boolean}
     * @memberof Schedule
     */
    'c2': boolean;
    /**
     * The Client confirms that it has not retained an Agent, prior to this appointment that is currently in force, to sell the Property. 
     * @type {boolean}
     * @memberof Schedule
     */
    'd1': boolean;
    /**
     * The Client represents and warrants that they have reviewed the contents of this Appointment and acknowledges that its  contents correctly reflect the entirety of the instructions provided to the Agent. 
     * @type {boolean}
     * @memberof Schedule
     */
    'e1': boolean;
    /**
     * The Client commits to providing written instructions to the Agent in the event of any changes at any point throughout the duration of the Appointment. 
     * @type {boolean}
     * @memberof Schedule
     */
    'e2': boolean;
    /**
     * Market Value Advice: - no-advice: The Client understands that they will not get any advice from the Agent about the market value of the Property unless they later give the Agent written instructions to do so. - not-provided: The Client has asked the Agent for a comparative market analysis (CMA) and the agent has decided NOT to provide one to the Client. - provided: The Client has received a copy of a comparative market analysis (CMA) or a written explanation detailing how the real estate agent determined the property\'s market value (Market Information). 
     * @type {string}
     * @memberof Schedule
     */
    'f1': ScheduleF1Enum;
    /**
     * Not to disclose Reserve or other Price Section 216 - authorises the Agent to provide the Buyer with the Market Information; or - does not authorise the Agent to provide the Buyer with the Market Information. 
     * @type {string}
     * @memberof Schedule
     */
    'g1': ScheduleG1Enum;
    /**
     * The Client acknowledges that they have, if needed, received and completed a Privacy Notice and Consent from the Agent, either attached to this Appointment or available on the Agent\'s website. 
     * @type {boolean}
     * @memberof Schedule
     */
    'h1': boolean;
}

export const ScheduleF1Enum = {
    NoAdvice: 'no-advice',
    NotProvided: 'not-provided',
    Provided: 'provided'
} as const;

export type ScheduleF1Enum = typeof ScheduleF1Enum[keyof typeof ScheduleF1Enum];
export const ScheduleG1Enum = {
    Authorises: 'authorises',
    DoesNotAuthorise: 'does-not-authorise'
} as const;

export type ScheduleG1Enum = typeof ScheduleG1Enum[keyof typeof ScheduleG1Enum];

/**
 * 
 * @export
 * @interface SendAgentAppointmentForSigning400Response
 */
export interface SendAgentAppointmentForSigning400Response {
    /**
     * 
     * @type {Array<SendAgentAppointmentForSigning400ResponseItemsInner>}
     * @memberof SendAgentAppointmentForSigning400Response
     */
    'items'?: Array<SendAgentAppointmentForSigning400ResponseItemsInner>;
}
/**
 * 
 * @export
 * @interface SendAgentAppointmentForSigning400ResponseItemsInner
 */
export interface SendAgentAppointmentForSigning400ResponseItemsInner {
    /**
     * The ID of the entity failing validation.
     * @type {number}
     * @memberof SendAgentAppointmentForSigning400ResponseItemsInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SendAgentAppointmentForSigning400ResponseItemsInner
     */
    'step': SendAgentAppointmentForSigning400ResponseItemsInnerStepEnum;
    /**
     * 
     * @type {string}
     * @memberof SendAgentAppointmentForSigning400ResponseItemsInner
     */
    'reason': string;
}

export const SendAgentAppointmentForSigning400ResponseItemsInnerStepEnum = {
    Client: 'client',
    Agent: 'agent',
    Property: 'property',
    Appointment: 'appointment',
    Sale: 'sale',
    Commission: 'commission',
    Authorisation: 'authorisation'
} as const;

export type SendAgentAppointmentForSigning400ResponseItemsInnerStepEnum = typeof SendAgentAppointmentForSigning400ResponseItemsInnerStepEnum[keyof typeof SendAgentAppointmentForSigning400ResponseItemsInnerStepEnum];

/**
 * 
 * @export
 * @interface SetupIntent
 */
export interface SetupIntent {
    /**
     * 
     * @type {ContactDetails1}
     * @memberof SetupIntent
     */
    'contactDetails'?: ContactDetails1;
    /**
     * 
     * @type {string}
     * @memberof SetupIntent
     */
    'setupIntentID': string;
    /**
     * 
     * @type {string}
     * @memberof SetupIntent
     */
    'clientSecretID': string;
}
/**
 * 
 * @export
 * @interface State
 */
export interface State {
    /**
     * 
     * @type {string}
     * @memberof State
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof State
     */
    'abbreviation'?: string;
}
/**
 * 
 * @export
 * @interface Street
 */
export interface Street {
    /**
     * 
     * @type {string}
     * @memberof Street
     */
    'name'?: string;
    /**
     * 
     * @type {PhysicalAustralianAddressStructuredStreetType}
     * @memberof Street
     */
    'type'?: PhysicalAustralianAddressStructuredStreetType;
    /**
     * 
     * @type {PhysicalAustralianAddressStructuredStreetType}
     * @memberof Street
     */
    'suffix'?: PhysicalAustralianAddressStructuredStreetType;
}
/**
 * 
 * @export
 * @interface StructuredAddress
 */
export interface StructuredAddress {
    /**
     * 
     * @type {string}
     * @memberof StructuredAddress
     */
    'buildingName'?: string;
    /**
     * 
     * @type {PhysicalAustralianAddressStructuredLotNumber}
     * @memberof StructuredAddress
     */
    'lotNumber'?: PhysicalAustralianAddressStructuredLotNumber;
    /**
     * 
     * @type {PhysicalAustralianAddressStructuredFlat}
     * @memberof StructuredAddress
     */
    'flat'?: PhysicalAustralianAddressStructuredFlat;
    /**
     * 
     * @type {PhysicalAustralianAddressStructuredLotNumber}
     * @memberof StructuredAddress
     */
    'level'?: PhysicalAustralianAddressStructuredLotNumber;
    /**
     * 
     * @type {PhysicalAustralianAddressStructuredLotNumber}
     * @memberof StructuredAddress
     */
    'number'?: PhysicalAustralianAddressStructuredLotNumber;
    /**
     * 
     * @type {PhysicalAustralianAddressStructuredStreet}
     * @memberof StructuredAddress
     */
    'street'?: PhysicalAustralianAddressStructuredStreet;
    /**
     * 
     * @type {PhysicalAustralianAddressStructuredLocality}
     * @memberof StructuredAddress
     */
    'locality'?: PhysicalAustralianAddressStructuredLocality;
    /**
     * 
     * @type {PhysicalAustralianAddressStructuredState}
     * @memberof StructuredAddress
     */
    'state'?: PhysicalAustralianAddressStructuredState;
    /**
     * 
     * @type {string}
     * @memberof StructuredAddress
     */
    'postcode'?: string;
    /**
     * 
     * @type {number}
     * @memberof StructuredAddress
     */
    'confidence'?: number;
}
/**
 * Represents a real estate agency or organization within the system. Contains branding and contact information. 
 * @export
 * @interface Team
 */
export interface Team {
    /**
     * 
     * @type {number}
     * @memberof Team
     */
    'id'?: number;
    /**
     * The display name of the team
     * @type {string}
     * @memberof Team
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'companyType': TeamCompanyTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'abn'?: string;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'acn'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Team
     */
    'registeredForGst'?: boolean;
    /**
     * The Stripe customer ID for billing
     * @type {string}
     * @memberof Team
     */
    'customerID'?: string;
    /**
     * 
     * @type {ContactDetails1}
     * @memberof Team
     */
    'primaryContact'?: ContactDetails1;
    /**
     * The team\'s website URL
     * @type {string}
     * @memberof Team
     */
    'websiteUrl'?: string;
    /**
     * Primary brand color in hex format (e.g.
     * @type {string}
     * @memberof Team
     */
    'primaryColour'?: string;
    /**
     * Secondary brand color in hex format
     * @type {string}
     * @memberof Team
     */
    'secondaryColour'?: string;
}

export const TeamCompanyTypeEnum = {
    Solicitor: 'solicitor',
    RealEstateAgency: 'realEstateAgency'
} as const;

export type TeamCompanyTypeEnum = typeof TeamCompanyTypeEnum[keyof typeof TeamCompanyTypeEnum];

/**
 * 
 * @export
 * @interface TeamInvitation
 */
export interface TeamInvitation {
    /**
     * 
     * @type {number}
     * @memberof TeamInvitation
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TeamInvitation
     */
    'created': string;
    /**
     * 
     * @type {string}
     * @memberof TeamInvitation
     */
    'emailAddress'?: string;
}
/**
 * 
 * @export
 * @interface TenantContact
 */
export interface TenantContact {
    /**
     * 
     * @type {Participant1}
     * @memberof TenantContact
     */
    'contact'?: Participant1;
    /**
     * 
     * @type {string}
     * @memberof TenantContact
     */
    'licenceeNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantContact
     */
    'licenceeExpiryDate'?: string;
}
/**
 * 
 * @export
 * @interface Title
 */
export interface Title {
    /**
     * The unique reference number for the title
     * @type {string}
     * @memberof Title
     */
    'titleReference'?: string;
    /**
     * The date the title search was performed
     * @type {string}
     * @memberof Title
     */
    'searchDate'?: string;
    /**
     * The date the title was created
     * @type {string}
     * @memberof Title
     */
    'dateTitleCreated'?: string;
    /**
     * The request number for this search
     * @type {string}
     * @memberof Title
     */
    'requestNumber'?: string;
    /**
     * Reference to any previous title
     * @type {string}
     * @memberof Title
     */
    'previousTitle'?: string;
    /**
     * The lot number of the property
     * @type {string}
     * @memberof Title
     */
    'lotNumber'?: string;
    /**
     * The type of estate (e.g. Fee Simple)
     * @type {string}
     * @memberof Title
     */
    'estateType'?: string;
    /**
     * The plan number
     * @type {string}
     * @memberof Title
     */
    'planNumber'?: string;
    /**
     * The type of plan
     * @type {string}
     * @memberof Title
     */
    'planType'?: string;
    /**
     * Whether the owners/lessors are trustees
     * @type {boolean}
     * @memberof Title
     */
    'ownerOrLessorsIsTrustee'?: boolean;
    /**
     * The local government area
     * @type {string}
     * @memberof Title
     */
    'localGovernment'?: string;
    /**
     * Raw string of owner/lessor details
     * @type {string}
     * @memberof Title
     */
    'titleOwnerOrLessor'?: string;
    /**
     * List of parsed owners/lessors
     * @type {Array<GetTitle200ResponseOwnerOrLessorsInner>}
     * @memberof Title
     */
    'ownerOrLessors'?: Array<GetTitle200ResponseOwnerOrLessorsInner>;
}
/**
 * 
 * @export
 * @interface TitleSearchByLotPlan
 */
export interface TitleSearchByLotPlan {
    /**
     * 
     * @type {string}
     * @memberof TitleSearchByLotPlan
     */
    'titleReference': string;
    /**
     * 
     * @type {string}
     * @memberof TitleSearchByLotPlan
     */
    'titleStatus': string;
    /**
     * 
     * @type {string}
     * @memberof TitleSearchByLotPlan
     */
    'titleDescription': string;
    /**
     * 
     * @type {string}
     * @memberof TitleSearchByLotPlan
     */
    'lotNumber': string;
    /**
     * 
     * @type {string}
     * @memberof TitleSearchByLotPlan
     */
    'planNumber': string;
    /**
     * * BPA Building Unit Plan of Amalgamation  * BRP Building Unit Plan of Resubdivision  * BUP Building Unit Plan  * CP Crown Plan  * GRP Group Title Plan of Resubdivision  * GTA Group Title Plan of Amalgamation  * GTP Group Titles Plan  * LB Lower Building Units Plan  * MCP Mixed Community Plan  * MPP Mixed Precinct Plan  * MSP Mixed Stratum Plan  * RP Registered Plan  * SBA Leasehold Building Units Plan (South Bank)  * SBP Leasehold Building Units Plan (South Bank)  * SP Survey Plan  * SPA Stratum Plan of Amalgamation (South Bank)  * SPS Stratum Plan of Subdivision (South Bank)  * SRP Leasehold Building Units Plan (South Bank)  * SSP Stratum Plan (South Bank)  * UB Upper Building Units Plan 
     * @type {string}
     * @memberof TitleSearchByLotPlan
     */
    'planType': TitleSearchByLotPlanPlanTypeEnum;
}

export const TitleSearchByLotPlanPlanTypeEnum = {
    Bpa: 'BPA',
    Brp: 'BRP',
    Bup: 'BUP',
    Cp: 'CP',
    Grp: 'GRP',
    Gta: 'GTA',
    Gtp: 'GTP',
    Lb: 'LB',
    Mcp: 'MCP',
    Mpp: 'MPP',
    Msp: 'MSP',
    Rp: 'RP',
    Sba: 'SBA',
    Sbp: 'SBP',
    Sp: 'SP',
    Spa: 'SPA',
    Sps: 'SPS',
    Srp: 'SRP',
    Ssp: 'SSP',
    Ub: 'UB'
} as const;

export type TitleSearchByLotPlanPlanTypeEnum = typeof TitleSearchByLotPlanPlanTypeEnum[keyof typeof TitleSearchByLotPlanPlanTypeEnum];

/**
 * 
 * @export
 * @interface TitleSearchByLotPlanResult
 */
export interface TitleSearchByLotPlanResult {
    /**
     * 
     * @type {Array<TitleSearchByLotPlan>}
     * @memberof TitleSearchByLotPlanResult
     */
    'items'?: Array<TitleSearchByLotPlan>;
}
/**
 * 
 * @export
 * @interface TitleSummary
 */
export interface TitleSummary {
    /**
     * 
     * @type {string}
     * @memberof TitleSummary
     */
    'surnameCompanyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TitleSummary
     */
    'givenName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TitleSummary
     */
    'titleDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof TitleSummary
     */
    'titleStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof TitleSummary
     */
    'titleReference'?: string;
}
/**
 * 
 * @export
 * @interface TrustAccount
 */
export interface TrustAccount {
    /**
     * The name of the bank
     * @type {string}
     * @memberof TrustAccount
     */
    'accountBankName': string;
    /**
     * The name of the trust account
     * @type {string}
     * @memberof TrustAccount
     */
    'accountName': string;
    /**
     * The account number of the trust account
     * @type {string}
     * @memberof TrustAccount
     */
    'accountNumber': string;
    /**
     * The BSB of the trust account
     * @type {string}
     * @memberof TrustAccount
     */
    'accountBSB': string;
    /**
     * The name of the account holder
     * @type {string}
     * @memberof TrustAccount
     */
    'accountHolderName': string;
}
/**
 * 
 * @export
 * @interface TypeCodeName
 */
export interface TypeCodeName {
    /**
     * 
     * @type {string}
     * @memberof TypeCodeName
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeCodeName
     */
    'code'?: string;
}
/**
 * 
 * @export
 * @interface TypeCodeNameExtended
 */
export interface TypeCodeNameExtended {
    /**
     * 
     * @type {string}
     * @memberof TypeCodeNameExtended
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeCodeNameExtended
     */
    'code'?: string;
}
/**
 * 
 * @export
 * @interface Unit
 */
export interface Unit {
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    'typeCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    'number'?: string;
}
/**
 * 
 * @export
 * @interface UpdatedBenefitToAgent
 */
export interface UpdatedBenefitToAgent {
    /**
     * 
     * @type {string}
     * @memberof UpdatedBenefitToAgent
     */
    'service'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatedBenefitToAgent
     */
    'source'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatedBenefitToAgent
     */
    'estimatedAmount'?: string;
}
/**
 * 
 * @export
 * @interface UpdatedFee
 */
export interface UpdatedFee {
    /**
     * 
     * @type {string}
     * @memberof UpdatedFee
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatedFee
     */
    'whenPayable'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdatedFee
     */
    'estimatedAmount'?: number;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'sub'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'participantType'?: UserParticipantTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'modifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'middleNames'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'organisationName'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'streetAddress1'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'streetAddress2'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'locality'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'stateOrTerritory'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'postCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'mobilePhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'countryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'abn'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'acn'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'registeredForGst'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'licenceeNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'licenceeExpiryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'tradingName'?: string;
}

export const UserParticipantTypeEnum = {
    Individual: 'individual',
    Organisation: 'organisation',
    Trust: 'trust'
} as const;

export type UserParticipantTypeEnum = typeof UserParticipantTypeEnum[keyof typeof UserParticipantTypeEnum];

/**
 * 
 * @export
 * @interface User1
 */
export interface User1 {
    /**
     * 
     * @type {string}
     * @memberof User1
     */
    'sub'?: string;
    /**
     * 
     * @type {string}
     * @memberof User1
     */
    'participantType'?: User1ParticipantTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof User1
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof User1
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof User1
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof User1
     */
    'modifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof User1
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof User1
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof User1
     */
    'middleNames'?: string;
    /**
     * 
     * @type {string}
     * @memberof User1
     */
    'organisationName'?: string;
    /**
     * 
     * @type {string}
     * @memberof User1
     */
    'streetAddress1'?: string;
    /**
     * 
     * @type {string}
     * @memberof User1
     */
    'streetAddress2'?: string;
    /**
     * 
     * @type {string}
     * @memberof User1
     */
    'locality'?: string;
    /**
     * 
     * @type {string}
     * @memberof User1
     */
    'stateOrTerritory'?: string;
    /**
     * 
     * @type {string}
     * @memberof User1
     */
    'postCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof User1
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof User1
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof User1
     */
    'mobilePhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof User1
     */
    'countryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof User1
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof User1
     */
    'abn'?: string;
    /**
     * 
     * @type {string}
     * @memberof User1
     */
    'acn'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof User1
     */
    'registeredForGst'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User1
     */
    'licenceeNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof User1
     */
    'licenceeExpiryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof User1
     */
    'tradingName'?: string;
}

export const User1ParticipantTypeEnum = {
    Individual: 'individual',
    Organisation: 'organisation',
    Trust: 'trust'
} as const;

export type User1ParticipantTypeEnum = typeof User1ParticipantTypeEnum[keyof typeof User1ParticipantTypeEnum];

/**
 * 
 * @export
 * @interface ValidAddress
 */
export interface ValidAddress {
    /**
     * 
     * @type {RoadNumber}
     * @memberof ValidAddress
     */
    'roadNumber'?: RoadNumber;
    /**
     * 
     * @type {Road}
     * @memberof ValidAddress
     */
    'road'?: Road;
    /**
     * 
     * @type {Unit}
     * @memberof ValidAddress
     */
    'unit'?: Unit;
    /**
     * 
     * @type {string}
     * @memberof ValidAddress
     */
    'locality'?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidAddress
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidAddress
     */
    'postcode'?: string;
}
/**
 * 
 * @export
 * @interface ValidAddressDetails
 */
export interface ValidAddressDetails {
    /**
     * 
     * @type {string}
     * @memberof ValidAddressDetails
     */
    'physicalAddressIndicator'?: string;
    /**
     * 
     * @type {Array<MetaData>}
     * @memberof ValidAddressDetails
     */
    'metaData'?: Array<MetaData>;
    /**
     * 
     * @type {ValidAddressDetailsAddress}
     * @memberof ValidAddressDetails
     */
    'address'?: ValidAddressDetailsAddress;
    /**
     * 
     * @type {Parcel}
     * @memberof ValidAddressDetails
     */
    'parcel'?: Parcel;
    /**
     * 
     * @type {LocalGovernmentArea}
     * @memberof ValidAddressDetails
     */
    'localGovernmentArea'?: LocalGovernmentArea;
    /**
     * 
     * @type {Geocode}
     * @memberof ValidAddressDetails
     */
    'geocode'?: Geocode;
    /**
     * 
     * @type {string}
     * @memberof ValidAddressDetails
     */
    'confidence'?: string;
}
/**
 * 
 * @export
 * @interface ValidAddressDetailsAddress
 */
export interface ValidAddressDetailsAddress {
    /**
     * 
     * @type {RoadNumber}
     * @memberof ValidAddressDetailsAddress
     */
    'roadNumber'?: RoadNumber;
    /**
     * 
     * @type {Road}
     * @memberof ValidAddressDetailsAddress
     */
    'road'?: Road;
    /**
     * 
     * @type {Unit}
     * @memberof ValidAddressDetailsAddress
     */
    'unit'?: Unit;
    /**
     * 
     * @type {string}
     * @memberof ValidAddressDetailsAddress
     */
    'locality'?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidAddressDetailsAddress
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidAddressDetailsAddress
     */
    'postcode'?: string;
}
/**
 * 
 * @export
 * @interface ValidateAddress200Response
 */
export interface ValidateAddress200Response {
    /**
     * 
     * @type {Array<ValidAddressDetails>}
     * @memberof ValidateAddress200Response
     */
    'items'?: Array<ValidAddressDetails>;
}
/**
 * 
 * @export
 * @interface Validation
 */
export interface Validation {
    /**
     * The ID of the entity failing validation.
     * @type {number}
     * @memberof Validation
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Validation
     */
    'step': ValidationStepEnum;
    /**
     * 
     * @type {string}
     * @memberof Validation
     */
    'reason': string;
}

export const ValidationStepEnum = {
    Client: 'client',
    Agent: 'agent',
    Property: 'property',
    Appointment: 'appointment',
    Sale: 'sale',
    Commission: 'commission',
    Authorisation: 'authorisation'
} as const;

export type ValidationStepEnum = typeof ValidationStepEnum[keyof typeof ValidationStepEnum];

/**
 * 
 * @export
 * @interface Validations
 */
export interface Validations {
    /**
     * 
     * @type {Array<SendAgentAppointmentForSigning400ResponseItemsInner>}
     * @memberof Validations
     */
    'items'?: Array<SendAgentAppointmentForSigning400ResponseItemsInner>;
}
/**
 * 
 * @export
 * @interface Workflow
 */
export interface Workflow {
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    'agentAppointment': WorkflowAgentAppointmentEnum;
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    'contractPreparation': WorkflowContractPreparationEnum;
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    'offerNegotiation': WorkflowOfferNegotiationEnum;
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    'conveyance': WorkflowConveyanceEnum;
    /**
     * The sub state of the workspace.
     * @type {string}
     * @memberof Workflow
     */
    'state': WorkflowStateEnum;
    /**
     * The sub state of the workspace.
     * @type {string}
     * @memberof Workflow
     */
    'subState': WorkflowSubStateEnum;
    /**
     * 
     * @type {Array<WorkflowOffersInner>}
     * @memberof Workflow
     */
    'offers': Array<WorkflowOffersInner>;
}

export const WorkflowAgentAppointmentEnum = {
    AgentAppointmentPreparing: 'agentAppointmentPreparing',
    AgentAppointmentAwaitingSigning: 'agentAppointmentAwaitingSigning',
    AgentAppointmentSigned: 'agentAppointmentSigned',
    AgentAppointmentDeclined: 'agentAppointmentDeclined',
    AgentAppointmentAwaitingSellerAdmission: 'agentAppointmentAwaitingSellerAdmission',
    AgentAppointmentComplete: 'agentAppointmentComplete'
} as const;

export type WorkflowAgentAppointmentEnum = typeof WorkflowAgentAppointmentEnum[keyof typeof WorkflowAgentAppointmentEnum];
export const WorkflowContractPreparationEnum = {
    ContractPreparationNotStarted: 'contractPreparationNotStarted',
    ContractPreparationAwaitingSellerSolicitorSelection: 'contractPreparationAwaitingSellerSolicitorSelection',
    ContractPreparationAwaitingSellerSolicitorAdmission: 'contractPreparationAwaitingSellerSolicitorAdmission',
    ContractPreparationPreparing: 'contractPreparationPreparing',
    ContractPreparationComplete: 'contractPreparationComplete'
} as const;

export type WorkflowContractPreparationEnum = typeof WorkflowContractPreparationEnum[keyof typeof WorkflowContractPreparationEnum];
export const WorkflowOfferNegotiationEnum = {
    OfferNegotiationNotStarted: 'offerNegotiationNotStarted',
    OfferNegotiationAcceptingOffers: 'offerNegotiationAcceptingOffers',
    OfferNegotiationComplete: 'offerNegotiationComplete'
} as const;

export type WorkflowOfferNegotiationEnum = typeof WorkflowOfferNegotiationEnum[keyof typeof WorkflowOfferNegotiationEnum];
export const WorkflowConveyanceEnum = {
    ConveyanceNotStarted: 'conveyanceNotStarted',
    ConveyanceInprogress: 'conveyanceInprogress'
} as const;

export type WorkflowConveyanceEnum = typeof WorkflowConveyanceEnum[keyof typeof WorkflowConveyanceEnum];
export const WorkflowStateEnum = {
    AgentAppointment: 'agentAppointment',
    ContractPreparation: 'contractPreparation',
    AcceptingOffers: 'acceptingOffers',
    ContractSigned: 'contractSigned',
    Abandoned: 'abandoned'
} as const;

export type WorkflowStateEnum = typeof WorkflowStateEnum[keyof typeof WorkflowStateEnum];
export const WorkflowSubStateEnum = {
    AgentAppointmentPreparing: 'agentAppointmentPreparing',
    AgentAppointmentAwaitingSigning: 'agentAppointmentAwaitingSigning',
    AgentAppointmentSigned: 'agentAppointmentSigned',
    AgentAppointmentDeclined: 'agentAppointmentDeclined',
    AgentAppointmentAwaitingSellerAdmission: 'agentAppointmentAwaitingSellerAdmission',
    ContractPreparationAwaitingSellerSolicitorSelection: 'contractPreparationAwaitingSellerSolicitorSelection',
    ContractPreparationAwaitingSellerSolicitorAdmission: 'contractPreparationAwaitingSellerSolicitorAdmission',
    ContractPreparationPreparing: 'contractPreparationPreparing',
    AcceptingOffers: 'acceptingOffers',
    ContractSigned: 'contractSigned',
    Abandoned: 'abandoned'
} as const;

export type WorkflowSubStateEnum = typeof WorkflowSubStateEnum[keyof typeof WorkflowSubStateEnum];

/**
 * 
 * @export
 * @interface WorkflowOffersInner
 */
export interface WorkflowOffersInner {
    /**
     * 
     * @type {number}
     * @memberof WorkflowOffersInner
     */
    'offerId': number;
    /**
     * 
     * @type {string}
     * @memberof WorkflowOffersInner
     */
    'state': WorkflowOffersInnerStateEnum;
    /**
     * 
     * @type {boolean}
     * @memberof WorkflowOffersInner
     */
    'buyerSolicitorInvited': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WorkflowOffersInner
     */
    'buyerInvited': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WorkflowOffersInner
     */
    'sharedWithSeller': boolean;
}

export const WorkflowOffersInnerStateEnum = {
    OfferCreated: 'OfferCreated',
    OfferBuyerSigningSent: 'OfferBuyerSigningSent',
    OfferBuyerSigningCompleted: 'OfferBuyerSigningCompleted',
    OfferBuyerSigningDeclined: 'OfferBuyerSigningDeclined',
    OfferSellerSigningSent: 'OfferSellerSigningSent',
    OfferSellerSigningCompleted: 'OfferSellerSigningCompleted',
    OfferSellerSigningDeclined: 'OfferSellerSigningDeclined',
    OfferCompletedSuccess: 'OfferCompletedSuccess',
    OfferCompletedDeclined: 'OfferCompletedDeclined'
} as const;

export type WorkflowOffersInnerStateEnum = typeof WorkflowOffersInnerStateEnum[keyof typeof WorkflowOffersInnerStateEnum];

/**
 * The sub state of the workspace.
 * @export
 * @enum {string}
 */

export const WorkflowState = {
    AgentAppointment: 'agentAppointment',
    ContractPreparation: 'contractPreparation',
    AcceptingOffers: 'acceptingOffers',
    ContractSigned: 'contractSigned',
    Abandoned: 'abandoned'
} as const;

export type WorkflowState = typeof WorkflowState[keyof typeof WorkflowState];


/**
 * The sub state of the workspace.
 * @export
 * @enum {string}
 */

export const WorkflowSubState = {
    AgentAppointmentPreparing: 'agentAppointmentPreparing',
    AgentAppointmentAwaitingSigning: 'agentAppointmentAwaitingSigning',
    AgentAppointmentSigned: 'agentAppointmentSigned',
    AgentAppointmentDeclined: 'agentAppointmentDeclined',
    AgentAppointmentAwaitingSellerAdmission: 'agentAppointmentAwaitingSellerAdmission',
    ContractPreparationAwaitingSellerSolicitorSelection: 'contractPreparationAwaitingSellerSolicitorSelection',
    ContractPreparationAwaitingSellerSolicitorAdmission: 'contractPreparationAwaitingSellerSolicitorAdmission',
    ContractPreparationPreparing: 'contractPreparationPreparing',
    AcceptingOffers: 'acceptingOffers',
    ContractSigned: 'contractSigned',
    Abandoned: 'abandoned'
} as const;

export type WorkflowSubState = typeof WorkflowSubState[keyof typeof WorkflowSubState];


/**
 * workspace
 * @export
 * @interface Workspace
 */
export interface Workspace {
    /**
     * 
     * @type {number}
     * @memberof Workspace
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    'role': WorkspaceRoleEnum;
}

export const WorkspaceRoleEnum = {
    Seller: 'seller',
    SellerAgent: 'sellerAgent',
    SellerSolicitor: 'sellerSolicitor',
    Buyer: 'buyer',
    BuyerSolicitor: 'buyerSolicitor',
    Licencee: 'licencee',
    TenancyAgent: 'tenancyAgent'
} as const;

export type WorkspaceRoleEnum = typeof WorkspaceRoleEnum[keyof typeof WorkspaceRoleEnum];

/**
 * 
 * @export
 * @interface WorkspaceDetails
 */
export interface WorkspaceDetails {
    /**
     * 
     * @type {Property}
     * @memberof WorkspaceDetails
     */
    'property': Property;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDetails
     */
    'agentId': string;
}
/**
 * 
 * @export
 * @interface WorkspaceSummary
 */
export interface WorkspaceSummary {
    /**
     * 
     * @type {Metadata}
     * @memberof WorkspaceSummary
     */
    'metadata': Metadata;
    /**
     * 
     * @type {Workspace}
     * @memberof WorkspaceSummary
     */
    'workspace': Workspace;
    /**
     * 
     * @type {Property}
     * @memberof WorkspaceSummary
     */
    'property': Property;
    /**
     * 
     * @type {Array<Branding>}
     * @memberof WorkspaceSummary
     */
    'branding': Array<Branding>;
}
/**
 * 
 * @export
 * @interface WorkspaceSummaryNew
 */
export interface WorkspaceSummaryNew {
    /**
     * 
     * @type {number}
     * @memberof WorkspaceSummaryNew
     */
    'id': number;
    /**
     * 
     * @type {Property}
     * @memberof WorkspaceSummaryNew
     */
    'property': Property;
    /**
     * 
     * @type {Array<Participant1>}
     * @memberof WorkspaceSummaryNew
     */
    'sellers': Array<Participant1>;
    /**
     * 
     * @type {Participant1}
     * @memberof WorkspaceSummaryNew
     */
    'agent': Participant1;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceSummaryNew
     */
    'createdDate': string;
    /**
     * 
     * @type {Array<OfferSummary>}
     * @memberof WorkspaceSummaryNew
     */
    'offers'?: Array<OfferSummary>;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceSummaryNew
     */
    'price': number;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceSummaryNew
     */
    'role': WorkspaceSummaryNewRoleEnum;
    /**
     * The sub state of the workspace.
     * @type {string}
     * @memberof WorkspaceSummaryNew
     */
    'state': WorkspaceSummaryNewStateEnum;
}

export const WorkspaceSummaryNewRoleEnum = {
    Seller: 'seller',
    SellerAgent: 'sellerAgent',
    SellerSolicitor: 'sellerSolicitor',
    Buyer: 'buyer',
    BuyerSolicitor: 'buyerSolicitor',
    Licencee: 'licencee',
    TenancyAgent: 'tenancyAgent'
} as const;

export type WorkspaceSummaryNewRoleEnum = typeof WorkspaceSummaryNewRoleEnum[keyof typeof WorkspaceSummaryNewRoleEnum];
export const WorkspaceSummaryNewStateEnum = {
    AgentAppointmentPreparing: 'agentAppointmentPreparing',
    AgentAppointmentAwaitingSigning: 'agentAppointmentAwaitingSigning',
    AgentAppointmentSigned: 'agentAppointmentSigned',
    AgentAppointmentDeclined: 'agentAppointmentDeclined',
    AgentAppointmentAwaitingSellerAdmission: 'agentAppointmentAwaitingSellerAdmission',
    ContractPreparationAwaitingSellerSolicitorSelection: 'contractPreparationAwaitingSellerSolicitorSelection',
    ContractPreparationAwaitingSellerSolicitorAdmission: 'contractPreparationAwaitingSellerSolicitorAdmission',
    ContractPreparationPreparing: 'contractPreparationPreparing',
    AcceptingOffers: 'acceptingOffers',
    ContractSigned: 'contractSigned',
    Abandoned: 'abandoned'
} as const;

export type WorkspaceSummaryNewStateEnum = typeof WorkspaceSummaryNewStateEnum[keyof typeof WorkspaceSummaryNewStateEnum];

/**
 * 
 * @export
 * @interface Workspaces
 */
export interface Workspaces {
    /**
     * 
     * @type {Array<WorkspaceSummary>}
     * @memberof Workspaces
     */
    'items'?: Array<WorkspaceSummary>;
}
/**
 * 
 * @export
 * @interface Workspaces1
 */
export interface Workspaces1 {
    /**
     * 
     * @type {Array<WorkspaceSummaryNew>}
     * @memberof Workspaces1
     */
    'items'?: Array<WorkspaceSummaryNew>;
}

/**
 * AddressSearchApi - axios parameter creator
 * @export
 */
export const AddressSearchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get an address details
         * @param {string} addressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddress: async (addressId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addressId' is not null or undefined
            assertParamExists('getAddress', 'addressId', addressId)
            const localVarPath = `/address-search/{addressId}`
                .replace(`{${"addressId"}}`, encodeURIComponent(String(addressId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary lookup an address
         * @param {string} addressSearchTerm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAddress: async (addressSearchTerm: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addressSearchTerm' is not null or undefined
            assertParamExists('searchAddress', 'addressSearchTerm', addressSearchTerm)
            const localVarPath = `/address-search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)

            if (addressSearchTerm !== undefined) {
                localVarQueryParameter['addressSearchTerm'] = addressSearchTerm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AddressSearchApi - functional programming interface
 * @export
 */
export const AddressSearchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AddressSearchApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get an address details
         * @param {string} addressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAddress(addressId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PhysicalAustralianAddress>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAddress(addressId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddressSearchApi.getAddress']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary lookup an address
         * @param {string} addressSearchTerm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchAddress(addressSearchTerm: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FlattenedAddresses>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchAddress(addressSearchTerm, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddressSearchApi.searchAddress']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AddressSearchApi - factory interface
 * @export
 */
export const AddressSearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AddressSearchApiFp(configuration)
    return {
        /**
         * 
         * @summary get an address details
         * @param {string} addressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddress(addressId: string, options?: RawAxiosRequestConfig): AxiosPromise<PhysicalAustralianAddress> {
            return localVarFp.getAddress(addressId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary lookup an address
         * @param {string} addressSearchTerm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAddress(addressSearchTerm: string, options?: RawAxiosRequestConfig): AxiosPromise<FlattenedAddresses> {
            return localVarFp.searchAddress(addressSearchTerm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AddressSearchApi - object-oriented interface
 * @export
 * @class AddressSearchApi
 * @extends {BaseAPI}
 */
export class AddressSearchApi extends BaseAPI {
    /**
     * 
     * @summary get an address details
     * @param {string} addressId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressSearchApi
     */
    public getAddress(addressId: string, options?: RawAxiosRequestConfig) {
        return AddressSearchApiFp(this.configuration).getAddress(addressId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary lookup an address
     * @param {string} addressSearchTerm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressSearchApi
     */
    public searchAddress(addressSearchTerm: string, options?: RawAxiosRequestConfig) {
        return AddressSearchApiFp(this.configuration).searchAddress(addressSearchTerm, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AgentAppointmentApi - axios parameter creator
 * @export
 */
export const AgentAppointmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary add an annexure to the agent appointment
         * @param {number} workspaceId 
         * @param {string} documentName 
         * @param {AddAgentAppointmentAnnexureDocumentTypeEnum} documentType 
         * @param {File} body the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAgentAppointmentAnnexure: async (workspaceId: number, documentName: string, documentType: AddAgentAppointmentAnnexureDocumentTypeEnum, body: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('addAgentAppointmentAnnexure', 'workspaceId', workspaceId)
            // verify required parameter 'documentName' is not null or undefined
            assertParamExists('addAgentAppointmentAnnexure', 'documentName', documentName)
            // verify required parameter 'documentType' is not null or undefined
            assertParamExists('addAgentAppointmentAnnexure', 'documentType', documentType)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('addAgentAppointmentAnnexure', 'body', body)
            const localVarPath = `/workspaces/{workspaceId}/agent-appointment/annexures/add/{documentName}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"documentName"}}`, encodeURIComponent(String(documentName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)

            if (documentType !== undefined) {
                localVarQueryParameter['documentType'] = documentType;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/octet-stream';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the annexure from the agent appointment 
         * @summary delete annexure
         * @param {number} workspaceId 
         * @param {number} annexureId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAgentAppointmentAnnexure: async (workspaceId: number, annexureId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('deleteAgentAppointmentAnnexure', 'workspaceId', workspaceId)
            // verify required parameter 'annexureId' is not null or undefined
            assertParamExists('deleteAgentAppointmentAnnexure', 'annexureId', annexureId)
            const localVarPath = `/workspaces/{workspaceId}/agent-appointment/annexures/{annexureId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"annexureId"}}`, encodeURIComponent(String(annexureId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a BenefitToAgent
         * @param {number} workspaceId 
         * @param {number} benefitToAgentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBenefitToAgent: async (workspaceId: number, benefitToAgentId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('deleteBenefitToAgent', 'workspaceId', workspaceId)
            // verify required parameter 'benefitToAgentId' is not null or undefined
            assertParamExists('deleteBenefitToAgent', 'benefitToAgentId', benefitToAgentId)
            const localVarPath = `/workspaces/{workspaceId}/agent-appointment/benefitToAgent/{benefitToAgentId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"benefitToAgentId"}}`, encodeURIComponent(String(benefitToAgentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a fee
         * @param {number} workspaceId 
         * @param {number} feeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFee: async (workspaceId: number, feeId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('deleteFee', 'workspaceId', workspaceId)
            // verify required parameter 'feeId' is not null or undefined
            assertParamExists('deleteFee', 'feeId', feeId)
            const localVarPath = `/workspaces/{workspaceId}/agent-appointment/fees/{feeId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"feeId"}}`, encodeURIComponent(String(feeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get agent appointment
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentAppointment: async (workspaceId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getAgentAppointment', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/agent-appointment`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get agent appointment annexures
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentAppointmentAnnexures: async (workspaceId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getAgentAppointmentAnnexures', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/agent-appointment/annexures`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get agent appointment licencee
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentAppointmentLicencee: async (workspaceId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getAgentAppointmentLicencee', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/agent-appointment/licencee`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get the pdf for the appointment
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentAppointmentPdf: async (workspaceId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getAgentAppointmentPdf', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/agent-appointment/pdf`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get agent appointment schedule
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentAppointmentSchedule: async (workspaceId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getAgentAppointmentSchedule', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/agent-appointment/schedule`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the agent appointment status
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentAppointmentStatus: async (workspaceId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getAgentAppointmentStatus', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/agent-appointment/status`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of agent appointment validation erros.  These errors must be fixed before the agent appointment contract can be sent for signing. 
         * @summary get agent appointment validation errors
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentAppointmentValidation: async (workspaceId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getAgentAppointmentValidation', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/agent-appointment/validation`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a BenefitToAgent
         * @param {number} workspaceId 
         * @param {number} benefitToAgentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenefitToAgent: async (workspaceId: number, benefitToAgentId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getBenefitToAgent', 'workspaceId', workspaceId)
            // verify required parameter 'benefitToAgentId' is not null or undefined
            assertParamExists('getBenefitToAgent', 'benefitToAgentId', benefitToAgentId)
            const localVarPath = `/workspaces/{workspaceId}/agent-appointment/benefitToAgent/{benefitToAgentId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"benefitToAgentId"}}`, encodeURIComponent(String(benefitToAgentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the BenefitToAgents associated with an agent aoppointment
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenefitToAgents: async (workspaceId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getBenefitToAgents', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/agent-appointment/benefitToAgent`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a fee
         * @param {number} workspaceId 
         * @param {number} feeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFee: async (workspaceId: number, feeId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getFee', 'workspaceId', workspaceId)
            // verify required parameter 'feeId' is not null or undefined
            assertParamExists('getFee', 'feeId', feeId)
            const localVarPath = `/workspaces/{workspaceId}/agent-appointment/fees/{feeId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"feeId"}}`, encodeURIComponent(String(feeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the fees associated with an agent aoppointment
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFees: async (workspaceId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getFees', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/agent-appointment/fees`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a BenefitToAgent
         * @param {number} workspaceId 
         * @param {UpdatedBenefitToAgent} updatedBenefitToAgent Fee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postBenefitToAgent: async (workspaceId: number, updatedBenefitToAgent: UpdatedBenefitToAgent, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('postBenefitToAgent', 'workspaceId', workspaceId)
            // verify required parameter 'updatedBenefitToAgent' is not null or undefined
            assertParamExists('postBenefitToAgent', 'updatedBenefitToAgent', updatedBenefitToAgent)
            const localVarPath = `/workspaces/{workspaceId}/agent-appointment/benefitToAgent`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatedBenefitToAgent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a fee
         * @param {number} workspaceId 
         * @param {UpdatedFee} updatedFee Fee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFee: async (workspaceId: number, updatedFee: UpdatedFee, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('postFee', 'workspaceId', workspaceId)
            // verify required parameter 'updatedFee' is not null or undefined
            assertParamExists('postFee', 'updatedFee', updatedFee)
            const localVarPath = `/workspaces/{workspaceId}/agent-appointment/fees`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatedFee, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update an agent appointment
         * @param {number} workspaceId 
         * @param {GetAgentAppointment200ResponseAgentAppointment} getAgentAppointment200ResponseAgentAppointment AgentAppointment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAgentAppointment: async (workspaceId: number, getAgentAppointment200ResponseAgentAppointment: GetAgentAppointment200ResponseAgentAppointment, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('putAgentAppointment', 'workspaceId', workspaceId)
            // verify required parameter 'getAgentAppointment200ResponseAgentAppointment' is not null or undefined
            assertParamExists('putAgentAppointment', 'getAgentAppointment200ResponseAgentAppointment', getAgentAppointment200ResponseAgentAppointment)
            const localVarPath = `/workspaces/{workspaceId}/agent-appointment`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getAgentAppointment200ResponseAgentAppointment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update agent appointment licencee
         * @param {number} workspaceId 
         * @param {GetAgentAppointmentLicencee200Response} [getAgentAppointmentLicencee200Response] licencee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAgentAppointmentLicencee: async (workspaceId: number, getAgentAppointmentLicencee200Response?: GetAgentAppointmentLicencee200Response, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('putAgentAppointmentLicencee', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/agent-appointment/licencee`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getAgentAppointmentLicencee200Response, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update agent appointment schedule
         * @param {number} workspaceId 
         * @param {Schedule} schedule AgentAppointmentSchedule
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAgentAppointmentSchedule: async (workspaceId: number, schedule: Schedule, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('putAgentAppointmentSchedule', 'workspaceId', workspaceId)
            // verify required parameter 'schedule' is not null or undefined
            assertParamExists('putAgentAppointmentSchedule', 'schedule', schedule)
            const localVarPath = `/workspaces/{workspaceId}/agent-appointment/schedule`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(schedule, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Uploads the signed agent appointment document to the workspace. This is used when the agent appointment is signed manually.  The caller must either be the `sellerAgent`, or have the `workspace_administrator` role. 
         * @summary upload the signed agent appointment document
         * @param {number} workspaceId 
         * @param {File} body the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putSignedAgentAppointment: async (workspaceId: number, body: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('putSignedAgentAppointment', 'workspaceId', workspaceId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('putSignedAgentAppointment', 'body', body)
            const localVarPath = `/workspaces/{workspaceId}/agent-appointment/upload-signed-document`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/octet-stream';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send the agent appointment for signing
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendAgentAppointmentForSigning: async (workspaceId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('sendAgentAppointmentForSigning', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/agent-appointment/send-for-signing`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the rendering order of the annexure in the agent appointment 
         * @summary change annexure order in agent appointment
         * @param {number} workspaceId 
         * @param {number} annexureId 
         * @param {number} position 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgentAppointmentAnnexurePosition: async (workspaceId: number, annexureId: number, position: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateAgentAppointmentAnnexurePosition', 'workspaceId', workspaceId)
            // verify required parameter 'annexureId' is not null or undefined
            assertParamExists('updateAgentAppointmentAnnexurePosition', 'annexureId', annexureId)
            // verify required parameter 'position' is not null or undefined
            assertParamExists('updateAgentAppointmentAnnexurePosition', 'position', position)
            const localVarPath = `/workspaces/{workspaceId}/agent-appointment/annexures/{annexureId}/position/{position}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"annexureId"}}`, encodeURIComponent(String(annexureId)))
                .replace(`{${"position"}}`, encodeURIComponent(String(position)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a BenefitToAgent
         * @param {number} workspaceId 
         * @param {number} benefitToAgentId 
         * @param {UpdatedBenefitToAgent} updatedBenefitToAgent Fee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBenefitToAgent: async (workspaceId: number, benefitToAgentId: number, updatedBenefitToAgent: UpdatedBenefitToAgent, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateBenefitToAgent', 'workspaceId', workspaceId)
            // verify required parameter 'benefitToAgentId' is not null or undefined
            assertParamExists('updateBenefitToAgent', 'benefitToAgentId', benefitToAgentId)
            // verify required parameter 'updatedBenefitToAgent' is not null or undefined
            assertParamExists('updateBenefitToAgent', 'updatedBenefitToAgent', updatedBenefitToAgent)
            const localVarPath = `/workspaces/{workspaceId}/agent-appointment/benefitToAgent/{benefitToAgentId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"benefitToAgentId"}}`, encodeURIComponent(String(benefitToAgentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatedBenefitToAgent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a fee
         * @param {number} workspaceId 
         * @param {number} feeId 
         * @param {UpdatedFee} updatedFee Fee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFee: async (workspaceId: number, feeId: number, updatedFee: UpdatedFee, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateFee', 'workspaceId', workspaceId)
            // verify required parameter 'feeId' is not null or undefined
            assertParamExists('updateFee', 'feeId', feeId)
            // verify required parameter 'updatedFee' is not null or undefined
            assertParamExists('updateFee', 'updatedFee', updatedFee)
            const localVarPath = `/workspaces/{workspaceId}/agent-appointment/fees/{feeId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"feeId"}}`, encodeURIComponent(String(feeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatedFee, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AgentAppointmentApi - functional programming interface
 * @export
 */
export const AgentAppointmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AgentAppointmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary add an annexure to the agent appointment
         * @param {number} workspaceId 
         * @param {string} documentName 
         * @param {AddAgentAppointmentAnnexureDocumentTypeEnum} documentType 
         * @param {File} body the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addAgentAppointmentAnnexure(workspaceId: number, documentName: string, documentType: AddAgentAppointmentAnnexureDocumentTypeEnum, body: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addAgentAppointmentAnnexure(workspaceId, documentName, documentType, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AgentAppointmentApi.addAgentAppointmentAnnexure']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Removes the annexure from the agent appointment 
         * @summary delete annexure
         * @param {number} workspaceId 
         * @param {number} annexureId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAgentAppointmentAnnexure(workspaceId: number, annexureId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAgentAppointmentAnnexure(workspaceId, annexureId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AgentAppointmentApi.deleteAgentAppointmentAnnexure']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete a BenefitToAgent
         * @param {number} workspaceId 
         * @param {number} benefitToAgentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBenefitToAgent(workspaceId: number, benefitToAgentId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBenefitToAgent(workspaceId, benefitToAgentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AgentAppointmentApi.deleteBenefitToAgent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete a fee
         * @param {number} workspaceId 
         * @param {number} feeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFee(workspaceId: number, feeId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFee(workspaceId, feeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AgentAppointmentApi.deleteFee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get agent appointment
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgentAppointment(workspaceId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAgentAppointment200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgentAppointment(workspaceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AgentAppointmentApi.getAgentAppointment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get agent appointment annexures
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgentAppointmentAnnexures(workspaceId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAgentAppointmentAnnexuresResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgentAppointmentAnnexures(workspaceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AgentAppointmentApi.getAgentAppointmentAnnexures']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get agent appointment licencee
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgentAppointmentLicencee(workspaceId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAgentAppointmentLicencee200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgentAppointmentLicencee(workspaceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AgentAppointmentApi.getAgentAppointmentLicencee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get the pdf for the appointment
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgentAppointmentPdf(workspaceId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgentAppointmentPdf(workspaceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AgentAppointmentApi.getAgentAppointmentPdf']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get agent appointment schedule
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgentAppointmentSchedule(workspaceId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Schedule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgentAppointmentSchedule(workspaceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AgentAppointmentApi.getAgentAppointmentSchedule']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the agent appointment status
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgentAppointmentStatus(workspaceId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentAppointmentStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgentAppointmentStatus(workspaceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AgentAppointmentApi.getAgentAppointmentStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns a list of agent appointment validation erros.  These errors must be fixed before the agent appointment contract can be sent for signing. 
         * @summary get agent appointment validation errors
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgentAppointmentValidation(workspaceId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Validations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgentAppointmentValidation(workspaceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AgentAppointmentApi.getAgentAppointmentValidation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a BenefitToAgent
         * @param {number} workspaceId 
         * @param {number} benefitToAgentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBenefitToAgent(workspaceId: number, benefitToAgentId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenefitToAgent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBenefitToAgent(workspaceId, benefitToAgentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AgentAppointmentApi.getBenefitToAgent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the BenefitToAgents associated with an agent aoppointment
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBenefitToAgents(workspaceId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenefitToAgents>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBenefitToAgents(workspaceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AgentAppointmentApi.getBenefitToAgents']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a fee
         * @param {number} workspaceId 
         * @param {number} feeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFee(workspaceId: number, feeId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Fee>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFee(workspaceId, feeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AgentAppointmentApi.getFee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the fees associated with an agent aoppointment
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFees(workspaceId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Fees>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFees(workspaceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AgentAppointmentApi.getFees']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Add a BenefitToAgent
         * @param {number} workspaceId 
         * @param {UpdatedBenefitToAgent} updatedBenefitToAgent Fee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postBenefitToAgent(workspaceId: number, updatedBenefitToAgent: UpdatedBenefitToAgent, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postBenefitToAgent(workspaceId, updatedBenefitToAgent, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AgentAppointmentApi.postBenefitToAgent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Add a fee
         * @param {number} workspaceId 
         * @param {UpdatedFee} updatedFee Fee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postFee(workspaceId: number, updatedFee: UpdatedFee, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postFee(workspaceId, updatedFee, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AgentAppointmentApi.postFee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary update an agent appointment
         * @param {number} workspaceId 
         * @param {GetAgentAppointment200ResponseAgentAppointment} getAgentAppointment200ResponseAgentAppointment AgentAppointment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAgentAppointment(workspaceId: number, getAgentAppointment200ResponseAgentAppointment: GetAgentAppointment200ResponseAgentAppointment, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAgentAppointment(workspaceId, getAgentAppointment200ResponseAgentAppointment, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AgentAppointmentApi.putAgentAppointment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary update agent appointment licencee
         * @param {number} workspaceId 
         * @param {GetAgentAppointmentLicencee200Response} [getAgentAppointmentLicencee200Response] licencee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAgentAppointmentLicencee(workspaceId: number, getAgentAppointmentLicencee200Response?: GetAgentAppointmentLicencee200Response, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAgentAppointmentLicencee(workspaceId, getAgentAppointmentLicencee200Response, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AgentAppointmentApi.putAgentAppointmentLicencee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary update agent appointment schedule
         * @param {number} workspaceId 
         * @param {Schedule} schedule AgentAppointmentSchedule
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAgentAppointmentSchedule(workspaceId: number, schedule: Schedule, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAgentAppointmentSchedule(workspaceId, schedule, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AgentAppointmentApi.putAgentAppointmentSchedule']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Uploads the signed agent appointment document to the workspace. This is used when the agent appointment is signed manually.  The caller must either be the `sellerAgent`, or have the `workspace_administrator` role. 
         * @summary upload the signed agent appointment document
         * @param {number} workspaceId 
         * @param {File} body the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putSignedAgentAppointment(workspaceId: number, body: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putSignedAgentAppointment(workspaceId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AgentAppointmentApi.putSignedAgentAppointment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Send the agent appointment for signing
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendAgentAppointmentForSigning(workspaceId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentAppointmentStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendAgentAppointmentForSigning(workspaceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AgentAppointmentApi.sendAgentAppointmentForSigning']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the rendering order of the annexure in the agent appointment 
         * @summary change annexure order in agent appointment
         * @param {number} workspaceId 
         * @param {number} annexureId 
         * @param {number} position 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAgentAppointmentAnnexurePosition(workspaceId: number, annexureId: number, position: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAgentAppointmentAnnexurePosition(workspaceId, annexureId, position, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AgentAppointmentApi.updateAgentAppointmentAnnexurePosition']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a BenefitToAgent
         * @param {number} workspaceId 
         * @param {number} benefitToAgentId 
         * @param {UpdatedBenefitToAgent} updatedBenefitToAgent Fee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBenefitToAgent(workspaceId: number, benefitToAgentId: number, updatedBenefitToAgent: UpdatedBenefitToAgent, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBenefitToAgent(workspaceId, benefitToAgentId, updatedBenefitToAgent, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AgentAppointmentApi.updateBenefitToAgent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a fee
         * @param {number} workspaceId 
         * @param {number} feeId 
         * @param {UpdatedFee} updatedFee Fee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFee(workspaceId: number, feeId: number, updatedFee: UpdatedFee, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFee(workspaceId, feeId, updatedFee, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AgentAppointmentApi.updateFee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AgentAppointmentApi - factory interface
 * @export
 */
export const AgentAppointmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AgentAppointmentApiFp(configuration)
    return {
        /**
         * 
         * @summary add an annexure to the agent appointment
         * @param {number} workspaceId 
         * @param {string} documentName 
         * @param {AddAgentAppointmentAnnexureDocumentTypeEnum} documentType 
         * @param {File} body the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAgentAppointmentAnnexure(workspaceId: number, documentName: string, documentType: AddAgentAppointmentAnnexureDocumentTypeEnum, body: File, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.addAgentAppointmentAnnexure(workspaceId, documentName, documentType, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes the annexure from the agent appointment 
         * @summary delete annexure
         * @param {number} workspaceId 
         * @param {number} annexureId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAgentAppointmentAnnexure(workspaceId: number, annexureId: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteAgentAppointmentAnnexure(workspaceId, annexureId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a BenefitToAgent
         * @param {number} workspaceId 
         * @param {number} benefitToAgentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBenefitToAgent(workspaceId: number, benefitToAgentId: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteBenefitToAgent(workspaceId, benefitToAgentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a fee
         * @param {number} workspaceId 
         * @param {number} feeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFee(workspaceId: number, feeId: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteFee(workspaceId, feeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get agent appointment
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentAppointment(workspaceId: number, options?: RawAxiosRequestConfig): AxiosPromise<GetAgentAppointment200Response> {
            return localVarFp.getAgentAppointment(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get agent appointment annexures
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentAppointmentAnnexures(workspaceId: number, options?: RawAxiosRequestConfig): AxiosPromise<GetAgentAppointmentAnnexuresResponse> {
            return localVarFp.getAgentAppointmentAnnexures(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get agent appointment licencee
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentAppointmentLicencee(workspaceId: number, options?: RawAxiosRequestConfig): AxiosPromise<GetAgentAppointmentLicencee200Response> {
            return localVarFp.getAgentAppointmentLicencee(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get the pdf for the appointment
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentAppointmentPdf(workspaceId: number, options?: RawAxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.getAgentAppointmentPdf(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get agent appointment schedule
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentAppointmentSchedule(workspaceId: number, options?: RawAxiosRequestConfig): AxiosPromise<Schedule> {
            return localVarFp.getAgentAppointmentSchedule(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the agent appointment status
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentAppointmentStatus(workspaceId: number, options?: RawAxiosRequestConfig): AxiosPromise<AgentAppointmentStatus> {
            return localVarFp.getAgentAppointmentStatus(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of agent appointment validation erros.  These errors must be fixed before the agent appointment contract can be sent for signing. 
         * @summary get agent appointment validation errors
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentAppointmentValidation(workspaceId: number, options?: RawAxiosRequestConfig): AxiosPromise<Validations> {
            return localVarFp.getAgentAppointmentValidation(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a BenefitToAgent
         * @param {number} workspaceId 
         * @param {number} benefitToAgentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenefitToAgent(workspaceId: number, benefitToAgentId: number, options?: RawAxiosRequestConfig): AxiosPromise<BenefitToAgent> {
            return localVarFp.getBenefitToAgent(workspaceId, benefitToAgentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the BenefitToAgents associated with an agent aoppointment
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenefitToAgents(workspaceId: number, options?: RawAxiosRequestConfig): AxiosPromise<BenefitToAgents> {
            return localVarFp.getBenefitToAgents(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a fee
         * @param {number} workspaceId 
         * @param {number} feeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFee(workspaceId: number, feeId: number, options?: RawAxiosRequestConfig): AxiosPromise<Fee> {
            return localVarFp.getFee(workspaceId, feeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the fees associated with an agent aoppointment
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFees(workspaceId: number, options?: RawAxiosRequestConfig): AxiosPromise<Fees> {
            return localVarFp.getFees(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a BenefitToAgent
         * @param {number} workspaceId 
         * @param {UpdatedBenefitToAgent} updatedBenefitToAgent Fee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postBenefitToAgent(workspaceId: number, updatedBenefitToAgent: UpdatedBenefitToAgent, options?: RawAxiosRequestConfig): AxiosPromise<NewEntity> {
            return localVarFp.postBenefitToAgent(workspaceId, updatedBenefitToAgent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a fee
         * @param {number} workspaceId 
         * @param {UpdatedFee} updatedFee Fee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFee(workspaceId: number, updatedFee: UpdatedFee, options?: RawAxiosRequestConfig): AxiosPromise<NewEntity> {
            return localVarFp.postFee(workspaceId, updatedFee, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update an agent appointment
         * @param {number} workspaceId 
         * @param {GetAgentAppointment200ResponseAgentAppointment} getAgentAppointment200ResponseAgentAppointment AgentAppointment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAgentAppointment(workspaceId: number, getAgentAppointment200ResponseAgentAppointment: GetAgentAppointment200ResponseAgentAppointment, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.putAgentAppointment(workspaceId, getAgentAppointment200ResponseAgentAppointment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update agent appointment licencee
         * @param {number} workspaceId 
         * @param {GetAgentAppointmentLicencee200Response} [getAgentAppointmentLicencee200Response] licencee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAgentAppointmentLicencee(workspaceId: number, getAgentAppointmentLicencee200Response?: GetAgentAppointmentLicencee200Response, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.putAgentAppointmentLicencee(workspaceId, getAgentAppointmentLicencee200Response, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update agent appointment schedule
         * @param {number} workspaceId 
         * @param {Schedule} schedule AgentAppointmentSchedule
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAgentAppointmentSchedule(workspaceId: number, schedule: Schedule, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.putAgentAppointmentSchedule(workspaceId, schedule, options).then((request) => request(axios, basePath));
        },
        /**
         * Uploads the signed agent appointment document to the workspace. This is used when the agent appointment is signed manually.  The caller must either be the `sellerAgent`, or have the `workspace_administrator` role. 
         * @summary upload the signed agent appointment document
         * @param {number} workspaceId 
         * @param {File} body the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putSignedAgentAppointment(workspaceId: number, body: File, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.putSignedAgentAppointment(workspaceId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send the agent appointment for signing
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendAgentAppointmentForSigning(workspaceId: number, options?: RawAxiosRequestConfig): AxiosPromise<AgentAppointmentStatus> {
            return localVarFp.sendAgentAppointmentForSigning(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the rendering order of the annexure in the agent appointment 
         * @summary change annexure order in agent appointment
         * @param {number} workspaceId 
         * @param {number} annexureId 
         * @param {number} position 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgentAppointmentAnnexurePosition(workspaceId: number, annexureId: number, position: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateAgentAppointmentAnnexurePosition(workspaceId, annexureId, position, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a BenefitToAgent
         * @param {number} workspaceId 
         * @param {number} benefitToAgentId 
         * @param {UpdatedBenefitToAgent} updatedBenefitToAgent Fee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBenefitToAgent(workspaceId: number, benefitToAgentId: number, updatedBenefitToAgent: UpdatedBenefitToAgent, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateBenefitToAgent(workspaceId, benefitToAgentId, updatedBenefitToAgent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a fee
         * @param {number} workspaceId 
         * @param {number} feeId 
         * @param {UpdatedFee} updatedFee Fee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFee(workspaceId: number, feeId: number, updatedFee: UpdatedFee, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateFee(workspaceId, feeId, updatedFee, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AgentAppointmentApi - object-oriented interface
 * @export
 * @class AgentAppointmentApi
 * @extends {BaseAPI}
 */
export class AgentAppointmentApi extends BaseAPI {
    /**
     * 
     * @summary add an annexure to the agent appointment
     * @param {number} workspaceId 
     * @param {string} documentName 
     * @param {AddAgentAppointmentAnnexureDocumentTypeEnum} documentType 
     * @param {File} body the document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentAppointmentApi
     */
    public addAgentAppointmentAnnexure(workspaceId: number, documentName: string, documentType: AddAgentAppointmentAnnexureDocumentTypeEnum, body: File, options?: RawAxiosRequestConfig) {
        return AgentAppointmentApiFp(this.configuration).addAgentAppointmentAnnexure(workspaceId, documentName, documentType, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the annexure from the agent appointment 
     * @summary delete annexure
     * @param {number} workspaceId 
     * @param {number} annexureId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentAppointmentApi
     */
    public deleteAgentAppointmentAnnexure(workspaceId: number, annexureId: number, options?: RawAxiosRequestConfig) {
        return AgentAppointmentApiFp(this.configuration).deleteAgentAppointmentAnnexure(workspaceId, annexureId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a BenefitToAgent
     * @param {number} workspaceId 
     * @param {number} benefitToAgentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentAppointmentApi
     */
    public deleteBenefitToAgent(workspaceId: number, benefitToAgentId: number, options?: RawAxiosRequestConfig) {
        return AgentAppointmentApiFp(this.configuration).deleteBenefitToAgent(workspaceId, benefitToAgentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a fee
     * @param {number} workspaceId 
     * @param {number} feeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentAppointmentApi
     */
    public deleteFee(workspaceId: number, feeId: number, options?: RawAxiosRequestConfig) {
        return AgentAppointmentApiFp(this.configuration).deleteFee(workspaceId, feeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get agent appointment
     * @param {number} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentAppointmentApi
     */
    public getAgentAppointment(workspaceId: number, options?: RawAxiosRequestConfig) {
        return AgentAppointmentApiFp(this.configuration).getAgentAppointment(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get agent appointment annexures
     * @param {number} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentAppointmentApi
     */
    public getAgentAppointmentAnnexures(workspaceId: number, options?: RawAxiosRequestConfig) {
        return AgentAppointmentApiFp(this.configuration).getAgentAppointmentAnnexures(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get agent appointment licencee
     * @param {number} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentAppointmentApi
     */
    public getAgentAppointmentLicencee(workspaceId: number, options?: RawAxiosRequestConfig) {
        return AgentAppointmentApiFp(this.configuration).getAgentAppointmentLicencee(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get the pdf for the appointment
     * @param {number} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentAppointmentApi
     */
    public getAgentAppointmentPdf(workspaceId: number, options?: RawAxiosRequestConfig) {
        return AgentAppointmentApiFp(this.configuration).getAgentAppointmentPdf(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get agent appointment schedule
     * @param {number} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentAppointmentApi
     */
    public getAgentAppointmentSchedule(workspaceId: number, options?: RawAxiosRequestConfig) {
        return AgentAppointmentApiFp(this.configuration).getAgentAppointmentSchedule(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the agent appointment status
     * @param {number} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentAppointmentApi
     */
    public getAgentAppointmentStatus(workspaceId: number, options?: RawAxiosRequestConfig) {
        return AgentAppointmentApiFp(this.configuration).getAgentAppointmentStatus(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of agent appointment validation erros.  These errors must be fixed before the agent appointment contract can be sent for signing. 
     * @summary get agent appointment validation errors
     * @param {number} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentAppointmentApi
     */
    public getAgentAppointmentValidation(workspaceId: number, options?: RawAxiosRequestConfig) {
        return AgentAppointmentApiFp(this.configuration).getAgentAppointmentValidation(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a BenefitToAgent
     * @param {number} workspaceId 
     * @param {number} benefitToAgentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentAppointmentApi
     */
    public getBenefitToAgent(workspaceId: number, benefitToAgentId: number, options?: RawAxiosRequestConfig) {
        return AgentAppointmentApiFp(this.configuration).getBenefitToAgent(workspaceId, benefitToAgentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the BenefitToAgents associated with an agent aoppointment
     * @param {number} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentAppointmentApi
     */
    public getBenefitToAgents(workspaceId: number, options?: RawAxiosRequestConfig) {
        return AgentAppointmentApiFp(this.configuration).getBenefitToAgents(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a fee
     * @param {number} workspaceId 
     * @param {number} feeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentAppointmentApi
     */
    public getFee(workspaceId: number, feeId: number, options?: RawAxiosRequestConfig) {
        return AgentAppointmentApiFp(this.configuration).getFee(workspaceId, feeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the fees associated with an agent aoppointment
     * @param {number} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentAppointmentApi
     */
    public getFees(workspaceId: number, options?: RawAxiosRequestConfig) {
        return AgentAppointmentApiFp(this.configuration).getFees(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add a BenefitToAgent
     * @param {number} workspaceId 
     * @param {UpdatedBenefitToAgent} updatedBenefitToAgent Fee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentAppointmentApi
     */
    public postBenefitToAgent(workspaceId: number, updatedBenefitToAgent: UpdatedBenefitToAgent, options?: RawAxiosRequestConfig) {
        return AgentAppointmentApiFp(this.configuration).postBenefitToAgent(workspaceId, updatedBenefitToAgent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add a fee
     * @param {number} workspaceId 
     * @param {UpdatedFee} updatedFee Fee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentAppointmentApi
     */
    public postFee(workspaceId: number, updatedFee: UpdatedFee, options?: RawAxiosRequestConfig) {
        return AgentAppointmentApiFp(this.configuration).postFee(workspaceId, updatedFee, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update an agent appointment
     * @param {number} workspaceId 
     * @param {GetAgentAppointment200ResponseAgentAppointment} getAgentAppointment200ResponseAgentAppointment AgentAppointment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentAppointmentApi
     */
    public putAgentAppointment(workspaceId: number, getAgentAppointment200ResponseAgentAppointment: GetAgentAppointment200ResponseAgentAppointment, options?: RawAxiosRequestConfig) {
        return AgentAppointmentApiFp(this.configuration).putAgentAppointment(workspaceId, getAgentAppointment200ResponseAgentAppointment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update agent appointment licencee
     * @param {number} workspaceId 
     * @param {GetAgentAppointmentLicencee200Response} [getAgentAppointmentLicencee200Response] licencee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentAppointmentApi
     */
    public putAgentAppointmentLicencee(workspaceId: number, getAgentAppointmentLicencee200Response?: GetAgentAppointmentLicencee200Response, options?: RawAxiosRequestConfig) {
        return AgentAppointmentApiFp(this.configuration).putAgentAppointmentLicencee(workspaceId, getAgentAppointmentLicencee200Response, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update agent appointment schedule
     * @param {number} workspaceId 
     * @param {Schedule} schedule AgentAppointmentSchedule
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentAppointmentApi
     */
    public putAgentAppointmentSchedule(workspaceId: number, schedule: Schedule, options?: RawAxiosRequestConfig) {
        return AgentAppointmentApiFp(this.configuration).putAgentAppointmentSchedule(workspaceId, schedule, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Uploads the signed agent appointment document to the workspace. This is used when the agent appointment is signed manually.  The caller must either be the `sellerAgent`, or have the `workspace_administrator` role. 
     * @summary upload the signed agent appointment document
     * @param {number} workspaceId 
     * @param {File} body the document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentAppointmentApi
     */
    public putSignedAgentAppointment(workspaceId: number, body: File, options?: RawAxiosRequestConfig) {
        return AgentAppointmentApiFp(this.configuration).putSignedAgentAppointment(workspaceId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send the agent appointment for signing
     * @param {number} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentAppointmentApi
     */
    public sendAgentAppointmentForSigning(workspaceId: number, options?: RawAxiosRequestConfig) {
        return AgentAppointmentApiFp(this.configuration).sendAgentAppointmentForSigning(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the rendering order of the annexure in the agent appointment 
     * @summary change annexure order in agent appointment
     * @param {number} workspaceId 
     * @param {number} annexureId 
     * @param {number} position 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentAppointmentApi
     */
    public updateAgentAppointmentAnnexurePosition(workspaceId: number, annexureId: number, position: number, options?: RawAxiosRequestConfig) {
        return AgentAppointmentApiFp(this.configuration).updateAgentAppointmentAnnexurePosition(workspaceId, annexureId, position, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a BenefitToAgent
     * @param {number} workspaceId 
     * @param {number} benefitToAgentId 
     * @param {UpdatedBenefitToAgent} updatedBenefitToAgent Fee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentAppointmentApi
     */
    public updateBenefitToAgent(workspaceId: number, benefitToAgentId: number, updatedBenefitToAgent: UpdatedBenefitToAgent, options?: RawAxiosRequestConfig) {
        return AgentAppointmentApiFp(this.configuration).updateBenefitToAgent(workspaceId, benefitToAgentId, updatedBenefitToAgent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a fee
     * @param {number} workspaceId 
     * @param {number} feeId 
     * @param {UpdatedFee} updatedFee Fee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentAppointmentApi
     */
    public updateFee(workspaceId: number, feeId: number, updatedFee: UpdatedFee, options?: RawAxiosRequestConfig) {
        return AgentAppointmentApiFp(this.configuration).updateFee(workspaceId, feeId, updatedFee, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const AddAgentAppointmentAnnexureDocumentTypeEnum = {
    AgentAppointment: 'AgentAppointment',
    Annexure: 'Annexure',
    ContractOfSale: 'ContractOfSale',
    Title: 'Title'
} as const;
export type AddAgentAppointmentAnnexureDocumentTypeEnum = typeof AddAgentAppointmentAnnexureDocumentTypeEnum[keyof typeof AddAgentAppointmentAnnexureDocumentTypeEnum];


/**
 * ContractsApi - axios parameter creator
 * @export
 */
export const ContractsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary add an annexure to the contract
         * @param {number} workspaceId 
         * @param {string} documentName 
         * @param {AddAnnexureDocumentTypeEnum} documentType 
         * @param {File} body the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAnnexure: async (workspaceId: number, documentName: string, documentType: AddAnnexureDocumentTypeEnum, body: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('addAnnexure', 'workspaceId', workspaceId)
            // verify required parameter 'documentName' is not null or undefined
            assertParamExists('addAnnexure', 'documentName', documentName)
            // verify required parameter 'documentType' is not null or undefined
            assertParamExists('addAnnexure', 'documentType', documentType)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('addAnnexure', 'body', body)
            const localVarPath = `/workspaces/{workspaceId}/contract/annexures/add/{documentName}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"documentName"}}`, encodeURIComponent(String(documentName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)

            if (documentType !== undefined) {
                localVarQueryParameter['documentType'] = documentType;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/octet-stream';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * - publishes the draft contract to the document list of the workspace - moves the worklfow from `contract-preparation-drafting` to `contract-preparation-disclosures`. 
         * @summary complete contract drafting
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeContractDrafting: async (workspaceId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('completeContractDrafting', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/contract/complete`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the annexure from the contract 
         * @summary delete annexure
         * @param {number} workspaceId 
         * @param {number} annexureId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnnexure: async (workspaceId: number, annexureId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('deleteAnnexure', 'workspaceId', workspaceId)
            // verify required parameter 'annexureId' is not null or undefined
            assertParamExists('deleteAnnexure', 'annexureId', annexureId)
            const localVarPath = `/workspaces/{workspaceId}/contract/annexures/{annexureId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"annexureId"}}`, encodeURIComponent(String(annexureId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get contract
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContract: async (workspaceId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getContract', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/contract`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get contract annexures
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractAnnexures: async (workspaceId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getContractAnnexures', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/contract/annexures`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get the contract as a PDF
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPdf: async (workspaceId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getPdf', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/contract/pdf`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary invite a solicitor to prepare contract disclosures
         * @param {number} workspaceId 
         * @param {Invitation} invitation SellerSolicitorInvitationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteSellerSolicitor: async (workspaceId: number, invitation: Invitation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('inviteSellerSolicitor', 'workspaceId', workspaceId)
            // verify required parameter 'invitation' is not null or undefined
            assertParamExists('inviteSellerSolicitor', 'invitation', invitation)
            const localVarPath = `/workspaces/{workspaceId}/contract/invite-seller-solicitor`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invitation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update a contract
         * @param {number} workspaceId 
         * @param {PutContractRequest} putContractRequest Contract
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putContract: async (workspaceId: number, putContractRequest: PutContractRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('putContract', 'workspaceId', workspaceId)
            // verify required parameter 'putContractRequest' is not null or undefined
            assertParamExists('putContract', 'putContractRequest', putContractRequest)
            const localVarPath = `/workspaces/{workspaceId}/contract`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putContractRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the rendering order of the annexure in the contract 
         * @summary change annexure order in contract
         * @param {number} workspaceId 
         * @param {number} annexureId 
         * @param {number} position 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnnexurePosition: async (workspaceId: number, annexureId: number, position: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateAnnexurePosition', 'workspaceId', workspaceId)
            // verify required parameter 'annexureId' is not null or undefined
            assertParamExists('updateAnnexurePosition', 'annexureId', annexureId)
            // verify required parameter 'position' is not null or undefined
            assertParamExists('updateAnnexurePosition', 'position', position)
            const localVarPath = `/workspaces/{workspaceId}/contract/annexures/{annexureId}/position/{position}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"annexureId"}}`, encodeURIComponent(String(annexureId)))
                .replace(`{${"position"}}`, encodeURIComponent(String(position)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContractsApi - functional programming interface
 * @export
 */
export const ContractsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContractsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary add an annexure to the contract
         * @param {number} workspaceId 
         * @param {string} documentName 
         * @param {AddAnnexureDocumentTypeEnum} documentType 
         * @param {File} body the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addAnnexure(workspaceId: number, documentName: string, documentType: AddAnnexureDocumentTypeEnum, body: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addAnnexure(workspaceId, documentName, documentType, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContractsApi.addAnnexure']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * - publishes the draft contract to the document list of the workspace - moves the worklfow from `contract-preparation-drafting` to `contract-preparation-disclosures`. 
         * @summary complete contract drafting
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completeContractDrafting(workspaceId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completeContractDrafting(workspaceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContractsApi.completeContractDrafting']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Removes the annexure from the contract 
         * @summary delete annexure
         * @param {number} workspaceId 
         * @param {number} annexureId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAnnexure(workspaceId: number, annexureId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAnnexure(workspaceId, annexureId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContractsApi.deleteAnnexure']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get contract
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContract(workspaceId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contract>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContract(workspaceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContractsApi.getContract']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get contract annexures
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContractAnnexures(workspaceId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractAnnexuresResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContractAnnexures(workspaceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContractsApi.getContractAnnexures']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get the contract as a PDF
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPdf(workspaceId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPdf(workspaceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContractsApi.getPdf']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary invite a solicitor to prepare contract disclosures
         * @param {number} workspaceId 
         * @param {Invitation} invitation SellerSolicitorInvitationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inviteSellerSolicitor(workspaceId: number, invitation: Invitation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inviteSellerSolicitor(workspaceId, invitation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContractsApi.inviteSellerSolicitor']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary update a contract
         * @param {number} workspaceId 
         * @param {PutContractRequest} putContractRequest Contract
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putContract(workspaceId: number, putContractRequest: PutContractRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putContract(workspaceId, putContractRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContractsApi.putContract']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the rendering order of the annexure in the contract 
         * @summary change annexure order in contract
         * @param {number} workspaceId 
         * @param {number} annexureId 
         * @param {number} position 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAnnexurePosition(workspaceId: number, annexureId: number, position: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAnnexurePosition(workspaceId, annexureId, position, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContractsApi.updateAnnexurePosition']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ContractsApi - factory interface
 * @export
 */
export const ContractsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContractsApiFp(configuration)
    return {
        /**
         * 
         * @summary add an annexure to the contract
         * @param {number} workspaceId 
         * @param {string} documentName 
         * @param {AddAnnexureDocumentTypeEnum} documentType 
         * @param {File} body the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAnnexure(workspaceId: number, documentName: string, documentType: AddAnnexureDocumentTypeEnum, body: File, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.addAnnexure(workspaceId, documentName, documentType, body, options).then((request) => request(axios, basePath));
        },
        /**
         * - publishes the draft contract to the document list of the workspace - moves the worklfow from `contract-preparation-drafting` to `contract-preparation-disclosures`. 
         * @summary complete contract drafting
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeContractDrafting(workspaceId: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.completeContractDrafting(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes the annexure from the contract 
         * @summary delete annexure
         * @param {number} workspaceId 
         * @param {number} annexureId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnnexure(workspaceId: number, annexureId: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteAnnexure(workspaceId, annexureId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get contract
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContract(workspaceId: number, options?: RawAxiosRequestConfig): AxiosPromise<Contract> {
            return localVarFp.getContract(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get contract annexures
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractAnnexures(workspaceId: number, options?: RawAxiosRequestConfig): AxiosPromise<ContractAnnexuresResponse> {
            return localVarFp.getContractAnnexures(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get the contract as a PDF
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPdf(workspaceId: number, options?: RawAxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.getPdf(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary invite a solicitor to prepare contract disclosures
         * @param {number} workspaceId 
         * @param {Invitation} invitation SellerSolicitorInvitationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteSellerSolicitor(workspaceId: number, invitation: Invitation, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.inviteSellerSolicitor(workspaceId, invitation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update a contract
         * @param {number} workspaceId 
         * @param {PutContractRequest} putContractRequest Contract
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putContract(workspaceId: number, putContractRequest: PutContractRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.putContract(workspaceId, putContractRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the rendering order of the annexure in the contract 
         * @summary change annexure order in contract
         * @param {number} workspaceId 
         * @param {number} annexureId 
         * @param {number} position 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnnexurePosition(workspaceId: number, annexureId: number, position: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateAnnexurePosition(workspaceId, annexureId, position, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContractsApi - object-oriented interface
 * @export
 * @class ContractsApi
 * @extends {BaseAPI}
 */
export class ContractsApi extends BaseAPI {
    /**
     * 
     * @summary add an annexure to the contract
     * @param {number} workspaceId 
     * @param {string} documentName 
     * @param {AddAnnexureDocumentTypeEnum} documentType 
     * @param {File} body the document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public addAnnexure(workspaceId: number, documentName: string, documentType: AddAnnexureDocumentTypeEnum, body: File, options?: RawAxiosRequestConfig) {
        return ContractsApiFp(this.configuration).addAnnexure(workspaceId, documentName, documentType, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * - publishes the draft contract to the document list of the workspace - moves the worklfow from `contract-preparation-drafting` to `contract-preparation-disclosures`. 
     * @summary complete contract drafting
     * @param {number} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public completeContractDrafting(workspaceId: number, options?: RawAxiosRequestConfig) {
        return ContractsApiFp(this.configuration).completeContractDrafting(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the annexure from the contract 
     * @summary delete annexure
     * @param {number} workspaceId 
     * @param {number} annexureId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public deleteAnnexure(workspaceId: number, annexureId: number, options?: RawAxiosRequestConfig) {
        return ContractsApiFp(this.configuration).deleteAnnexure(workspaceId, annexureId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get contract
     * @param {number} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public getContract(workspaceId: number, options?: RawAxiosRequestConfig) {
        return ContractsApiFp(this.configuration).getContract(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get contract annexures
     * @param {number} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public getContractAnnexures(workspaceId: number, options?: RawAxiosRequestConfig) {
        return ContractsApiFp(this.configuration).getContractAnnexures(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get the contract as a PDF
     * @param {number} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public getPdf(workspaceId: number, options?: RawAxiosRequestConfig) {
        return ContractsApiFp(this.configuration).getPdf(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary invite a solicitor to prepare contract disclosures
     * @param {number} workspaceId 
     * @param {Invitation} invitation SellerSolicitorInvitationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public inviteSellerSolicitor(workspaceId: number, invitation: Invitation, options?: RawAxiosRequestConfig) {
        return ContractsApiFp(this.configuration).inviteSellerSolicitor(workspaceId, invitation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update a contract
     * @param {number} workspaceId 
     * @param {PutContractRequest} putContractRequest Contract
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public putContract(workspaceId: number, putContractRequest: PutContractRequest, options?: RawAxiosRequestConfig) {
        return ContractsApiFp(this.configuration).putContract(workspaceId, putContractRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the rendering order of the annexure in the contract 
     * @summary change annexure order in contract
     * @param {number} workspaceId 
     * @param {number} annexureId 
     * @param {number} position 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public updateAnnexurePosition(workspaceId: number, annexureId: number, position: number, options?: RawAxiosRequestConfig) {
        return ContractsApiFp(this.configuration).updateAnnexurePosition(workspaceId, annexureId, position, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const AddAnnexureDocumentTypeEnum = {
    AgentAppointment: 'AgentAppointment',
    Annexure: 'Annexure',
    ContractOfSale: 'ContractOfSale',
    Title: 'Title'
} as const;
export type AddAnnexureDocumentTypeEnum = typeof AddAnnexureDocumentTypeEnum[keyof typeof AddAnnexureDocumentTypeEnum];


/**
 * DocumentsApi - axios parameter creator
 * @export
 */
export const DocumentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a document
         * @param {number} workspaceId 
         * @param {string} documentName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocument: async (workspaceId: number, documentName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDocument', 'workspaceId', workspaceId)
            // verify required parameter 'documentName' is not null or undefined
            assertParamExists('getDocument', 'documentName', documentName)
            const localVarPath = `/workspaces/{workspaceId}/documents/{documentName}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"documentName"}}`, encodeURIComponent(String(documentName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the documents in a workspace
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocuments: async (workspaceId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDocuments', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/documents`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Put a document
         * @param {number} workspaceId 
         * @param {string} documentName 
         * @param {PutDocumentDocumentTypeEnum} documentType 
         * @param {File} body the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDocument: async (workspaceId: number, documentName: string, documentType: PutDocumentDocumentTypeEnum, body: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('putDocument', 'workspaceId', workspaceId)
            // verify required parameter 'documentName' is not null or undefined
            assertParamExists('putDocument', 'documentName', documentName)
            // verify required parameter 'documentType' is not null or undefined
            assertParamExists('putDocument', 'documentType', documentType)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('putDocument', 'body', body)
            const localVarPath = `/workspaces/{workspaceId}/documents/{documentName}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"documentName"}}`, encodeURIComponent(String(documentName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)

            if (documentType !== undefined) {
                localVarQueryParameter['documentType'] = documentType;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/octet-stream';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentsApi - functional programming interface
 * @export
 */
export const DocumentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get a document
         * @param {number} workspaceId 
         * @param {string} documentName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocument(workspaceId: number, documentName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocument(workspaceId, documentName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentsApi.getDocument']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the documents in a workspace
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocuments(workspaceId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDocuments200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocuments(workspaceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentsApi.getDocuments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Put a document
         * @param {number} workspaceId 
         * @param {string} documentName 
         * @param {PutDocumentDocumentTypeEnum} documentType 
         * @param {File} body the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putDocument(workspaceId: number, documentName: string, documentType: PutDocumentDocumentTypeEnum, body: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putDocument(workspaceId, documentName, documentType, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentsApi.putDocument']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DocumentsApi - factory interface
 * @export
 */
export const DocumentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get a document
         * @param {number} workspaceId 
         * @param {string} documentName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocument(workspaceId: number, documentName: string, options?: RawAxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.getDocument(workspaceId, documentName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the documents in a workspace
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocuments(workspaceId: number, options?: RawAxiosRequestConfig): AxiosPromise<GetDocuments200Response> {
            return localVarFp.getDocuments(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Put a document
         * @param {number} workspaceId 
         * @param {string} documentName 
         * @param {PutDocumentDocumentTypeEnum} documentType 
         * @param {File} body the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDocument(workspaceId: number, documentName: string, documentType: PutDocumentDocumentTypeEnum, body: File, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.putDocument(workspaceId, documentName, documentType, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentsApi - object-oriented interface
 * @export
 * @class DocumentsApi
 * @extends {BaseAPI}
 */
export class DocumentsApi extends BaseAPI {
    /**
     * 
     * @summary Get a document
     * @param {number} workspaceId 
     * @param {string} documentName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public getDocument(workspaceId: number, documentName: string, options?: RawAxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).getDocument(workspaceId, documentName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the documents in a workspace
     * @param {number} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public getDocuments(workspaceId: number, options?: RawAxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).getDocuments(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Put a document
     * @param {number} workspaceId 
     * @param {string} documentName 
     * @param {PutDocumentDocumentTypeEnum} documentType 
     * @param {File} body the document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public putDocument(workspaceId: number, documentName: string, documentType: PutDocumentDocumentTypeEnum, body: File, options?: RawAxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).putDocument(workspaceId, documentName, documentType, body, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const PutDocumentDocumentTypeEnum = {
    AgentAppointment: 'AgentAppointment',
    Annexure: 'Annexure',
    ContractOfSale: 'ContractOfSale',
    Title: 'Title'
} as const;
export type PutDocumentDocumentTypeEnum = typeof PutDocumentDocumentTypeEnum[keyof typeof PutDocumentDocumentTypeEnum];


/**
 * OffersApi - axios parameter creator
 * @export
 */
export const OffersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary delete offer
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOffer: async (workspaceId: number, offerId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('deleteOffer', 'workspaceId', workspaceId)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('deleteOffer', 'offerId', offerId)
            const localVarPath = `/workspaces/{workspaceId}/offers/{offerId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary remove a participant from the contract
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {number} participantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOfferParticipant: async (workspaceId: number, offerId: number, participantId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('deleteOfferParticipant', 'workspaceId', workspaceId)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('deleteOfferParticipant', 'offerId', offerId)
            // verify required parameter 'participantId' is not null or undefined
            assertParamExists('deleteOfferParticipant', 'participantId', participantId)
            const localVarPath = `/workspaces/{workspaceId}/offers/{offerId}/participants/{participantId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)))
                .replace(`{${"participantId"}}`, encodeURIComponent(String(participantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get offer
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOffer: async (workspaceId: number, offerId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getOffer', 'workspaceId', workspaceId)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('getOffer', 'offerId', offerId)
            const localVarPath = `/workspaces/{workspaceId}/offers/{offerId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get draft of the offer contract
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfferDraft: async (workspaceId: number, offerId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getOfferDraft', 'workspaceId', workspaceId)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('getOfferDraft', 'offerId', offerId)
            const localVarPath = `/workspaces/{workspaceId}/offers/{offerId}/draft`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary contract as a PDF
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfferDraftPDF: async (workspaceId: number, offerId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getOfferDraftPDF', 'workspaceId', workspaceId)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('getOfferDraftPDF', 'offerId', offerId)
            const localVarPath = `/workspaces/{workspaceId}/offers/{offerId}/pdf`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get an offer participant
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {number} participantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfferParticipant: async (workspaceId: number, offerId: number, participantId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getOfferParticipant', 'workspaceId', workspaceId)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('getOfferParticipant', 'offerId', offerId)
            // verify required parameter 'participantId' is not null or undefined
            assertParamExists('getOfferParticipant', 'participantId', participantId)
            const localVarPath = `/workspaces/{workspaceId}/offers/{offerId}/participants/{participantId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)))
                .replace(`{${"participantId"}}`, encodeURIComponent(String(participantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get offer participants
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfferParticipants: async (workspaceId: number, offerId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getOfferParticipants', 'workspaceId', workspaceId)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('getOfferParticipants', 'offerId', offerId)
            const localVarPath = `/workspaces/{workspaceId}/offers/{offerId}/participants`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the offers that have been created by the `sellerAgent` on behalf of `buyers`. If  the `role` type of the user is `buyer`, only the offers that have been shared with the `buyer` will be visible. 
         * @summary get offers
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOffers: async (workspaceId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getOffers', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/offers`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Buyers are invited to the workspace so they can view the contract and other documents. 
         * @summary Invite buyers to the workspace.
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteBuyers: async (workspaceId: number, offerId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('inviteBuyers', 'workspaceId', workspaceId)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('inviteBuyers', 'offerId', offerId)
            const localVarPath = `/workspaces/{workspaceId}/offers/{offerId}/invite-buyers`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create an offer
         * @param {number} workspaceId 
         * @param {Contract} contract Offer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOffer: async (workspaceId: number, contract: Contract, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('postOffer', 'workspaceId', workspaceId)
            // verify required parameter 'contract' is not null or undefined
            assertParamExists('postOffer', 'contract', contract)
            const localVarPath = `/workspaces/{workspaceId}/offers`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary add a participant
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {Participant1} participant1 ParticipantDetails
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOfferParticipant: async (workspaceId: number, offerId: number, participant1: Participant1, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('postOfferParticipant', 'workspaceId', workspaceId)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('postOfferParticipant', 'offerId', offerId)
            // verify required parameter 'participant1' is not null or undefined
            assertParamExists('postOfferParticipant', 'participant1', participant1)
            const localVarPath = `/workspaces/{workspaceId}/offers/{offerId}/participants`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(participant1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Presenting an offer makes the offer visible to the seller.  The seller will be notified by email that the offer is ready for viewing in the workspace. 
         * @summary Present offer to seller
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        presentOfferToSeller: async (workspaceId: number, offerId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('presentOfferToSeller', 'workspaceId', workspaceId)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('presentOfferToSeller', 'offerId', offerId)
            const localVarPath = `/workspaces/{workspaceId}/offers/{offerId}/present`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update offer
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {Contract} contract Offer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putOffer: async (workspaceId: number, offerId: number, contract: Contract, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('putOffer', 'workspaceId', workspaceId)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('putOffer', 'offerId', offerId)
            // verify required parameter 'contract' is not null or undefined
            assertParamExists('putOffer', 'contract', contract)
            const localVarPath = `/workspaces/{workspaceId}/offers/{offerId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update a participant
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {number} participantId 
         * @param {Participant1} participant1 Offer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putOfferParticipant: async (workspaceId: number, offerId: number, participantId: number, participant1: Participant1, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('putOfferParticipant', 'workspaceId', workspaceId)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('putOfferParticipant', 'offerId', offerId)
            // verify required parameter 'participantId' is not null or undefined
            assertParamExists('putOfferParticipant', 'participantId', participantId)
            // verify required parameter 'participant1' is not null or undefined
            assertParamExists('putOfferParticipant', 'participant1', participant1)
            const localVarPath = `/workspaces/{workspaceId}/offers/{offerId}/participants/{participantId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)))
                .replace(`{${"participantId"}}`, encodeURIComponent(String(participantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(participant1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Signing occurs is two stages. The first stage is the buyer signing the offer. The signed contract  is saved to the workspace until the second time `send-for-signing` is called, at which point it is sent to the sellers. The offer contract can only be sent for signing once the contract disclosures  have been completed by the solicitor. 
         * @summary Send the offer for signing
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendForSigning: async (workspaceId: number, offerId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('sendForSigning', 'workspaceId', workspaceId)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('sendForSigning', 'offerId', offerId)
            const localVarPath = `/workspaces/{workspaceId}/offers/{offerId}/send-for-signing`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Starts the offer workflow which manages the process of negotiating and accepting an offer. This includes: - Inviting buyers to view the contract - Allowing buyers to propose changes to the contract - Managing contract versions and amendments - Tracking offer status through the negotiation process - Facilitating final contract acceptance 
         * @summary starts the offer workflow.
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startOfferWorkflow: async (workspaceId: number, offerId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('startOfferWorkflow', 'workspaceId', workspaceId)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('startOfferWorkflow', 'offerId', offerId)
            const localVarPath = `/workspaces/{workspaceId}/offers/{offerId}/start`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OffersApi - functional programming interface
 * @export
 */
export const OffersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OffersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary delete offer
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOffer(workspaceId: number, offerId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOffer(workspaceId, offerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OffersApi.deleteOffer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary remove a participant from the contract
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {number} participantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOfferParticipant(workspaceId: number, offerId: number, participantId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOfferParticipant(workspaceId, offerId, participantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OffersApi.deleteOfferParticipant']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get offer
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOffer(workspaceId: number, offerId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contract>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOffer(workspaceId, offerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OffersApi.getOffer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get draft of the offer contract
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOfferDraft(workspaceId: number, offerId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOfferDraft(workspaceId, offerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OffersApi.getOfferDraft']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary contract as a PDF
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOfferDraftPDF(workspaceId: number, offerId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOfferDraftPDF(workspaceId, offerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OffersApi.getOfferDraftPDF']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get an offer participant
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {number} participantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOfferParticipant(workspaceId: number, offerId: number, participantId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Participant1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOfferParticipant(workspaceId, offerId, participantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OffersApi.getOfferParticipant']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get offer participants
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOfferParticipants(workspaceId: number, offerId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Participants>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOfferParticipants(workspaceId, offerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OffersApi.getOfferParticipants']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns the offers that have been created by the `sellerAgent` on behalf of `buyers`. If  the `role` type of the user is `buyer`, only the offers that have been shared with the `buyer` will be visible. 
         * @summary get offers
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOffers(workspaceId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Offers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOffers(workspaceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OffersApi.getOffers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Buyers are invited to the workspace so they can view the contract and other documents. 
         * @summary Invite buyers to the workspace.
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inviteBuyers(workspaceId: number, offerId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inviteBuyers(workspaceId, offerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OffersApi.inviteBuyers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary create an offer
         * @param {number} workspaceId 
         * @param {Contract} contract Offer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postOffer(workspaceId: number, contract: Contract, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postOffer(workspaceId, contract, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OffersApi.postOffer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary add a participant
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {Participant1} participant1 ParticipantDetails
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postOfferParticipant(workspaceId: number, offerId: number, participant1: Participant1, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postOfferParticipant(workspaceId, offerId, participant1, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OffersApi.postOfferParticipant']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Presenting an offer makes the offer visible to the seller.  The seller will be notified by email that the offer is ready for viewing in the workspace. 
         * @summary Present offer to seller
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async presentOfferToSeller(workspaceId: number, offerId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.presentOfferToSeller(workspaceId, offerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OffersApi.presentOfferToSeller']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary update offer
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {Contract} contract Offer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putOffer(workspaceId: number, offerId: number, contract: Contract, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contract>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putOffer(workspaceId, offerId, contract, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OffersApi.putOffer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary update a participant
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {number} participantId 
         * @param {Participant1} participant1 Offer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putOfferParticipant(workspaceId: number, offerId: number, participantId: number, participant1: Participant1, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putOfferParticipant(workspaceId, offerId, participantId, participant1, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OffersApi.putOfferParticipant']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Signing occurs is two stages. The first stage is the buyer signing the offer. The signed contract  is saved to the workspace until the second time `send-for-signing` is called, at which point it is sent to the sellers. The offer contract can only be sent for signing once the contract disclosures  have been completed by the solicitor. 
         * @summary Send the offer for signing
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendForSigning(workspaceId: number, offerId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendForSigning(workspaceId, offerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OffersApi.sendForSigning']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Starts the offer workflow which manages the process of negotiating and accepting an offer. This includes: - Inviting buyers to view the contract - Allowing buyers to propose changes to the contract - Managing contract versions and amendments - Tracking offer status through the negotiation process - Facilitating final contract acceptance 
         * @summary starts the offer workflow.
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startOfferWorkflow(workspaceId: number, offerId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startOfferWorkflow(workspaceId, offerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OffersApi.startOfferWorkflow']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OffersApi - factory interface
 * @export
 */
export const OffersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OffersApiFp(configuration)
    return {
        /**
         * 
         * @summary delete offer
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOffer(workspaceId: number, offerId: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteOffer(workspaceId, offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary remove a participant from the contract
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {number} participantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOfferParticipant(workspaceId: number, offerId: number, participantId: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteOfferParticipant(workspaceId, offerId, participantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get offer
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOffer(workspaceId: number, offerId: number, options?: RawAxiosRequestConfig): AxiosPromise<Contract> {
            return localVarFp.getOffer(workspaceId, offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get draft of the offer contract
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfferDraft(workspaceId: number, offerId: number, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.getOfferDraft(workspaceId, offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary contract as a PDF
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfferDraftPDF(workspaceId: number, offerId: number, options?: RawAxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.getOfferDraftPDF(workspaceId, offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get an offer participant
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {number} participantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfferParticipant(workspaceId: number, offerId: number, participantId: number, options?: RawAxiosRequestConfig): AxiosPromise<Participant1> {
            return localVarFp.getOfferParticipant(workspaceId, offerId, participantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get offer participants
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfferParticipants(workspaceId: number, offerId: number, options?: RawAxiosRequestConfig): AxiosPromise<Participants> {
            return localVarFp.getOfferParticipants(workspaceId, offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the offers that have been created by the `sellerAgent` on behalf of `buyers`. If  the `role` type of the user is `buyer`, only the offers that have been shared with the `buyer` will be visible. 
         * @summary get offers
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOffers(workspaceId: number, options?: RawAxiosRequestConfig): AxiosPromise<Offers> {
            return localVarFp.getOffers(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Buyers are invited to the workspace so they can view the contract and other documents. 
         * @summary Invite buyers to the workspace.
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteBuyers(workspaceId: number, offerId: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.inviteBuyers(workspaceId, offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create an offer
         * @param {number} workspaceId 
         * @param {Contract} contract Offer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOffer(workspaceId: number, contract: Contract, options?: RawAxiosRequestConfig): AxiosPromise<NewEntity> {
            return localVarFp.postOffer(workspaceId, contract, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary add a participant
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {Participant1} participant1 ParticipantDetails
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOfferParticipant(workspaceId: number, offerId: number, participant1: Participant1, options?: RawAxiosRequestConfig): AxiosPromise<NewEntity> {
            return localVarFp.postOfferParticipant(workspaceId, offerId, participant1, options).then((request) => request(axios, basePath));
        },
        /**
         * Presenting an offer makes the offer visible to the seller.  The seller will be notified by email that the offer is ready for viewing in the workspace. 
         * @summary Present offer to seller
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        presentOfferToSeller(workspaceId: number, offerId: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.presentOfferToSeller(workspaceId, offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update offer
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {Contract} contract Offer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putOffer(workspaceId: number, offerId: number, contract: Contract, options?: RawAxiosRequestConfig): AxiosPromise<Contract> {
            return localVarFp.putOffer(workspaceId, offerId, contract, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update a participant
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {number} participantId 
         * @param {Participant1} participant1 Offer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putOfferParticipant(workspaceId: number, offerId: number, participantId: number, participant1: Participant1, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.putOfferParticipant(workspaceId, offerId, participantId, participant1, options).then((request) => request(axios, basePath));
        },
        /**
         * Signing occurs is two stages. The first stage is the buyer signing the offer. The signed contract  is saved to the workspace until the second time `send-for-signing` is called, at which point it is sent to the sellers. The offer contract can only be sent for signing once the contract disclosures  have been completed by the solicitor. 
         * @summary Send the offer for signing
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendForSigning(workspaceId: number, offerId: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sendForSigning(workspaceId, offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Starts the offer workflow which manages the process of negotiating and accepting an offer. This includes: - Inviting buyers to view the contract - Allowing buyers to propose changes to the contract - Managing contract versions and amendments - Tracking offer status through the negotiation process - Facilitating final contract acceptance 
         * @summary starts the offer workflow.
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startOfferWorkflow(workspaceId: number, offerId: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.startOfferWorkflow(workspaceId, offerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OffersApi - object-oriented interface
 * @export
 * @class OffersApi
 * @extends {BaseAPI}
 */
export class OffersApi extends BaseAPI {
    /**
     * 
     * @summary delete offer
     * @param {number} workspaceId 
     * @param {number} offerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffersApi
     */
    public deleteOffer(workspaceId: number, offerId: number, options?: RawAxiosRequestConfig) {
        return OffersApiFp(this.configuration).deleteOffer(workspaceId, offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary remove a participant from the contract
     * @param {number} workspaceId 
     * @param {number} offerId 
     * @param {number} participantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffersApi
     */
    public deleteOfferParticipant(workspaceId: number, offerId: number, participantId: number, options?: RawAxiosRequestConfig) {
        return OffersApiFp(this.configuration).deleteOfferParticipant(workspaceId, offerId, participantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get offer
     * @param {number} workspaceId 
     * @param {number} offerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffersApi
     */
    public getOffer(workspaceId: number, offerId: number, options?: RawAxiosRequestConfig) {
        return OffersApiFp(this.configuration).getOffer(workspaceId, offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get draft of the offer contract
     * @param {number} workspaceId 
     * @param {number} offerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffersApi
     */
    public getOfferDraft(workspaceId: number, offerId: number, options?: RawAxiosRequestConfig) {
        return OffersApiFp(this.configuration).getOfferDraft(workspaceId, offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary contract as a PDF
     * @param {number} workspaceId 
     * @param {number} offerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffersApi
     */
    public getOfferDraftPDF(workspaceId: number, offerId: number, options?: RawAxiosRequestConfig) {
        return OffersApiFp(this.configuration).getOfferDraftPDF(workspaceId, offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get an offer participant
     * @param {number} workspaceId 
     * @param {number} offerId 
     * @param {number} participantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffersApi
     */
    public getOfferParticipant(workspaceId: number, offerId: number, participantId: number, options?: RawAxiosRequestConfig) {
        return OffersApiFp(this.configuration).getOfferParticipant(workspaceId, offerId, participantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get offer participants
     * @param {number} workspaceId 
     * @param {number} offerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffersApi
     */
    public getOfferParticipants(workspaceId: number, offerId: number, options?: RawAxiosRequestConfig) {
        return OffersApiFp(this.configuration).getOfferParticipants(workspaceId, offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the offers that have been created by the `sellerAgent` on behalf of `buyers`. If  the `role` type of the user is `buyer`, only the offers that have been shared with the `buyer` will be visible. 
     * @summary get offers
     * @param {number} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffersApi
     */
    public getOffers(workspaceId: number, options?: RawAxiosRequestConfig) {
        return OffersApiFp(this.configuration).getOffers(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Buyers are invited to the workspace so they can view the contract and other documents. 
     * @summary Invite buyers to the workspace.
     * @param {number} workspaceId 
     * @param {number} offerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffersApi
     */
    public inviteBuyers(workspaceId: number, offerId: number, options?: RawAxiosRequestConfig) {
        return OffersApiFp(this.configuration).inviteBuyers(workspaceId, offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create an offer
     * @param {number} workspaceId 
     * @param {Contract} contract Offer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffersApi
     */
    public postOffer(workspaceId: number, contract: Contract, options?: RawAxiosRequestConfig) {
        return OffersApiFp(this.configuration).postOffer(workspaceId, contract, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary add a participant
     * @param {number} workspaceId 
     * @param {number} offerId 
     * @param {Participant1} participant1 ParticipantDetails
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffersApi
     */
    public postOfferParticipant(workspaceId: number, offerId: number, participant1: Participant1, options?: RawAxiosRequestConfig) {
        return OffersApiFp(this.configuration).postOfferParticipant(workspaceId, offerId, participant1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Presenting an offer makes the offer visible to the seller.  The seller will be notified by email that the offer is ready for viewing in the workspace. 
     * @summary Present offer to seller
     * @param {number} workspaceId 
     * @param {number} offerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffersApi
     */
    public presentOfferToSeller(workspaceId: number, offerId: number, options?: RawAxiosRequestConfig) {
        return OffersApiFp(this.configuration).presentOfferToSeller(workspaceId, offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update offer
     * @param {number} workspaceId 
     * @param {number} offerId 
     * @param {Contract} contract Offer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffersApi
     */
    public putOffer(workspaceId: number, offerId: number, contract: Contract, options?: RawAxiosRequestConfig) {
        return OffersApiFp(this.configuration).putOffer(workspaceId, offerId, contract, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update a participant
     * @param {number} workspaceId 
     * @param {number} offerId 
     * @param {number} participantId 
     * @param {Participant1} participant1 Offer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffersApi
     */
    public putOfferParticipant(workspaceId: number, offerId: number, participantId: number, participant1: Participant1, options?: RawAxiosRequestConfig) {
        return OffersApiFp(this.configuration).putOfferParticipant(workspaceId, offerId, participantId, participant1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Signing occurs is two stages. The first stage is the buyer signing the offer. The signed contract  is saved to the workspace until the second time `send-for-signing` is called, at which point it is sent to the sellers. The offer contract can only be sent for signing once the contract disclosures  have been completed by the solicitor. 
     * @summary Send the offer for signing
     * @param {number} workspaceId 
     * @param {number} offerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffersApi
     */
    public sendForSigning(workspaceId: number, offerId: number, options?: RawAxiosRequestConfig) {
        return OffersApiFp(this.configuration).sendForSigning(workspaceId, offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Starts the offer workflow which manages the process of negotiating and accepting an offer. This includes: - Inviting buyers to view the contract - Allowing buyers to propose changes to the contract - Managing contract versions and amendments - Tracking offer status through the negotiation process - Facilitating final contract acceptance 
     * @summary starts the offer workflow.
     * @param {number} workspaceId 
     * @param {number} offerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffersApi
     */
    public startOfferWorkflow(workspaceId: number, offerId: number, options?: RawAxiosRequestConfig) {
        return OffersApiFp(this.configuration).startOfferWorkflow(workspaceId, offerId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ParticipantsApi - axios parameter creator
 * @export
 */
export const ParticipantsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary remove a participant from the contract
         * @param {number} workspaceId 
         * @param {number} participantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteParticipant: async (workspaceId: number, participantId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('deleteParticipant', 'workspaceId', workspaceId)
            // verify required parameter 'participantId' is not null or undefined
            assertParamExists('deleteParticipant', 'participantId', participantId)
            const localVarPath = `/workspaces/{workspaceId}/participants/{participantId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"participantId"}}`, encodeURIComponent(String(participantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a participantcontracts
         * @param {number} workspaceId 
         * @param {number} participantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParticipant: async (workspaceId: number, participantId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getParticipant', 'workspaceId', workspaceId)
            // verify required parameter 'participantId' is not null or undefined
            assertParamExists('getParticipant', 'participantId', participantId)
            const localVarPath = `/workspaces/{workspaceId}/participants/{participantId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"participantId"}}`, encodeURIComponent(String(participantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get participants
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParticipants: async (workspaceId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getParticipants', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/participants`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary add a participant
         * @param {number} workspaceId 
         * @param {Participant1} participant1 ParticipantDetails
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postParticipant: async (workspaceId: number, participant1: Participant1, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('postParticipant', 'workspaceId', workspaceId)
            // verify required parameter 'participant1' is not null or undefined
            assertParamExists('postParticipant', 'participant1', participant1)
            const localVarPath = `/workspaces/{workspaceId}/participants`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(participant1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update a participant
         * @param {number} workspaceId 
         * @param {number} participantId 
         * @param {Participant1} participant1 Offer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putParticipant: async (workspaceId: number, participantId: number, participant1: Participant1, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('putParticipant', 'workspaceId', workspaceId)
            // verify required parameter 'participantId' is not null or undefined
            assertParamExists('putParticipant', 'participantId', participantId)
            // verify required parameter 'participant1' is not null or undefined
            assertParamExists('putParticipant', 'participant1', participant1)
            const localVarPath = `/workspaces/{workspaceId}/participants/{participantId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"participantId"}}`, encodeURIComponent(String(participantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(participant1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ParticipantsApi - functional programming interface
 * @export
 */
export const ParticipantsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ParticipantsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary remove a participant from the contract
         * @param {number} workspaceId 
         * @param {number} participantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteParticipant(workspaceId: number, participantId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteParticipant(workspaceId, participantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParticipantsApi.deleteParticipant']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get a participantcontracts
         * @param {number} workspaceId 
         * @param {number} participantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParticipant(workspaceId: number, participantId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Participant1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParticipant(workspaceId, participantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParticipantsApi.getParticipant']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get participants
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParticipants(workspaceId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Participants>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParticipants(workspaceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParticipantsApi.getParticipants']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary add a participant
         * @param {number} workspaceId 
         * @param {Participant1} participant1 ParticipantDetails
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postParticipant(workspaceId: number, participant1: Participant1, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postParticipant(workspaceId, participant1, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParticipantsApi.postParticipant']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary update a participant
         * @param {number} workspaceId 
         * @param {number} participantId 
         * @param {Participant1} participant1 Offer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putParticipant(workspaceId: number, participantId: number, participant1: Participant1, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putParticipant(workspaceId, participantId, participant1, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParticipantsApi.putParticipant']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ParticipantsApi - factory interface
 * @export
 */
export const ParticipantsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ParticipantsApiFp(configuration)
    return {
        /**
         * 
         * @summary remove a participant from the contract
         * @param {number} workspaceId 
         * @param {number} participantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteParticipant(workspaceId: number, participantId: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteParticipant(workspaceId, participantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get a participantcontracts
         * @param {number} workspaceId 
         * @param {number} participantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParticipant(workspaceId: number, participantId: number, options?: RawAxiosRequestConfig): AxiosPromise<Participant1> {
            return localVarFp.getParticipant(workspaceId, participantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get participants
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParticipants(workspaceId: number, options?: RawAxiosRequestConfig): AxiosPromise<Participants> {
            return localVarFp.getParticipants(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary add a participant
         * @param {number} workspaceId 
         * @param {Participant1} participant1 ParticipantDetails
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postParticipant(workspaceId: number, participant1: Participant1, options?: RawAxiosRequestConfig): AxiosPromise<NewEntity> {
            return localVarFp.postParticipant(workspaceId, participant1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update a participant
         * @param {number} workspaceId 
         * @param {number} participantId 
         * @param {Participant1} participant1 Offer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putParticipant(workspaceId: number, participantId: number, participant1: Participant1, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.putParticipant(workspaceId, participantId, participant1, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ParticipantsApi - object-oriented interface
 * @export
 * @class ParticipantsApi
 * @extends {BaseAPI}
 */
export class ParticipantsApi extends BaseAPI {
    /**
     * 
     * @summary remove a participant from the contract
     * @param {number} workspaceId 
     * @param {number} participantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParticipantsApi
     */
    public deleteParticipant(workspaceId: number, participantId: number, options?: RawAxiosRequestConfig) {
        return ParticipantsApiFp(this.configuration).deleteParticipant(workspaceId, participantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get a participantcontracts
     * @param {number} workspaceId 
     * @param {number} participantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParticipantsApi
     */
    public getParticipant(workspaceId: number, participantId: number, options?: RawAxiosRequestConfig) {
        return ParticipantsApiFp(this.configuration).getParticipant(workspaceId, participantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get participants
     * @param {number} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParticipantsApi
     */
    public getParticipants(workspaceId: number, options?: RawAxiosRequestConfig) {
        return ParticipantsApiFp(this.configuration).getParticipants(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary add a participant
     * @param {number} workspaceId 
     * @param {Participant1} participant1 ParticipantDetails
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParticipantsApi
     */
    public postParticipant(workspaceId: number, participant1: Participant1, options?: RawAxiosRequestConfig) {
        return ParticipantsApiFp(this.configuration).postParticipant(workspaceId, participant1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update a participant
     * @param {number} workspaceId 
     * @param {number} participantId 
     * @param {Participant1} participant1 Offer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParticipantsApi
     */
    public putParticipant(workspaceId: number, participantId: number, participant1: Participant1, options?: RawAxiosRequestConfig) {
        return ParticipantsApiFp(this.configuration).putParticipant(workspaceId, participantId, participant1, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PropertyApi - axios parameter creator
 * @export
 */
export const PropertyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get property
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProperty: async (workspaceId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getProperty', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/property`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update a property
         * @param {number} workspaceId 
         * @param {PropertyNew} propertyNew PutPropertyRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putProperty: async (workspaceId: number, propertyNew: PropertyNew, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('putProperty', 'workspaceId', workspaceId)
            // verify required parameter 'propertyNew' is not null or undefined
            assertParamExists('putProperty', 'propertyNew', propertyNew)
            const localVarPath = `/workspaces/{workspaceId}/property`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(propertyNew, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PropertyApi - functional programming interface
 * @export
 */
export const PropertyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PropertyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get property
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProperty(workspaceId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Property>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProperty(workspaceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PropertyApi.getProperty']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary update a property
         * @param {number} workspaceId 
         * @param {PropertyNew} propertyNew PutPropertyRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putProperty(workspaceId: number, propertyNew: PropertyNew, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putProperty(workspaceId, propertyNew, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PropertyApi.putProperty']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PropertyApi - factory interface
 * @export
 */
export const PropertyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PropertyApiFp(configuration)
    return {
        /**
         * 
         * @summary get property
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProperty(workspaceId: number, options?: RawAxiosRequestConfig): AxiosPromise<Property> {
            return localVarFp.getProperty(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update a property
         * @param {number} workspaceId 
         * @param {PropertyNew} propertyNew PutPropertyRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putProperty(workspaceId: number, propertyNew: PropertyNew, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.putProperty(workspaceId, propertyNew, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PropertyApi - object-oriented interface
 * @export
 * @class PropertyApi
 * @extends {BaseAPI}
 */
export class PropertyApi extends BaseAPI {
    /**
     * 
     * @summary get property
     * @param {number} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyApi
     */
    public getProperty(workspaceId: number, options?: RawAxiosRequestConfig) {
        return PropertyApiFp(this.configuration).getProperty(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update a property
     * @param {number} workspaceId 
     * @param {PropertyNew} propertyNew PutPropertyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyApi
     */
    public putProperty(workspaceId: number, propertyNew: PropertyNew, options?: RawAxiosRequestConfig) {
        return PropertyApiFp(this.configuration).putProperty(workspaceId, propertyNew, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ReportsApi - axios parameter creator
 * @export
 */
export const ReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Dashboard summary of workspaces 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboard: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reports/dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsApi - functional programming interface
 * @export
 */
export const ReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Dashboard summary of workspaces 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDashboard(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Dashboard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDashboard(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsApi.getDashboard']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ReportsApi - factory interface
 * @export
 */
export const ReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsApiFp(configuration)
    return {
        /**
         * 
         * @summary Dashboard summary of workspaces 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboard(options?: RawAxiosRequestConfig): AxiosPromise<Dashboard> {
            return localVarFp.getDashboard(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportsApi - object-oriented interface
 * @export
 * @class ReportsApi
 * @extends {BaseAPI}
 */
export class ReportsApi extends BaseAPI {
    /**
     * 
     * @summary Dashboard summary of workspaces 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public getDashboard(options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).getDashboard(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * StatusApi - axios parameter creator
 * @export
 */
export const StatusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the status of the service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatus: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatusApi - functional programming interface
 * @export
 */
export const StatusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StatusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get the status of the service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatus(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStatus(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StatusApi.getStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * StatusApi - factory interface
 * @export
 */
export const StatusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatusApiFp(configuration)
    return {
        /**
         * 
         * @summary Get the status of the service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatus(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.getStatus(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StatusApi - object-oriented interface
 * @export
 * @class StatusApi
 * @extends {BaseAPI}
 */
export class StatusApi extends BaseAPI {
    /**
     * 
     * @summary Get the status of the service
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusApi
     */
    public getStatus(options?: RawAxiosRequestConfig) {
        return StatusApiFp(this.configuration).getStatus(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TeamApi - axios parameter creator
 * @export
 */
export const TeamApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary complete the on-boarding process
         * @param {CompleteOnBoardingRequest1} [completeOnBoardingRequest1] completeOnBoarding
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeOnBoarding: async (completeOnBoardingRequest1?: CompleteOnBoardingRequest1, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/team/on-boarding/complete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(completeOnBoardingRequest1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Licencee details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicencee: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/team/licencee`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get referral partners
         * @summary get referral partners
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralPartners: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/team/referral-partners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get the setup intent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSetupIntent: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/team/on-boarding/setup-intent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves information about the authenticated user\'s team including name, branding settings, and contact details. Requires authentication. 
         * @summary Get team information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeam: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/team`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get the trust account banking details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrustAccount: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/team/trust-account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * add a referral partner
         * @summary add a referral partner
         * @param {ReferralPartnersItemsInner} [referralPartnersItemsInner] referral partner\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReferralPartner: async (referralPartnersItemsInner?: ReferralPartnersItemsInner, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/team/referral-partners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(referralPartnersItemsInner, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update the account holder\'s licencee details
         * @param {GetAgentAppointmentLicencee200Response} [getAgentAppointmentLicencee200Response] licencee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putLicencee: async (getAgentAppointmentLicencee200Response?: GetAgentAppointmentLicencee200Response, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/team/licencee`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getAgentAppointmentLicencee200Response, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates team information including name, branding colors, website URL, and contact details. Requires admin privileges within the team. 
         * @summary Update team information
         * @param {Team | null} [team] Updated team details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTeam: async (team?: Team | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/team`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(team, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload a new logo image for the team. Supports common image formats  (PNG, JPEG, etc). The image will be resized and optimized automatically. Requires admin privileges. 
         * @summary Upload team logo image
         * @param {File} body The logo image file to upload (max 5MB)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTeamLogo: async (body: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('putTeamLogo', 'body', body)
            const localVarPath = `/team/logo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'image/*';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update the trust account banking details
         * @param {GetTrustAccount200Response} [getTrustAccount200Response] trust account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTrustAccount: async (getTrustAccount200Response?: GetTrustAccount200Response, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/team/trust-account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getTrustAccount200Response, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary start the on-boarding process
         * @param {OnBoarding} [onBoarding] onBoardingRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startOnBoarding: async (onBoarding?: OnBoarding, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/team/on-boarding/start`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(onBoarding, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TeamApi - functional programming interface
 * @export
 */
export const TeamApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TeamApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary complete the on-boarding process
         * @param {CompleteOnBoardingRequest1} [completeOnBoardingRequest1] completeOnBoarding
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completeOnBoarding(completeOnBoardingRequest1?: CompleteOnBoardingRequest1, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completeOnBoarding(completeOnBoardingRequest1, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TeamApi.completeOnBoarding']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Licencee details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLicencee(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLicenceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLicencee(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TeamApi.getLicencee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * get referral partners
         * @summary get referral partners
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReferralPartners(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferralPartners>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReferralPartners(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TeamApi.getReferralPartners']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get the setup intent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSetupIntent(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SetupIntent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSetupIntent(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TeamApi.getSetupIntent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves information about the authenticated user\'s team including name, branding settings, and contact details. Requires authentication. 
         * @summary Get team information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeam(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Team>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeam(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TeamApi.getTeam']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get the trust account banking details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTrustAccount(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTrustAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTrustAccount(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TeamApi.getTrustAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * add a referral partner
         * @summary add a referral partner
         * @param {ReferralPartnersItemsInner} [referralPartnersItemsInner] referral partner\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postReferralPartner(referralPartnersItemsInner?: ReferralPartnersItemsInner, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postReferralPartner(referralPartnersItemsInner, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TeamApi.postReferralPartner']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary update the account holder\'s licencee details
         * @param {GetAgentAppointmentLicencee200Response} [getAgentAppointmentLicencee200Response] licencee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putLicencee(getAgentAppointmentLicencee200Response?: GetAgentAppointmentLicencee200Response, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putLicencee(getAgentAppointmentLicencee200Response, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TeamApi.putLicencee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates team information including name, branding colors, website URL, and contact details. Requires admin privileges within the team. 
         * @summary Update team information
         * @param {Team | null} [team] Updated team details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putTeam(team?: Team | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putTeam(team, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TeamApi.putTeam']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Upload a new logo image for the team. Supports common image formats  (PNG, JPEG, etc). The image will be resized and optimized automatically. Requires admin privileges. 
         * @summary Upload team logo image
         * @param {File} body The logo image file to upload (max 5MB)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putTeamLogo(body: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putTeamLogo(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TeamApi.putTeamLogo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary update the trust account banking details
         * @param {GetTrustAccount200Response} [getTrustAccount200Response] trust account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putTrustAccount(getTrustAccount200Response?: GetTrustAccount200Response, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putTrustAccount(getTrustAccount200Response, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TeamApi.putTrustAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary start the on-boarding process
         * @param {OnBoarding} [onBoarding] onBoardingRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startOnBoarding(onBoarding?: OnBoarding, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateOnBoardingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startOnBoarding(onBoarding, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TeamApi.startOnBoarding']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TeamApi - factory interface
 * @export
 */
export const TeamApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TeamApiFp(configuration)
    return {
        /**
         * 
         * @summary complete the on-boarding process
         * @param {CompleteOnBoardingRequest1} [completeOnBoardingRequest1] completeOnBoarding
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeOnBoarding(completeOnBoardingRequest1?: CompleteOnBoardingRequest1, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.completeOnBoarding(completeOnBoardingRequest1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Licencee details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicencee(options?: RawAxiosRequestConfig): AxiosPromise<GetLicenceResponse> {
            return localVarFp.getLicencee(options).then((request) => request(axios, basePath));
        },
        /**
         * get referral partners
         * @summary get referral partners
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralPartners(options?: RawAxiosRequestConfig): AxiosPromise<ReferralPartners> {
            return localVarFp.getReferralPartners(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get the setup intent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSetupIntent(options?: RawAxiosRequestConfig): AxiosPromise<SetupIntent> {
            return localVarFp.getSetupIntent(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves information about the authenticated user\'s team including name, branding settings, and contact details. Requires authentication. 
         * @summary Get team information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeam(options?: RawAxiosRequestConfig): AxiosPromise<Team> {
            return localVarFp.getTeam(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get the trust account banking details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrustAccount(options?: RawAxiosRequestConfig): AxiosPromise<GetTrustAccount200Response> {
            return localVarFp.getTrustAccount(options).then((request) => request(axios, basePath));
        },
        /**
         * add a referral partner
         * @summary add a referral partner
         * @param {ReferralPartnersItemsInner} [referralPartnersItemsInner] referral partner\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReferralPartner(referralPartnersItemsInner?: ReferralPartnersItemsInner, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.postReferralPartner(referralPartnersItemsInner, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update the account holder\'s licencee details
         * @param {GetAgentAppointmentLicencee200Response} [getAgentAppointmentLicencee200Response] licencee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putLicencee(getAgentAppointmentLicencee200Response?: GetAgentAppointmentLicencee200Response, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.putLicencee(getAgentAppointmentLicencee200Response, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates team information including name, branding colors, website URL, and contact details. Requires admin privileges within the team. 
         * @summary Update team information
         * @param {Team | null} [team] Updated team details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTeam(team?: Team | null, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.putTeam(team, options).then((request) => request(axios, basePath));
        },
        /**
         * Upload a new logo image for the team. Supports common image formats  (PNG, JPEG, etc). The image will be resized and optimized automatically. Requires admin privileges. 
         * @summary Upload team logo image
         * @param {File} body The logo image file to upload (max 5MB)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTeamLogo(body: File, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.putTeamLogo(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update the trust account banking details
         * @param {GetTrustAccount200Response} [getTrustAccount200Response] trust account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTrustAccount(getTrustAccount200Response?: GetTrustAccount200Response, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.putTrustAccount(getTrustAccount200Response, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary start the on-boarding process
         * @param {OnBoarding} [onBoarding] onBoardingRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startOnBoarding(onBoarding?: OnBoarding, options?: RawAxiosRequestConfig): AxiosPromise<CreateOnBoardingResponse> {
            return localVarFp.startOnBoarding(onBoarding, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TeamApi - object-oriented interface
 * @export
 * @class TeamApi
 * @extends {BaseAPI}
 */
export class TeamApi extends BaseAPI {
    /**
     * 
     * @summary complete the on-boarding process
     * @param {CompleteOnBoardingRequest1} [completeOnBoardingRequest1] completeOnBoarding
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public completeOnBoarding(completeOnBoardingRequest1?: CompleteOnBoardingRequest1, options?: RawAxiosRequestConfig) {
        return TeamApiFp(this.configuration).completeOnBoarding(completeOnBoardingRequest1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Licencee details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public getLicencee(options?: RawAxiosRequestConfig) {
        return TeamApiFp(this.configuration).getLicencee(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get referral partners
     * @summary get referral partners
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public getReferralPartners(options?: RawAxiosRequestConfig) {
        return TeamApiFp(this.configuration).getReferralPartners(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get the setup intent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public getSetupIntent(options?: RawAxiosRequestConfig) {
        return TeamApiFp(this.configuration).getSetupIntent(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves information about the authenticated user\'s team including name, branding settings, and contact details. Requires authentication. 
     * @summary Get team information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public getTeam(options?: RawAxiosRequestConfig) {
        return TeamApiFp(this.configuration).getTeam(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get the trust account banking details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public getTrustAccount(options?: RawAxiosRequestConfig) {
        return TeamApiFp(this.configuration).getTrustAccount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * add a referral partner
     * @summary add a referral partner
     * @param {ReferralPartnersItemsInner} [referralPartnersItemsInner] referral partner\&quot;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public postReferralPartner(referralPartnersItemsInner?: ReferralPartnersItemsInner, options?: RawAxiosRequestConfig) {
        return TeamApiFp(this.configuration).postReferralPartner(referralPartnersItemsInner, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update the account holder\'s licencee details
     * @param {GetAgentAppointmentLicencee200Response} [getAgentAppointmentLicencee200Response] licencee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public putLicencee(getAgentAppointmentLicencee200Response?: GetAgentAppointmentLicencee200Response, options?: RawAxiosRequestConfig) {
        return TeamApiFp(this.configuration).putLicencee(getAgentAppointmentLicencee200Response, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates team information including name, branding colors, website URL, and contact details. Requires admin privileges within the team. 
     * @summary Update team information
     * @param {Team | null} [team] Updated team details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public putTeam(team?: Team | null, options?: RawAxiosRequestConfig) {
        return TeamApiFp(this.configuration).putTeam(team, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upload a new logo image for the team. Supports common image formats  (PNG, JPEG, etc). The image will be resized and optimized automatically. Requires admin privileges. 
     * @summary Upload team logo image
     * @param {File} body The logo image file to upload (max 5MB)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public putTeamLogo(body: File, options?: RawAxiosRequestConfig) {
        return TeamApiFp(this.configuration).putTeamLogo(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update the trust account banking details
     * @param {GetTrustAccount200Response} [getTrustAccount200Response] trust account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public putTrustAccount(getTrustAccount200Response?: GetTrustAccount200Response, options?: RawAxiosRequestConfig) {
        return TeamApiFp(this.configuration).putTrustAccount(getTrustAccount200Response, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary start the on-boarding process
     * @param {OnBoarding} [onBoarding] onBoardingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public startOnBoarding(onBoarding?: OnBoarding, options?: RawAxiosRequestConfig) {
        return TeamApiFp(this.configuration).startOnBoarding(onBoarding, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TeamMembersApi - axios parameter creator
 * @export
 */
export const TeamMembersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Accepting a team inviation will adds you to the team. 
         * @summary accept a team inviation
         * @param {number} invitationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptTeamInvitation: async (invitationId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invitationId' is not null or undefined
            assertParamExists('acceptTeamInvitation', 'invitationId', invitationId)
            const localVarPath = `/team/members/invitations/{invitationId}`
                .replace(`{${"invitationId"}}`, encodeURIComponent(String(invitationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Accepting a team inviation will adds you to the team. 
         * @summary cancel a team inviation
         * @param {number} invitationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelInvitation: async (invitationId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invitationId' is not null or undefined
            assertParamExists('cancelInvitation', 'invitationId', invitationId)
            const localVarPath = `/team/members/invitations/{invitationId}`
                .replace(`{${"invitationId"}}`, encodeURIComponent(String(invitationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all the users in the same organisation as the current user. 
         * @summary get the invitations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamInvitations: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/team/members/invitations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all the users in the same organisation as the current user. 
         * @summary get the users in my organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/team/members`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Adds the user to the team. If the users does not yet have an account, an invitation  will be sent to the email address. 
         * @summary invite user to team
         * @param {string} emailAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteUserToTeam: async (emailAddress: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailAddress' is not null or undefined
            assertParamExists('inviteUserToTeam', 'emailAddress', emailAddress)
            const localVarPath = `/team/members/invitations/invite/{emailAddress}`
                .replace(`{${"emailAddress"}}`, encodeURIComponent(String(emailAddress)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Remove a user 
         * @summary remove a user from team
         * @param {string} sub 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUserFromTeam: async (sub: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sub' is not null or undefined
            assertParamExists('removeUserFromTeam', 'sub', sub)
            const localVarPath = `/team/members/{sub}`
                .replace(`{${"sub"}}`, encodeURIComponent(String(sub)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a user 
         * @summary update a user\'s details
         * @param {string} sub 
         * @param {PutMyDetailsRequest1} [putMyDetailsRequest1] user details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (sub: string, putMyDetailsRequest1?: PutMyDetailsRequest1, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sub' is not null or undefined
            assertParamExists('updateUser', 'sub', sub)
            const localVarPath = `/team/members/{sub}`
                .replace(`{${"sub"}}`, encodeURIComponent(String(sub)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putMyDetailsRequest1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TeamMembersApi - functional programming interface
 * @export
 */
export const TeamMembersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TeamMembersApiAxiosParamCreator(configuration)
    return {
        /**
         * Accepting a team inviation will adds you to the team. 
         * @summary accept a team inviation
         * @param {number} invitationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptTeamInvitation(invitationId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acceptTeamInvitation(invitationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TeamMembersApi.acceptTeamInvitation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Accepting a team inviation will adds you to the team. 
         * @summary cancel a team inviation
         * @param {number} invitationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelInvitation(invitationId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelInvitation(invitationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TeamMembersApi.cancelInvitation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns all the users in the same organisation as the current user. 
         * @summary get the invitations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamInvitations(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTeamInvitationsResposne>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamInvitations(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TeamMembersApi.getTeamInvitations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns all the users in the same organisation as the current user. 
         * @summary get the users in my organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUsersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TeamMembersApi.getUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Adds the user to the team. If the users does not yet have an account, an invitation  will be sent to the email address. 
         * @summary invite user to team
         * @param {string} emailAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inviteUserToTeam(emailAddress: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inviteUserToTeam(emailAddress, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TeamMembersApi.inviteUserToTeam']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Remove a user 
         * @summary remove a user from team
         * @param {string} sub 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeUserFromTeam(sub: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeUserFromTeam(sub, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TeamMembersApi.removeUserFromTeam']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update a user 
         * @summary update a user\'s details
         * @param {string} sub 
         * @param {PutMyDetailsRequest1} [putMyDetailsRequest1] user details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(sub: string, putMyDetailsRequest1?: PutMyDetailsRequest1, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(sub, putMyDetailsRequest1, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TeamMembersApi.updateUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TeamMembersApi - factory interface
 * @export
 */
export const TeamMembersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TeamMembersApiFp(configuration)
    return {
        /**
         * Accepting a team inviation will adds you to the team. 
         * @summary accept a team inviation
         * @param {number} invitationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptTeamInvitation(invitationId: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.acceptTeamInvitation(invitationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Accepting a team inviation will adds you to the team. 
         * @summary cancel a team inviation
         * @param {number} invitationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelInvitation(invitationId: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.cancelInvitation(invitationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all the users in the same organisation as the current user. 
         * @summary get the invitations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamInvitations(options?: RawAxiosRequestConfig): AxiosPromise<GetTeamInvitationsResposne> {
            return localVarFp.getTeamInvitations(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all the users in the same organisation as the current user. 
         * @summary get the users in my organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(options?: RawAxiosRequestConfig): AxiosPromise<GetUsersResponse> {
            return localVarFp.getUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * Adds the user to the team. If the users does not yet have an account, an invitation  will be sent to the email address. 
         * @summary invite user to team
         * @param {string} emailAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteUserToTeam(emailAddress: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.inviteUserToTeam(emailAddress, options).then((request) => request(axios, basePath));
        },
        /**
         * Remove a user 
         * @summary remove a user from team
         * @param {string} sub 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUserFromTeam(sub: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.removeUserFromTeam(sub, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a user 
         * @summary update a user\'s details
         * @param {string} sub 
         * @param {PutMyDetailsRequest1} [putMyDetailsRequest1] user details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(sub: string, putMyDetailsRequest1?: PutMyDetailsRequest1, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateUser(sub, putMyDetailsRequest1, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TeamMembersApi - object-oriented interface
 * @export
 * @class TeamMembersApi
 * @extends {BaseAPI}
 */
export class TeamMembersApi extends BaseAPI {
    /**
     * Accepting a team inviation will adds you to the team. 
     * @summary accept a team inviation
     * @param {number} invitationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamMembersApi
     */
    public acceptTeamInvitation(invitationId: number, options?: RawAxiosRequestConfig) {
        return TeamMembersApiFp(this.configuration).acceptTeamInvitation(invitationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Accepting a team inviation will adds you to the team. 
     * @summary cancel a team inviation
     * @param {number} invitationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamMembersApi
     */
    public cancelInvitation(invitationId: number, options?: RawAxiosRequestConfig) {
        return TeamMembersApiFp(this.configuration).cancelInvitation(invitationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all the users in the same organisation as the current user. 
     * @summary get the invitations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamMembersApi
     */
    public getTeamInvitations(options?: RawAxiosRequestConfig) {
        return TeamMembersApiFp(this.configuration).getTeamInvitations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all the users in the same organisation as the current user. 
     * @summary get the users in my organisation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamMembersApi
     */
    public getUsers(options?: RawAxiosRequestConfig) {
        return TeamMembersApiFp(this.configuration).getUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Adds the user to the team. If the users does not yet have an account, an invitation  will be sent to the email address. 
     * @summary invite user to team
     * @param {string} emailAddress 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamMembersApi
     */
    public inviteUserToTeam(emailAddress: string, options?: RawAxiosRequestConfig) {
        return TeamMembersApiFp(this.configuration).inviteUserToTeam(emailAddress, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Remove a user 
     * @summary remove a user from team
     * @param {string} sub 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamMembersApi
     */
    public removeUserFromTeam(sub: string, options?: RawAxiosRequestConfig) {
        return TeamMembersApiFp(this.configuration).removeUserFromTeam(sub, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a user 
     * @summary update a user\'s details
     * @param {string} sub 
     * @param {PutMyDetailsRequest1} [putMyDetailsRequest1] user details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamMembersApi
     */
    public updateUser(sub: string, putMyDetailsRequest1?: PutMyDetailsRequest1, options?: RawAxiosRequestConfig) {
        return TeamMembersApiFp(this.configuration).updateUser(sub, putMyDetailsRequest1, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TitlesApi - axios parameter creator
 * @export
 */
export const TitlesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get title summary
         * @param {string} titleReference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTitleSummary: async (titleReference: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'titleReference' is not null or undefined
            assertParamExists('getTitleSummary', 'titleReference', titleReference)
            const localVarPath = `/titles/title-summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)

            if (titleReference !== undefined) {
                localVarQueryParameter['titleReference'] = titleReference;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary search for a title by lot and plan
         * @param {string} lotNumber The lot number
         * @param {string} planNumber The plan number
         * @param {SearchByLotAndPlanPlanTypeEnum} planType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchByLotAndPlan: async (lotNumber: string, planNumber: string, planType: SearchByLotAndPlanPlanTypeEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lotNumber' is not null or undefined
            assertParamExists('searchByLotAndPlan', 'lotNumber', lotNumber)
            // verify required parameter 'planNumber' is not null or undefined
            assertParamExists('searchByLotAndPlan', 'planNumber', planNumber)
            // verify required parameter 'planType' is not null or undefined
            assertParamExists('searchByLotAndPlan', 'planType', planType)
            const localVarPath = `/titles/search-by-lot-and-plan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)

            if (lotNumber !== undefined) {
                localVarQueryParameter['lot-number'] = lotNumber;
            }

            if (planNumber !== undefined) {
                localVarQueryParameter['plan-number'] = planNumber;
            }

            if (planType !== undefined) {
                localVarQueryParameter['plan-type'] = planType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary validate an address
         * @param {string} addressSearchTerm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateAddress: async (addressSearchTerm: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addressSearchTerm' is not null or undefined
            assertParamExists('validateAddress', 'addressSearchTerm', addressSearchTerm)
            const localVarPath = `/titles/validate-address`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)

            if (addressSearchTerm !== undefined) {
                localVarQueryParameter['addressSearchTerm'] = addressSearchTerm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TitlesApi - functional programming interface
 * @export
 */
export const TitlesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TitlesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get title summary
         * @param {string} titleReference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTitleSummary(titleReference: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TitleSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTitleSummary(titleReference, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TitlesApi.getTitleSummary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary search for a title by lot and plan
         * @param {string} lotNumber The lot number
         * @param {string} planNumber The plan number
         * @param {SearchByLotAndPlanPlanTypeEnum} planType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchByLotAndPlan(lotNumber: string, planNumber: string, planType: SearchByLotAndPlanPlanTypeEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TitleSearchByLotPlanResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchByLotAndPlan(lotNumber, planNumber, planType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TitlesApi.searchByLotAndPlan']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary validate an address
         * @param {string} addressSearchTerm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateAddress(addressSearchTerm: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidateAddress200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateAddress(addressSearchTerm, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TitlesApi.validateAddress']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TitlesApi - factory interface
 * @export
 */
export const TitlesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TitlesApiFp(configuration)
    return {
        /**
         * 
         * @summary get title summary
         * @param {string} titleReference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTitleSummary(titleReference: string, options?: RawAxiosRequestConfig): AxiosPromise<TitleSummary> {
            return localVarFp.getTitleSummary(titleReference, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary search for a title by lot and plan
         * @param {string} lotNumber The lot number
         * @param {string} planNumber The plan number
         * @param {SearchByLotAndPlanPlanTypeEnum} planType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchByLotAndPlan(lotNumber: string, planNumber: string, planType: SearchByLotAndPlanPlanTypeEnum, options?: RawAxiosRequestConfig): AxiosPromise<TitleSearchByLotPlanResult> {
            return localVarFp.searchByLotAndPlan(lotNumber, planNumber, planType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary validate an address
         * @param {string} addressSearchTerm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateAddress(addressSearchTerm: string, options?: RawAxiosRequestConfig): AxiosPromise<ValidateAddress200Response> {
            return localVarFp.validateAddress(addressSearchTerm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TitlesApi - object-oriented interface
 * @export
 * @class TitlesApi
 * @extends {BaseAPI}
 */
export class TitlesApi extends BaseAPI {
    /**
     * 
     * @summary get title summary
     * @param {string} titleReference 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TitlesApi
     */
    public getTitleSummary(titleReference: string, options?: RawAxiosRequestConfig) {
        return TitlesApiFp(this.configuration).getTitleSummary(titleReference, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary search for a title by lot and plan
     * @param {string} lotNumber The lot number
     * @param {string} planNumber The plan number
     * @param {SearchByLotAndPlanPlanTypeEnum} planType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TitlesApi
     */
    public searchByLotAndPlan(lotNumber: string, planNumber: string, planType: SearchByLotAndPlanPlanTypeEnum, options?: RawAxiosRequestConfig) {
        return TitlesApiFp(this.configuration).searchByLotAndPlan(lotNumber, planNumber, planType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary validate an address
     * @param {string} addressSearchTerm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TitlesApi
     */
    public validateAddress(addressSearchTerm: string, options?: RawAxiosRequestConfig) {
        return TitlesApiFp(this.configuration).validateAddress(addressSearchTerm, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const SearchByLotAndPlanPlanTypeEnum = {
    Bpa: 'BPA',
    Brp: 'BRP',
    Bup: 'BUP',
    Cp: 'CP',
    Grp: 'GRP',
    Gta: 'GTA',
    Gtp: 'GTP',
    Lb: 'LB',
    Mcp: 'MCP',
    Mpp: 'MPP',
    Msp: 'MSP',
    Rp: 'RP',
    Sba: 'SBA',
    Sbp: 'SBP',
    Sp: 'SP',
    Spa: 'SPA',
    Sps: 'SPS',
    Srp: 'SRP',
    Ssp: 'SSP',
    Ub: 'UB'
} as const;
export type SearchByLotAndPlanPlanTypeEnum = typeof SearchByLotAndPlanPlanTypeEnum[keyof typeof SearchByLotAndPlanPlanTypeEnum];


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary assert user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assertUser: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the current users details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyDetails: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/team/my-details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update the current users details
         * @param {PutMyDetailsRequest} [putMyDetailsRequest] details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMyDetails: async (putMyDetailsRequest?: PutMyDetailsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/team/my-details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putMyDetailsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary assert user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assertUser(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssertUser200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assertUser(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.assertUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the current users details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyDetails(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMyDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyDetails(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.getMyDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary update the current users details
         * @param {PutMyDetailsRequest} [putMyDetailsRequest] details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putMyDetails(putMyDetailsRequest?: PutMyDetailsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putMyDetails(putMyDetailsRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.putMyDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @summary assert user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assertUser(options?: RawAxiosRequestConfig): AxiosPromise<AssertUser200Response> {
            return localVarFp.assertUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the current users details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyDetails(options?: RawAxiosRequestConfig): AxiosPromise<GetMyDetailsResponse> {
            return localVarFp.getMyDetails(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update the current users details
         * @param {PutMyDetailsRequest} [putMyDetailsRequest] details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMyDetails(putMyDetailsRequest?: PutMyDetailsRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.putMyDetails(putMyDetailsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @summary assert user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public assertUser(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).assertUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the current users details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getMyDetails(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).getMyDetails(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update the current users details
     * @param {PutMyDetailsRequest} [putMyDetailsRequest] details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public putMyDetails(putMyDetailsRequest?: PutMyDetailsRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).putMyDetails(putMyDetailsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WebhooksApi - axios parameter creator
 * @export
 */
export const WebhooksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Envelope completed
         * @param {EnvelopeChangedRequest} envelopeChangedRequest the envelope update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        envelopeChanged: async (envelopeChangedRequest: EnvelopeChangedRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'envelopeChangedRequest' is not null or undefined
            assertParamExists('envelopeChanged', 'envelopeChangedRequest', envelopeChangedRequest)
            const localVarPath = `/webhooks/envelope`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Annature required
            await setApiKeyToObject(localVarHeaderParameter, "X-Annature-Signature", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(envelopeChangedRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebhooksApi - functional programming interface
 * @export
 */
export const WebhooksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebhooksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Envelope completed
         * @param {EnvelopeChangedRequest} envelopeChangedRequest the envelope update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async envelopeChanged(envelopeChangedRequest: EnvelopeChangedRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.envelopeChanged(envelopeChangedRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WebhooksApi.envelopeChanged']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WebhooksApi - factory interface
 * @export
 */
export const WebhooksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebhooksApiFp(configuration)
    return {
        /**
         * 
         * @summary Envelope completed
         * @param {EnvelopeChangedRequest} envelopeChangedRequest the envelope update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        envelopeChanged(envelopeChangedRequest: EnvelopeChangedRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.envelopeChanged(envelopeChangedRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WebhooksApi - object-oriented interface
 * @export
 * @class WebhooksApi
 * @extends {BaseAPI}
 */
export class WebhooksApi extends BaseAPI {
    /**
     * 
     * @summary Envelope completed
     * @param {EnvelopeChangedRequest} envelopeChangedRequest the envelope update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public envelopeChanged(envelopeChangedRequest: EnvelopeChangedRequest, options?: RawAxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).envelopeChanged(envelopeChangedRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WorkspacesApi - axios parameter creator
 * @export
 */
export const WorkspacesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary accept an invitation to a workspace
         * @param {string} invitationToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptInvitation: async (invitationToken: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invitationToken' is not null or undefined
            assertParamExists('acceptInvitation', 'invitationToken', invitationToken)
            const localVarPath = `/accept-invitation/{invitationToken}`
                .replace(`{${"invitationToken"}}`, encodeURIComponent(String(invitationToken)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Cancel a solicitor invitation. This cannot occur if the solicitor has already accepted the invitation. 
         * @summary Cancel a solicitor invitation
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSolicitorInvitation: async (workspaceId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('cancelSolicitorInvitation', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/cancel-solicitor-invitation`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the activity log for the workspace. The list of events will differ depending on the user\'s role in the workspace. 
         * @summary Get the acivity log
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityLog: async (workspaceId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getActivityLog', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/activity-log`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the invoice for the workspace. 
         * @summary Get the invoice for the workspace
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoice: async (workspaceId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getInvoice', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/invoice`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get title
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTitle: async (workspaceId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getTitle', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/title`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get workflow
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflow: async (workspaceId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getWorkflow', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/workflow`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get workspace
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspace: async (workspaceId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getWorkspace', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get workspaces
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaces: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/workspaces`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get workspaces2
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaces2: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/workspaces2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary invite a solicitor to represent the buyer
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {Invitation} invitation BuyerSolicitorInvitationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteBuyerSolicitor: async (workspaceId: number, offerId: number, invitation: Invitation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('inviteBuyerSolicitor', 'workspaceId', workspaceId)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('inviteBuyerSolicitor', 'offerId', offerId)
            // verify required parameter 'invitation' is not null or undefined
            assertParamExists('inviteBuyerSolicitor', 'invitation', invitation)
            const localVarPath = `/workspaces/{workspaceId}/offers/{offerId}/invite-buyer-solicitor`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invitation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create a new workspace
         * @param {WorkspaceDetails} workspaceDetails Contract
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWorkspace: async (workspaceDetails: WorkspaceDetails, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceDetails' is not null or undefined
            assertParamExists('postWorkspace', 'workspaceDetails', workspaceDetails)
            const localVarPath = `/workspaces`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workspaceDetails, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkspacesApi - functional programming interface
 * @export
 */
export const WorkspacesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkspacesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary accept an invitation to a workspace
         * @param {string} invitationToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptInvitation(invitationToken: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acceptInvitation(invitationToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkspacesApi.acceptInvitation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Cancel a solicitor invitation. This cannot occur if the solicitor has already accepted the invitation. 
         * @summary Cancel a solicitor invitation
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelSolicitorInvitation(workspaceId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelSolicitorInvitation(workspaceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkspacesApi.cancelSolicitorInvitation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns the activity log for the workspace. The list of events will differ depending on the user\'s role in the workspace. 
         * @summary Get the acivity log
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivityLog(workspaceId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLogResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivityLog(workspaceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkspacesApi.getActivityLog']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns the invoice for the workspace. 
         * @summary Get the invoice for the workspace
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvoice(workspaceId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvoice(workspaceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkspacesApi.getInvoice']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get title
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTitle(workspaceId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTitle200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTitle(workspaceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkspacesApi.getTitle']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get workflow
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkflow(workspaceId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Workflow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkflow(workspaceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkspacesApi.getWorkflow']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get workspace
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkspace(workspaceId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkspace(workspaceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkspacesApi.getWorkspace']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get workspaces
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkspaces(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Workspaces>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkspaces(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkspacesApi.getWorkspaces']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get workspaces2
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkspaces2(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Workspaces1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkspaces2(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkspacesApi.getWorkspaces2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary invite a solicitor to represent the buyer
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {Invitation} invitation BuyerSolicitorInvitationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inviteBuyerSolicitor(workspaceId: number, offerId: number, invitation: Invitation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inviteBuyerSolicitor(workspaceId, offerId, invitation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkspacesApi.inviteBuyerSolicitor']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary create a new workspace
         * @param {WorkspaceDetails} workspaceDetails Contract
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postWorkspace(workspaceDetails: WorkspaceDetails, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postWorkspace(workspaceDetails, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkspacesApi.postWorkspace']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WorkspacesApi - factory interface
 * @export
 */
export const WorkspacesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkspacesApiFp(configuration)
    return {
        /**
         * 
         * @summary accept an invitation to a workspace
         * @param {string} invitationToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptInvitation(invitationToken: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.acceptInvitation(invitationToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Cancel a solicitor invitation. This cannot occur if the solicitor has already accepted the invitation. 
         * @summary Cancel a solicitor invitation
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSolicitorInvitation(workspaceId: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.cancelSolicitorInvitation(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the activity log for the workspace. The list of events will differ depending on the user\'s role in the workspace. 
         * @summary Get the acivity log
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityLog(workspaceId: number, options?: RawAxiosRequestConfig): AxiosPromise<ActivityLogResponse> {
            return localVarFp.getActivityLog(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the invoice for the workspace. 
         * @summary Get the invoice for the workspace
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoice(workspaceId: number, options?: RawAxiosRequestConfig): AxiosPromise<Invoice> {
            return localVarFp.getInvoice(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get title
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTitle(workspaceId: number, options?: RawAxiosRequestConfig): AxiosPromise<GetTitle200Response> {
            return localVarFp.getTitle(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get workflow
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflow(workspaceId: number, options?: RawAxiosRequestConfig): AxiosPromise<Workflow> {
            return localVarFp.getWorkflow(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get workspace
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspace(workspaceId: number, options?: RawAxiosRequestConfig): AxiosPromise<WorkspaceSummary> {
            return localVarFp.getWorkspace(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get workspaces
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaces(options?: RawAxiosRequestConfig): AxiosPromise<Workspaces> {
            return localVarFp.getWorkspaces(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get workspaces2
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaces2(options?: RawAxiosRequestConfig): AxiosPromise<Workspaces1> {
            return localVarFp.getWorkspaces2(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary invite a solicitor to represent the buyer
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {Invitation} invitation BuyerSolicitorInvitationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteBuyerSolicitor(workspaceId: number, offerId: number, invitation: Invitation, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.inviteBuyerSolicitor(workspaceId, offerId, invitation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create a new workspace
         * @param {WorkspaceDetails} workspaceDetails Contract
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWorkspace(workspaceDetails: WorkspaceDetails, options?: RawAxiosRequestConfig): AxiosPromise<NewEntity> {
            return localVarFp.postWorkspace(workspaceDetails, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkspacesApi - object-oriented interface
 * @export
 * @class WorkspacesApi
 * @extends {BaseAPI}
 */
export class WorkspacesApi extends BaseAPI {
    /**
     * 
     * @summary accept an invitation to a workspace
     * @param {string} invitationToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public acceptInvitation(invitationToken: string, options?: RawAxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).acceptInvitation(invitationToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Cancel a solicitor invitation. This cannot occur if the solicitor has already accepted the invitation. 
     * @summary Cancel a solicitor invitation
     * @param {number} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public cancelSolicitorInvitation(workspaceId: number, options?: RawAxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).cancelSolicitorInvitation(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the activity log for the workspace. The list of events will differ depending on the user\'s role in the workspace. 
     * @summary Get the acivity log
     * @param {number} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public getActivityLog(workspaceId: number, options?: RawAxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).getActivityLog(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the invoice for the workspace. 
     * @summary Get the invoice for the workspace
     * @param {number} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public getInvoice(workspaceId: number, options?: RawAxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).getInvoice(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get title
     * @param {number} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public getTitle(workspaceId: number, options?: RawAxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).getTitle(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get workflow
     * @param {number} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public getWorkflow(workspaceId: number, options?: RawAxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).getWorkflow(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get workspace
     * @param {number} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public getWorkspace(workspaceId: number, options?: RawAxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).getWorkspace(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get workspaces
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public getWorkspaces(options?: RawAxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).getWorkspaces(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get workspaces2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public getWorkspaces2(options?: RawAxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).getWorkspaces2(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary invite a solicitor to represent the buyer
     * @param {number} workspaceId 
     * @param {number} offerId 
     * @param {Invitation} invitation BuyerSolicitorInvitationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public inviteBuyerSolicitor(workspaceId: number, offerId: number, invitation: Invitation, options?: RawAxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).inviteBuyerSolicitor(workspaceId, offerId, invitation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create a new workspace
     * @param {WorkspaceDetails} workspaceDetails Contract
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public postWorkspace(workspaceDetails: WorkspaceDetails, options?: RawAxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).postWorkspace(workspaceDetails, options).then((request) => request(this.axios, this.basePath));
    }
}



