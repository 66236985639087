import { FC } from "react"
import { Workflow } from "../../../landconnex-api-client"
import { Chip, ChipProps, useTheme } from "@mui/material"
import { convertCamelCase } from "../../../helpers/helpers"
export interface WorkflowStatusProps extends ChipProps {
  workflow?: Workflow
}

const WorkflowStatus: FC<WorkflowStatusProps> = ({
  workflow,
  ...restProps
}) => {
  const theme = useTheme()

  let backgroundColour = ""
  switch (workflow?.state) {
    case "agentAppointment":
      backgroundColour = "rgba(229, 249, 237)"
      break
    case "contractPreparation":
      backgroundColour = "rgb(239, 235, 247)"
      break
    case "acceptingOffers":
      backgroundColour = "rgb(232, 244, 253)"
      break

    default:
      break
  }
  return (
    <Chip
      label={convertCamelCase(workflow?.state || "")}
      size="medium"
      variant="filled"
      sx={{
        ...restProps.sx,
        color: theme.palette.getContrastText(theme.palette.primary.main),
        backgroundColor: backgroundColour,
        padding: "4px 4px 4px 4px",
      }}
    />
  )
}

export default WorkflowStatus
