import { Container, Typography, Box, Paper } from "@mui/material"
import { useParams } from "react-router-dom"
import { appConfig } from "../../constants"

const OpenHouse = () => {
  const params = useParams()
  const workspaceId = params["workspaceId"]
  const imageStore = appConfig.imageBucket
  return (
    <Container
      maxWidth="xl"
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: `url(${imageStore}/${workspaceId}.jpeg)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        position: "relative",
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          zIndex: 1,
        },
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: 3,
          width: "100%",
          maxWidth: 800,
          position: "relative",
          zIndex: 2,
        }}
      >
        <Typography variant="h4" gutterBottom>
          Welcome to Your Future Home
        </Typography>
        <Box mb={3}>
          <Typography variant="body1">
            <strong>Address:</strong> 123 Dream Street, Brisbane, QLD
          </Typography>
          <Typography variant="body1">
            <strong>Description:</strong> A charming 3-bedroom home featuring
            modern amenities, a spacious backyard, and located in a peaceful
            neighbourhood.
          </Typography>
        </Box>
        <Box mb={3} sx={{ textAlign: "center" }}>
          <Typography variant="body1" gutterBottom>
            Scan the QR code below to access the contract and learn more:
          </Typography>
          <img
            src="https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=https://example.com/contract"
            alt="QR Code"
          />
        </Box>
        <Box>
          <Typography variant="body1">
            Contact us at (07) 1234 5678 or email info@example.com for more
            information.
          </Typography>
        </Box>
      </Paper>
    </Container>
  )
}

export default OpenHouse
