import { FC } from "react"
import {
  WorkflowSubStateEnum,
  WorkspaceSummaryNewStateEnum,
} from "../landconnex-api-client"
import { Chip } from "@mui/material"

export interface StatusIndicatorProps {
  // state: WorkflowStateEnum
  subState: WorkflowSubStateEnum | WorkspaceSummaryNewStateEnum
}

const StatusIndicator: FC<StatusIndicatorProps> = ({ subState }) => {
  let subStateLabel: string = ""

  // switch (state) {
  //   case WorkflowStateEnum.AgentAppointment:
  //     stateLabel = "Agent Appointment"
  //     break
  //   case WorkflowStateEnum.ContractPreparation:
  //     stateLabel = "Contract Preparation"
  //     break
  //   case WorkflowStateEnum.AcceptingOffers:
  //     stateLabel = "Accepting Offers"
  //     break
  //   case WorkflowStateEnum.ContractSigned:
  //     stateLabel = "Contract Signed"
  //     break
  // }

  switch (subState) {
    case WorkflowSubStateEnum.AgentAppointmentPreparing:
    case WorkspaceSummaryNewStateEnum.AgentAppointmentPreparing:
      subStateLabel = "Preparing Appointment"
      break
    case WorkflowSubStateEnum.AgentAppointmentAwaitingSigning:
    case WorkspaceSummaryNewStateEnum.AgentAppointmentAwaitingSigning:
      subStateLabel = "Awaiting Appointment Signing"
      break
    case WorkflowSubStateEnum.AgentAppointmentSigned:
    case WorkspaceSummaryNewStateEnum.AgentAppointmentSigned:
      subStateLabel = "Agent Appointment Signed"
      break
    case WorkflowSubStateEnum.AgentAppointmentAwaitingSellerAdmission:
    case WorkspaceSummaryNewStateEnum.AgentAppointmentAwaitingSellerAdmission:
      subStateLabel = "Awaiting Seller Admission"
      break
    case WorkflowSubStateEnum.ContractPreparationAwaitingSellerSolicitorAdmission:
    case WorkspaceSummaryNewStateEnum.ContractPreparationAwaitingSellerSolicitorAdmission:
      subStateLabel = "Awaiting Seller Solicitor Admission"
      break
    case WorkflowSubStateEnum.ContractPreparationAwaitingSellerSolicitorSelection:
    case WorkspaceSummaryNewStateEnum.ContractPreparationAwaitingSellerSolicitorSelection:
      subStateLabel = "Awaiting Seller Solicitor Selection"
      break
    case WorkflowSubStateEnum.ContractPreparationPreparing:
    case WorkspaceSummaryNewStateEnum.ContractPreparationPreparing:
      subStateLabel = "Preparing Disclosures"
      break
    case WorkflowSubStateEnum.AcceptingOffers:
    case WorkspaceSummaryNewStateEnum.AcceptingOffers:
      subStateLabel = "Accepting Offers"
      break
    case WorkflowSubStateEnum.ContractSigned:
    case WorkspaceSummaryNewStateEnum.ContractSigned:
      subStateLabel = "Contract Signed"
      break
  }

  return (
    <Chip
      label={subStateLabel}
      variant="outlined"
      size="small"
      color="success"
    />
  )
}

export default StatusIndicator
