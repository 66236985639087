import { Typography, Breadcrumbs, Paper, Box, useTheme } from "@mui/material"

import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { Link, useParams } from "react-router-dom"
import {
  getWorkspace,
  selectWorkspace,
} from "../../app/slices/workspaces-slice"
import Grid from "@mui/material/Grid2"
import {
  getOffer,
  getOfferParticipants,
  selectOffer,
  selectOfferLoading,
  selectOfferParticipants,
} from "../../app/slices/offers-slice"
import { useEffect, useState } from "react"
import ContractStepperPage from "../contract-stepper/contract-stepper-page"
import { getParticipants } from "../../app/slices/participants-slice"
import { getDocuments } from "../../app/slices/documents-slice"
import SelectBuyerSolicitor from "../workspace/components/actions/select-buyer-solicitor"
import WorkspaceHeader from "../workspace/components/workspace-header"
import {
  IconBuildingBank,
  IconCalendar,
  IconCashBanknotePlus,
  IconChevronRight,
} from "@tabler/icons-react"
import MainCard from "../../components/cards/MainCard"
import { gridSpacing } from "../../constants"
import OfferSummaryCard from "./offer-summary-card"
import ParticipantListItem from "../../components/participant-list-item"
import { formatCurrency, formatHumanDate } from "../../helpers/helpers"
import { getProperty } from "../../app/slices/property-slice"

interface OfferFact {
  icon: React.ReactNode
  label: string
  value: string
}
const OfferPage = () => {
  const dispatch = useAppDispatch()
  const params = useParams()
  const offerId = Number(params.offerId)
  const workspaceId = Number(params.workspaceId)

  const theme = useTheme()
  const workspace = useAppSelector(selectWorkspace)
  const offer = useAppSelector(selectOffer)
  const offerLoading = useAppSelector(selectOfferLoading)
  const participants = useAppSelector(selectOfferParticipants)
  const [brandingPrimaryColor, setBrandingPrimaryColor] =
    useState<string>("#fff")
  const [brandingSecondaryColor, setBrandingSecondaryColor] =
    useState<string>("#fff")
  const [offerFacts, setOfferFacts] = useState<OfferFact[]>([])

  // useEffect(() => {
  //   if (!workspace) {
  //     dispatch(getWorkspace(workspaceId))
  //   }
  // }, [dispatch, workspace, workspaceId])
  useEffect(() => {

    if (!workspace || workspace.workspace?.id !== workspaceId) {
      dispatch(getWorkspace(workspaceId))
      dispatch(getParticipants(workspaceId))
      dispatch(getDocuments(workspaceId))
      dispatch(getProperty(workspaceId))
    }
    if (!offer || offer.id !== offerId) {
      dispatch(getOffer({ offerId: offerId, workspaceId: workspaceId }))
      dispatch(
        getOfferParticipants({ offerId: offerId, workspaceId: workspaceId }),
      )
    }
  }, [dispatch, offerId, workspaceId])

  useEffect(() => {
    if (
      workspace?.branding.find(
        branding => branding.companyType === "realEstateAgency",
      )
    ) {
      setBrandingPrimaryColor(
        workspace?.branding.find(
          branding => branding.companyType === "realEstateAgency",
        )?.primaryColor || "#fff",
      )
      setBrandingSecondaryColor(
        workspace?.branding.find(
          branding => branding.companyType === "realEstateAgency",
        )?.secondaryColor || "#fff",
      )
    } else {
      setBrandingPrimaryColor(theme.palette.primary.main)
      setBrandingSecondaryColor(theme.palette.secondary.main)
    }

    setOfferFacts([
      {
        icon: <IconCalendar />,
        label: "Settlement Date",
        value: offer?.settlementDate
          ? formatHumanDate(offer.settlementDate)
          : "",
      },
      {
        icon: <IconCashBanknotePlus />,
        label: "Deposit Initial",
        value: formatCurrency(offer?.depositInitial),
      },
      {
        icon: <IconBuildingBank />,
        label: "Financier",
        value: offer?.financier ? "Has finance" : "No finance",
      },
    ])
  }, [workspace])
  return (
    <Grid container spacing={2}>
      <Grid size={12}>
        <WorkspaceHeader />
      </Grid>

      <Grid size={12}>
        <Breadcrumbs separator={<IconChevronRight size={16} stroke={2} />}>
          <Link
            to={`/workspaces/${workspaceId}`}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Typography variant="caption">Workspace Overview</Typography>
          </Link>

          <Typography variant="caption" sx={{ fontWeight: 600 }}>
            Offer
          </Typography>
        </Breadcrumbs>
      </Grid>
      <Grid size={12}>
        <Grid container spacing={gridSpacing}>
          <Grid size={12}>
            <Paper
              sx={{
                p: 2,
                bgcolor: workspace?.branding.find(
                  branding => branding.companyType === "realEstateAgency",
                )?.secondaryColor,
              }}
            >
              <Grid>
                <Grid
                  container
                  direction="row"
                  spacing={1}
                  sx={{ alignItems: "center" }}
                >
                  {" "}
                  <Grid>
                    <Typography
                      variant="h2"
                      color={theme.palette.getContrastText(
                        brandingSecondaryColor,
                      )}
                    >
                      {formatCurrency(offer?.purchasePrice)}
                    </Typography>
                  </Grid>
                  {offerFacts.map(fact => {
                    return (
                      <>
                        <Grid>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              bgcolor: brandingPrimaryColor,
                              color:
                                theme.palette.getContrastText(
                                  brandingPrimaryColor,
                                ),
                              borderRadius: 1,
                              p: 0.5,
                            }}
                          >
                            {fact.icon}
                          </Box>
                        </Grid>
                        <Grid>
                          <Typography
                            variant="h6"
                            color={theme.palette.getContrastText(
                              brandingSecondaryColor,
                            )}
                          >
                            {fact.value}
                          </Typography>
                        </Grid>
                      </>
                    )
                  })}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid size={9}>
            {workspace?.workspace.role == "buyer" && <SelectBuyerSolicitor />}
            {offerLoading.offer == false && <ContractStepperPage />}
          </Grid>
          <Grid size={3}>
            <Grid container spacing={gridSpacing}>
              <Grid size={12}>
                <OfferSummaryCard />
              </Grid>
              <Grid size={12}>
                <MainCard title="Participants">
                  {participants.map(participant => (
                    <ParticipantListItem
                      key={participant.id}
                      participant={participant}
                    />
                  ))}
                </MainCard>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default OfferPage
