import { PayloadAction } from "@reduxjs/toolkit"
import {
  AddAnnexureDocumentTypeEnum,
  AgentAppointment,
  Annexure,
  BenefitToAgent,
  Fee,
  Licencee,
  Participant,
  Property,
  Validation,
} from "../../landconnex-api-client"

import dayjs from "dayjs"
import { createAppSlice } from "../../app/createAppSlice"
import { agentsApi } from "../../api"
import { appConfig } from "../../constants"
import { User } from "oidc-client-ts"
import axios from "axios"
import { Status } from "./status"
import { UpdateAnnexurePositionArgs } from "./contract-slice"

export enum AgentAppointmentStatus {
  initial,
  loading,
  ready,
  dirty,
  updated,
  pdfReadyToDownload,
  sentForSigning,
  error,
  agentAppointmentSigned,
}
export enum GeneratedPDFStatus {
  initial,
  loading,
  ready,
  stale,
}

interface AgentAppointmentState {
  annexureStatus: Status
  status: AgentAppointmentStatus
  pdfStatus: GeneratedPDFStatus
  property: Property
  agentAppointment: AgentAppointment | undefined
  fees: Array<Fee>
  benefits: Array<BenefitToAgent>
  clients: Array<Participant>
  licencee: Licencee
  licenceeAgent: Participant
  pdf?: string
  pdfName?: string
  step: string
  workspaceId: number
  validationErrors: Validation[]
  annexures: Array<Annexure>
}

export interface UpdateAgentAppointmentArgs {
  workspaceId: number
  agentAppointment: AgentAppointment
}
export interface AddAnnexureArgs {
  workspaceId: number
  documentName: string
  documentType: AddAnnexureDocumentTypeEnum
  file: File
}
const initialState: AgentAppointmentState = {
  status: AgentAppointmentStatus.initial,
  pdfStatus: GeneratedPDFStatus.initial,
  agentAppointment: undefined,
  fees: [],
  benefits: [],
  property: {},
  clients: [],
  licencee: {
    name: "",
    number: "",
    expiryDate: "",
  },
  licenceeAgent: {},
  step: "client",
  workspaceId: 0,
  validationErrors: [],
  annexures: [],
  annexureStatus: Status.initial,
}

export interface UpdateLicenceeArgs {
  licencee: Licencee
  workspaceId: number
}

export interface AddAgentAppointmentAnnexureResponse {
  workspaceId: number
  annexure: File
}

export interface DeleteAgentAppointmentAnnexureResponse {
  workspaceId: number
  annexureId: number
}
export const agentAppointmentSlice = createAppSlice({
  name: "agentAppointment",
  initialState,
  reducers: create => ({
    initialise: create.reducer((state, action: PayloadAction<number>) => {
      state.workspaceId = action.payload
      state.status = AgentAppointmentStatus.initial
    }),
    moveToStep: create.reducer((state, action: PayloadAction<string>) => {
      state.step = action.payload
    }),
    participantChanged: create.reducer(
      (state, action: PayloadAction<Participant>) => {
        const index = state.clients.findIndex(v => v.id == action.payload.id)
        console.log(state.clients)
        state.clients[index] = action.payload
        console.log(state.clients)
        state.status = AgentAppointmentStatus.dirty
      },
    ),
    getLicencee: create.asyncThunk<Licencee, number>(
      async (workspaceId: number) => {
        const response = await agentsApi.getAgentAppointmentLicencee(workspaceId)
        return response.data
      },
      {
        pending: state => {
          state.status = AgentAppointmentStatus.loading
        },
        fulfilled: (state, action) => { 
          state.licencee = action.payload
        },
      },
    ),
    licenceeChanged: create.reducer(
      (state, action: PayloadAction<Licencee>) => {
        state.licencee = action.payload
        state.status = AgentAppointmentStatus.dirty
      },
    ),
    propertyUpdated: create.reducer(
      (state, action: PayloadAction<Property>) => {
        state.property = action.payload
        state.status = AgentAppointmentStatus.dirty
      },
    ),
    licenceeAgentChanged: create.reducer(
      (state, action: PayloadAction<Participant>) => {
        state.licenceeAgent = action.payload
        state.status = AgentAppointmentStatus.dirty
      },
    ),
    uploadSignedAgentAppointment: create.asyncThunk<
      void,
      { workspaceId: number; signedAgentAppointment: File }
    >(
      async ({ workspaceId, signedAgentAppointment }) => {
        await agentsApi.putSignedAgentAppointment(
          workspaceId,
          signedAgentAppointment,
        )
      },
      {
        pending: state => {
          state.status = AgentAppointmentStatus.loading
        },
        fulfilled: state => {
          state.status = AgentAppointmentStatus.agentAppointmentSigned
        },
      },
    ),
    getAgentAppointmentPdf: create.asyncThunk<string, number>(
      async (workspaceId: number) => {
        const oidcStorage = sessionStorage.getItem(
          `oidc.user:${appConfig.authority}:${appConfig.clientId}`,
        )
        const user = User.fromStorageString(oidcStorage!)

        const response = await axios.get(
          `${appConfig.workspacesUri}/workspaces/${workspaceId}/agent-appointment/pdf`,
          {
            headers: { Authorization: `Bearer ${user.access_token}` },
            responseType: "blob",
          },
        )

        const url = window.URL.createObjectURL(response.data)

        return url
      },
      {
        fulfilled: (state, action) => {
          state.pdf = action.payload
          state.pdfStatus = GeneratedPDFStatus.ready
        },
      },
    ),
    updateLicencee: create.asyncThunk<Licencee, UpdateLicenceeArgs>(
      async ({workspaceId, licencee}) => {
        await agentsApi.putAgentAppointmentLicencee(workspaceId, licencee)
        return licencee
      },
      {
        pending: state => {
          state.status = AgentAppointmentStatus.loading
        },
        fulfilled: (state, action) => {
          state.licencee = action.payload
          state.status = AgentAppointmentStatus.ready
        },
      },
    ),
    updateAgentAppointmentAnnexurePosition: create.asyncThunk<void, UpdateAnnexurePositionArgs>(
      async ({ workspaceId, annexureId, position }) => {
        await agentsApi.updateAgentAppointmentAnnexurePosition(
          workspaceId,
          annexureId,
          position,
        )
      },
    ),
    addAgentAppointmentAnnexure: create.asyncThunk<AddAgentAppointmentAnnexureResponse, AddAnnexureArgs>(
      async ({ workspaceId, documentName, documentType, file }) => {
        await agentsApi.addAgentAppointmentAnnexure(
          workspaceId,
          documentName,
          documentType,
          file,
        )
        return {
          workspaceId,
          annexure: file,
        }
      },
      {
        pending: state => {
          state.annexureStatus = Status.loading
        },
        fulfilled: (state, action) => {
          state.annexureStatus = Status.ready
          state.annexures.push({
            id: 0,
            document: {
              name: action.payload.annexure.name,
              documentType: AddAnnexureDocumentTypeEnum.Annexure,
            },
            order: state.annexures.length,
          })
        },
      },
    ),
    deleteAgentAppointmentAnnexure: create.asyncThunk<
      DeleteAgentAppointmentAnnexureResponse,
      { workspaceId: number; annexureId: number }
    >(
      async ({ workspaceId, annexureId }) => {
        await agentsApi.deleteAgentAppointmentAnnexure(workspaceId, annexureId)
        return {
          workspaceId,
          annexureId,
        }
      },
      {
        pending: state => {
          state.annexureStatus = Status.loading
        },

        fulfilled: (state, action) => {
          state.annexureStatus = Status.ready
          const index = state.annexures.findIndex(x => x.id == action.payload.annexureId)
          state.annexures.splice(index, 1)
        },
      },
    ),

    getAgentAppointmentAnnexures: create.asyncThunk<Annexure[], number>(
      async (workspaceId: number) => {
        const response =
          await agentsApi.getAgentAppointmentAnnexures(workspaceId)
        return response.data.items!
      },
      {
        pending: state => {
          state.annexureStatus = Status.loading
        },
        fulfilled: (state, action) => {
          state.annexures = action.payload
          state.annexureStatus = Status.ready
        },
      },
    ),
    getAgentAppointment: create.asyncThunk<AgentAppointment, number>(
      async (workspaceId: number) => {
        const response = await agentsApi.getAgentAppointment(workspaceId)
        return response.data.agentAppointment
      },
      {
        pending: state => {
          state.status = AgentAppointmentStatus.loading
        },
        fulfilled: (state, action) => {
          state.agentAppointment = action.payload

          if (state.agentAppointment.appointmentTermStart) {
            const start = dayjs(Date.now())
            const end = start.add(90, "day")
            state.agentAppointment.appointmentTermStart = start.toISOString()
            state.agentAppointment.appointmentTermEnd = end.toISOString()
          }
          state.status = AgentAppointmentStatus.ready
        },
        rejected: state => {
          state.status = AgentAppointmentStatus.error
        },
      },
    ),
    updateAgentAppointment: create.asyncThunk<
      AgentAppointment,
      UpdateAgentAppointmentArgs
    >(
      async ({ workspaceId, agentAppointment }) => {
        // agentAppointment.commissionAmount = Number(
        //   agentAppointment.commissionAmount!.toString().replace(/[^\d]/g, ""),
        // )

        await agentsApi.putAgentAppointment(workspaceId, agentAppointment)
        return agentAppointment
      },
      {
        fulfilled: (state, action) => {
          state.agentAppointment = action.payload
          state.status = AgentAppointmentStatus.updated
        },
      },
    ),
    getFees: create.asyncThunk<Array<Fee>, number>(
      async (workspaceId: number) => {
        const response = await agentsApi.getFees(workspaceId)
        return response.data.items!
      },
      {
        fulfilled: (state, action) => {
          state.fees = action.payload
        },
      },
    ),
    addFee: create.asyncThunk<Fee, { workspaceId: number; fee: Fee }>(
      async ({ workspaceId, fee }) => {
        const response = await agentsApi.postFee(workspaceId, fee)
        fee.id = response.data.id!
        return fee
      },
      {
        fulfilled: (state, action) => {
          state.fees.push(action.payload)
        },
      },
    ),
    updateFee: create.asyncThunk<void, { workspaceId: number; fee: Fee }>(
      async ({ workspaceId, fee }) => {
        await agentsApi.updateFee(workspaceId, fee.id, fee)
      },
    ),
    deleteFee: create.asyncThunk<
      number,
      { workspaceId: number; feeId: number }
    >(
      async ({ workspaceId, feeId }) => {
        await agentsApi.deleteFee(workspaceId, feeId)
        return feeId
      },
      {
        fulfilled: (state, action) => {
          const index = state.fees.findIndex(x => x.id == action.payload)
          state.fees.splice(index, 1)
        },
      },
    ),

    getBenefitToAgents: create.asyncThunk<Array<BenefitToAgent>, number>(
      async (workspaceId: number) => {
        const response = await agentsApi.getBenefitToAgents(workspaceId)
        return response.data.items!
      },
      {
        fulfilled: (state, action) => {
          state.benefits = action.payload
        },
      },
    ),
    addBenefitToAgent: create.asyncThunk<
      BenefitToAgent,
      { workspaceId: number; benefitToAgent: BenefitToAgent }
    >(
      async ({ workspaceId, benefitToAgent }) => {
        const response = await agentsApi.postBenefitToAgent(
          workspaceId,
          benefitToAgent,
        )
        benefitToAgent.id = response.data.id!
        return benefitToAgent
      },
      {
        fulfilled: (state, action) => {
          state.benefits.push(action.payload)
        },
      },
    ),
    updateBenefitToAgent: create.asyncThunk<
      void,
      { workspaceId: number; benefitToAgent: BenefitToAgent }
    >(async ({ workspaceId, benefitToAgent }) => {
      await agentsApi.updateBenefitToAgent(
        workspaceId,
        benefitToAgent.id,
        benefitToAgent,
      )
    }),
    deleteBenefitToAgent: create.asyncThunk<
      number,
      { workspaceId: number; benefitToAgentId: number }
    >(
      async ({ workspaceId, benefitToAgentId }) => {
        await agentsApi.deleteBenefitToAgent(workspaceId, benefitToAgentId)
        return benefitToAgentId
      },
      {
        fulfilled: (state, action) => {
          const index = state.benefits.findIndex(x => x.id == action.payload)
          state.benefits.splice(index, 1)
        },
      },
    ),
    sendAgentAppointmentForSigning: create.asyncThunk<void, number>(
      async (workspaceId: number) => {
        await agentsApi.sendAgentAppointmentForSigning(workspaceId)
      },
      {
        pending: state => {
          state.status = AgentAppointmentStatus.loading
        },
        fulfilled: state => {
          state.status = AgentAppointmentStatus.sentForSigning
        },
        rejected: (state, action) => {
          if (
            axios.isAxiosError(action.error) &&
            action.error.response?.status === 400
          ) {
            const errors = action.error.response.data as Validation[]
            state.validationErrors = errors
          }
          state.status = AgentAppointmentStatus.error
        },
      },
    ),
    validateAgentAppointment: create.asyncThunk<Validation[], number>(
      async (workspaceId: number) => {
        const response =
          await agentsApi.getAgentAppointmentValidation(workspaceId)
        return response.data.items!
      },
      {
        fulfilled: (state, action) => {
          if (action.payload.length > 0) {
            state.status = AgentAppointmentStatus.error
            state.validationErrors = action.payload
          } else {
            state.status = AgentAppointmentStatus.ready
            state.validationErrors = []
          }
        },
      },
    ),
  }),
  selectors: {
    selectAgentAppointment: agentAppointment =>
      agentAppointment.agentAppointment,
    selectAgentAppointmentPdf: agentAppointment => agentAppointment.pdf,
    selectAgentAppointmentPdfStatus: agentAppointment =>
      agentAppointment.pdfStatus,
    selectAgentAppointmentStatus: agentAppointment => agentAppointment.status,
    selectFees: agentAppointment => agentAppointment.fees,
    selectBenefits: agentAppointment => agentAppointment.benefits,
    selectClients: agentAppointment => agentAppointment.clients,
    selectLicencee: agentAppointment => agentAppointment.licencee,
    selectLicenceeAgent: agentAppointment => agentAppointment.licenceeAgent,
    selectProperty: agentAppointment => agentAppointment.property,
    selectStep: agentAppointment => agentAppointment.step,
    selectValidationErrors: agentAppointment =>
      agentAppointment.validationErrors,
    selectAgentAppointmentAnnexures: agentAppointment =>
      agentAppointment.annexures,
    selectAnnexureStatus: agentAppointment => agentAppointment.annexureStatus,
  },
})

export const {
  moveToStep,
  initialise,
  participantChanged,
  licenceeChanged,
  licenceeAgentChanged,
  propertyUpdated,
  sendAgentAppointmentForSigning,
  getAgentAppointmentPdf,
  getAgentAppointment,
  getLicencee,
  updateLicencee,
  updateAgentAppointment,
  addFee,
  updateFee,
  getFees,
  deleteFee,
  addBenefitToAgent,
  updateBenefitToAgent,
  getBenefitToAgents,
  deleteBenefitToAgent,
  uploadSignedAgentAppointment,
  validateAgentAppointment,
  addAgentAppointmentAnnexure,
  deleteAgentAppointmentAnnexure,
  getAgentAppointmentAnnexures,
  updateAgentAppointmentAnnexurePosition,
} = agentAppointmentSlice.actions

// export default agentAppointmentSlice.reducer;
export const {
  selectAgentAppointment,
  selectAgentAppointmentPdf,
  selectAgentAppointmentPdfStatus,
  selectAgentAppointmentStatus,
  selectFees,
  selectBenefits,
  selectClients,
  selectLicencee,
  selectLicenceeAgent,
  selectProperty,
  selectStep,
  selectValidationErrors,
  selectAgentAppointmentAnnexures,
  selectAnnexureStatus,
} = agentAppointmentSlice.selectors
