import { FC, useEffect } from "react"

import { useNavigate, useParams } from "react-router-dom"
import {
  acceptTeamInvitation,
  selectAcceptTeamInvitationStatus,
  selectError,
} from "../../app/slices/team-members-slice"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import RedirectToLogin from "../../components/redirect-to-login"
import { withAuthenticationRequired } from "react-oidc-context"
import { Status } from "../../app/slices/status"
import Page from "../../components/page"

const AcceptTeamInvitation: FC = () => {
  const dispatch = useAppDispatch()
  const { invitationId } = useParams()
  const error = useAppSelector(selectError)
  const status = useAppSelector(selectAcceptTeamInvitationStatus)

  const navigate = useNavigate()
  useEffect(() => {
    dispatch(acceptTeamInvitation(Number(invitationId)))
  }, [dispatch, invitationId])

  useEffect(() => {
    if (status === Status.ready) {
      navigate("/settings")
    }
  }, [status, navigate])
  let content = <div>Accepting team invitation...</div>

  switch (status) {
    case Status.loading:
    case Status.initial:
      content = <div>Accepting team invitation...</div>
      break
    case Status.ready:
      content = <div>Team invitation accepted</div>
      break
    case Status.error:
      content = <div>Error accepting team invitation: {error}</div>
      break
  }

  return <Page title="Accept Team Invitation">{content}</Page>
}

export default withAuthenticationRequired(AcceptTeamInvitation, {
  OnRedirecting: () => <RedirectToLogin />,
  signinRedirectArgs: { redirect_uri: window.location.href },
})
