// material-ui

import { Link } from "@mui/material"
import { Link as RouterLink } from "react-router-dom"
import Logo from "../../components/logo"
// project imports

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
  return (
    <Link component={RouterLink} to={"/dashboard"} aria-label="theme-logo">
      <Logo />
    </Link>
  )
}

export default LogoSection
