import { FC, Fragment } from "react"
import { Grid, Typography, useTheme, TextField } from "@mui/material"

export interface SellerSignatureProps {}

const SellerSignature: FC<SellerSignatureProps> = () => {
  const theme = useTheme()

  return (
    <Fragment>
      <Grid item>
        <form>
          <Grid container spacing={1}>
            {/* Participant name form */}
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" color={theme.palette.text.secondary}>
                Seller
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={"Signature Of Seller"}
                size="small"
                fullWidth
                autoComplete="false"
                variant="filled"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={"Signature Of Seller"}
                size="small"
                fullWidth
                autoComplete="false"
                variant="filled"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label={"Name Of Seller"}
                size="small"
                fullWidth
                autoComplete="false"
                variant="filled"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={"Name Of Seller"}
                size="small"
                fullWidth
                autoComplete="false"
                variant="filled"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label={"Date"}
                size="small"
                fullWidth
                autoComplete="false"
                variant="filled"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={"Date"}
                size="small"
                fullWidth
                autoComplete="false"
                variant="filled"
              />
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Fragment>
  )
}

export default SellerSignature
