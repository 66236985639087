import {
  Box,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  debounce,
  useTheme,
} from "@mui/material"
import { FC, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import {
  Participant,
  ParticipantParticipantTypeEnum,
} from "../../../landconnex-api-client"
import { OnParticipantChanged } from "../../../components/events"
import AddressForm from "../../../components/address-form"
import ConfirmationDialog from "../../../components/confirmation-dialog"
import ContactDetails from "../../../components/contact-details"
import ParticipantNameForm from "../../../components/participant-name-form"
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined"
import { putOfferParticipant } from "../../../app/slices/offers-slice"
import { useAppDispatch } from "../../../app/hooks"
import { useParams } from "react-router-dom"
import { zodResolver } from "@hookform/resolvers/zod"
import React from "react"
import { participantSchema } from "../../../utils/validation-schemas"

export interface BuyerDetailProps {
  buyer: Participant
  index: number
  onChanged: OnParticipantChanged
  onDelete: OnParticipantChanged
}

const BuyerDetail: FC<BuyerDetailProps> = ({
  buyer,
  onChanged,
  onDelete,
  index,
}) => {
  const [confirmationDialogState, setConfirmationDialogState] =
    useState(Array<number>())
  const theme = useTheme()

  const dispatch = useAppDispatch()
  const params = useParams()
  const offerId = parseInt(params.offerId!)
  const workspaceId = parseInt(params.workspaceId!)

  function handleDeleteBuyer(participant: Participant): void {
    setConfirmationDialogState([participant.id!])
  }
  const schema = participantSchema

  const participantForm = useForm<Participant>({
    resolver: zodResolver(schema),
    mode: "onChange",
    defaultValues: buyer,
  })

  const {
    control,
    watch,
    formState: { errors, isDirty, defaultValues },
    setValue,
    reset,
  } = participantForm

  const formValues = watch()

  const saveParticipant = (data: Participant) => {
    try {
      dispatch(
        putOfferParticipant({
          workspaceId: workspaceId,
          offerId: offerId,
          participant: data,
        }),
      )
      reset(data, { keepErrors: true })
    } catch (serverError) {
      console.error("Autosave failed", serverError)
    }
  }

  // Debounced autosave function
  const debouncedAutoSave = React.useCallback(
    debounce(async (data: Participant) => {
      saveParticipant(data)
    }, 1000),
    [dispatch, workspaceId],
  )
  useEffect(() => {
    if (isDirty) {
      debouncedAutoSave(formValues)
    }
  }, [formValues, isDirty, debouncedAutoSave])
  return (
    <Box key={buyer.id}>
      {/* <pre>{JSON.stringify(buyer, null, 2)}</pre> */}
      <Grid item xs={12} sm={12}>
        <ConfirmationDialog
          open={confirmationDialogState.includes(buyer.id!)}
          message={`Remove ${
            defaultValues?.organisationName ||
            (defaultValues?.firstName && defaultValues?.lastName
              ? `${defaultValues?.firstName} ${defaultValues?.lastName}`
              : `Buyer ${index + 1}`)
          } from the contract?`}
          title="Remove buyer?"
          onClose={result => {
            if (result) {
              onDelete(buyer)
            }
            setConfirmationDialogState([])
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">
            {`${
              defaultValues?.organisationName ||
              (defaultValues?.firstName && defaultValues?.lastName
                ? `${defaultValues?.firstName} ${defaultValues?.lastName}`
                : `Buyer ${index + 1}`)
            }`}
          </Typography>
          <IconButton
            color="primary"
            onClick={() => handleDeleteBuyer(buyer)}
            sx={{
              "&:hover": {
                backgroundColor: "rgba(0,0,0,0)",
                "& .MuiTypography-root": {
                  color: "#555",
                },
                "& .MuiSvgIcon-root": {
                  color: "#555",
                },
              },
            }}
          >
            <DeleteForeverOutlinedIcon
              fontSize="large"
              sx={{ color: "#8F1219" }}
            />
          </IconButton>
        </Box>
        <Divider />
      </Grid>
      {/* Select buyer type */}
      <Grid item xs={8} sm={5} md={4} marginTop={2}>
        <FormControl fullWidth>
          <InputLabel id="buyerTypeLabel">Buyer Type</InputLabel>
          <Select
            labelId="buyerTypeLabel"
            id="buyerType"
            label="Buyer Type"
            value={
              buyer.participantType ?? ParticipantParticipantTypeEnum.Individual
            }
            onChange={e => {
              const updated = { ...buyer }
              const p = Object.values(ParticipantParticipantTypeEnum).find(
                value => value === e.target.value,
              ) as ParticipantParticipantTypeEnum
              updated.participantType = p
              onChanged(updated)
            }}
          >
            {Object.values(ParticipantParticipantTypeEnum).map(p => (
              <MenuItem key={p} value={p}>
                {p}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {/* buyer name form */}
      <Grid item xs={12} sm={12} marginTop={2}>
        <Typography
          variant="h6"
          color={theme.palette.text.secondary}
          marginBottom={1}
        >
          Name
        </Typography>
      </Grid>
      <ParticipantNameForm
        control={control}
        captureGst={true}
        participantType={buyer.participantType ?? "individual"}
      />
      <Grid container spacing={2}>
        {/* Contact details */}
        <Grid item xs={12} sm={12}>
          <Typography variant="h6" color={theme.palette.text.secondary}>
            Contact Details
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <ContactDetails control={control} errors={errors} />
        </Grid>

        {/* Address form */}
        <Grid item xs={12} sm={12}>
          <Typography variant="h6" color={theme.palette.text.secondary}>
            Address
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <AddressForm
            control={control}
            address={buyer}
            setValue={setValue}
            errors={errors}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default BuyerDetail
