import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import React, { useEffect } from "react"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Radio from "@mui/material/Radio"
import { useAppSelector, useAppDispatch } from "../../../app/hooks"
import { putContract, selectContract } from "../../../app/slices/contract-slice"
import { selectWorkspace } from "../../../app/slices/workspaces-slice"
import { Contract } from "../../../landconnex-api-client"
import { zodResolver } from "@hookform/resolvers/zod"
import { Card, CardContent, CardHeader, debounce } from "@mui/material"
import { Controller, useForm } from "react-hook-form"
import { contractSchema } from "../../../utils/validation-schemas"

const PoolSafety: React.FC = () => {
  const dispatch = useAppDispatch()

  const contract = useAppSelector(selectContract)
  const workspace = useAppSelector(selectWorkspace)
  const disableField = ["buyer", "buyerSolicitor"].includes(
    workspace!.workspace!.role!,
  )
  const schema = contractSchema

  const contractFormMethods = useForm<Contract>({
    defaultValues: contract,
    resolver: zodResolver(schema),
    mode: "onChange",
  })

  const {
    control,
    watch,
    reset,
    formState: { isDirty },
  } = contractFormMethods

  const formValues = watch()
  const hasPool = watch("hasPool")

  const debouncedSave = React.useCallback(
    debounce(async (data: Contract) => {
      dispatch(
        putContract({
          workspaceId: workspace?.workspace.id!,
          contract: data,
        }),
      )
      reset(data, { keepErrors: true })
    }, 1000),
    [dispatch, workspace!.workspace!.id],
  )

  useEffect(() => {
    if (isDirty) {
      debouncedSave(formValues)
    }
  }, [formValues, isDirty])

  return (
    <Card sx={{ border: "none" }}>
      <CardHeader title="Pool Safety" iinheritlayout="true" />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item sm={12} lg={12}>
            <Typography variant="body1">
              Is there a pool on the Land or on adjacent land used in
              association with the Land?
            </Typography>
          </Grid>
          <Grid item sm={12} lg={12}>
            <Controller
              name="hasPool"
              control={control}
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  defaultValue={field.value ? "true" : "false"}
                  onChange={({ target }) =>
                    field.onChange(target.value === "true")
                  }
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Yes"
                    disabled={disableField}
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="No"
                    disabled={disableField}
                  />
                </RadioGroup>
              )}
            />
          </Grid>

          {hasPool ? (
            <React.Fragment>
              <Grid item sm={12} lg={12}>
                <Typography variant="body1">
                  Is there a Pool Compliance Certificate for the pool at the
                  time of contract?
                </Typography>
              </Grid>
              <Grid item sm={12} lg={12}>
                <Controller
                  name="hasPoolCertificate"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup
                      {...field}
                      defaultValue={field.value ? "true" : "false"}
                      onChange={({ target }) =>
                        field.onChange(target.value === "true")
                      }
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="Yes"
                        disabled={disableField}
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label="No"
                        disabled={disableField}
                      />
                    </RadioGroup>
                  )}
                />
              </Grid>
            </React.Fragment>
          ) : null}
        </Grid>
      </CardContent>
    </Card>
  )
}

export default PoolSafety
