import { FC, Fragment } from "react"
import {
  Grid,
  Typography,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Link,
} from "@mui/material"
import { Notes } from "../../features/agent-appointment/components/form-wrapper"

export interface BuildingAndStructureProps {}

const BuildingAndStructure: FC<BuildingAndStructureProps> = () => {
  const notes = [
    {
      title: "Warning to Buyer",
      items: [
        "The seller does not warrant the structural soundness of the buildings or improvements on the property, or that the buildings on the property have the required approval, or that there is no pest infestation affecting the property. You should engage a licensed building inspector or an appropriately qualified engineer, builder or pest inspector to inspect the property and provide a report and also undertake searches to determine whether buildings and improvements on the property have the required approvals.",
      ],
    },
  ]

  return (
    <Fragment>
      <Grid item>
        <Notes notes={notes} />
        <form>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} my={2}>
              <Typography variant="h6" mb={4}>
                Swimming pool
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  borderBottom: "1px solid silver",
                  paddingBottom: 3,
                  gap: 2,
                }}
              >
                <Typography variant="body1">
                  There is a relevant pool for the property
                </Typography>

                <RadioGroup row>
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                  <FormControlLabel
                    value="idk"
                    control={<Radio />}
                    label="I Don't Know"
                  />
                </RadioGroup>

                <Typography variant="body1">
                  If a community titles scheme or a BUGTA scheme – a shared pool
                  is located in the scheme.
                </Typography>

                <RadioGroup row>
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                  <FormControlLabel
                    value="idk"
                    control={<Radio />}
                    label="I Don't Know"
                  />
                </RadioGroup>

                <Typography variant="body1">
                  Pool compliance certificate is given
                </Typography>

                <RadioGroup row>
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                  <FormControlLabel
                    value="idk"
                    control={<Radio />}
                    label="I Don't Know"
                  />
                </RadioGroup>

                <Typography variant="body1">
                  Notice of no pool safety certificate is given
                </Typography>

                <RadioGroup row>
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                  <FormControlLabel
                    value="idk"
                    control={<Radio />}
                    label="I Don't Know"
                  />
                </RadioGroup>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} my={2}>
              <Typography variant="h6" mb={4}>
                Unlicensed building work under owner builder permit
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  borderBottom: "1px solid silver",
                  paddingBottom: 3,
                  gap: 2,
                }}
              >
                <Typography variant="body1">
                  Building work was carried out on the property under an owner
                  builder permit in the last 6 years.
                </Typography>

                <RadioGroup row>
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                  <FormControlLabel
                    value="idk"
                    control={<Radio />}
                    label="I Don't Know"
                  />
                </RadioGroup>

                <Typography variant="caption">
                  A notice under section 47 of the Queensland Building and
                  Construction Commission Act 1991 must be given by the seller
                  and you may be required to sign the notice and return it to
                  the seller prior to signing the contract.
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} my={2}>
              <Typography variant="h6" mb={4}>
                Notices and orders
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  borderBottom: "1px solid silver",
                  paddingBottom: 3,
                  gap: 2,
                }}
              >
                <Typography variant="body1">
                  There is an unsatisfied show cause notice or enforcement
                  notice under the Building Act 1975, section 246AG, 247 or 248
                  or under the Planning Act 2016, section 167 or 168.
                </Typography>

                <RadioGroup row>
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                  <FormControlLabel
                    value="idk"
                    control={<Radio />}
                    label="I Don't Know"
                  />
                </RadioGroup>

                <Typography variant="body1">
                  The seller has been given a notice or order, that remains in
                  effect, from a local, State or Commonwealth government, a
                  court or tribunal, or other competent authority, requiring
                  work to be done or money to be spent in relation to the
                  property.
                </Typography>

                <RadioGroup row>
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                  <FormControlLabel
                    value="idk"
                    control={<Radio />}
                    label="I Don't Know"
                  />
                </RadioGroup>

                <Typography variant="caption">
                  If Yes, a copy of the notice or order must be given by the
                  seller.
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} my={2}>
              <Typography variant="h6" mb={4}>
                Building Energy Efficiency Certificate
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  borderBottom: "1px solid silver",
                  paddingBottom: 3,
                  gap: 2,
                }}
              >
                <Typography variant="body1">
                  If the property is a commercial office building of more than
                  1,000m2, a Building Energy Efficiency Certificate is available
                  on the Building Energy Efficiency Register.
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} my={2}>
              <Typography variant="h6" mb={4}>
                Asbestos
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  borderBottom: "1px solid silver",
                  paddingBottom: 3,
                  gap: 2,
                }}
              >
                <Typography variant="body1">
                  The seller does not warrant whether asbestos is present within
                  buildings or improvements on the property. Buildings or
                  improvements built before 1990 may contain asbestos. Asbestos
                  containing materials (ACM) may have been used up until the
                  early 2000s. Asbestos or ACM may become dangerous when
                  damaged, disturbed, or deteriorating. Information about
                  asbestos is available at the Queensland Government Asbestos
                  Website (
                  <Link
                    href="https://www.asbestos.qld.gov.au/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    asbestos.qld.gov.au
                  </Link>
                  ) including common locations of asbestos and other practical
                  guidance for homeowners.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Fragment>
  )
}

export default BuildingAndStructure
