import React, { useEffect, useCallback } from "react"
import {
  Grid,
  Typography,
  useTheme,
  debounce,
  Paper,
} from "@mui/material"
import { Participant } from "../../../landconnex-api-client"
import { useForm } from "react-hook-form"
import ParticipantNameForm from "../../../components/participant-name-form"
import ContactDetails from "../../../components/contact-details"
import AddressForm from "../../../components/address-form"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { selectWorkspace } from "../../../app/slices/workspaces-slice"
import {
  putParticipant,
  selectParticipants,
} from "../../../app/slices/participants-slice"
import {
  getMyDetails,
  selectMyDetails,
} from "../../../app/slices/my-details-slice"
import { zodResolver } from "@hookform/resolvers/zod"
import { participantSchema } from "../../../utils/validation-schemas"
import { gridSpacing } from "../../../constants"

const SellerSolicitor: React.FC = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()

  const workspace = useAppSelector(selectWorkspace)
  const participants = useAppSelector(selectParticipants)
  const sellerSolicitor = participants.find(p => p.role === "sellerSolicitor")

  const myDetails = useAppSelector(selectMyDetails)

  useEffect(() => {
    if (!myDetails) {
      dispatch(getMyDetails())
    }
  }, [dispatch, myDetails])

  const disableField = ["buyer", "buyerSolicitor"].includes(
    workspace?.workspace?.role || "",
  )

  const detailsForm = useForm<Participant>({
    resolver: zodResolver(participantSchema),
    mode: "onChange",
    defaultValues: sellerSolicitor || {},
  })

  const {
    control,
    watch,
    formState: { errors, isDirty },
    setValue,
    reset,
  } = detailsForm

  const formValues = watch()

  const handleOnParticipantChanged = (data: Participant) => {
    try {
      dispatch(
        putParticipant({
          workspaceId: workspace?.workspace?.id!,
          participant: data,
        }),
      )
      reset(data, { keepErrors: true })
    } catch (serverError) {
      console.error("Autosave failed", serverError)
    }
  }

  const debouncedAutoSave = useCallback(
    debounce(async (data: Participant) => {
      handleOnParticipantChanged(data)
    }, 1000),
    [dispatch, workspace?.workspace?.id],
  )

  useEffect(() => {
    if (isDirty) {
      debouncedAutoSave(formValues)
    }
  }, [formValues, isDirty, debouncedAutoSave])

  // const handleCopySolicitorDetails = () => {
  //   if (!sellerSolicitor || !myDetails) {
  //     console.warn("No seller solicitor or myDetails data available")
  //     return
  //   }

  //   const updatedSellerSolicitor = produce(sellerSolicitor, draft => {
  //     draft.firstName = myDetails.firstName
  //     draft.lastName = myDetails.lastName
  //     draft.email = myDetails.email
  //     draft.phone = myDetails.phone
  //     draft.streetAddress1 = myDetails.streetAddress1
  //     draft.streetAddress2 = myDetails.streetAddress2
  //     draft.locality = myDetails.locality
  //     draft.stateOrTerritory = myDetails.stateOrTerritory
  //     draft.postCode = myDetails.postCode
  //     draft.country = myDetails.country
  //     draft.abn = myDetails.abn
  //     draft.acn = myDetails.acn
  //   })

  //   console.log("Updating Seller Solicitor:", updatedSellerSolicitor)

  //   dispatch(
  //     putParticipant({
  //       workspaceId: workspace?.workspace?.id!,
  //       participant: updatedSellerSolicitor,
  //     }),
  //   )

  //   // Sync form state
  //   Object.entries(updatedSellerSolicitor).forEach(([key, value]) => {
  //     setValue(key as keyof Participant, value)
  //   })
  // }

  return (
    <Paper sx={{ p: 2 }}>
      <Grid container spacing={gridSpacing}>
        {/* <Grid item xs={12}>
            <Box sx={{ maxWidth: 130 }}>
              <Button
                color="success"
                onClick={handleCopySolicitorDetails}
                startIcon={<AddOutlinedIcon />}
              >
                My details
              </Button>
            </Box>
          </Grid> */}
        <Grid item xs={12} sm={12}>
          <Typography variant="h5" color={theme.palette.text.secondary}>
            Name
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12}>
          <ParticipantNameForm
            control={control}
            participantType="organisation"
            disabled={disableField}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography variant="h5" color={theme.palette.text.secondary}>
            Contact Details
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <ContactDetails
            control={control}
            disabled={disableField}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography variant="h5" color={theme.palette.text.secondary}>
            Address
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} spacing={gridSpacing}>
          <AddressForm
            address={sellerSolicitor!}
            setValue={setValue}
            control={control}
            disabled={disableField}
            errors={errors}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default SellerSolicitor
