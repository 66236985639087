import Grid from "@mui/material/Grid2"
import dayjs from "dayjs"
import React, { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { Contract } from "../../../landconnex-api-client"
import { useForm } from "react-hook-form"
import FormInputDatePicker from "../../../components/form-input-date-picker"
import { putContract, selectContract } from "../../../app/slices/contract-slice"
import { selectWorkspace } from "../../../app/slices/workspaces-slice"
import { produce } from "immer"
import { zodResolver } from "@hookform/resolvers/zod"
import { debounce, Paper } from "@mui/material"
import { contractSchema } from "../../../utils/validation-schemas"
import { gridSpacing } from "../../../constants"

const Settlement: React.FC = () => {
  const dispatch = useAppDispatch()
  const contract = useAppSelector(selectContract)
  const workspace = useAppSelector(selectWorkspace)
  const schema = contractSchema

  const contractFormMethods = useForm<Contract>({
    defaultValues: contract,
    resolver: zodResolver(schema),
    mode: "onChange",
  })

  const {
    control,
    watch,
    reset,
    formState: { errors, isDirty },
  } = contractFormMethods

  const formValues = watch()

  const debouncedSave = React.useCallback(
    debounce(async (data: Contract) => {
      let updatedContract = data

      if (data.settlementDate) {
        const settlementDateObj = dayjs(data.settlementDate)
        if (settlementDateObj.isValid()) {
          updatedContract = produce(data, draft => {
            draft.settlementDate = settlementDateObj.toISOString()
          })
        } else {
          console.error("Invalid settlementDate:", data.settlementDate)
        }
      } else {
        updatedContract = produce(data, draft => {
          draft.settlementDate = "0001-01-01T00:00:00Z"
        })
      }

      updatedContract = produce(updatedContract, draft => {
        draft.depositHolder = "test"
      })

      dispatch(
        putContract({
          workspaceId: workspace!.workspace!.id,
          contract: updatedContract,
        }),
      )

      reset(data, { keepErrors: true })
    }, 1000),
    [dispatch, workspace!.workspace!.id],
  )

  useEffect(() => {
    if (isDirty) {
      debouncedSave(formValues)
    }
  }, [formValues, isDirty, debouncedSave])

  return (
    <Paper sx={{ p: gridSpacing }}>
      <Grid component="form" container spacing={gridSpacing}>
        <Grid size={12}>
          <Grid container spacing={2}>
            <Grid size={12}>
              <FormInputDatePicker
                control={control}
                name="settlementDate"
                label="Settlement Date"
                error={errors.settlementDate}
                required
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default Settlement
