import React, { useEffect } from "react"
import FormInputText from "../../../components/form-input-text"
import { debounce, Paper } from "@mui/material"
import Grid from "@mui/material/Grid2"
import { useForm } from "react-hook-form"
import { Contract } from "../../../landconnex-api-client"
import { useAppSelector, useAppDispatch } from "../../../app/hooks"
import { putContract, selectContract } from "../../../app/slices/contract-slice"
import { selectWorkspace } from "../../../app/slices/workspaces-slice"
import { zodResolver } from "@hookform/resolvers/zod"
import { contractSchema } from "../../../utils/validation-schemas"
import { useParams } from "react-router-dom"
import { putOffer, selectOffer } from "../../../app/slices/offers-slice"
import { gridSpacing } from "../../../constants"

const SpecialConditions: React.FC = ({}) => {
  const dispatch = useAppDispatch()
  const params = useParams()
  let contract
  const workspace = useAppSelector(selectWorkspace)
  const schema = contractSchema
  const workspaceID = Number(params.workspaceId)
  const offerID = Number(params.offerId)


  if (offerID) {
    contract = useAppSelector(selectOffer)
  } else {
    contract = useAppSelector(selectContract)
  }

  const contractFormMethods = useForm<Contract>({
    defaultValues: contract,
    resolver: zodResolver(schema),
    mode: "onChange",
  })



  const {
    control,
    watch,
    reset,
    formState: { isDirty },
  } = contractFormMethods

  const formValues = watch()

  const debouncedSave = React.useCallback(
    debounce(async (data: Contract) => {
      if (offerID) {
        dispatch(
          putOffer({
            workspaceId: workspaceID,
            offer: data,
          }),
        )
      } else {
        dispatch(
          putContract({
            workspaceId: workspace!.workspace!.id!,
            contract: data,
          }),
        )
      }

      reset(data, { keepErrors: true })
    }, 1000),
    [dispatch, workspace!.workspace!.id],
  )

  useEffect(() => {
    if (isDirty) {
      debouncedSave(formValues)
    }
  }, [formValues, isDirty])

  return (
    <Paper sx={{ p: gridSpacing }}>
      <Grid container spacing={gridSpacing}>
        <Grid size={12}>
            <FormInputText
              control={control}
              name="sellerSpecialConditions"
              label="Seller's Special Conditions"
              disabled={workspace?.workspace.role === "buyer" || workspace?.workspace.role === "buyerSolicitor"}
              multiline
              minRows={4}
            />
          </Grid>
          <Grid size={12}>
            <FormInputText
              control={control}
              name="buyerSpecialConditions"
              label="Buyer's Special Conditions"
              multiline
              minRows={4}
            />
          </Grid>
      </Grid>
    </Paper>
  )
}

export default SpecialConditions
