import React, { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { useParams } from "react-router-dom"

import ClientDetails from "./components/client-details"
import LicenceeDetails from "./components/licencee-details"
import Property from "./components/property"
import Appointment from "./components/appointment"
import PropertySale from "./components/property-sale"
import Commission from "./components/commission"
import Authorisation from "./components/authorisation"
import {
  AgentAppointmentStatus,
  getAgentAppointment,
  selectAgentAppointment,
  selectAgentAppointmentStatus,
} from "../../app/slices/agent-appointment-slice"
import { getParticipants } from "../../app/slices/participants-slice"
import { getProperty } from "../../app/slices/property-slice"
import { Box, Tab, Tabs, Typography } from "@mui/material"
import MainCard from "../../components/cards/MainCard"
import Annexures from "./components/annexures"
import Generate from "./components/generate"
import ScheduleForm from "./components/schedule-form"
import prepareAgentAppointment from "/prepare-agent-appointment.svg"
import signagentappointment from "/sign-agent-appointment.svg"
import reviewagentappointment from "/review-agent-appointment.svg"
import Guide from "./components/guide"
import { TabDefinition } from "../../components/tabs"
import CustomTabPanel from "../../components/tabs/custom-tab-panel"

const AgentAppointmentPage: React.FC = () => {
  const params = useParams()
  const workspaceId = parseInt(params["workspaceId"] as string)
  const dispatch = useAppDispatch()
  const agentAppointment = useAppSelector(selectAgentAppointment)
  const agentAppointmentStatus = useAppSelector(selectAgentAppointmentStatus)

  const [tab, setTab] = useState<number>(0)

  useEffect(() => {
    if (
      agentAppointment === undefined &&
      agentAppointmentStatus !== AgentAppointmentStatus.loading
    ) {
      dispatch(getAgentAppointment(workspaceId))
      dispatch(getParticipants(workspaceId))
      dispatch(getProperty(workspaceId))
    } else if (
      agentAppointment?.workspaceId !== workspaceId &&
      agentAppointmentStatus !== AgentAppointmentStatus.loading
    ) {
      dispatch(getAgentAppointment(workspaceId))
      dispatch(getParticipants(workspaceId))
      dispatch(getProperty(workspaceId))
    }
  }, [workspaceId, agentAppointment, agentAppointmentStatus, dispatch])

  const onStepChanged = (sectionName: string): void => {
    switch (sectionName) {
      case "client":
        setTab(0)
        break
      case "agent":
        setTab(1)
        break
      case "property":
        setTab(2)
        break
      case "appointment":
        setTab(3)
        break
      case "sale":
        setTab(4)
        break
      case "commission":
        setTab(5)
        break
      case "authorisation":
        setTab(6)
        break
      case "schedule":
        setTab(7)
        break
      case "annexures":
        setTab(8)
        break
      case "review":
        setTab(9)
        break
    }
  }

  const tabs: TabDefinition[] = [
    {
      label: "Guide",
      value: 0,
      view: (
        <Guide
          title="Agent Appointment"
          subtitle="Appoint an agent to represent a seller"
          steps={[
            "Prepare the appointment",
            "Review the compliance checklist",
            "Sign the appointment",
          ]}
          images={[
            <img
              src={prepareAgentAppointment}
              alt="Prepare Agent Appointment"
            />,
            <img
              src={reviewagentappointment}
              alt="Review Compliance Checklist"
            />,
            <img src={signagentappointment} alt="Sign Agent Appointment" />,
          ]}
          stepInstructions={[
            <Box sx={{ p: 2, width: "100%" }}>
              <Typography
                variant="h4"
                sx={{ color: "info.contrastText", textAlign: "justify" }}
              >
                Prepare the appointment
              </Typography>
              <Typography
                variant="body1"
                component="div"
                sx={{ color: "info.contrastText", textAlign: "justify" }}
              >
                TODO: Instructions for Preparing the appointment.
              </Typography>
              <br />
              <Typography
                variant="body1"
                sx={{ color: "info.contrastText", textAlign: "justify" }}
              >
                <ul>
                  <li>
                    The owners or lessees will be imported into the agent
                    appointment from the title
                  </li>
                  <li>
                    Use the tabs above to navigate through the sections of the
                    agent appointment.
                  </li>
                  <li>
                    You can review the appointment at any time by clicking the
                    "Review and Sign" tab.
                  </li>
                  <li>
                    The appointment will be automatically saved as you navigate
                    through the sections.
                  </li>
                </ul>
              </Typography>
            </Box>,
            <Box sx={{ p: 2, width: "100%" }}>
              <Typography
                variant="h4"
                sx={{ color: "info.contrastText", textAlign: "justify" }}
              >
                Review the compliance checklist
              </Typography>
              <Typography
                variant="body1"
                component="div"
                sx={{ color: "info.contrastText", textAlign: "justify" }}
              >
                TODO: Instructions for Reviewing the compliance checklist.
                <br /> Filler text below
              </Typography>
              <br />
              <Typography
                variant="body1"
                component="div"
                sx={{ color: "info.contrastText", textAlign: "justify" }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                quae ab illo inventore veritatis et quasi architecto beatae
                vitae dicta sunt explicabo.
              </Typography>
            </Box>,
            <Box sx={{ p: 2, width: "100%" }}>
              <Typography
                variant="h4"
                sx={{ color: "info.contrastText", textAlign: "justify" }}
              >
                Sign the appointment
              </Typography>
              <Typography
                variant="body1"
                component="div"
                sx={{ color: "info.contrastText", textAlign: "justify" }}
              >
                TODO: Instructions for Signing the appointment.
                <br /> Filler text below
              </Typography>
              <br />
              <Typography
                variant="body1"
                component="div"
                sx={{ color: "info.contrastText", textAlign: "justify" }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                quae ab illo inventore veritatis et quasi architecto beatae
                vitae dicta sunt explicabo.
              </Typography>
            </Box>,
          ]}
        />
      ),
    },
    { label: "Client", value: 1, view: <ClientDetails /> },
    { label: "Agent", value: 2, view: <LicenceeDetails /> },
    { label: "Property", value: 3, view: <Property /> },
    { label: "Appointment", value: 4, view: <Appointment /> },
    { label: "Sale", value: 5, view: <PropertySale /> },
    { label: "Commission", value: 6, view: <Commission /> },
    { label: "Authorisation", value: 7, view: <Authorisation /> },
    { label: "Schedule", value: 8, view: <ScheduleForm /> },
    { label: "Annexures", value: 9, view: <Annexures /> },
    {
      label: "Review and Sign",
      value: 10,
      view: <Generate onStepChanged={onStepChanged} />,
    },
  ]

  return (
    <MainCard>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs 
          value={tab} 
          onChange={(_, newValue) => setTab(newValue)}
          
          sx={{
            maxHeight: "120px", // Allow for two rows of tabs
            "& .MuiTabs-flexContainer": {
              flexWrap: "wrap"
            }
          }}
        >
          {tabs.map(tab => (
            <Tab 
              key={tab.value} 
              label={tab.label}
              sx={{
                minHeight: "48px",
                py: 0.5
              }}
            />
          ))}
        </Tabs>
      </Box>
      {tabs.map(tabDetails => (
        <CustomTabPanel
          value={tab}
          index={tabDetails.value}
          sx={{ p: 0, borderRadius: 3 }}
        >
          {tabDetails.view}
        </CustomTabPanel>
      ))}
    </MainCard>
  )
}

export default AgentAppointmentPage
