import { useEffect, useState } from "react"
import PropTypes from "prop-types"

// material-ui
import { alpha, useTheme } from "@mui/material/styles"
import Grid from "@mui/material/Grid2"
import Box from "@mui/material/Box"

// project imports
import MainCard from "../../components/cards/MainCard"
// import SkeletonEarningCard from 'ui-component/cards/Skeleton/EarningCard';

// assets
// import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import { Button, CircularProgress } from "@mui/material"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { IconSignature, IconShare, IconUserPlus } from "@tabler/icons-react"
import {
  presentOfferToSeller,
  sendForSigning,
} from "../../app/slices/offers-slice"
import {
  selectWorkflow,
  selectWorkspace,
} from "../../app/slices/workspaces-slice"
import { useParams } from "react-router-dom"
import { WorkflowOffersInner } from "../../landconnex-api-client"

interface IEarningCardProps {
  isLoading?: boolean
}

const OfferSummaryCard = ({ isLoading }: IEarningCardProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const workspace = useAppSelector(selectWorkspace)
  const workspaceWorkflow = useAppSelector(selectWorkflow)
  const params = useParams()
  const offerId = Number(params.offerId)
  const workspaceId = Number(params.workspaceId)
  const [workflow, setWorkflow] = useState<WorkflowOffersInner | null>(null)

  const [anchorEl, _setAnchorEl] = useState<null | HTMLElement>(null)

  const branding = workspace?.branding.find(
    branding => branding.companyType === "realEstateAgency",
  )

  useEffect(() => {
    if (workspaceWorkflow && workspaceWorkflow.offers) {
      const offerWorkflow = workspaceWorkflow.offers.find(
        offer => offer.offerId === offerId,
      )
      if (offerWorkflow) {
        setWorkflow(offerWorkflow!)
      }
    }
  }, [workspaceWorkflow, offerId])

  return (
    <>
      {isLoading ? (
        // <SkeletonEarningCard />
        <CircularProgress />
      ) : (
        <MainCard
          border={false}
          content={false}
          aria-hidden={Boolean(anchorEl)}
          sx={{
            bgcolor: branding?.secondaryColor,
            color: "#fff",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <Box sx={{ p: 2 }}>
            <Grid container spacing={2}>
              <Grid size={12}>
                <Button
                  fullWidth
                  variant="contained"
                  startIcon={<IconShare />}
                  disabled={workflow?.sharedWithSeller}
                  onClick={() => {
                    dispatch(
                      presentOfferToSeller({
                        workspaceId,
                        offerId,
                      }),
                    )
                  }}
                  sx={{
                    bgcolor: branding?.primaryColor,
                    color: theme.palette.getContrastText(
                      branding?.primaryColor || "#fff",
                    ),
                    mb: 1,
                    "&:hover": {
                      bgcolor: alpha(branding?.primaryColor || "#fff", 0.8),
                    },
                  }}
                >
                  Share with Seller
                </Button>
              </Grid>

              <Grid size={12}>
                <Button
                  fullWidth
                  variant="contained"
                  startIcon={<IconSignature />}
                  disabled={workflow?.state != "OfferCreated"}
                  onClick={() => {
                    dispatch(
                      sendForSigning({
                        workspaceId,
                        offerId,
                      }),
                    )
                  }}
                  sx={{
                    bgcolor: branding?.primaryColor,
                    color: theme.palette.getContrastText(
                      branding?.primaryColor || "#fff",
                    ),
                    mb: 1,
                    "&:hover": {
                      bgcolor: alpha(branding?.primaryColor || "#fff", 0.8),
                    },
                  }}
                >
                  Send to Buyer for Signing
                </Button>
              </Grid>

              <Grid size={12}>
                <Button
                  fullWidth
                  variant="contained"
                  startIcon={<IconSignature />}
                  disabled={workflow?.state != "OfferBuyerSigningCompleted"}
                  onClick={() => {
                    dispatch(
                      sendForSigning({
                        workspaceId,
                        offerId,
                      }),
                    )
                  }}
                  sx={{
                    bgcolor: branding?.primaryColor,
                    color: theme.palette.getContrastText(
                      branding?.primaryColor || "#fff",
                    ),
                    mb: 1,
                    "&:hover": {
                      bgcolor: alpha(branding?.primaryColor || "#fff", 0.8),
                    },
                  }}
                >
                  Send to Seller for Signing
                </Button>
              </Grid>

              <Grid size={12}>
                <Button
                  fullWidth
                  variant="contained"
                  startIcon={<IconUserPlus />}
                  disabled={workflow?.buyerSolicitorInvited}
                  sx={{
                    bgcolor: branding?.primaryColor,
                    color: theme.palette.getContrastText(
                      branding?.primaryColor || "#fff",
                    ),
                    mb: 1,
                    "&:hover": {
                      bgcolor: alpha(branding?.primaryColor || "#fff", 0.8),
                    },
                  }}
                >
                  Invite Buyer Solicitor
                </Button>
              </Grid>
            </Grid>
          </Box>
          <pre>{JSON.stringify(workflow, null, 2)}</pre>
        </MainCard>
      )}
    </>
  )
}

OfferSummaryCard.propTypes = { isLoading: PropTypes.bool }

export default OfferSummaryCard
