import React, { forwardRef } from "react"

// material-ui
import { SxProps, Theme } from "@mui/material"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardHeader from "@mui/material/CardHeader"
import Divider from "@mui/material/Divider"
import Typography from "@mui/material/Typography"

// project imports

// types
interface IMainCardProps {
  border?: boolean
  boxShadow?: boolean
  children: React.ReactNode
  content?: boolean
  contentClass?: string
  contentSX?: SxProps<Theme>
  headerSX?: SxProps<Theme>
  darkTitle?: boolean
  secondary?: React.ReactNode
  shadow?: string
  sx?: SxProps<Theme>
  title?: string | React.ReactNode
  [key: string]: any // for ...others props
}

// constant
const headerStyle = {
  "& .MuiCardHeader-action": { mr: 0 },
} as const

const MainCard = forwardRef<HTMLDivElement, IMainCardProps>(function MainCard(
  {
    border = false,
    boxShadow,
    children,
    content = true,
    contentClass = "",
    contentSX = {},
    headerSX = {},
    darkTitle,
    secondary,
    shadow,
    sx = {},
    title,
    ...others
  },
  ref,
) {
  const defaultShadow = "0 2px 14px 0 rgb(32 40 45 / 8%)"

  return (
    <Card
      ref={ref}
      {...others}
      sx={{
        border: border ? "1px solid" : "none",
        borderColor: "divider",
        ":hover": {
          boxShadow: boxShadow ? shadow || defaultShadow : "inherit",
        },
        ...sx,
      }}
    >
      {/* card header and action */}
      {!darkTitle && title && (
        <CardHeader
          sx={{ ...headerStyle, ...headerSX }}
          title={title}
          action={secondary}
        />
      )}
      {darkTitle && title && (
        <CardHeader
          sx={{ ...headerStyle, ...headerSX }}
          title={<Typography variant="h3">{title}</Typography>}
          action={secondary}
        />
      )}

      {/* content & header divider */}
      {title && <Divider />}

      {/* card content */}
      {content && (
        <CardContent sx={contentSX} className={contentClass}>
          {children}
        </CardContent>
      )}
      {!content && children}
    </Card>
  )
})

export default MainCard
