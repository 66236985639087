import * as React from "react"
import Box from "@mui/material/Box"
import { debounce, IconButton } from "@mui/material"
import { Delete } from "@mui/icons-material"
import { useEffect, useCallback } from "react"
import {
  deleteFee,
  updateFee,
} from "../../../app/slices/agent-appointment-slice"
import { useParams } from "react-router-dom"
import { useAppDispatch } from "../../../app/hooks"
import { zodResolver } from "@hookform/resolvers/zod"
import { Fee } from "../../../landconnex-api-client"
import { useForm } from "react-hook-form"
import FormInputText from "../../../components/form-input-text"
import FormInputNumeric from "../../../components/form-input-numeric"
import { feeSchema } from "../../../utils/validation-schemas"

export interface EditFeeDataProps {
  fee: Fee
}

const EditFee: React.FC<EditFeeDataProps> = ({ fee }) => {
  const dispatch = useAppDispatch()
  const params = useParams()
  const workspaceId = Number(params.workspaceId)

  const schema = feeSchema

  const {
    control,
    watch,
    reset,
    formState: { isDirty, errors },
  } = useForm<Fee>({
    resolver: zodResolver(schema),
    mode: "onChange",
    defaultValues: fee,
  })

  const formValues = watch()

  const handleOnFeeChanged = useCallback(
    (data: Fee) => {
      try {
        dispatch(updateFee({ workspaceId, fee: data }))

        reset(data, { keepErrors: true })
      } catch (serverError) {
        console.error("Autosave failed", serverError)
      }
    },
    [dispatch, workspaceId, reset],
  )

  const debouncedAutoSave = React.useCallback(
    debounce(async (data: Fee) => {
      handleOnFeeChanged(data)
    }, 1000),
    [handleOnFeeChanged],
  )

  useEffect(() => {
    if (isDirty) {
      debouncedAutoSave(formValues)
    }
  }, [formValues, isDirty, debouncedAutoSave])

  function handleDelete(id: number): void {
    dispatch(deleteFee({ feeId: id, workspaceId }))
  }

  return (
    <Box
      component="form"
      key={fee.id}
      sx={{ display: "flex", alignItems: "center", gap: 2 }}
    >
      <FormInputText
        control={control}
        name="description"
        label="Description"
        error={errors.description}
      />
      <FormInputNumeric
        control={control}
        name="estimatedAmount"
        label="Estimated Amount"
        error={errors.estimatedAmount}
      />
      <FormInputText
        control={control}
        name="whenPayable"
        label="When Payable"
        error={errors.whenPayable}
      />

      <IconButton onClick={() => handleDelete(fee.id)} color="error">
        <Delete />
      </IconButton>
    </Box>
  )
}

export default EditFee
