import { useEffect } from "react"
import { useAppDispatch } from "../../../app/hooks"
import { useParams } from "react-router-dom"
import { completeOnBoarding } from "../../../app/slices/team-slice"
import Page from "../../../components/page"
import { Typography } from "@mui/material"
import { useAuth } from "react-oidc-context"


const CompletePayment = () => {
  const dispatch = useAppDispatch()
  const { setupIntentId } = useParams()
  const auth = useAuth()
  useEffect(() => {
    dispatch(completeOnBoarding({ setupIntentID: setupIntentId! }))
    auth.signinSilent()
  }, [dispatch, setupIntentId])

  return (
    <Page>
      <Typography variant="h4">Payment Complete</Typography>
    </Page>
  )
}

export default CompletePayment
