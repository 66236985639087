import { useEffect, useState } from "react"
import { produce } from "immer"
import {
  Alert,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"

import { useAppDispatch, useAppSelector } from "../../app/hooks"

import { appConfig } from "../../constants"
import {
  completeOnBoarding,
  getTeam,
  selectClientSecretID,
  selectSetupIntentID,
  selectTeamStatus,
  startOnBoarding,
  TeamStatus,
} from "../../app/slices/team-slice"
import { withAuthenticationRequired } from "react-oidc-context"
import RedirectToLogin from "../../components/redirect-to-login"
import SelectCompanyType from "./select-company-type"
import {
  OnBoarding,
  OnBoardingCompanyTypeEnum,
} from "../../landconnex-api-client"
import CompanyDetails from "./company-details"
import PaymentDetails from "./payment-details"

// import SubscriptionDetails from "../create-subscription/subscription-details"

// Initialize Stripe
const stripePromise = loadStripe(appConfig.stripePublicKey)

const steps = ["Select Type", "Company Details", "Payment Method"]

const OnboardingPage = () => {
  const dispatch = useAppDispatch()
  const [activeStep, setActiveStep] = useState(0)

  const [onboarding, setOnboarding] = useState<OnBoarding>({
    companyType: OnBoardingCompanyTypeEnum.RealEstateAgency,
    companyName: "",
    abn: "",
    acn: "",
    phone: "",
    mobilePhone: "",
    email: "",
    licenceeNumber: "",
    licenceeExpiryDate: "",
    registeredForGst: false,
    physicalAddress: {
      streetAddress1: "",
      streetAddress2: "",
      stateOrTerritory: "",
      postCode: "",
      country: "",
      locality: "",
    },
    primaryContactName: {
      firstName: "",
      lastName: "",
      middleName: "",
      organisationName: "",
    },

  })

  const teamStatus = useAppSelector(selectTeamStatus)
  const clientSecretID = useAppSelector(selectClientSecretID)
  const setupIntentID = useAppSelector(selectSetupIntentID)

  useEffect(() => {
    dispatch(getTeam())
  }, [dispatch])

  // const handleNext = () => {
  //   if (activeStep === 0) {
  //     setActiveStep(prevStep => prevStep + 1)
  //   }
  //   if (activeStep === 1) {
  //     dispatch(startOnBoarding(onboarding)).then(() => {
  //       setActiveStep(prevStep => prevStep + 1)
  //     })
  //   }
  // }

  const handleCompanyDetailsChange = (onBoarding: OnBoarding) => {
    setOnboarding(onBoarding)
  }

  const handleCompleteCompanyDetails = (onBoarding: OnBoarding) => {
    dispatch(startOnBoarding(onBoarding)).then(() => {
      setActiveStep(3)
    })
  }

  const handleCompleteOnBoarding = () => {
    dispatch(
      completeOnBoarding({
        setupIntentID: setupIntentID!,
      }),
    ).then(() => {
      setActiveStep(prevStep => prevStep + 1)
    })
  }

  const handleSelectCompanyType = (type: OnBoardingCompanyTypeEnum) => {
    setOnboarding(
      produce(onboarding => {
        onboarding.companyType = type
        onboarding.licenceeNumber = ""
        onboarding.licenceeExpiryDate = new Date().toISOString()
      }),
    )
    setActiveStep(1)
  }

  // const handleSubmit = async () => {
  //   try {
  //     await dispatch(updateMyDetails(formData as Participant)).unwrap()
  //     // Handle successful submission
  //   } catch (error) {
  //     console.error("Failed to update details:", error)
  //   }
  // }

  const renderStepContent = (step: number) => {
    if (teamStatus === TeamStatus.error) {
      return (
        <Alert severity="error" sx={{ mt: 2 }}>
          An error occurred while creating your company. Please try again later.
        </Alert>
      )
    }
    if (teamStatus === TeamStatus.creatingCompany) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
          }}
        >
          <CircularProgress />
          <Typography>Creating company...</Typography>
        </Box>
      )
      return <div>Creating company...</div>
    }
    switch (step) {
      case 0:
        return <SelectCompanyType onComplete={handleSelectCompanyType} />
      case 1:
        return (
          <CompanyDetails
            onBoarding={onboarding}
            onCompanyDetailsChange={handleCompanyDetailsChange}
            onComplete={handleCompleteCompanyDetails}
          />
        )
      case 2:
      case 3:
        return (
          <Elements
            stripe={stripePromise}
            options={{
              clientSecret: clientSecretID!,

              // appearance: appearance,
            }}
          >
            <PaymentDetails
              clientSecretID={clientSecretID!}
              setupIntentID={setupIntentID!}
              onComplete={handleCompleteOnBoarding}
            />
          </Elements>
        )

      default:
        return null
    }
  }

  const isStepOptional = (_: number) => {
    return false
    // return step === 2 && userType === "other"
  }

  return (
    <Box sx={{ height: "100vh", backgroundColor: "#fafafa" }}>
      <Container sx={{ py: 4 }}>
        <Card>
          <CardContent>
            <Typography variant="h4" gutterBottom align="center">
              Welcome to LandConnex
            </Typography>
            <Typography
              variant="subtitle1"
              gutterBottom
              align="center"
              color="text.secondary"
            >
              Let's get you set up
            </Typography>

            {activeStep > 0 && (
              <Stepper alternativeLabel activeStep={activeStep} sx={{ my: 4 }}>
                {steps.map((label, index) => {
                  const stepProps: { completed?: boolean } = {}
                  const labelProps: { optional?: React.ReactNode } = {}

                  if (isStepOptional(index)) {
                    labelProps.optional = (
                      <Typography variant="caption">Optional</Typography>
                    )
                  }

                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  )
                })}
              </Stepper>
            )}

            {renderStepContent(activeStep)}
          </CardContent>
        </Card>
      </Container>
    </Box>
  )
}

export default withAuthenticationRequired(OnboardingPage, {
  OnRedirecting: () => <RedirectToLogin />,
  signinRedirectArgs: { redirect_uri: window.location.href },
})
