import TextField from "@mui/material/TextField"
import { FC } from "react"
import { FieldError } from "react-hook-form"
import { NumericFormat } from "react-number-format"

export interface PercentageFieldProps {
  amount?: number
  label: string
  required?: boolean
  onChange?: (amount: number | undefined) => void
  disabled?: boolean
  error?: FieldError | undefined
}
const PercentageField: FC<PercentageFieldProps> = ({
  amount,
  required,
  label,
  onChange,
  disabled = false,
  error,
}) => {
  let val
  if (amount) {
    val = amount / 100
    val = val.toFixed(2)
  }
  return (
    <NumericFormat
      value={val}
      customInput={TextField}
      suffix="%"
      fixedDecimalScale
      label={label}
      required={required}
      fullWidth
      // decimalScale={2}
      disabled={disabled}
      onValueChange={values => {
        values.floatValue
        let t = values.floatValue! * 100
        onChange!(t)
      }}
      size="small"
      
      helperText={error?.message}
      error={!!error}
      aria-invalid={error ? true : false}
    />
  )
}

export default PercentageField
