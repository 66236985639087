import {
  Grid,
  Typography,
  debounce,
  useTheme,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  CardHeader,
  Box,
} from "@mui/material"
import React, { FC, useEffect, useState } from "react"
import ConfirmationDialog from "../../../components/confirmation-dialog"
import {
  Participant,
  ParticipantParticipantTypeEnum,
} from "../../../landconnex-api-client"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { useAppDispatch } from "../../../app/hooks"
import {
  getParticipant,
  putParticipant,
} from "../../../app/slices/participants-slice"
import { useParams } from "react-router-dom"
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined"
import FormInputText from "../../../components/form-input-text"
import AddressForm from "../../../components/address-form"
import ParticipantNameForm from "../../../components/participant-name-form"
import { participantSchema } from "../../../utils/validation-schemas"
import { gridSpacing } from "../../../constants"

export interface ClientDetailProps {
  client: Participant
  index: number
  disabled?: boolean
  participantId?: number
}

const ClientDetail: FC<ClientDetailProps> = ({
  index,

  participantId,
  client,
}) => {
  const [confirmationDialogState, setConfirmationDialogState] =
    useState(Array<number>())
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const params = useParams()
  const [editingParticipantId, setEditingParticipantId] = useState<number>(0)
  const workspaceId = Number(params.workspaceId)
  const schema = participantSchema

  const participantForm = useForm<Participant>({
    resolver: zodResolver(schema),
    mode: "onChange",
    defaultValues: client,
  })

  const {
    control,
    watch,
    formState: { errors, isDirty, defaultValues },
    setValue,
    reset,
  } = participantForm

  const formValues = watch()

  const saveParticipant = (data: Participant) => {
    try {
      dispatch(putParticipant({ participant: data, workspaceId: workspaceId! }))
      reset(data, { keepErrors: true })
    } catch (serverError) {
      console.error("Autosave failed", serverError)
    }
  }

  const debouncedAutoSave = React.useCallback(
    debounce(async (data: Participant) => {
      saveParticipant(data)
    }, 1000),
    [dispatch, workspaceId],
  )
  useEffect(() => {
    if (isDirty) {
      debouncedAutoSave(formValues)
    }
  }, [formValues, isDirty, debouncedAutoSave])

  useEffect(() => {
    if (editingParticipantId !== participantId) {
      dispatch(
        getParticipant({
          participantId: participantId!,
          workspaceId: workspaceId!,
        }),
      ).then(data => {
        reset(data.payload as Participant)
      })
      setEditingParticipantId(participantId!)
    }
  }, [editingParticipantId])

  return (
    <React.Fragment key={participantId!}>
      <Grid item xs={12} sm={12}>
        <ConfirmationDialog
          open={confirmationDialogState.indexOf(participantId!) > -1}
          message={`Remove ${
            defaultValues!.firstName ? defaultValues!.firstName : "un-named"
          } from the contract?`}
          title="Remove client?"
          onClose={result => {
            if (result) {
              setConfirmationDialogState([])
            } else {
              setConfirmationDialogState([])
            }
          }}
        ></ConfirmationDialog>
      </Grid>
      <Grid item>
        <Grid component="form" container spacing={2} mb={2}>
          <Grid item xs={12} sm={12}>
            <CardHeader
              title={`Client ${index + 1}`}
              inheritlayout="true"
              // action={
              //   <IconButton
              //     size="small"
              //     color="primary"
              //     onClick={() => setConfirmationDialogState([participantId!])}
              //   >
              //     <DeleteForeverOutlinedIcon fontSize="large" color="error" />
              //   </IconButton>
              // }
            />
            <Divider />
            <Box sx={{ justifySelf: "right" }}>
              <IconButton
                size="small"
                color="primary"
                onClick={() => setConfirmationDialogState([participantId!])}
              >
                <DeleteForeverOutlinedIcon fontSize="large" color="error" />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel size="small" id="clientTypeLabel">
                Client Type
              </InputLabel>
              <Select
                name="participantType"
                variant="outlined"
                value={client.participantType}
                onChange={val => {
                  dispatch(
                    putParticipant({
                      participant: {
                        ...formValues,
                        participantType: val.target
                          .value as ParticipantParticipantTypeEnum,
                      },
                      workspaceId: workspaceId!,
                    }),
                  )
                }}
              >
                {Object.values(ParticipantParticipantTypeEnum).map(p => (
                  <MenuItem key={p} value={p}>
                    {p}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" color={theme.palette.text.secondary}>
              Name
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <ParticipantNameForm
              control={control}
              errors={errors}
              captureMiddleName={true}
              participantType={client.participantType!}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" color={theme.palette.text.secondary}>
              Contact Details
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormInputText
              name="phone"
              control={control}
              label="Phone"
              error={errors.phone}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormInputText
              name="mobilePhone"
              control={control}
              label="Mobile Phone"
              error={errors.mobilePhone}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormInputText
              name="email"
              control={control}
              label="Email"
              error={errors.email}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Typography variant="h6" color={theme.palette.text.secondary}>
              Address
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} spacing={gridSpacing}>
            <AddressForm
              control={control}
              address={client}
              setValue={setValue}
              errors={errors}
            />
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default ClientDetail
