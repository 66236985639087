import { List } from "@mui/material"
import { useAppSelector } from "../../../app/hooks"
import { selectDocuments } from "../../../app/slices/documents-slice"
import DocumentListItem from "../../../components/document-list-item"
import { useParams } from "react-router-dom"

const WorkspaceDocuments = () => {
  const documents = useAppSelector(selectDocuments)
  const params = useParams()
  const workspaceId = Number(params.workspaceId)

  return (
      <List sx={{ "& .MuiListItem-root": { px: 1 } }}>
        {documents.map(document => (
          <DocumentListItem
            key={document.name}
            document={document}
            workspaceId={workspaceId}
          />
        ))}
      </List>
  )
}

export default WorkspaceDocuments
