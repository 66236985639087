import React, { useEffect } from "react"
import {
  Autocomplete,
  Grid,
  Typography,
  TextField,
  useTheme,
  debounce,
  Paper,
} from "@mui/material"
import { useAppSelector, useAppDispatch } from "../../../app/hooks"
import { Controller, useForm } from "react-hook-form"
import { Contract } from "../../../landconnex-api-client"
import FormInputNumeric from "../../../components/form-input-numeric"
import { selectWorkspace } from "../../../app/slices/workspaces-slice"
import { putOffer, selectOffer } from "../../../app/slices/offers-slice"
import { zodResolver } from "@hookform/resolvers/zod"
import { contractSchema } from "../../../utils/validation-schemas"
import { gridSpacing } from "../../../constants"
const Price: React.FC = () => {
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const workspace = useAppSelector(selectWorkspace)
  const offer = useAppSelector(selectOffer)
  const schema = contractSchema

  const depositInitialDueOptions = [
    "1 day after contract signing",
    "3 days after contract signing",
  ]

  const depositBalanceDueOptions = [
    "1 business day from satisfaction of finance condition",
    "1 business day from satisfaction of building and pest condition",
    "1 business day from unconditional date",
  ]

  const contractFormMethods = useForm<Contract>({
    defaultValues: offer,
    resolver: zodResolver(schema),
    mode: "onChange",
  })

  const {
    control,
    watch,
    reset,
    formState: { errors, isDirty },
  } = contractFormMethods

  const formValues = watch()

  const debouncedSave = React.useCallback(
    debounce(async (data: Contract) => {
      dispatch(
        putOffer({
          workspaceId: workspace!.workspace!.id,
          offer: data,
        }),
      )
      reset(data, { keepErrors: true })
    }, 1000),
    [dispatch, workspace!.workspace!.id],
  )

  useEffect(() => {
    if (isDirty) {
      debouncedSave(formValues)
    }
  }, [formValues, isDirty])

  return (
    <Paper sx={{ padding: gridSpacing }}>
      <Grid component="form" container spacing={gridSpacing}>
        
        <Grid item xs={12} sm={4}>
          <FormInputNumeric
            name="purchasePrice"
            label="Price"
            control={control}
            error={errors.purchasePrice}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography variant="h6" color={theme.palette.text.secondary}>
            Deposits
          </Typography>
        </Grid>

        {/* Initial Deposit Field */}
        <Grid item xs={12} sm={6}>
          <FormInputNumeric
            name="depositInitial"
            label="Initial Deposit"
            control={control}
            error={errors.depositInitial}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="depositInitialDue"
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                freeSolo
                options={depositInitialDueOptions}
                onChange={(_, value) => field.onChange(value)}
                renderInput={params => (
                  <TextField {...params} label="Initial deposit due" />
                )}
              />
            )}
          />
        </Grid>

        {/* Balance Deposit Field */}
        <Grid item xs={12} sm={6}>
          <FormInputNumeric
            name="depositBalance"
            label="Balance Deposit"
            control={control}
            error={errors.depositBalance}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="depositBalanceDue"
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                freeSolo
                options={depositBalanceDueOptions}
                onChange={(_, value) => field.onChange(value)}
                renderInput={params => (
                  <TextField {...params} label="Balance deposit due" />
                )}
              />
            )}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default Price
