import { FC, ReactElement, useEffect, useState } from "react"
import Page from "../../components/page"
import { withAuthenticationRequired } from "react-oidc-context"
import RedirectToLogin from "../../components/redirect-to-login"
import {
  BottomNavigationAction,
  BottomNavigation,
  Box,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
  Container,
  Paper,
} from "@mui/material"
import { selectIsWorkspaceAdministrator } from "../../app/slices/session-slice"
import PersonIcon from "@mui/icons-material/Person"
import GroupIcon from "@mui/icons-material/Group"
import TeamPage from "./components/team-page"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { Business, CreditCard } from "@mui/icons-material"
import ManageUsers from "../manage-users"
import { PaymentFormContainer } from "./components/payment-form"
import { gridSpacing } from "../../constants"
import { TabDefinition } from "../../components/tabs"
import { IconBrandAbstract, IconHomeStar, IconUsers } from "@tabler/icons-react"
import { getTeam, selectTeam } from "../../app/slices/team-slice"
import CustomTabPanel from "../../components/tabs/custom-tab-panel"
import Branding from "./components/branding"
import AgencyDetails from "./components/agency-details"
const SettingsPage: FC = () => {
  const team = useAppSelector(selectTeam)
  const [selectedTab, setSelectedTab] = useState(0)
  const isWorkspaceAdministrator = useAppSelector(
    selectIsWorkspaceAdministrator,
  )
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"))
  const isMedium = useMediaQuery(theme.breakpoints.between("sm", "md"))
  const dispatch = useAppDispatch()
  const [tabs, setTabs] = useState<TabDefinition[]>([])

  useEffect(() => {
    if (!selectedTab) {
      setSelectedTab(0)
    }
  }, [selectedTab])

  useEffect(() => {
    dispatch(getTeam())
  }, [dispatch])

  useEffect(() => {
    let index = 0
    let t: TabDefinition[] = []
    if (team?.companyType === "realEstateAgency") {
      t.push({
        label: "Agency Information",
        value: index++,
        icon: (<IconHomeStar />) as ReactElement,
        view: <AgencyDetails />,
      })
    }
    t.push(
      {
        label: "Company Information",
        value: index++,
        icon: (<IconUsers />) as ReactElement,
        view: <TeamPage />,
      },
      {
        label: "Branding",
        value: index++,
        icon: (<IconBrandAbstract />) as ReactElement,
        view: <Branding />,
      },
      {
        label: "Users",
        value: index++,
        view: <ManageUsers />,
      },
      {
        label: "Billing",
        value: index++,
        view: <PaymentFormContainer />,
      },
    )
    setTabs(t)
  }, [team])

  if (isSmall || isMedium) {
    return (
      <Page>
        <Box sx={{ position: "relative", overflow: "hidden" }}>
          <Box
            sx={{
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 1000,
            }}
          >
            {isWorkspaceAdministrator && (
              <BottomNavigation
                value={selectedTab}
                onChange={(_event, newValue) => setSelectedTab(newValue)}
                showLabels
              >
                <BottomNavigationAction
                  label="Me"
                  icon={<PersonIcon />}
                  value="me"
                />
                <BottomNavigationAction
                  label="Company"
                  icon={<Business />}
                  value="team"
                />
                <BottomNavigationAction
                  label="Members"
                  icon={<GroupIcon />}
                  value="members"
                />
                <BottomNavigationAction
                  label="Billing"
                  icon={<CreditCard />}
                  value="billing"
                />
              </BottomNavigation>
            )}
          </Box>
        </Box>
      </Page>
    )
  }

  return (
    <Container>
      <Paper sx={{ p: gridSpacing }}>
        <Tabs
          value={selectedTab}
          onChange={(_event, newValue: number) => setSelectedTab(newValue)}
        >
          {tabs.map(tabDetails => (
            <Tab
              key={tabDetails.value}
              label={tabDetails.label}
              value={tabDetails.value}
              icon={tabDetails.icon || <IconUsers />}
              iconPosition="start"
            />
          ))}
        </Tabs>
        {tabs.map(tabDetails => (
          <CustomTabPanel
            key={tabDetails.value}
            index={tabDetails.value}
            value={selectedTab}
          >
            {tabDetails.view}
          </CustomTabPanel>
        ))}
      </Paper>
    </Container>
  )
}

export default withAuthenticationRequired(SettingsPage, {
  OnRedirecting: () => <RedirectToLogin />,
  signinRedirectArgs: { redirect_uri: window.location.href },
})
