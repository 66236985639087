import { Box, Grid, Typography, Button } from "@mui/material"
import {
  Address,
  OnBoarding,
  OnBoardingCompanyTypeEnum,
  PhysicalAustralianAddress,
} from "../../landconnex-api-client"
import { FC, Fragment, useEffect } from "react"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { onboardingSchema } from "../../utils/validation-schemas"
import AddressLookup from "../../components/address-lookup"
import FormInputText from "../../components/form-input-text"
import FormInputCheckbox from "../../components/form-input-checkbox"
import FormInputDatePicker from "../../components/form-input-date-picker"
export interface CompanyDetailsProps {
  onBoarding: OnBoarding
  onComplete: (onBoarding: OnBoarding) => void
  onCompanyDetailsChange: (onBoarding: OnBoarding) => void
}

const CompanyDetails: FC<CompanyDetailsProps> = ({
  onBoarding,
  onComplete,
  onCompanyDetailsChange,
}) => {
  const companyDetailsForm = useForm<OnBoarding>({
    resolver: zodResolver(onboardingSchema),
    mode: "onChange",
    defaultValues: onBoarding,
  })

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors, isDirty, isValid },
  } = companyDetailsForm

  const formValues = watch()

  useEffect(() => {
    if (isDirty) {
      onCompanyDetailsChange(formValues)
    }
  }, [formValues, isDirty, onCompanyDetailsChange])

  const handleAddressSelected = (
    _: PhysicalAustralianAddress,
    formattedAddress: Address,
  ) => {
    setValue(
      "physicalAddress",
      {
        ...(formValues.physicalAddress || {}),
        streetAddress1: formattedAddress.streetAddress1,
        locality: formattedAddress.locality,
        stateOrTerritory: formattedAddress.stateOrTerritory,
        postCode: formattedAddress.postCode,
        country: formattedAddress.country,
      },
      { shouldDirty: true },
    )
  }

  return (
    <Box sx={{ p: 2 }}>
      <form onSubmit={handleSubmit(onComplete)}>
        <pre>{JSON.stringify(isValid, null, 2)}</pre>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Company Details</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              name="companyName"
              label="Company Name"
              control={control}
              required
              error={errors.companyName}
            />
          </Grid>
          <Grid item xs={6}>
            <FormInputText
              name="abn"
              label="ABN"
              control={control}
              required
              error={errors.abn}
            />
          </Grid>
          <Grid item xs={6}>
            <FormInputText
              name="acn"
              label="ACN"
              control={control}
              required
              error={errors.acn}
            />
          </Grid>
          {onBoarding.companyType ===
            OnBoardingCompanyTypeEnum.RealEstateAgency && (
            <Fragment>
              <Grid item xs={6}>
                <FormInputText
                  name="licenceeNumber"
                  label="Licencee Number"
                  control={control}
                />
              </Grid>
              <Grid item xs={6}>
                <FormInputDatePicker
                  name="licenceeExpiryDate"
                  label="Licencee Expiry Date"
                  control={control}
                />
              </Grid>
            </Fragment>
          )}
          <Grid item xs={12}>
            <FormInputCheckbox
              name="registeredForGst"
              label="Registered for GST"
              control={control}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Primary Contact</Typography>
          </Grid>
          <Grid item xs={6}>
            <FormInputText
              name="primaryContactName.firstName"
              label="First Name"
              control={control}
              required
              error={errors.primaryContactName?.firstName}
            />
          </Grid>
          <Grid item xs={6}>
            <FormInputText
              name="primaryContactName.lastName"
              label="Last Name"
              control={control}
              required
              error={errors.primaryContactName?.lastName}
            />
          </Grid>

          <Grid item xs={6}>
            <FormInputText
              name="email"
              label="Email"
              control={control}
              required
              error={errors.email}
            />
          </Grid>

          <Grid item xs={3}>
            <FormInputText
              name="phone"
              label="Phone"
              control={control}
              required
              error={errors.phone}
            />
          </Grid>
          <Grid item xs={3}>
            <FormInputText
              name="mobilePhone"
              label="Mobile"
              control={control}
              required
              error={errors.mobilePhone}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Physical Address</Typography>
          </Grid>
          <Grid item xs={12}>
            <AddressLookup onAddressSelected={handleAddressSelected} />
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              name="physicalAddress.streetAddress1"
              label="Street Address"
              control={control}
              required
              error={errors.physicalAddress?.streetAddress1}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInputText
              name="physicalAddress.locality"
              label="City/Suburb"
              control={control}
              required
              error={errors.physicalAddress?.locality}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInputText
              name="physicalAddress.stateOrTerritory"
              label="State/Territory"
              control={control}
              required
              error={errors.physicalAddress?.stateOrTerritory}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInputText
              name="physicalAddress.postCode"
              label="Postcode"
              control={control}
              required
              error={errors.physicalAddress?.postCode}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInputText
              name="physicalAddress.country"
              label="Country"
              control={control}
              required
              error={errors.physicalAddress?.country}
            />
          </Grid>
        </Grid>
        <Box sx={{ mt: 6, width: 110, justifySelf: "center" }}>
          <Button
            size="large"
            onClick={() => {
              handleSubmit(data => {
                if (isValid) {
                  onComplete(data)
                }
              })()
            }}
          >
            Next
          </Button>
        </Box>
      </form>
    </Box>
  )
}

export default CompanyDetails
