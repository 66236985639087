import {
  Icon,
  IconAdjustmentsAlt,
  IconBrandChrome,
  IconKey,
  IconDashboard,
  IconLayoutList,
  IconPlus,
  IconUser,
  IconUsers,
  IconHelp,
  IconSettings,
} from "@tabler/icons-react"

const iconNameMapper = (iconName?: string) => {
  let Icon: Icon | null = null

  switch (iconName) {
    case "IconDashboard":
      Icon = IconDashboard
      break
    case "IconKey":
      Icon = IconKey
      break
    case "IconBrandChrome":
      Icon = IconBrandChrome
      break
    case "IconHelp":
      Icon = IconHelp
      break
    case "IconLayoutList":
      Icon = IconLayoutList
      break
    case "IconPlus":
      Icon = IconPlus
      break
    case "IconAdjustmentsAlt":
      Icon = IconAdjustmentsAlt
      break
    case "IconUser":
      Icon = IconUser
      break
    case "IconUsers":
      Icon = IconUsers
      break
    case "IconSettings":
      Icon = IconSettings
      break
    default:
      break
  }

  return Icon
}

export default iconNameMapper
