import { FC } from "react"
import { Controller, FieldError } from "react-hook-form"
import { FormInputProps } from "./events"
import CurrencyField from "./currency-field"

interface ExtendedFormInputProps extends FormInputProps {
  disabled?: boolean
  error?: FieldError | undefined
}

const FormInputNumeric: FC<ExtendedFormInputProps> = ({
  name,
  label,
  control,
  required,
  disabled, // default value for disabled
  error,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <CurrencyField
          label={label}
          amount={value}
          required={required}
          disabled={disabled} // pass disabled to CurrencyField
          onChange={amount => {
            onChange(amount)
          }}
          error={error}
          aria-invalid={error ? true : false}
        />
      )}
    />
  )
}

export default FormInputNumeric
