import { Box, Typography, Grid2, useTheme } from "@mui/material"
import { singleLineFullAddress } from "../../../helpers/helpers"
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined"
import SubtitlesOutlinedIcon from "@mui/icons-material/SubtitlesOutlined"
import PollOutlinedIcon from "@mui/icons-material/PollOutlined"
import WorkspaceCard, {
  WorkspaceCardContent,
} from "../../../components/workspace-card"
import { selectWorkspace } from "../../../app/slices/workspaces-slice"
import { useAppSelector } from "../../../app/hooks"
import {
  Property,
} from "../../../landconnex-api-client"
import { appConfig } from "../../../constants"
import { selectProperty } from "../../../app/slices/property-slice"
import useBranding from "../../../themes/branding"

const WorkspaceHeader = () => {
  const workspaceSummary = useAppSelector(selectWorkspace)
  const property = useAppSelector(selectProperty)
  const imageStore = appConfig.imageBucket
  const theme = useTheme()
  const { primaryColour, secondaryColour, logo } = useBranding()

  console.log(primaryColour, secondaryColour)

  if (!workspaceSummary) {
    return <></>
  }

  const inferPlanNumber = (property: Property) => {
    if (property.planType === "CP") {
      return property.plan
    }

    return property.planType + " " + property.plan
  }

  return (
    <WorkspaceCard sx={{ backgroundColor: primaryColour }}>
      <WorkspaceCardContent>
        <Grid2 container>
          <Grid2 size={6}>
            <img
              style={{ border: "1 solid black" }}
              src={`${imageStore}/logos/${logo}.png`}
              height="96px"
              alt=""
            />
          </Grid2>
          <Grid2 size={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "end",
              }}
            >
              <Typography
                variant="h1"
                color={theme.palette.getContrastText(primaryColour)}
              >{`${singleLineFullAddress(property)}`}</Typography>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 3,
                  color: "#3e3e3e",
                  alignItems: "center",
                }}
              >
                {[
                  {
                    label: "Lot",
                    value: property.lot || "N/A",
                    icon: <HomeOutlinedIcon sx={{ color: theme.palette.getContrastText(primaryColour) }} />,
                  },
                  {
                    label: "Title Reference",
                    value: property.titleReference || "N/A",
                    icon: <SubtitlesOutlinedIcon sx={{ color: theme.palette.getContrastText(primaryColour) }} />,
                  },
                  {
                    label: "Plan",
                    value: inferPlanNumber(property) || "N/A",
                    icon: <PollOutlinedIcon sx={{ color: theme.palette.getContrastText(primaryColour) }} />,
                  },
                ].map((item, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {item.icon}
                    <Typography variant="h6" component="span" color={theme.palette.getContrastText(primaryColour)}>
                      {item.label}:
                    </Typography>
                    <Typography variant="caption" component="span" color={theme.palette.getContrastText(primaryColour)}  >
                      &nbsp;{item.value}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Grid2>
        </Grid2>
      </WorkspaceCardContent>
    </WorkspaceCard>
  )
}

export default WorkspaceHeader
