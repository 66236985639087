
import PropTypes from "prop-types"

// material-ui
import { useTheme } from "@mui/material/styles"
import Avatar from "@mui/material/Avatar"
import Grid from "@mui/material/Grid2"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import MainCard from "../../../components/cards/MainCard"
import { CircularProgress } from "@mui/material"
import { selectGcRevenue } from "../../../app/slices/reports-slice"
import { useAppSelector } from "../../../app/hooks"
import { formatCurrency } from "../../../helpers/helpers"
import { IconReceiptDollar } from "@tabler/icons-react"

interface IEarningCardProps {
  isLoading?: boolean
}

const TotalGcrCard = ({ isLoading }: IEarningCardProps): JSX.Element => {
  const theme = useTheme()
  const totalGcr = useAppSelector(selectGcRevenue)
 

  return (
    <>
      {isLoading ? (
        // <SkeletonEarningCard />
        <CircularProgress />
      ) : (
        <MainCard
          border={false}
          content={false}
        
          sx={{
            bgcolor: theme.palette.orange.dark,
            color: "#fff",
            overflow: "hidden",
            position: "relative",
            "&:after": {
              content: '""',
              position: "absolute",
              width: 210,
              height: 210,
              background: theme.palette.orange.main,
              borderRadius: "50%",
              top: { xs: -85 },
              right: { xs: -95 },
            },
            "&:before": {
              content: '""',
              position: "absolute",
              width: 210,
              height: 210,
              background: theme.palette.orange.light,
              borderRadius: "50%",
              top: { xs: -125 },
              right: { xs: -15 },
              opacity: 0.5,
            },
          }}
        >
          <Box sx={{ p: 2.25 }}>
            <Grid container direction="column">
              <Grid>
                <Grid container sx={{ justifyContent: "space-between" }}>
                  <Grid>
                    <Avatar
                      variant="rounded"
                      sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        bgcolor: theme.palette.orange.main,
                        color: theme.palette.orange.dark,
                        mt: 1,
                      }}
                    >
                      <IconReceiptDollar />
                    </Avatar>
                  </Grid>
                </Grid>
              </Grid>
              <Grid>
                <Grid container sx={{ alignItems: "center" }}>
                  <Grid>
                    <Typography
                      sx={{
                        fontSize: "2.125rem",
                        fontWeight: 500,
                        mr: 1,
                        mt: 1.75,
                        mb: 0.75,
                      }}
                    >
                      {formatCurrency(totalGcr)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid sx={{ mb: 1.25 }}>
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: 500,
                    color: theme.palette.orange.light,
                  }}
                >
                  Total Gross Commission Revenue
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </MainCard>
      )}
    </>
  )
}

TotalGcrCard.propTypes = { isLoading: PropTypes.bool }

export default TotalGcrCard
