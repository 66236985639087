import { useEffect } from "react"

import { useAppDispatch, useAppSelector } from "../../app/hooks"

import { withAuthenticationRequired } from "react-oidc-context"
import { Box, CircularProgress } from "@mui/material"
import { useNavigate } from "react-router-dom"
import RedirectToLogin from "../../components/redirect-to-login"
import {
  getWorkflow,
  selectWorkspaces,
  selectWorkspacesStatus,
  WorkspacesStatus,
} from "../../app/slices/workspaces-slice"
import { getTeam } from "../../app/slices/team-slice"
import {
  initialise,
  selectIsWorkspaceReader,
  selectSessionStatus,
  SessionStatus,
} from "../../app/slices/session-slice"
const LandingPage: React.FC = () => {
  const workspacesStatus = useAppSelector(selectWorkspacesStatus)
  const workspaces = useAppSelector(selectWorkspaces)
  const isWorkspaceReader = useAppSelector(selectIsWorkspaceReader)

  const sessionStatus = useAppSelector(selectSessionStatus)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(getTeam())
  }, [dispatch])

  useEffect(() => {
    if (sessionStatus === SessionStatus.ready) {
      if (isWorkspaceReader) {
        navigate("/workspaces")
      } else {
        navigate("/my-workspaces")
      }
    }
  }, [sessionStatus, navigate])

  useEffect(() => {
    if (workspacesStatus === WorkspacesStatus.initial) {
      dispatch(initialise())
    }
  }, [workspacesStatus, dispatch])

  useEffect(() => {
    workspaces.forEach(w => {
      dispatch(getWorkflow(w.workspace.id))
    })
  }, [workspaces, dispatch])

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <CircularProgress />
    </Box>
  )
}

export default withAuthenticationRequired(LandingPage, {
  OnRedirecting: () => <RedirectToLogin />,
})
