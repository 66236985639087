import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"

import { useAppSelector } from "../../app/hooks"
import { selectOffers } from "../../app/slices/offers-slice"
import { selectWorkflow } from "../../app/slices/workspaces-slice"
import { formatCurrency, formatHumanDate } from "../../helpers/helpers"
import { fullName } from "../../helpers/helpers"
import OfferStatusDisplay from "./offer-status-display"
import { useNavigate } from "react-router-dom"
const OfferTable = () => {
  const offers = useAppSelector(selectOffers)
  const workflow = useAppSelector(selectWorkflow)
  // useEffect(() => {
  //   if (!workflow) {
  //     dispatch(getWorkflow(workspaceId))
  //   }
  // }, [dispatch, workflow, workspaceId])

  const navigate = useNavigate()
  return (
    <>
      {offers.length > 0 ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Buyers</TableCell>
                <TableCell>Purchase Price</TableCell>
                <TableCell>Deposit</TableCell>
                <TableCell>Settlement Date</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {workflow &&
                workflow.offers &&
                offers.map(offer => {
                  const workflowOffer = workflow?.offers.find(
                    wOffer => wOffer.offerId === offer.offer.id,
                  )
                  return (
                    <TableRow
                      hover
                      sx={{ cursor: "pointer" }}
                      key={offer.offer.id}
                      onClick={() => {
                        navigate(`offers/${offer.offer.id}`)
                      }}
                    >
                      <TableCell>
                        {offer.participants
                          ?.map(buyer => fullName(buyer))
                          .join(", ")}
                      </TableCell>
                      <TableCell>
                        {formatCurrency(offer.offer.purchasePrice)}
                      </TableCell>
                      <TableCell>
                        {formatCurrency(offer.offer.depositInitial)}
                      </TableCell>
                      <TableCell>
                        {formatHumanDate(offer.offer.settlementDate)}
                      </TableCell>
                      <TableCell>
                        <OfferStatusDisplay state={workflowOffer?.state} />
                      </TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h6" color="textSecondary">
            No Offers
          </Typography>
        </Box>
      )}
    </>
  )
}
export default OfferTable
