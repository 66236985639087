// material-ui
import { alpha, Theme } from '@mui/material/styles';
import { PaletteColor, ChipProps } from '@mui/material';

// Define valid color options type
type ChipColorType = NonNullable<ChipProps['color']>;
type ChipVariantType = NonNullable<ChipProps['variant']>;

const Chip = (theme: Theme) => {
  return {
    MuiChip: {
      defaultProps: {
        color: 'primary' as ChipColorType,
        variant: 'filled' as ChipVariantType
      },
      styleOverrides: {
        root: {
          '&.MuiChip-deletable .MuiChip-deleteIcon': {
            color: theme.palette.secondary.light
          },
          variants: [
            {
              props: { variant: 'light' as ChipVariantType },
              style: ({ ownerState }: { ownerState: { color: ChipColorType } }) => {
                // Handle the default color case separately
                if (ownerState.color === 'default') {
                  return {
                    color: theme.palette.grey[700],
                    backgroundColor: theme.palette.grey[200],
                    '&.MuiChip-clickable': {
                      '&:hover': {
                        color: theme.palette.grey[200],
                        backgroundColor: theme.palette.grey[700]
                      }
                    }
                  };
                }

                const paletteColor = theme.palette[ownerState.color] as PaletteColor;

                return {
                  ...(paletteColor && {
                    color: paletteColor.main,
                    backgroundColor: paletteColor.light,
                    ...(ownerState.color === 'error' && {
                      backgroundColor: alpha(paletteColor.light, 0.25)
                    }),
                    ...(ownerState.color === 'success' && {
                      backgroundColor: alpha(paletteColor.light, 0.5)
                    }),
                    ...((ownerState.color === 'warning' || ownerState.color === 'success') && {
                      color: paletteColor.dark
                    }),
                    '&.MuiChip-clickable': {
                      '&:hover': {
                        color: paletteColor.light,
                        backgroundColor: paletteColor.dark
                      }
                    }
                  })
                };
              }
            },
            {
              props: { variant: 'outlined', color: 'warning' as ChipColorType },
              style: {
                borderColor: theme.palette.warning.dark,
                color: theme.palette.warning.dark
              }
            },
            {
              props: { variant: 'outlined', color: 'success' as ChipColorType },
              style: {
                borderColor: theme.palette.success.dark,
                color: theme.palette.success.dark
              }
            }
          ]
        }
      }
    }
  };
}

export default Chip;
