import { useCallback } from "react"
import { useAppDispatch, useAppSelector } from "../../hooks"
import {
  selectMenu,
  selectIsDrawerOpen,
  selectActiveItem,
  selectOpenedComponent,
  selectIsComponentDrawerOpen,
  selectOpenedHorizontalItem,
  selectMenuItems,
  setDrawerOpen,
  setActiveItem,
  setComponentDrawerOpen,
  setOpenedComponent,
  setOpenedHorizontalItem,
} from "./menuSlice"

export const useMenu = () => {
  const dispatch = useAppDispatch()
  const menuState = useAppSelector(selectMenu)
  const isDrawerOpen = useAppSelector(selectIsDrawerOpen)
  const menuItems = useAppSelector(selectMenuItems)
  const activeItem = useAppSelector(selectActiveItem)
  const openedComponent = useAppSelector(selectOpenedComponent)
  const isComponentDrawerOpen = useAppSelector(selectIsComponentDrawerOpen)
  const openedHorizontalItem = useAppSelector(selectOpenedHorizontalItem)

  const handleDrawerOpen = useCallback(
    (isOpen: boolean) => {
      dispatch(setDrawerOpen(isOpen))
    },
    [dispatch],
  )

  const handleActiveItem = useCallback(
    (item: string) => {
      dispatch(setActiveItem(item))
    },
    [dispatch],
  )

  const handleComponentDrawerOpen = useCallback(
    (isOpen: boolean) => {
      dispatch(setComponentDrawerOpen(isOpen))
    },
    [dispatch],
  )

  const handleOpenedComponent = useCallback(
    (component: string) => {
      dispatch(setOpenedComponent(component))
    },
    [dispatch],
  )

  const handleOpenedHorizontalItem = useCallback(
    (item: string | null) => {
      dispatch(setOpenedHorizontalItem(item))
    },
    [dispatch],
  )

  return {
    // State
    menuState,
    isDrawerOpen,
    activeItem,
    openedComponent,
    isComponentDrawerOpen,
    openedHorizontalItem,
    menuItems,
    // Actions
    handleDrawerOpen,
    handleActiveItem,
    handleComponentDrawerOpen,
    handleOpenedComponent,
    handleOpenedHorizontalItem,
  }
}
