import Grid from "@mui/material/Grid"
import React from "react"
import { Participant } from "../landconnex-api-client"

import { Control, FieldErrors } from "react-hook-form"
import { gridSpacing } from "../constants"  
import FormInputPattern from "./form-input-pattern"
import FormInputText from "./form-input-text"

export interface ContactDetailsProps {
  control: Control<Participant>
  disabled?: boolean
  errors: FieldErrors<Participant>
}

const ContactDetails: React.FC<ContactDetailsProps> = ({
  control,
  disabled,
  errors,
}) => {
  return (
    <React.Fragment>
      <Grid item>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12} sm={3}>
            <FormInputPattern
              name="phone"
              format="##########"
              label="Phone"
              control={control}
              required
              disabled={disabled}
              error={errors.phone}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormInputPattern
              name="mobilePhone"
              format="##########"
              label="Mobile Phone"
              control={control}
              required
              disabled={disabled}
              error={errors.mobilePhone}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInputText
              name="email"
              label="Email"
              control={control}
              key="email"
              required
              disabled={disabled}
              error={errors.email}
            />
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default ContactDetails
