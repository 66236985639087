import Grid from "@mui/material/Grid2"
import { gridSpacing } from "../../../constants"
import { Button, Typography } from "@mui/material"
import FormInputText from "../../../components/form-input-text"
import { useForm } from "react-hook-form"
import {
  Licencee,
  TrustAccount,
} from "../../../landconnex-api-client"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import {
  putLicencee,
  selectLicencee,
  selectTrustAccount,
  putTrustAccount,
  getLicencee,
  getTrustAccount,
} from "../../../app/slices/team-slice"
import { useEffect } from "react"
import FormInputDatePicker from "../../../components/form-input-date-picker"

const AgencyDetails = () => {
  const licencee = useAppSelector(selectLicencee)
  const trustAccount = useAppSelector(selectTrustAccount)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getLicencee())
    dispatch(getTrustAccount())
  }, [dispatch])

  const licenceeForm = useForm<Licencee>({
    mode: "onChange",
    defaultValues: licencee ?? {
      name: "",
      number: "",
      expiryDate: "",
    },

    // resolver: zodResolver(teamSchema),
  })

  useEffect(() => {
    if (licencee) {
      licenceeForm.reset(licencee)
    }
  }, [licencee])

  useEffect(() => {
    if (trustAccount) {
      trustAccountForm.reset(trustAccount)
    }
  }, [trustAccount])

  const trustAccountForm = useForm<TrustAccount>({
    mode: "onChange",
    defaultValues: trustAccount ?? {
      accountHolderName: "",
      accountBSB: "",
      accountName: "",
      accountNumber: "",
      accountBankName: "",
    },

    // resolver: zodResolver(teamSchema),
  })
  const {
    handleSubmit: handleLicenceeSubmit,
    control: licenceeControl,

    formState: { errors: _licenceeErrors, isValid: licenceeValid },
  } = licenceeForm

  const {
    control: trustAccountControl,
    handleSubmit: handleTrustAccountSubmit,
    formState: { errors: trustAccountErrors, isValid: trustAccountValid },
  } = trustAccountForm

  const onLicenceeSubmit = (data: Licencee) => {
    dispatch(putLicencee(data))
  }

  const onTrustAccountSubmit = (data: TrustAccount) => {
    dispatch(putTrustAccount(data))
  }
  const onSubmit = () => {
    if (licenceeValid) {
      handleLicenceeSubmit(onLicenceeSubmit)()
    }
    if (trustAccountValid) {
      handleTrustAccountSubmit(onTrustAccountSubmit)()
    }
  }

  return (
    <Grid container spacing={gridSpacing}>
      <Grid size={{ xs: 12 }}>
        <Typography variant="h5">Licencee</Typography>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <Grid component="form" container spacing={gridSpacing}>
          <Grid size={6}>
            <FormInputText
              name="companyName"
              label="Organisation Name"
              control={licenceeControl}
            />
          </Grid>
          <Grid size={6}>
            <FormInputText
              name="tradingName"
              label="Trading Name"
              control={licenceeControl}
            />
          </Grid>
          <Grid size={3}>
            <FormInputText name="abn" label="ABN" control={licenceeControl} />
          </Grid>
          <Grid size={3}>
            <FormInputText name="acn" label="ACN" control={licenceeControl} />
          </Grid>
          <Grid size={3}>
            <FormInputText
              name="number"
              label="Licensee Number"
              control={licenceeControl}
            />
          </Grid>
          <Grid size={3}>
            <FormInputDatePicker
              name="expiryDate"
              label="License Expiry Date"
              control={licenceeControl}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid size={12}>
        <Typography variant="h6">Address</Typography>
      </Grid>
      <Grid size={12}>
        <Grid container spacing={gridSpacing}>
          {/* <Grid size={12}>
            <Autocomplete
              freeSolo
              id="address"
              fullWidth
              options={lookupProperty.map<FlattenedAddress>(m => m)}
              filterOptions={x => x}
              onInputChange={(_e, n) => setInputAddress(n)}
              disabled={disabled}
              onChange={(_event, addr) => {
                if (addr === null) {
                  return
                }
                const x = addr as FlattenedAddress
                handleAddressSelected(x?.pid!)
              }}
              isOptionEqualToValue={(a, b) => a.pid === b.pid}
              getOptionLabel={o => {
                const addr = o as FlattenedAddress
                return addr.sla
              }}
              renderInput={params => (
                <TextField {...params} label="look up an address" />
              )}
            />
          </Grid> */}
          <Grid size={6}>
            <FormInputText
              control={licenceeControl}
              name="contactDetails.address.streetAddress1"
              label="Street Address 1"
              required
            />
          </Grid>
          <Grid size={6}>
            <FormInputText
              control={licenceeControl}
              name="contactDetails.address.streetAddress2"
              label="Street Address 2"
            />
          </Grid>
          <Grid size={7}>
            <FormInputText
              control={licenceeControl}
              name="contactDetails.address.locality"
              label="Locality"
              required
            />
          </Grid>
          <Grid size={3}>
            <FormInputText
              control={licenceeControl}
              name="contactDetails.address.stateOrTerritory"
              label="State or Territory"
              required
            />
          </Grid>
          <Grid size={2}>
            <FormInputText
              control={licenceeControl}
              name="contactDetails.address.postCode"
              label="Post Code"
              required
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid size={12}>
        <Typography variant="h6">Contact Details</Typography>
      </Grid>
      <Grid size={12}>
        <Grid container spacing={gridSpacing}>
          <Grid size={3}>
            <FormInputText
              name="contactDetails.phone"
              label="Phone"
              control={licenceeControl}
            />
          </Grid>
          <Grid size={3}>
            <FormInputText
              name="contactDetails.mobilePhone"
              label="Mobile Phone"
              control={licenceeControl}
            />
          </Grid>
          <Grid size={6}>
            <FormInputText
              name="contactDetails.email"
              label="Email"
              control={licenceeControl}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <Typography variant="h5">Trust Account Bank Details</Typography>
      </Grid>
      <Grid component="form" container spacing={gridSpacing}>
        <Grid size={8}>
          <FormInputText
            name="accountBankName"
            control={trustAccountControl}
            label="Bank Name"
            error={trustAccountErrors.accountBankName}
          />
        </Grid>
        <Grid size={4}>
          <FormInputText
            name="accountBSB"
            control={trustAccountControl}
            label="BSB"
            error={trustAccountErrors.accountBSB}
          />
        </Grid>
        <Grid size={8}>
          <FormInputText
            name="accountName"
            control={trustAccountControl}
            label="Account Name"
            error={trustAccountErrors.accountName}
          />
        </Grid>
        <Grid size={4}>
          <FormInputText
            name="accountNumber"
            control={trustAccountControl}
            label="Account Number"
            error={trustAccountErrors.accountNumber}
          />
        </Grid>
        <Grid size={12}>
          <FormInputText
            name="accountHolderName"
            control={trustAccountControl}
            label="Account Holder Name"
            error={trustAccountErrors.accountHolderName}
          />
        </Grid>
      </Grid>

      <Grid size={12} sx={{ display: "flex", justifyContent: "center" }}>
        <Button variant="contained" color="primary" onClick={() => onSubmit()}>
          Save
        </Button>
      </Grid>
    </Grid>
  )
}

export default AgencyDetails
