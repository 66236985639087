import Box from "@mui/material/Box"
import { Button } from "@mui/material"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { useParams } from "react-router-dom"
import {
  addBenefitToAgent,
  getBenefitToAgents,
  selectBenefits,
} from "../../../app/slices/agent-appointment-slice"
import EditBenefit from "./edit-benefit"
import { useEffect } from "react"

const BenefitsData = () => {
  const dispatch = useAppDispatch()
  const params = useParams()
  const benefits = useAppSelector(selectBenefits)
  const workspaceId = Number(params.workspaceId)

  const addBenefit = () => {
    dispatch(
      addBenefitToAgent({
        workspaceId,
        benefitToAgent: { id: 0, service: "", source: "", estimatedAmount: "" },
      }),
    )
  }

  useEffect(() => {
    dispatch(getBenefitToAgents(workspaceId))
  }, [workspaceId, dispatch]) 

  return (
    <Box
      sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 1 }}
    >
      {benefits.map(benefit => (
        <EditBenefit key={benefit.id} benefit={benefit} />
      ))}

      <Box width={120} mt={2} alignSelf="center">
        <Button onClick={addBenefit} color="success">
          Add Benefit
        </Button>
      </Box>
    </Box>
  )
}

export default BenefitsData
