import {
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  TableBody,
  CardContent,
} from "@mui/material"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import {
  getWorkspaces,
  selectWorkspaces,
} from "../../../app/slices/workspaces-slice"

import WorkspaceRow from "../../../components/workspace-table/workspace-table-row"
import { useEffect } from "react"

const WorkspacesLarge = () => {
  const dispatch = useAppDispatch()
  const workspaces = useAppSelector(selectWorkspaces)

  useEffect(() => {
    dispatch(getWorkspaces())
  }, [dispatch])

  return (
    <CardContent>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Address</TableCell>
              <TableCell>Seller/s</TableCell>
              <TableCell>Agent</TableCell>
              <TableCell>Asking Price</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Offers</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {workspaces.map(workspace => (
              <WorkspaceRow
                key={workspace.workspace.id}
                workspace={workspace.workspace}
                workflow={workspace.workflow}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </CardContent>
  )
}

export default WorkspacesLarge
