import { Autocomplete, debounce, TextField } from "@mui/material"
import {
  Address,
  FlattenedAddress,
  PhysicalAustralianAddress,
} from "../landconnex-api-client"
import React, { useState } from "react"
import { addressSearchApi } from "../api"

interface AddressLookupProps {
  onAddressSelected: (address: PhysicalAustralianAddress, add: Address) => void
}

const AddressLookup = ({ onAddressSelected }: AddressLookupProps) => {
  const [matchingAddresses, setMatchingAddresses] = useState<
    Array<FlattenedAddress>
  >([])

  const generateStreetAddress1 = (
    address: PhysicalAustralianAddress,
  ): string => {
    let streetAddress1 = ""
    if (address.structured.buildingName) {
      streetAddress1 = `${address.structured.buildingName},`
    }
    if (address.structured.flat?.number) {
      streetAddress1 = ` ${streetAddress1}${address.structured.flat.type?.name} ${address.structured.flat?.number}, `
    }

    if (address.structured.street?.name) {
      streetAddress1 = ` ${streetAddress1}${address.structured.number?.number} ${address.structured.street?.name} ${address.structured.street?.type?.name}`
    }
    return streetAddress1
  }

  const handleAddressSelected = async (pid: string) => {
    const address = await addressSearchApi.getAddress(pid)

    const add: Address = {
      streetAddress1: generateStreetAddress1(address.data),
      streetAddress2: "",
      stateOrTerritory: address.data.structured.state?.abbreviation!,
      postCode: address.data.structured.postcode!,
      locality: address.data.structured.locality!.name,
      country: "Australia",
    }
    onAddressSelected(address.data, add)
  }

  const debouncedAddressLookup = React.useCallback(
    debounce(async (addressSearchTerm: string) => {
      try {
        // Dispatch to Redux for local state update
        const address = await addressSearchApi.searchAddress(addressSearchTerm)
        setMatchingAddresses(address.data.items!)

        // Always attempt to save to server
      } catch (serverError) {
        console.error("Autosave failed", serverError)
      }
    }, 1000),
    [],
  )

  return (
    <Autocomplete
      freeSolo
      id="address"
      fullWidth
      options={matchingAddresses.map<FlattenedAddress>(m => m)}
      filterOptions={x => x}
      onInputChange={(_e, n) => {
        debouncedAddressLookup(n)
      }}
      //   disabled={disabled}
      onChange={(_event, addr) => {
        if (addr === null) {
          return
        }
        const x = addr as FlattenedAddress
        handleAddressSelected(x?.pid!)
      }}
      isOptionEqualToValue={(a, b) => a.pid === b.pid}
      getOptionLabel={o => {
        const addr = o as FlattenedAddress
        return addr.sla
      }}
      renderInput={params => (
        <TextField {...params} label="look up an address" />
      )}
    />
  )
}

export default AddressLookup
