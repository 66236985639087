import { ActionReducerMapBuilder } from "@reduxjs/toolkit"
import {
  cancelInvitation,
  getTeamInvitations,
  getTeamMembers,
  inviteUserToTeam,
  removeUserFromTeam,
  updateUser,
} from "../../app/slices/team-members-slice"
import { Team, TeamInvitation, User } from "../../landconnex-api-client"
import { createAppSlice } from "../../app/createAppSlice"
import { getTeam } from "../../app/slices/settings-slice"

export enum ManageUsersStatus {
  initial,
  ready,
  updating,
}

export interface ManageUsersState {
  status: ManageUsersStatus
  members: Array<User>
  teamInvitations: Array<TeamInvitation>
  team: Team
}

const initialState: ManageUsersState = {
  status: ManageUsersStatus.initial,
  members: [],
  teamInvitations: [],
  team: {
    name: "",
    companyType: "solicitor"
  },
}

export const manageUsersSlice = createAppSlice({
  name: "manageUsers",
  initialState,
  reducers: {},
  extraReducers: builder => {
    getMembersReducers(builder)
    cancelInvitationReducers(builder)
    getTeamInvitationsReducers(builder)
    getTeamReducers(builder)
    inviteUserToTeamReducers(builder)
    updateUserReducers(builder)
    removeUserFromTeamReducers(builder)
  },
  selectors: {
    selectMembers: state => state.members,
    selectTeam: state => state.team,
    selectTeamInvitations: state => state.teamInvitations,
    selectStatus: state => state.status,
  },
})

function getMembersReducers(
  builder: ActionReducerMapBuilder<ManageUsersState>,
) {
  builder.addCase(getTeamMembers.fulfilled, (state, action) => {
    state.status = ManageUsersStatus.ready
    state.members = action.payload.users!
  })

  builder.addCase(getTeamMembers.pending, state => {
    state.status = ManageUsersStatus.updating
  })
}

function getTeamReducers(builder: ActionReducerMapBuilder<ManageUsersState>) {
  builder.addCase(getTeam.fulfilled, (state, action) => {
    state.status = ManageUsersStatus.ready
    state.team = action.payload
  })

  builder.addCase(getTeam.pending, state => {
    state.status = ManageUsersStatus.updating
  })
}

function removeUserFromTeamReducers(
  builder: ActionReducerMapBuilder<ManageUsersState>,
) {
  builder.addCase(removeUserFromTeam.fulfilled, state => {
    state.status = ManageUsersStatus.initial
  })

  builder.addCase(removeUserFromTeam.pending, state => {
    state.status = ManageUsersStatus.updating
  })
}

function updateUserReducers(
  builder: ActionReducerMapBuilder<ManageUsersState>,
) {
  builder.addCase(updateUser.fulfilled, state => {
    state.status = ManageUsersStatus.initial
  })

  builder.addCase(updateUser.pending, state => {
    state.status = ManageUsersStatus.updating
  })
}

function cancelInvitationReducers(
  builder: ActionReducerMapBuilder<ManageUsersState>,
) {
  builder.addCase(cancelInvitation.fulfilled, state => {
    state.status = ManageUsersStatus.initial
  })

  builder.addCase(cancelInvitation.pending, state => {
    state.status = ManageUsersStatus.updating
  })
}

function inviteUserToTeamReducers(
  builder: ActionReducerMapBuilder<ManageUsersState>,
) {
  builder.addCase(inviteUserToTeam.fulfilled, state => {
    state.status = ManageUsersStatus.initial
  })

  builder.addCase(inviteUserToTeam.pending, state => {
    state.status = ManageUsersStatus.updating
  })
}
function getTeamInvitationsReducers(
  builder: ActionReducerMapBuilder<ManageUsersState>,
) {
  builder.addCase(getTeamInvitations.fulfilled, (state, action) => {
    state.teamInvitations = action.payload
    state.status = ManageUsersStatus.ready
  })

  builder.addCase(getTeamInvitations.pending, state => {
    state.status = ManageUsersStatus.updating
  })
}
export const {} = manageUsersSlice.actions

export const {
  selectMembers,
  selectTeam,
  selectTeamInvitations,
  selectStatus,
} = manageUsersSlice.selectors
