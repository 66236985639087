import { Box } from "@mui/material"

import { TabPanelProps } from "."

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, sx, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: 3,
            height: "calc(100vh - 180px)", // Account for header and tabs
            overflowY: "auto",
            ...sx,
          }}
        >
          {children}
        </Box>
      )}
    </div>
  )
}

export default CustomTabPanel
