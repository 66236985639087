import { debounce, Paper } from "@mui/material"
import Grid from "@mui/material/Grid2"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { putContract, selectContract } from "../../../app/slices/contract-slice"
import FormInputText from "../../../components/form-input-text"
import { useForm } from "react-hook-form"
import { Contract } from "../../../landconnex-api-client"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"
import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import { Properties } from "../../../helpers/helpers"
import { gridSpacing } from "../../../constants"

const DepositStep: React.FC = ({}) => {
  const contract = useAppSelector(selectContract)
  const dispatch = useAppDispatch()
  const params = useParams()
  const workspaceId = Number(params.workspaceId)

  const contractSchema = z.object<Properties<Contract>>({
    depositHolderBank: z.string().min(1, "Deposit Holder Bank is required"),
    gstPaymentRequired: z.boolean().optional(),
    depositHolderBsb: z
      .string()
      .min(6, "Deposit Holder BSB must be 6 characters")
      .max(6, "Deposit Holder BSB must be 6 characters"),
    depositHolderAccountName: z
      .string()
      .min(1, "Deposit Holder Account Name is required"),
    depositHolderAccountNumber: z
      .string()
      .min(1, "Deposit Holder Account Number is required"),
    depositHolderName: z.string().min(1, "Deposit Holder Name is required"),
    account: z.string().min(1, "Account is required"),
    bank: z.string().min(1, "Bank is required"),
    bsb: z.string().min(6, "BSB is required").max(6, "BSB is required"),
    buildingAndPestInspection: z.string().optional(),
    buyerRegisteredForGst: z.boolean().optional(),
    createdAt: z.string().optional(),
    createdBy: z.string().optional(),
    id: z.number(),
    defaultInterestRate: z.string().optional(),
    depositBalanceDue: z.string().optional(),
    depositBalance: z.number().optional(),
    depositHolder: z.string().optional(),
    depositInitial: z.number().optional(),
    depositInitialDue: z.string().optional(),
    encumbrances: z.string().optional(),
    excludedFixtures: z.string().optional(),
    externalReference: z.string().optional(),
    financeAmount: z.string().optional(),
    financeDate: z.string().optional(),
    financier: z.string().optional(),
    hasEncumbrances: z.boolean(),
    hasNeighbourhoodDisputes: z.boolean(),
    hasRtaAgreement: z.boolean(),
    lastRentIncreaseDate: z.string().optional(),
    hasPool: z.boolean(),
    hasPoolCertificate: z.boolean(),
    hasSafetySwitches: z.boolean(),
    hasSellerSolicitor: z.boolean(),
    hasSmokeAlarms: z.boolean(),
    hasTenant: z.boolean(),
    includedChattels: z.string().optional(),
    purchasePrice: z.number().optional(),
    buyerSpecialConditions: z.string().optional(),
    sellerSpecialConditions: z.string().optional(),
    status: z.string(),
    tenancyBond: z.number().optional(),
    tenancyRent: z.number().optional(),
    tenancyTenantName: z.string().optional(),
    tenancyTermAndOptions: z.string().optional(),
    tenancyTermEnd: z.string().optional(),
    tenancyTermStart: z.string().optional(),
    trustAccount: z.string().optional(),
    modifiedAt: z.string().optional(),
    modifiedBy: z.string().optional(),
    reference: z.string().optional(),
    settlementDate: z.string().optional(),
  })

  const depositForm = useForm<Contract>({
    mode: "onChange",
    defaultValues: {
      depositHolderBank: contract?.depositHolderBank,
      depositHolderBsb: contract?.depositHolderBsb,
      depositHolderAccountName: contract?.depositHolderAccountName,
      depositHolderAccountNumber: contract?.depositHolderAccountNumber,
      depositHolderName: contract?.depositHolderName,
    },
    resolver: zodResolver(contractSchema),
  })

  const {
    control,
    watch,
    reset,
    formState: { errors, isDirty },
  } = depositForm

  const formValues = watch()

  const debouncedAutoSave = React.useCallback(
    debounce(async (data: Contract) => {
      dispatch(putContract({ contract: data, workspaceId: workspaceId }))
      reset(data, { keepErrors: true })
    }, 1000),
    [dispatch, workspaceId],
  )

  useEffect(() => {
    if (isDirty) {
      debouncedAutoSave(formValues)
    }
  }, [formValues, isDirty, debouncedAutoSave])

  return (
    <Paper sx={{ padding: gridSpacing }}>
      <Grid component="form" container spacing={gridSpacing}>
          <Grid size={8}>
            <FormInputText
              name="depositHolderBank"
              control={control}
              label="Deposit Holder Bank"
              error={errors.depositHolderBank}
            />
          </Grid>
          <Grid size={4}>
            <FormInputText
              name="depositHolderBsb"
              control={control}
              label="Deposit Holder BSB"
              error={errors.depositHolderBsb}
            />
          </Grid>
          <Grid size={8}>
            <FormInputText
              name="depositHolderAccountName"
              control={control}
              label="Deposit Holder Account Name"
              error={errors.depositHolderAccountName}
            />
          </Grid>
          <Grid size={4}>
            <FormInputText
              name="depositHolderAccountNumber"
              control={control}
              label="Deposit Holder Account Number"
              error={errors.depositHolderAccountNumber}
            />
          </Grid>
          <Grid size={12}>
            <FormInputText
              name="depositHolderName"
              control={control}
              label="Deposit Holder Name"
              error={errors.depositHolderName}
            />
          </Grid>
        </Grid>
    </Paper>
  )
}

export default DepositStep
