import { useEffect, useState } from "react"
import {
  PaymentElement,
  useStripe,
  useElements,
  AddressElement,
  Elements,
} from "@stripe/react-stripe-js"
import { Box, Button, Alert, Typography, Paper } from "@mui/material"

import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import {
  completeOnBoarding,
  createSetupIntent,
  selectSetupIntent,
} from "../../../app/slices/team-slice"
import { appConfig } from "../../../constants"
import { loadStripe } from "@stripe/stripe-js"

interface PaymentFormProps {
  onSuccess: () => void
}

export const PaymentFormContainer = () => {
  const stripePromise = loadStripe(appConfig.stripePublicKey)
  const setupIntent = useAppSelector(selectSetupIntent)
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (!setupIntent) {
      dispatch(createSetupIntent())
    }
  }, [setupIntent])

  return (
    setupIntent && (
      <Elements
        stripe={stripePromise}
        options={{
          clientSecret: setupIntent!.clientSecretID,
        }}
      >
        <PaymentForm onSuccess={() => {}} />
      </Elements>
    )
  )
}

const PaymentForm = ({ onSuccess }: PaymentFormProps) => {
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useAppDispatch()
  const [error, setError] = useState<string | null>(null)
  const [processing, setProcessing] = useState(false)

  const setupIntent = useAppSelector(selectSetupIntent)
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()

    if (!stripe || !elements) {
      return
    }

    setProcessing(true)
    setError(null)

    try {
      const { error: submitError } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/settings/setup-intent/${setupIntent!.setupIntentID}`,
        },
      })

      if (submitError) {
        setError(submitError.message || "An error occurred")
      } else {
        onSuccess()
        dispatch(
          completeOnBoarding({
            setupIntentID: setupIntent!.setupIntentID!,
          }),
        )
      }
    } catch (e) {
      setError("An unexpected error occurred")
    } finally {
      setProcessing(false)
    }
  }

  return (
    <Paper sx={{ p: 2 }}>
      <Box component="form" onSubmit={handleSubmit}>
        <Box sx={{ pt: 2 }}>
          <Typography variant="body1">
            Our simple pay-as-you-go pricing means no monthly fees. A standard
            workspace package is approximately $80 and provides:
            <ul>
              <li>Complete workspace setup and configuration</li>
              <li>Full property title extract</li>
              <li>
                Two or more digital signing envelopes (extra envelopes available
                as needed for multiple offers)
              </li>
            </ul>
          </Typography>
        </Box>
        <Typography variant="h6" gutterBottom>
          Credit Card Details
        </Typography>
        <AddressElement
          options={{
            mode: "billing",
            allowedCountries: ["AU"],
          }}
        />
        <PaymentElement />
        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
        <Button
          type="submit"
          variant="contained"
          disabled={!stripe || processing}
          sx={{ width: 230 }}
        >
          {processing ? "Processing..." : "Save"}
        </Button>
      </Box>
    </Paper>
  )
}
export default PaymentForm
