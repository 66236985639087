import { FC } from "react"

import { Controller, FieldError } from "react-hook-form"
import { TextField } from "@mui/material"
import { FormInputProps } from "./events"

export interface FormInputTextProps extends FormInputProps {
  multiline?: boolean
  minRows?: string | number
  disabled?: boolean
  error?: FieldError | undefined
  placeholder?: string
}

const FormInputText: FC<FormInputTextProps> = ({
  name,
  control,
  label,
  required,
  minRows,
  multiline,
  disabled,
  error,
  placeholder,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <TextField
          {...field}
          value={field.value}
          key={name}
          label={label}
          
          fullWidth
          required={required}
          helperText={error?.message}
          error={!!error}
          aria-invalid={error ? true : false}
          autoComplete="false"
          multiline={multiline}
          placeholder={placeholder}
          minRows={minRows}
          disabled={disabled}
        />
      )}
    />
  )
}

export default FormInputText
