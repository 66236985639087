import Box from "@mui/material/Box"
import { Button } from "@mui/material"
import { useEffect } from "react"
import {
  addFee,
  getFees,
  selectFees,
} from "../../../app/slices/agent-appointment-slice"
import { useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import EditFee from "./fee"

const FeesData = () => {
  const dispatch = useAppDispatch()
  const params = useParams()
  const workspaceId = Number(params.workspaceId)

  const fees = useAppSelector(selectFees)
  useEffect(() => {
    dispatch(getFees(workspaceId))
  }, [workspaceId, dispatch])

  const handleAddFields = () => {
    dispatch(
      addFee({
        workspaceId,
        fee: {
          id: 0,
          description: "",
          whenPayable: "",
          estimatedAmount: 0,
        },
      }),
    )
  }

  return (
    <Box
      sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 1 }}
    >
      {fees.map(fee => (
        <EditFee key={fee.id} fee={fee} />
      ))}
      <Box width={100} mt={2} alignSelf="center">
        <Button onClick={handleAddFields} color="success">
          Add Fee
        </Button>
      </Box>
    </Box>
  )
}

export default FeesData
