import { FC, useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  Box,
} from "@mui/material"
import { withAuthenticationRequired } from "react-oidc-context"
import RedirectToLogin from "../../components/redirect-to-login"
import {
  getWorkspaces,
  selectWorkspacesStatus,
  WorkspacesStatus,
} from "../../app/slices/workspaces-slice"
import {
  initialise,
  selectSessionStatus,
  SessionStatus,
} from "../../app/slices/session-slice"
import WorkspacesSmallMedium from "./components/workspaces-small-medium"

const MyWorkspacesPage: FC = () => {

  const workspacesStatus = useAppSelector(selectWorkspacesStatus)
  const dispatch = useAppDispatch()
  const sessionStatus = useAppSelector(selectSessionStatus)

  // const roles = useAppSelector(getRoles)

  useEffect(() => {
    if (sessionStatus == SessionStatus.initial) {
      dispatch(initialise())
    }
  }, [sessionStatus, dispatch])
  useEffect(() => {
    if (workspacesStatus === WorkspacesStatus.initial) {
      dispatch(getWorkspaces())
    }
  }, [workspacesStatus, dispatch])

 



  return (
       <>
      <Box
        id="property-image"
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: "url(/retail-background-desktop.png)",
          backgroundSize: "cover",
          backgroundPosition: "top",
          backgroundRepeat: "no-repeat",
          opacity: 0.5,
          zIndex: 0,
        }}
      />

      <Box
        sx={{
          position: "relative",
          padding: 2,
        }}
      >
        <WorkspacesSmallMedium />
      </Box>
    </>
  )
}

export default withAuthenticationRequired(MyWorkspacesPage, {
  OnRedirecting: () => <RedirectToLogin />,
  signinRedirectArgs: { redirect_uri: window.location.href },
})
