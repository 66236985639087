import { FC } from "react"

import { Controller, FieldError } from "react-hook-form"
import { FormInputProps } from "./events"
import { DatePicker } from "@mui/x-date-pickers"
import dayjs from "dayjs"

interface FormInputDatePickerProps extends FormInputProps {
  disabled?: boolean
  error?: FieldError | undefined
}

const FormInputDatePicker: FC<FormInputDatePickerProps> = ({
  name,
  label,
  control,
  required,
  disabled = false,
  error,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <DatePicker
          label={label}
          value={dayjs(field.value)}
          disabled={disabled}
          aria-invalid={error ? true : false}
          onChange={value => {
            const d = value?.toISOString()
            field.onChange(d)
          }}
          slotProps={{
            textField: {
              error: error ? true : false,
              helperText: error ? error.message : "",
              fullWidth: true,
              required,
            },
          }}
        />
      )}
    />
  )
}

export default FormInputDatePicker
