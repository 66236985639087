import { titlesApi } from "../../api"
import { SearchByLotAndPlanPlanTypeEnum, TitleSearchByLotPlan, TitleSummary, ValidAddressDetails } from "../../landconnex-api-client"
import { createAppSlice } from "../createAppSlice"
import { Status } from "./status"

export enum TitleStatus {
  ready = "ready",
  loading = "loading",
  error = "error",
}

export interface TitleState {
  validateAddressStatus: Status
  validateAddress: ValidAddressDetails[]
  titleSummary: TitleSummary | undefined
  titleSummaryStatus: Status
  searchByLotAndPlanStatus: Status
  searchByLotAndPlan: TitleSearchByLotPlan[]
}

export const initialState: TitleState = {
  validateAddressStatus: Status.initial,
  validateAddress: [],
  titleSummary: undefined,
  titleSummaryStatus: Status.initial,
  searchByLotAndPlanStatus: Status.initial,
  searchByLotAndPlan: [],
}

export interface SearchByLotAndPlanParams {
  lotNumber: string
  planNumber: string
  planType: SearchByLotAndPlanPlanTypeEnum
}

export const titlesSlice = createAppSlice({
  name: "titles",
  initialState,
  reducers: create => ({

    searchForTitleByLotAndPlan: create.asyncThunk<TitleSearchByLotPlan[], SearchByLotAndPlanParams>(
      async (params) => {
        

        

        const result = await titlesApi.searchByLotAndPlan(params.lotNumber, params.planNumber, params.planType)
        return result.data.items!
      },
      {
        pending: state => {
          state.searchByLotAndPlanStatus = Status.loading
        },
        fulfilled: (state, action) => {
          state.searchByLotAndPlanStatus = Status.ready
          state.searchByLotAndPlan = action.payload
        },
        rejected: state => {
          state.searchByLotAndPlanStatus = Status.error
        },
      },
    ),

    validateAddress: create.asyncThunk<ValidAddressDetails[], string>(
      async (address) => {
        const result = await titlesApi.validateAddress(address)
        return result.data.items!
      },
      {
        pending: state => {
          state.validateAddressStatus = Status.loading
        },
        fulfilled: (state, action) => {
          state.validateAddressStatus = Status.ready
          action.payload.forEach((item, index) => {
            if (item.metaData) {
              item.metaData.push({
                name: `key`,
                value: index.toString(),
              })
            }
          })
          state.validateAddress = action.payload
        },
        rejected: state => {
          state.validateAddressStatus = Status.error
        },
      },
    ),

    
    getTitleSummary: create.asyncThunk<TitleSummary, string>(
      async (titleNumber) => {
        const result = await titlesApi.getTitleSummary(titleNumber)
        return result.data
      },
      {
        pending: state => {
          state.validateAddressStatus = Status.loading
        },
        fulfilled: (state, action) => {
          state.validateAddressStatus = Status.ready
          state.titleSummary = action.payload
        },
        rejected: state => {
          state.validateAddressStatus = Status.error
        },
      },
    ),
  }),
  selectors: {
    selectValidateAddress: titlesState => titlesState.validateAddress,
    selectValidateAddressStatus: titlesState => titlesState.validateAddressStatus,
    selectTitleSummary: titlesState => titlesState.titleSummary,
    selectTitleSummaryStatus: titlesState => titlesState.titleSummaryStatus,
    selectSearchByLotAndPlan: titlesState => titlesState.searchByLotAndPlan,
    selectSearchByLotAndPlanStatus: titlesState => titlesState.searchByLotAndPlanStatus,
  },
})

export const { getTitleSummary, validateAddress, searchForTitleByLotAndPlan } = titlesSlice.actions
export const { selectValidateAddress, selectValidateAddressStatus, selectTitleSummary, selectTitleSummaryStatus, selectSearchByLotAndPlan, selectSearchByLotAndPlanStatus } = titlesSlice.selectors
