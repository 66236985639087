import { FC, Fragment, useState } from "react"
import {
  List,
  Typography,
  Box,
  Grid2,
  Button,
  Stack,
  useMediaQuery,
  useTheme,
  Card,
  CardHeader,
  CardContent,
  Collapse,
  IconButton,
  Chip,
  Divider,
  ListItem,
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import { formatCurrency, formatDate } from "../../helpers/helpers"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  presentOfferToSeller,
  selectOffers,
  sendForSigning,
  startOfferWorkflow,
} from "../../app/slices/offers-slice"
import {
  selectWorkflow,
  selectWorkspace,
} from "../../app/slices/workspaces-slice"
import OfferStatusDisplay from "./offer-status-display"
import ParticipantListItem from "../participant-list-item"

import { ParticipantRoleEnum, Workflow } from "../../landconnex-api-client"
import DoneIcon from "@mui/icons-material/Done"
import { useNavigate } from "react-router-dom"

export interface OfferCardProps {
  offerId: number
}
const OfferCard: FC<OfferCardProps> = ({ offerId }) => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"))
  const isMedium = useMediaQuery(theme.breakpoints.between("sm", "md"))
  const workspace = useAppSelector(selectWorkspace)
  const navigate = useNavigate()
  const offers = useAppSelector(selectOffers)
  const offer = offers.find(offer => offer.offer.id === offerId)
  const workflow = useAppSelector(selectWorkflow)
  const offerWorkflow = workflow?.offers.find(
    workflow => workflow.offerId === offerId,
  )

  const concatenatedBuyerNames = offer?.participants
    ?.map(participant => participant.firstName + " " + participant.lastName)
    .join(", ")

  const handleShareWithSeller = (id: number): void => {
    dispatch(
      presentOfferToSeller({
        workspaceId: workspace?.workspace?.id!,
        offerId: id,
      }),
    )
  }

  const OfferWorkflowControls = () => {
    return (
      <Fragment>
        {workflow?.state !== "acceptingOffers" && (
          <Grid2 size={12}>
            <Box sx={{ border: "1px solid #ccc", borderRadius: 1, p: 1 }}>
              <Typography variant="h6" color="#666">
                Offers cannot be started until the contract disclosures are
                prepared.
              </Typography>
            </Box>
          </Grid2>
        )}
        {offerWorkflow === undefined &&
          workflow?.state === "acceptingOffers" && (
            <Grid2 size={12}>
              <Box sx={{ p: 1 }}>
                <Typography variant="h6" color="#666">
                  Offer not started
                </Typography>
                <Typography component="div" variant="body1" color="#666">
                  This offer has not been started. Until it is started, you
                  cannot:
                  <List
                    sx={{
                      listStyleType: "disc",
                      "& .MuiListItem-root": {
                        display: "list-item",
                        listStylePosition: "inside",
                        pl: 1,
                        py: 0,
                      },
                    }}
                  >
                    <ListItem>
                      share the offer with the seller or buyer
                    </ListItem>
                    <ListItem>send the offer for signing</ListItem>
                  </List>
                </Typography>
                <Box width={130} mx="auto" mt={2}>
                  <Button
                    onClick={() =>
                      dispatch(
                        startOfferWorkflow({
                          workspaceId: workspace?.workspace?.id!,
                          offerId: offerId,
                        }),
                      )
                    }
                  >
                    Start Offer
                  </Button>
                </Box>
              </Box>
            </Grid2>
          )}
      </Fragment>
    )
  }

  const ContractSigningStatus = () => {
    return (
      <Box
        sx={{
          width: "100%",
          mt: { md: 0, sm: 2 },
          px: 1,
        }}
      >
        {offerWorkflow !== undefined &&
          workflow?.state === "acceptingOffers" && (
            <>
              <Typography variant="h6" color="#666" mt={{ lg: 0, md: 2 }}>
                Contract Signing Status
              </Typography>
              {offerWorkflow === undefined &&
                workflow?.state !== "acceptingOffers" && (
                  <Typography variant="body2" color="#666" align="center">
                    Signing cannot be started until the contract disclosures are
                    prepared.
                  </Typography>
                )}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "space-evenly",
                  my: 1,
                  gap: 2,
                }}
              >
                {offerSigning(offerId, workflow, "buyer")}
                {offerSigning(offerId, workflow, "seller")}
              </Box>
            </>
          )}
      </Box>
    )
  }

  const offerSigning = (
    offerId: number,
    workflow: Workflow | undefined,
    role: ParticipantRoleEnum,
  ) => {
    const handleSendForSigning = () => {
      dispatch(
        sendForSigning({
          workspaceId: workspace?.workspace?.id!,
          offerId: offerId,
        }),
      )
    }
    let label
    let actionDisabled = true
    let showDoneIcon = false
    const offerWorkflow = workflow?.offers.find(
      offer => offer.offerId === offerId,
    )
    if (!offerWorkflow) {
      label = "Not Started"
    } else if (role == "buyer") {
      switch (offerWorkflow!.state) {
        case "OfferCreated":
          label = "Not Started"
          break
        case "OfferBuyerSigningSent":
          label = "Awaiting Buyer Signing"
          break
        case "OfferBuyerSigningDeclined":
          label = "Buyer Declined"
          break
        default:
          label = "Buyer Signed"
          showDoneIcon = true
          break
      }
    } else {
      switch (offerWorkflow!.state) {
        case "OfferCreated":
        case "OfferBuyerSigningSent":
        case "OfferBuyerSigningDeclined":
          label = "Awaiting Buyer Signing"
          break
        case "OfferBuyerSigningCompleted":
          label = "Ready to Sign"
          break
        case "OfferSellerSigningSent":
          label = "Awaiting Seller Signing"
          break
        case "OfferSellerSigningDeclined":
          label = "Seller Declined"
          break
        default:
          label = "Seller Signed"
          showDoneIcon = true
          break
      }
    }

    if (
      role == "buyer" &&
      (workspace?.workspace?.role == "buyer" ||
        workspace?.workspace?.role == "sellerAgent") &&
      offerWorkflow?.state == "OfferCreated"
    ) {
      actionDisabled = false
    }

    if (
      role == "seller" &&
      (workspace?.workspace?.role == "seller" ||
        workspace?.workspace?.role == "sellerAgent") &&
      offerWorkflow?.state == "OfferBuyerSigningCompleted"
    ) {
      actionDisabled = false
    }

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography variant="body1">
            {role == "buyer" ? "Buyer" : "Seller"}
          </Typography>
          <Typography variant="caption">({label})</Typography>
        </Box>
        {showDoneIcon && <DoneIcon color="success" />}
        <Box
          sx={{
            width: "100px",
          }}
        >
          <Button
            sx={{
              mx: "auto",
              mt: 1,
            }}
            disabled={actionDisabled}
            onClick={() => handleSendForSigning()}
          >
            Sign
          </Button>
        </Box>
      </Box>
    )
  }

  const large = () => {
    return (
      <Grid2 container>
        <Grid2
          size={{ lg: 6, md: 12 }}
          sx={{ borderRight: { lg: "1px solid #ccc", md: "none" } }}
        >
          <Grid2
            container
            size={12}
            columnGap={3}
            sx={{
              justifyContent: "center",
              my: 1,
            }}
          >
            <Box>
              {workspace?.workspace?.role == "sellerAgent" &&
              !offerWorkflow?.sharedWithSeller! ? (
                <Fragment>
                  <Box width={165} mx="auto">
                    <Button onClick={() => handleShareWithSeller(offerId)}>
                      Share with Seller
                    </Button>
                  </Box>
                </Fragment>
              ) : (
                <Chip label="Shared with Seller" />
              )}
            </Box>
          </Grid2>

          <Divider />
          <List>
            {offer?.participants?.map(participant => (
              <ParticipantListItem
                key={participant.id}
                participant={participant}
              />
            ))}
          </List>
          <Divider />
        </Grid2>
        <Grid2
          container
          size={{ lg: 6, md: 12 }}
          sx={{
            pl: { lg: 1, md: 0 },
            pb: 1,
          }}
        >
          <ContractSigningStatus />
          <OfferWorkflowControls />
        </Grid2>
      </Grid2>
    )
  }

  if (isSmall || isMedium) {
    const [expanded, setExpanded] = useState(false)
    return (
      <Card>
        <CardHeader
          title={
            <Stack direction="row" justifyContent="space-between" spacing={1}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6" color="#666" fontWeight="bold">
                  {concatenatedBuyerNames}
                </Typography>
                <Typography variant="caption">
                  <OfferStatusDisplay state={offerWorkflow?.state} />
                </Typography>
              </Box>
              <IconButton onClick={() => setExpanded(!expanded)}>
                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </Stack>
          }
        />
        <CardContent sx={{ paddingTop: 0 }}>
          <Grid2 container>
            <Grid2 size={8}>
              <Typography variant="h5" color="#666" fontWeight="bold">
                {formatCurrency(offer?.offer.purchasePrice)}
              </Typography>
              <Typography variant="caption">Offer Amount</Typography>
            </Grid2>
            <Grid2 size={4}>
              <Typography variant="h6" color="#666" fontWeight="bold">
                {formatDate(offer?.offer.settlementDate)}
              </Typography>
              <Typography variant="caption">Settlement Date</Typography>
            </Grid2>
          </Grid2>

          <Collapse in={expanded}>
            <Fragment>
              <Box
                sx={{
                  paddingTop: 2,
                  width: 150,
                  mx: "auto",
                }}
              >
                <Button
                  color="info"
                  onClick={() => navigate(`/offer/${offerId}/contract`)}
                >
                  View Contract
                </Button>
              </Box>
              <List sx={{ borderBottom: "1px solid #ccc" }}>
                {offer?.participants?.map(participant => (
                  <ParticipantListItem participant={participant} />
                ))}
              </List>
              <ContractSigningStatus />
              <OfferWorkflowControls />
            </Fragment>
          </Collapse>
        </CardContent>
      </Card>
    )
  }
  return large()
}

export default OfferCard
