import { FC, Fragment } from "react"
import {
  Grid,
  Typography,
  TextField,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Link,
} from "@mui/material"
import { Notes } from "../../features/agent-appointment/components/form-wrapper"

export interface LandUseProps {}

const LandUse: FC<LandUseProps> = () => {
  const notes = [
    {
      title: "Warning to Buyer",
      items: [
        "You may not have any rights if the current or proposed use of the property is not lawful under the local planning scheme. You can obtain further information about any planning and development restrictions applicable to the lot, including in relation to short-term letting, from the relevant local government.",
      ],
    },
  ]

  return (
    <Fragment>
      <Grid item>
        <Notes notes={notes} />
        <form>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} my={2}>
              {/* <Typography variant="h6" mb={4}>
                Registered encumbrances
              </Typography> */}

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  borderBottom: "1px solid silver",
                  paddingBottom: 3,
                  gap: 2,
                }}
              >
                <Typography variant="body1">
                  The zoning of the property is (Insert zoning under the
                  planning scheme, the Economic Development Act 2012; the
                  Integrated Resort Development Act 1987; the Mixed Use
                  Development Act 199; the State Development and Public Works
                  Organisation Act 1971 or the Sanctuary Cove Resort Act 1985,
                  as applicable):
                </Typography>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label={"Insert applicable zoning"}
                    size="small"
                    fullWidth
                    autoComplete="false"
                    variant="filled"
                  />
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} my={2}>
              <Typography variant="h6" mb={4}>
                Transport proposals and resumptions
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  borderBottom: "1px solid silver",
                  paddingBottom: 3,
                  gap: 2,
                }}
              >
                <Typography variant="body1">
                  The lot is affected by a notice issued by a Commonwealth,
                  State or local government entity and given to the seller about
                  a transport infrastructure proposal* to: locate transport
                  infrastructure on the property; or alter the dimensions of the
                  property.
                </Typography>

                <RadioGroup row>
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                  <FormControlLabel
                    value="idk"
                    control={<Radio />}
                    label="I Don't Know"
                  />
                </RadioGroup>

                <Typography variant="body1">
                  The lot is affected by a notice of intention to resume the
                  property or any part of the property.
                </Typography>

                <RadioGroup row>
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                  <FormControlLabel
                    value="idk"
                    control={<Radio />}
                    label="I Don't Know"
                  />
                </RadioGroup>
                <Typography variant="caption">
                  If Yes, a copy of the notice, order, proposal or
                  correspondence must be given by the seller.
                </Typography>
              </Box>
              <Typography variant="subtitle1">
                * Transport infrastructure has the meaning defined in the
                Transport Infrastructure Act 1994. A proposal means a resolution
                or adoption by some official process to establish plans or
                options that will physically affect the property.
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} my={2}>
              <Typography variant="h6" mb={4}>
                Contamination and environmental protection
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  borderBottom: "1px solid silver",
                  paddingBottom: 3,
                  gap: 2,
                }}
              >
                <Typography variant="body1">
                  The property is recorded on the Environmental Management
                  Register or the Contaminated Land Register under the
                  Environmental Protection Act 1994.
                </Typography>

                <RadioGroup row>
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                  <FormControlLabel
                    value="idk"
                    control={<Radio />}
                    label="I Don't Know"
                  />
                </RadioGroup>

                <Typography variant="body1" className="roboto-bold">
                  The following notices are, or have been, given:
                </Typography>

                <Typography variant="body1">
                  A notice under section 408(2) of the Environmental Protection
                  Act 1994 (for example, land is contaminated, show cause
                  notice, requirement for site investigation, clean up notice or
                  site management plan).
                </Typography>

                <RadioGroup row>
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                  <FormControlLabel
                    value="idk"
                    control={<Radio />}
                    label="I Don't Know"
                  />
                </RadioGroup>

                <Typography variant="body1">
                  A notice under section 369C(2) of the Environmental Protection
                  Act 1994 (the property is a place or business to which an
                  environmental enforcement order applies).
                </Typography>

                <RadioGroup row>
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                  <FormControlLabel
                    value="idk"
                    control={<Radio />}
                    label="I Don't Know"
                  />
                </RadioGroup>

                <Typography variant="body1">
                  A notice under section 347(2) of the Environmental Protection
                  Act 1994 (the property is a place or business to which a
                  prescribed transitional environmental program applies).
                </Typography>

                <RadioGroup row>
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                  <FormControlLabel
                    value="idk"
                    control={<Radio />}
                    label="I Don't Know"
                  />
                </RadioGroup>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} my={2}>
              <Typography variant="h6" mb={4}>
                Trees
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  borderBottom: "1px solid silver",
                  paddingBottom: 3,
                  gap: 2,
                }}
              >
                <Typography variant="body1">
                  There is a tree order or application under the Neighbourhood
                  Disputes (Dividing Fences and Trees) Act 2011 affecting the
                  property.
                </Typography>

                <RadioGroup row>
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                  <FormControlLabel
                    value="idk"
                    control={<Radio />}
                    label="I Don't Know"
                  />
                </RadioGroup>

                <Typography variant="caption">
                  If Yes, a copy of the notice, order, proposal or
                  correspondence must be given by the seller.
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} my={2}>
              <Typography variant="h6" mb={4}>
                Heritage
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  borderBottom: "1px solid silver",
                  paddingBottom: 3,
                  gap: 2,
                }}
              >
                <Typography variant="body1">
                  The property is affected by the Queensland Heritage Act 1992
                  or is included in the World Heritage List under the
                  Environment Protection and Biodiversity Conservation Act 1999
                  (Cwlth).
                </Typography>

                <RadioGroup row>
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                  <FormControlLabel
                    value="idk"
                    control={<Radio />}
                    label="I Don't Know"
                  />
                </RadioGroup>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} my={2}>
              <Typography variant="h6" mb={4}>
                Flooding
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  borderBottom: "1px solid silver",
                  paddingBottom: 3,
                  gap: 2,
                }}
              >
                <Typography variant="body1">
                  Information about whether the property is affected by flooding
                  or another natural hazard or within a natural hazard overlay
                  can be obtained from the relevant local government and you
                  should make your own enquires. Flood information for the
                  property may also be available at the
                  <Link
                    href="https://floodcheck.information.qld.gov.au/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    FloodCheck Queensland{" "}
                  </Link>
                  portal or the{" "}
                  <Link
                    href="https://afrip.ga.gov.au/flood-study-web/#/search/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Australian Flood Risk Information{" "}
                  </Link>{" "}
                  portal.
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} my={2}>
              <Typography variant="h6" mb={4}>
                Vegetation, habitats and protected plants
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  borderBottom: "1px solid silver",
                  paddingBottom: 3,
                  gap: 2,
                }}
              >
                <Typography variant="body1">
                  Information about vegetation clearing, koala habitats and
                  other restrictions on development of the land that may apply
                  can be obtained from the relevant State government agency.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Fragment>
  )
}

export default LandUse
