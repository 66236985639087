import StepLabel from "@mui/material/StepLabel"
import Step from "@mui/material/Step"
import Stepper from "@mui/material/Stepper"
import { FC, useEffect, useState } from "react"
import {
  Workflow,
  WorkflowAgentAppointmentEnum,
  WorkflowContractPreparationEnum,
  WorkflowOfferNegotiationEnum,
  WorkflowStateEnum,
} from "../../landconnex-api-client"
import { Typography} from "@mui/material"
interface TimelineProps {
  workflow: Workflow
}

interface Step {
  label: string
  subLabel:
    | WorkflowAgentAppointmentEnum
    | WorkflowContractPreparationEnum
    | WorkflowOfferNegotiationEnum
  completed: boolean
  inProgress: boolean
  notStarted: boolean
}
const Timeline: FC<TimelineProps> = ({ workflow }) => {

  const formatSubLabel = (
    subLabel:
      | WorkflowAgentAppointmentEnum
      | WorkflowContractPreparationEnum
      | WorkflowOfferNegotiationEnum,
  ) => {
    switch (subLabel) {
      case WorkflowAgentAppointmentEnum.AgentAppointmentPreparing:
        return "Preparing for appointment"
      case WorkflowAgentAppointmentEnum.AgentAppointmentAwaitingSigning:
        return "Awaiting signing"
      case WorkflowAgentAppointmentEnum.AgentAppointmentSigned:
        return "Signed"
      case WorkflowAgentAppointmentEnum.AgentAppointmentDeclined:
        return "Declined"
      case WorkflowAgentAppointmentEnum.AgentAppointmentComplete:
        return "Complete"
      case WorkflowContractPreparationEnum.ContractPreparationNotStarted:
        return "Not started"
      case WorkflowContractPreparationEnum.ContractPreparationAwaitingSellerSolicitorSelection:
        return "Awaiting seller solicitor selection"
      case WorkflowContractPreparationEnum.ContractPreparationAwaitingSellerSolicitorAdmission:
        return "Awaiting seller solicitor admission"
      case WorkflowContractPreparationEnum.ContractPreparationPreparing:
        return "Preparing"
      case WorkflowContractPreparationEnum.ContractPreparationComplete:
        return "Complete"
      case WorkflowOfferNegotiationEnum.OfferNegotiationNotStarted:
        return "Not started"
      case WorkflowOfferNegotiationEnum.OfferNegotiationAcceptingOffers:
        return "Accepting offers"
      case WorkflowOfferNegotiationEnum.OfferNegotiationComplete:
        return "Contract signed"
    }
  }
  const [activeStep, setActiveStep] = useState(0)
  const [steps] = useState<Step[]>([
    {
      label: "Agent Appointment",
      subLabel: workflow.agentAppointment,
      completed: true,
      inProgress: false,
      notStarted: false,
    },
    {
      label: "Contract Preparation",
      subLabel: workflow.contractPreparation,
      completed: false,
      inProgress: true,
      notStarted: false,
    },
    {
      label: "Offer Negotiation",
      subLabel: workflow.offerNegotiation,
      completed: false,
      inProgress: false,
      notStarted: true,
    },
  ])
  useEffect(() => {
    switch (workflow.state) {
      case WorkflowStateEnum.AgentAppointment:
        setActiveStep(0)
        break
      case WorkflowStateEnum.ContractPreparation:
        setActiveStep(1)
        break
      case WorkflowStateEnum.AcceptingOffers:
        setActiveStep(2)
        break
    }
  }, [workflow])

  return (
    <Stepper activeStep={activeStep}>
      {steps.map((step, index) => {
        const stepProps: { completed?: boolean } = {}
        const labelProps: {
          optional?: React.ReactNode
        } = {}
        labelProps.optional = (
          <Typography variant="caption">
            {formatSubLabel(step.subLabel)}
          </Typography>
        )

        return (
          <Step key={index} {...stepProps}>
            <StepLabel
              {...labelProps}
             
            >
              {step.label}
            </StepLabel>
          </Step>
        )
      })}
    </Stepper>
  )
}

export default Timeline
