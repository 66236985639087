// assets
import MenuItem from "./menu-item"
// constant

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const settings: MenuItem = {
  id: "settings",
  title: "Settings",
  type: "group",
  children: [
    {
      id: "my-details",
      title: "My Details",
      type: "item",
      url: "/settings",
      icon: "IconUser",
      breadcrumbs: false,
    },
    {
      id: "account-settings",
      title: "Account",
      type: "item",
      url: "/settings/team",
      icon: "IconSettings",
      breadcrumbs: false,
      
    },
  ],
}

export default settings
