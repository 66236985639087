import { FC, Fragment, useState } from "react"
import {
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
  Box,
} from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"

export interface TitleDetailsProps {}

const TitleDetails: FC<TitleDetailsProps> = () => {
  const [encumbrance, setEncumbrance] = useState("")
  const [accommodation, setAccommodation] = useState("")

  return (
    <Fragment>
      <Grid item>
        <form>
          <Grid container>
            <Grid item xs={12} sm={12} my={2}>
              <Typography variant="h6">Title details</Typography>
              <Typography variant="h6" mb={4}>
                The seller gives or has given the buyer the following—
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  borderBottom: "1px solid silver",
                  paddingBottom: 3,
                }}
              >
                <RadioGroup>
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label=" A title search for the property issued under the Land Title
                  Act 1994 showing interests registered under that Act for the
                  property."
                  />
                </RadioGroup>

                <RadioGroup>
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label=" A copy of the plan of survey registered for the property."
                  />
                </RadioGroup>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} my={2}>
              <Typography variant="h6" mb={4}>
                Registered encumbrances
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  borderBottom: "1px solid silver",
                  paddingBottom: 3,
                  gap: 2,
                }}
              >
                <Typography variant="body1">
                  Registered encumbrances, if any, are recorded on the title
                  search, and may affect your use of the property. Examples
                  include easements, statutory covenants, leases and mortgages.
                </Typography>
                <Typography variant="body1">
                  You should seek legal advice about your rights and obligations
                  before signing the contract.
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} my={2}>
              <Typography variant="h6" mb={4}>
                Statutory encumbrances
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  borderBottom: "1px solid silver",
                  paddingBottom: 3,
                }}
              >
                <Typography variant="body1">
                  There are statutory encumbrances that affect the property.
                </Typography>

                <RadioGroup
                  value={encumbrance}
                  onChange={e => setEncumbrance(e.target.value)}
                  row
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                  <FormControlLabel
                    value="idk"
                    control={<Radio />}
                    label="I Don't Know"
                  />
                </RadioGroup>

                {encumbrance === "yes" && (
                  <TextField
                    label="Insert a description of the encumbrance and a copy of the plan showing the location of the infrastructure, if available, is given"
                    size="small"
                    fullWidth
                    autoComplete="off"
                    variant="filled"
                    multiline
                    minRows={4}
                  />
                )}
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} my={2}>
              <Typography variant="h6" mb={4}>
                Residential tenancy or rooming accommodation agreement
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography variant="body1">
                  The property has been subject to a residential tenancy
                  agreement or a rooming accommodation agreement under the
                  Residential Tenancies and Rooming Accommodation Act 2008
                  during the last 12 months.
                </Typography>

                <RadioGroup
                  value={accommodation}
                  onChange={e => setAccommodation(e.target.value)}
                  row
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                  <FormControlLabel
                    value="idk"
                    control={<Radio />}
                    label="I Don't Know"
                  />
                </RadioGroup>

                {accommodation === "yes" && (
                  <Box my={3}>
                    <Typography variant="body1">
                      when was the rent for the premises or each of the
                      residents’ rooms last increased?
                    </Typography>
                    <Typography variant="caption">
                      (Insert date of the most recent rent increase for the
                      premises or rooms)
                    </Typography>
                    <Grid item xs={12} sm={4} my={2}>
                      <DatePicker
                        slotProps={{
                          textField: {
                            size: "small",
                            fullWidth: true,
                            variant: "filled",
                          },
                        }}
                      />
                    </Grid>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Fragment>
  )
}

export default TitleDetails
