import * as React from "react"
import Box from "@mui/material/Box"
import { debounce, IconButton } from "@mui/material"
import { Delete } from "@mui/icons-material"
import { useAppDispatch } from "../../../app/hooks"
import { useParams } from "react-router-dom"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { BenefitToAgent } from "../../../landconnex-api-client"
import {
  updateBenefitToAgent,
  deleteBenefitToAgent,
} from "../../../app/slices/agent-appointment-slice"
import FormInputText from "../../../components/form-input-text"
import FormInputNumeric from "../../../components/form-input-numeric"
import { useCallback, useEffect } from "react"
import { benefitSchema } from "../../../utils/validation-schemas"

export interface BenefitDataProps {
  benefit: BenefitToAgent
}

const EditBenefit: React.FC<BenefitDataProps> = ({ benefit }) => {
  const dispatch = useAppDispatch()
  const params = useParams()
  const workspaceId = Number(params.workspaceId)

  const schema = benefitSchema

  const {
    control,
    watch,
    reset,
    formState: { isDirty, errors },
  } = useForm<BenefitToAgent>({
    resolver: zodResolver(schema),
    mode: "onChange",
    defaultValues: benefit,
  })

  const formValues = watch()

  const handleOnBenefitChanged = useCallback(
    (data: BenefitToAgent) => {
      try {
        dispatch(
          updateBenefitToAgent({
            workspaceId: workspaceId,
            benefitToAgent: data,
          }),
        )
        reset(data, { keepErrors: true })
      } catch (serverError) {
        console.error("Autosave failed", serverError)
      }
    },
    [dispatch, workspaceId, reset],
  )

  const debouncedAutoSave = React.useCallback(
    debounce(async (data: BenefitToAgent) => {
      handleOnBenefitChanged(data)
    }, 1000),
    [handleOnBenefitChanged],
  )

  useEffect(() => {
    if (isDirty) {
      debouncedAutoSave(formValues)
    }
  }, [formValues, isDirty, debouncedAutoSave])

  const handleDelete = () => {
    dispatch(
      deleteBenefitToAgent({ workspaceId, benefitToAgentId: benefit.id }),
    )
  }

  return (
    <Box
      sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 1 }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <FormInputText
          control={control}
          name="service"
          label="Service"
          error={errors.service}
        />
        <FormInputText
          control={control}
          name="source"
          label="Source"
          error={errors.source}
        />
        <FormInputNumeric
          control={control}
          name="estimatedAmount"
          label="Estimated Amount"
          error={errors.estimatedAmount}
        />
        <IconButton onClick={() => handleDelete()} color="error">
          <Delete />
        </IconButton>
      </Box>
    </Box>
  )
}

export default EditBenefit
