import { MouseEvent, useState } from "react"
import PropTypes from "prop-types"

// material-ui
import { useTheme } from "@mui/material/styles"
import Avatar from "@mui/material/Avatar"
import Grid from "@mui/material/Grid2"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"

// project imports
import MainCard from "../../../components/cards/MainCard"
// import SkeletonEarningCard from 'ui-component/cards/Skeleton/EarningCard';

// assets
import EarningIcon from "../../../assets/icons/earning"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
// import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import GetAppTwoToneIcon from "@mui/icons-material/GetAppOutlined"
import FileCopyTwoToneIcon from "@mui/icons-material/FileCopyOutlined"
import PictureAsPdfTwoToneIcon from "@mui/icons-material/PictureAsPdfOutlined"
import ArchiveTwoToneIcon from "@mui/icons-material/ArchiveOutlined"
import { CircularProgress } from "@mui/material"
import {
  selectTotalListingsValue,
} from "../../../app/slices/reports-slice"
import { useAppSelector } from "../../../app/hooks"
import { formatCurrency } from "../../../helpers/helpers"

interface IEarningCardProps {
  isLoading?: boolean
}

const ListingsValueCard = ({ isLoading }: IEarningCardProps): JSX.Element => {
  const theme = useTheme()
  const totalListingsValue = useAppSelector(selectTotalListingsValue)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  return (
    <>
      {isLoading ? (
        // <SkeletonEarningCard />
        <CircularProgress />
      ) : (
        <MainCard
          border={false}
          content={false}
          aria-hidden={Boolean(anchorEl)}
          sx={{
            bgcolor: "secondary.dark",
            color: "#fff",
            overflow: "hidden",
            position: "relative",
            "&:after": {
              content: '""',
              position: "absolute",
              width: 210,
              height: 210,
              background: theme.palette.secondary.dark,
              borderRadius: "50%",
              top: { xs: -85 },
              right: { xs: -95 },
            },
            "&:before": {
              content: '""',
              position: "absolute",
              width: 210,
              height: 210,
              background: theme.palette.secondary.light,
              borderRadius: "50%",
              top: { xs: -125 },
              right: { xs: -15 },
              opacity: 0.5,
            },
          }}
        >
          <Box sx={{ p: 2.25 }}>
            <Grid container direction="column">
              <Grid>
                <Grid container sx={{ justifyContent: "space-between" }}>
                  <Grid>
                    <Avatar
                      variant="rounded"
                      sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        bgcolor: "secondary.800",
                        mt: 1,
                      }}
                    >
                      <EarningIcon />
                    </Avatar>
                  </Grid>
                  <Grid>
                    <Avatar
                      variant="rounded"
                      sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        bgcolor: "secondary.dark",
                        color: "secondary.200",
                        zIndex: 1,
                      }}
                      aria-controls="menu-earning-card"
                      aria-haspopup="true"
                      onClick={handleClick}
                    >
                      <MoreHorizIcon fontSize="inherit" />
                    </Avatar>
                    <Menu
                      id="menu-earning-card"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      variant="selectedMenu"
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <MenuItem onClick={handleClose}>
                        <GetAppTwoToneIcon sx={{ mr: 1.75 }} /> Import Card
                      </MenuItem>
                      <MenuItem onClick={handleClose}>
                        <FileCopyTwoToneIcon sx={{ mr: 1.75 }} /> Copy Data
                      </MenuItem>
                      <MenuItem onClick={handleClose}>
                        <PictureAsPdfTwoToneIcon sx={{ mr: 1.75 }} /> Export
                      </MenuItem>
                      <MenuItem onClick={handleClose}>
                        <ArchiveTwoToneIcon sx={{ mr: 1.75 }} /> Archive File
                      </MenuItem>
                    </Menu>
                  </Grid>
                </Grid>
              </Grid>
              <Grid>
                <Grid container sx={{ alignItems: "center" }}>
                  <Grid>
                    <Typography
                      sx={{
                        fontSize: "2.125rem",
                        fontWeight: 500,
                        mr: 1,
                        mt: 1.75,
                        mb: 0.75,
                      }}
                    >
                      {formatCurrency(totalListingsValue)}
                    </Typography>
                  </Grid>
                  {/* <Grid>
                    <Avatar
                      sx={{
                        cursor: "pointer",
                        ...theme.typography.smallAvatar,
                        bgcolor: "secondary.200",
                        color: "secondary.dark",
                      }}
                    >
                      <ArrowUpwardIcon
                        fontSize="inherit"
                        sx={{ transform: "rotate3d(1, 1, 1, 45deg)" }}
                      />
                    </Avatar>
                  </Grid> */}
                </Grid>
              </Grid>
              <Grid sx={{ mb: 1.25 }}>
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: 500,
                    color: "secondary.200",
                  }}
                >
                  Total Listings Value
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </MainCard>
      )}
    </>
  )
}

ListingsValueCard.propTypes = { isLoading: PropTypes.bool }

export default ListingsValueCard
