import { Box, Typography } from "@mui/material"
import Grid from "@mui/material/Grid2"

import { useTheme } from "@mui/material/styles"
import { Fragment, useState } from "react"

export interface GuideProps {
  title: string
  subtitle: string
  steps: string[]
  stepInstructions: React.ReactNode[]
  images: React.ReactNode[]
}

const Guide: React.FC<GuideProps> = ({
  title,
  subtitle,
  steps,
  stepInstructions,
  images,
}) => {
  const theme = useTheme()
  const [currentStep, setCurrentStep] = useState(0)

  return (
    <Grid container sx={{ height: "100%" }}>
      <Grid size={4} bgcolor={theme.palette.info.light}>
        <Grid
          container
          sx={{
            p: 2.25,
            paddingTop: "100px",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Grid size={12}>
            <Typography
              variant="h1"
              color={theme.palette.info.contrastText}
              sx={{
                fontSize: "2.5rem",
                fontWeight: "bold",
                mb: 2,
              }}
            >
              {title}
            </Typography>
            <Typography
              variant="h3"
              color={theme.palette.info.contrastText}
              sx={{
                fontSize: "1.75rem",
                mb: 4,
              }}
            >
              {subtitle}
            </Typography>
          </Grid>
          <Grid size={12}>
            <ol
              style={{
                color: theme.palette.info.contrastText,
                fontSize: "1.25rem",
                paddingLeft: 0,
                listStyle: "none",
                margin: 0,
              }}
            >
              {steps.map((step, index) => (
                <li
                  onClick={() => setCurrentStep(index)}
                  key={index}
                  style={{
                    marginBottom: "1rem",
                    cursor: "pointer",
                    padding: "0.5rem",
                    borderRadius: "4px",
                    backgroundColor:
                      currentStep === index
                        ? "rgba(255, 255, 255, 0.1)"
                        : "transparent",
                    transition: "background-color 0.2s ease",
                  }}
                >
                  {step}
                </li>
              ))}
            </ol>
          </Grid>
          <Grid sx={{ pt: 10 }} size={12}>
            {stepInstructions[currentStep]}
          </Grid>
        </Grid>
      </Grid>
      <Grid size={8} bgcolor={theme.palette.info.dark}>
        <Grid container sx={{ p: 2.25 }}>
          <Grid size={12}>
            <Box>
              <Fragment>{images[currentStep]}</Fragment>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Guide
