import { useEffect, Fragment } from "react"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import {
  Address,
  PhysicalAustralianAddress,
  Team,
  TeamCompanyTypeEnum,
} from "../../../landconnex-api-client"
import {
  getTeam,
  updateTeam,
  selectTeamStatus,
  selectTeam,
  TeamStatus,
} from "../../../app/slices/team-slice"
import {
  Typography,
  Box,
  Button,
  Grid,
  Paper,
  CircularProgress,
  Alert,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { gridSpacing } from "../../../constants"
import AddressLookup from "../../../components/address-lookup"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { teamSchema } from "../../../utils/validation-schemas"
import FormInputText from "../../../components/form-input-text"

const TeamPage = () => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"))
  const isMedium = useMediaQuery(theme.breakpoints.between("sm", "md"))

  if (isSmall || isMedium) {
    return (
      <Box>
        <ViewTeam />
      </Box>
    )
  }
  return <ViewTeam />
}

const ViewTeam = () => {
  const team = useAppSelector(selectTeam)
  const status = useAppSelector(selectTeamStatus)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (team === undefined) {
      dispatch(getTeam())
    }
  }, [team, dispatch])

  if (status === TeamStatus.loading) {
    return (
      <Box display="flex" justifyContent="center" p={4}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box>
      {status === TeamStatus.ready && team && <TeamForm team={team} />}
      {status === TeamStatus.error && team && (
        <Fragment>
          <Alert severity="error">Failed to load team details</Alert>
          <TeamForm team={team} />
        </Fragment>
      )}
    </Box>
  )
}

const TeamForm = ({ team }: { team: Team }) => {
  const dispatch = useAppDispatch()
  const status = useAppSelector(selectTeamStatus)

  const teamForm = useForm<Team>({
    defaultValues:
      team == null
        ? {
            abn: "",
            acn: "",
            companyType: TeamCompanyTypeEnum.RealEstateAgency,
            name: "",
            primaryColour: "",
            secondaryColour: "",
            websiteUrl: "",
            primaryContact: {
              name: {
                firstName: "",
                lastName: "",
                middleName: "",
                organisationName: "",
              },
              email: "",
              phone: "",
              mobilePhone: "",
              address: {
                streetAddress1: "",
                streetAddress2: "",
                locality: "",
                stateOrTerritory: "",
                postCode: "",
                country: "",
              },
            },
          }
        : team,
    mode: "onChange",
    resolver: zodResolver(teamSchema),
  })

  const {
    reset,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isValid },
  } = teamForm

  const onSubmit = (data: Team) => {
    if (isValid) {
      dispatch(updateTeam(data))
      reset(data)
    }
  }

  const handleAddressSelected = (
    _: PhysicalAustralianAddress,
    formattedAddress: Address,
  ) => {
    setValue(
      "primaryContact.address.streetAddress1",
      formattedAddress.streetAddress1,
    )
    setValue(
      "primaryContact.address.streetAddress2",
      formattedAddress.streetAddress2,
    )
    setValue("primaryContact.address.locality", formattedAddress.locality)
    setValue(
      "primaryContact.address.stateOrTerritory",
      formattedAddress.stateOrTerritory,
    )
    setValue("primaryContact.address.postCode", formattedAddress.postCode)
  }

  return (
    <Paper sx={{ padding: 2 }}>
      <form>
        <Grid container spacing={gridSpacing} mb={gridSpacing}>
          <Grid item xs={12}>
            <Typography variant="h5">Name</Typography>
          </Grid>
          <Grid item xs={12} md={4} lg={6}>
            <FormInputText
              name="name"
              label="Name"
              control={control}
              required={true}
              error={errors.name}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={6}>
            <FormInputText
              name="websiteUrl"
              label="Website URL"
              control={control}
              error={errors.websiteUrl}
            />
          </Grid>
        </Grid>
        <Grid container spacing={gridSpacing} mb={gridSpacing}>
          <Grid item xs={12}>
            <Typography variant="h5">Primary Contact</Typography>
          </Grid>
          <Grid item xs={12} md={4} lg={6}>
            <FormInputText
              name="primaryContact.name.firstName"
              label="First Name"
              required={true}
              error={errors.primaryContact?.name?.firstName}
              control={control}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={6}>
            <FormInputText
              name="primaryContact.name.lastName"
              label="Last Name"
              control={control}
              required={true}
              error={errors.primaryContact?.name?.lastName}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={6}>
            <FormInputText
              name="primaryContact.email"
              label="Email Address"
              control={control}
              required={true}
              error={errors.primaryContact?.email}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <FormInputText
              name="primaryContact.phone"
              label="Phone"
              control={control}
              required={true}
              error={errors.primaryContact?.phone}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <FormInputText
              name="primaryContact.mobilePhone"
              label="Mobile"
              control={control}
              required={true}
              error={errors.primaryContact?.mobilePhone}
            />
          </Grid>
        </Grid>

        <Grid container spacing={gridSpacing} mb={gridSpacing}>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              Street Address
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <AddressLookup onAddressSelected={handleAddressSelected} />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <FormInputText
              name="primaryContact.address.streetAddress1"
              label="Street Address 1"
              control={control}
              required={true}
              error={errors.primaryContact?.address?.streetAddress1}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <FormInputText
              name="primaryContact.address.streetAddress2"
              label="Street Address 2"
              control={control}
              error={errors.primaryContact?.address?.streetAddress2}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <FormInputText
              name="primaryContact.address.locality"
              label="Locality"
              control={control}
              required={true}
              error={errors.primaryContact?.address?.locality}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <FormInputText
              name="primaryContact.address.stateOrTerritory"
              label="State/Territory"
              control={control}
              required={true}
              error={errors.primaryContact?.address?.stateOrTerritory}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <FormInputText
              name="primaryContact.address.postCode"
              label="Post Code"
              control={control}
              required={true}
              error={errors.primaryContact?.address?.postCode}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <FormInputText
              name="primaryContact.address.country"
              label="Country"
              control={control}
              required={true}
              error={errors.primaryContact?.address?.country}
            />
          </Grid>
        </Grid>

        <Grid container spacing={gridSpacing} mb={gridSpacing}>
          <Grid item xs={12}>
            <Typography variant="h5">Business Details</Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormInputText
              name="abn"
              label="ABN"
              control={control}
              required={true}
              error={errors.abn}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormInputText
              name="acn"
              label="ACN"
              control={control}
              required={true}
              error={errors.acn}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1} my={1}>
          <Grid item xs={12}>
            <Box width={120} mx="auto" mt="30px">
              <Button
                size="large"
                variant="contained"
                onClick={() => {
                  handleSubmit(data => {
                    if (isValid) {
                      onSubmit(data)
                    }
                  })()
                }}
                disabled={status === TeamStatus.loading}
              >
                Save
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Paper>
  )
}

export default TeamPage
