const authority = (window as any)["AUTHORITY"]
const clientId = (window as any)["CLIENT_ID"]
const redirectUri = (window as any)["REDIRECT_URI"]
const workspacesUri = (window as any)["KONVEI_MATTERS_URI"]
const eventsUri = (window as any)["EVENTS_URI"]
const conveyancingDocumentsApi = (window as any)[
  "KONVEI_CONVEYANCING_DOCUMENTS_URI"
]
const stripePublicKey = (window as any)["STRIPE_PUBLIC_KEY"]
const imageBucket = (window as any)["IMAGE_BUCKET"]
export const appConfig = {
  authority,
  clientId,
  workspacesUri,
  conveyancingDocumentsApi,
  redirectUri,
  eventsUri,
  stripePublicKey,
  imageBucket,
}

// theme constant
export const gridSpacing = 3
export const drawerWidth = 260
export const appDrawerWidth = 320
export const borderRadius = 12
