import { Participant } from "../../../landconnex-api-client"
import { OnParticipantChanged } from "../../../components/events"
import ClientDetail from "./client-detail"
import { Button, Box, Typography } from "@mui/material"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { useParams } from "react-router-dom"
import {
  postParticipant,
  selectParticipants,
} from "../../../app/slices/participants-slice"
import {
  getTitle,
  selectTitle,
  selectWorkspace,
} from "../../../app/slices/workspaces-slice"
import AddOutlinedIcon from "@mui/icons-material/AddOutlined"
import { Fragment } from "react/jsx-runtime"
import { useEffect } from "react"

export type OnAddBuyer = () => void

export interface SellerDetailsProps {
  sellers: Array<Participant>
  onChanged: OnParticipantChanged
  onAdded: OnAddBuyer
  onRemoved: OnParticipantChanged
}
const SellerDetails: React.FC = ({}) => {
  const participants = useAppSelector(selectParticipants)
  const workspace = useAppSelector(selectWorkspace)
  const dispatch = useAppDispatch()
  const params = useParams()
  const workspaceId = Number(params.workspaceId)
  const title = useAppSelector(selectTitle)
  let disableField = true
  if (workspace != undefined) {
    disableField = ["buyer", "buyerSolicitor"].includes(
      workspace!.workspace!.role!,
    )
  }

  const sellers = participants.filter(p => p.role == "seller")

  useEffect(() => {
    dispatch(getTitle(workspaceId))
  }, [dispatch, workspaceId])

  return (
    <Fragment>
      {title?.ownerOrLessorsIsTrustee && (
        <Box
          sx={{
            border: "1px solid #ccc",
            padding: 1,
            borderRadius: 2,
            marginBottom: 1,
          }}
        >
          <Typography variant="h6">Trustee</Typography>
          <pre>
            <Typography variant="body1">{title?.titleOwnerOrLessor}</Typography>
          </pre>
        </Box>
      )}
      {sellers.map((client, index) => (
        <ClientDetail
          key={client.id}
          index={index}
          client={client}
          participantId={client.id}
          disabled={disableField}
        />
      ))}

      <Box sx={{ width: 120, margin: "0 auto 10px auto" }}>
        <Button
          size="small"
          color="success"
          onClick={() => {
            dispatch(
              postParticipant({
                participant: { role: "seller" },
                workspaceId: workspaceId,
              }),
            )
          }}
          startIcon={<AddOutlinedIcon />}
        >
          seller
        </Button>
      </Box>
    </Fragment>
  )
}

export default SellerDetails
