import FormControlLabel from "@mui/material/FormControlLabel"
import Grid from "@mui/material/Grid"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import Typography from "@mui/material/Typography"
import { FC, useEffect, useState } from "react"
import {
  AgentAppointment,
  AgentAppointmentAppointmentTermTypeEnum,
} from "../../../landconnex-api-client"
import { OnAgentAppointmentChanged } from "../../../components/events"
import FormControl from "@mui/material/FormControl"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  debounce,
  FormGroup,
} from "@mui/material"
import FormInputText from "../../../components/form-input-text"
import FormInputDatePicker from "../../../components/form-input-date-picker"

import CurrencyField from "../../../components/currency-field"
import { useParams } from "react-router-dom"
import { useAppSelector, useAppDispatch } from "../../../app/hooks"
import {
  selectAgentAppointment,
  updateAgentAppointment,
} from "../../../app/slices/agent-appointment-slice"
import { zodResolver } from "@hookform/resolvers/zod"
import React from "react"
import FormInputNumeric from "../../../components/form-input-numeric"
import { agentAppointmentSchema } from "../../../utils/validation-schemas"
import dayjs from "dayjs"

export interface AppointmentProps {
  agent: AgentAppointment
  onAgentChanged: OnAgentAppointmentChanged
}
const Appointment: FC = () => {
  const agentAppointment = useAppSelector(selectAgentAppointment)
  const params = useParams()
  const dispatch = useAppDispatch()
  const workspaceId = Number(params.workspaceId)

  const [localAppointment, setLocalAppointment] = useState<AgentAppointment>({
    ...agentAppointment,
  })

  const schema = agentAppointmentSchema

  const agentAppointmentForm = useForm<AgentAppointment>({
    defaultValues: {
      ...agentAppointment,
      appointmentTermStart:
        agentAppointment?.appointmentTermStart || dayjs().toISOString(),
      appointmentTermEnd:
        agentAppointment?.appointmentTermEnd ||
        dayjs().add(90, "days").toISOString(),
    },

    resolver: zodResolver(schema),
    mode: "onBlur",
  })

  const {
    control,
    watch,
    formState: { errors, isDirty },
    setValue,
    reset,
  } = agentAppointmentForm

  const formValues = watch()

  const debouncedSave = React.useCallback(
    debounce((data: AgentAppointment) => {
      dispatch(updateAgentAppointment({ workspaceId, agentAppointment: data }))
      reset(data, { keepErrors: true })
    }, 1000),
    [dispatch, workspaceId],
  )

  useEffect(() => {
    if (isDirty) {
      debouncedSave(formValues)
    }
  }, [formValues, isDirty])

  const onSubmit: SubmitHandler<AgentAppointment> = data => {
    dispatch(
      updateAgentAppointment({
        workspaceId: workspaceId,
        agentAppointment: data,
      }),
    )
  }

  useEffect(() => {
    if (formValues.appointmentTermStart) {
      const newEndDate = dayjs(formValues.appointmentTermStart)
        .add(90, "days")
        .startOf("day")
        .toISOString()

      setValue("appointmentTermEnd", newEndDate, { shouldValidate: true })
    }
  }, [formValues.appointmentTermStart, setValue])

  console.log("Start Date:", formValues.appointmentTermStart)
  console.log(
    "Computed End Date:",
    dayjs(formValues.appointmentTermStart).add(90, "days").format("YYYY-MM-DD"),
  )

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target
    const updatedAgentAppointment = { ...localAppointment }

    switch (name) {
      case "Sale":
        updatedAgentAppointment.appointmentIsSale = checked
        break
      case "Purchase":
        updatedAgentAppointment.appointmentIsPurchase = checked
        break
      case "Letting":
        updatedAgentAppointment.appointmentIsLetting = checked
        break
      case "Leasing":
        updatedAgentAppointment.appointmentIsLeasing = checked
        break
      case "Auction":
        updatedAgentAppointment.appointmentIsAuction = checked
        break
      case "Other":
        updatedAgentAppointment.appointmentIsOther = checked
        break
      default:
        break
    }

    setLocalAppointment(updatedAgentAppointment)
    onSubmit(updatedAgentAppointment)
  }

  return (
    <form>
      <Card sx={{ border: "none" }}>
        <CardHeader
          title="Appointment of property agent"
          inheritlayout="true"
        />
        <CardContent>
          <Box
            className="section-1"
            sx={{
              display: "flex",
              flexDirection: "column",
              marginBottom: 4,
              borderBottom: "1px solid silver",
              paddingBottom: 5,
              gap: 1,
            }}
          >
            <Typography variant="h6" component="span" className="roboto-bold">
              Section 1
            </Typography>
            <Box>
              <Typography variant="h6">Performance of Service</Typography>
              <Typography variant="body2">
                Annexures detailing the performance of service may be attached
                if required.
              </Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl component="fieldset"  fullWidth>
                  <Typography component="legend">Service</Typography>
                  <FormGroup sx={{ display: "inline-block" }}>
                    {[
                      { name: "Sale", field: "appointmentIsSale" },
                      { name: "Purchase", field: "appointmentIsPurchase" },
                      { name: "Letting", field: "appointmentIsLetting" },
                      { name: "Leasing", field: "appointmentIsLeasing" },
                      { name: "Auction", field: "appointmentIsAuction" },
                      { name: "Other", field: "appointmentIsOther" },
                    ].map(({ name }) => (
                      <FormControlLabel
                        key={name}
                        control={
                          <Checkbox
                            checked={
                              !!localAppointment[
                                `appointmentIs${name}` as keyof AgentAppointment
                              ]
                            }
                            onChange={handleCheckboxChange}
                            name={`appointmentIs${name}`}
                          />
                        }
                        label={name}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </Grid>

              {agentAppointment!.appointmentIsOther ? (
                <Grid item xs={6}>
                  <FormInputText
                    control={control}
                    name="appointmentIsOtherDescription"
                    label="Description of Other"
                    required
                  />
                </Grid>
              ) : null}
              {agentAppointment!.appointmentIsAuction ? (
                <Grid item xs={6}>
                  <FormInputDatePicker
                    control={control}
                    name="auctionDate"
                    label="Auction Date"
                    error={errors.auctionDate}
                  />
                </Grid>
              ) : null}
            </Grid>
          </Box>

          <Box
            className="section-2"
            sx={{
              display: "flex",
              flexDirection: "column",
              marginBottom: 4,
              borderBottom: "1px solid silver",
              paddingBottom: 5,
              gap: 1,
            }}
          >
            <Typography variant="h6" component="span" className="roboto-bold">
              Section 2
            </Typography>
            <Typography variant="h6">Term of Appointment</Typography>
            <Typography variant="body2">
              Sole and exclusive appointments: for sales of one or two
              residential properties, the term is negotiable and agent can be
              appointed or reappointed up to a maximum of 90 days per term.
              There are no limitations on the length of an appointment for
              anything other than a residential property sale.
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={
                    agentAppointment!.appointmentTermType
                      ? agentAppointment!.appointmentTermType
                      : AgentAppointmentAppointmentTermTypeEnum.Single
                  }
                  onChange={value => {
                    const u = { ...agentAppointment }
                    u.appointmentTermType = value.currentTarget
                      .value as AgentAppointmentAppointmentTermTypeEnum
                    onSubmit(u)
                  }}
                  row
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value={AgentAppointmentAppointmentTermTypeEnum.Single}
                    control={<Radio />}
                    label="Single Appointment"
                  />

                  <FormControlLabel
                    value={AgentAppointmentAppointmentTermTypeEnum.Continuing}
                    control={<Radio />}
                    label="Continuing Appointment"
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormInputDatePicker
                  control={control}
                  label="Start"
                  name="appointmentTermStart"
                  required
                  error={errors.appointmentTermStart}
                />
              </Grid>
              {agentAppointment!.appointmentTermType ==
              AgentAppointmentAppointmentTermTypeEnum.Single ? (
                <Grid item xs={12} sm={4}>
                  <FormInputDatePicker
                    control={control}
                    label="End"
                    name="appointmentTermEnd"
                    error={errors.appointmentTermEnd}
                    required={
                      agentAppointment!.appointmentTermType ==
                      AgentAppointmentAppointmentTermTypeEnum.Single
                    }
                  />
                </Grid>
              ) : null}
            </Grid>
          </Box>

          <Box
            className="section-3"
            sx={{
              display: "flex",
              flexDirection: "column",
              marginBottom: 4,
              borderBottom: "1px solid silver",
              paddingBottom: 5,
              gap: 1,
            }}
          >
            <Typography variant="h6" component="span" className="roboto-bold">
              Section 3
            </Typography>
            <Typography variant="h6">List Price</Typography>
            <Typography variant="body2">
              State the price for which the property, land or business is to be
              sold or let. These are the sellers instructions to the agent.
              <br />
              If the seller's instructions change, i.e. reduce the price, then
              the agent must get these instructions in writing from the seller.
              <br />
            </Typography>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12}>
                <Typography variant="h6">Price List Range</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={5} lg={4}>
                <Controller
                  name="priceListLow"
                  defaultValue={agentAppointment!.priceListLow}
                  control={control}
                  render={({ field }) => (
                    <CurrencyField
                      onChange={field.onChange}
                      // {...field}
                      label="Price Range Low"
                      amount={agentAppointment!.priceListLow}
                      required
                      error={errors.priceListLow}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={5} lg={4}>
                <Controller
                  name="priceListHigh"
                  defaultValue={agentAppointment!.priceListHigh}
                  control={control}
                  render={({ field }) => (
                    <CurrencyField
                      onChange={field.onChange}
                      // {...field}
                      label="Price Range High"
                      amount={agentAppointment!.priceListHigh}
                      required
                      error={errors.priceListHigh}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12}>
                <Typography variant="h6">Marketing Price Range</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={5} lg={4}>
                <FormInputNumeric
                  control={control}
                  name="priceMarketingRangeLow"
                  label="Marketing Range Low"
                  required
                  error={errors.priceMarketingRangeLow}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={5} lg={4}>
                <FormInputNumeric
                  control={control}
                  name="priceMarketingRangeHigh"
                  label="Marketing Range High"
                  required
                  error={errors.priceMarketingRangeHigh}
                />
              </Grid>
            </Grid>
          </Box>

          <Box
            className="section-4"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              marginBottom: 4,
            }}
          >
            <Typography variant="h6" component="span" className="roboto-bold">
              Section 4
            </Typography>
            <Typography variant="h6">Instructions / Conditions</Typography>
            <Typography variant="body2">
              The client may list any condition, limitation or restriction on
              the performance of the service. <br />
              <Typography component="span" sx={{ color: "#c20029" }}>
                Note:{" "}
              </Typography>
              Annexures detailing instructions/conditions may be attached if
              required.
            </Typography>
            <Grid item xs={12}>
              <FormInputText
                control={control}
                label="Instructions"
                name="instructions"
                multiline
                minRows={5}
                placeholder="e.g. To be marketed without a sale price"
                error={errors.instructions}
              />
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </form>
  )
}

export default Appointment
