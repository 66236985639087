import React, { Fragment, useEffect } from "react"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Radio from "@mui/material/Radio"
import { Controller, useForm } from "react-hook-form"
import {
  Contract,
  Participant,
  ParticipantParticipantTypeEnum,
  ParticipantRoleEnum,
} from "../../../landconnex-api-client/api"
import {
  Box,
  debounce,
  Divider,
  Paper,
  useTheme,
} from "@mui/material"
import FormInputText from "../../../components/form-input-text"
import FormInputDatePicker from "../../../components/form-input-date-picker"
import FormInputNumeric from "../../../components/form-input-numeric"
import { useAppSelector, useAppDispatch } from "../../../app/hooks"
import { useParams } from "react-router-dom"
import { getParams } from "../helpers"
import { selectWorkspace } from "../../../app/slices/workspaces-slice"
import { putContract, selectContract } from "../../../app/slices/contract-slice"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  postParticipant,
  putParticipant,
  selectParticipants,
} from "../../../app/slices/participants-slice"
import AddressForm from "../../../components/address-form"
import ParticipantNameForm from "../../../components/participant-name-form"
import {
  contractSchema,
  participantSchema,
} from "../../../utils/validation-schemas"
import { gridSpacing } from "../../../constants"

const Encumbrances: React.FC = () => {
  const dispatch = useAppDispatch()
  const workspace = useAppSelector(selectWorkspace)
  const contract = useAppSelector(selectContract)

  const workspaceParams = getParams(useParams())
  const disableField = ["buyer", "buyerSolicitor"].includes(
    workspace!.workspace?.role!,
  )
  const theme = useTheme()

  const schema = contractSchema

  const contractFormMethods = useForm<Contract>({
    defaultValues: contract,
    resolver: zodResolver(schema),
    mode: "onChange",
  })

  const {
    control,
    watch,
    reset,
    formState: { isDirty, errors },
  } = contractFormMethods

  const formValues = watch()
  const hasEncumbrances = watch("hasEncumbrances")
  const hasRtaAgreement = watch("hasRtaAgreement")
  const hasTenant = watch("hasTenant")

  const debouncedSave = React.useCallback(
    debounce(async (data: Contract) => {
      dispatch(
        putContract({
          workspaceId: workspaceParams.workspaceId,
          contract: data,
        }),
      )
      reset(data, { keepErrors: true })
    }, 1000),
    [dispatch, workspace!.workspace!.id],
  )

  useEffect(() => {
    if (isDirty) {
      debouncedSave(formValues)
    }
  }, [formValues, isDirty])

  return (
    <Paper sx={{ p: 2 }}>
        <form>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12} sm={12} lg={12}>
              <Typography variant="h5">
                Is the Property sold subject to any Encumbrances?
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
              <Controller
                name="hasEncumbrances"
                control={control}
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    defaultValue={field.value ? "true" : "false"}
                    onChange={({ target }) =>
                      field.onChange(target.value === "true")
                    }
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Yes"
                      disabled={disableField}
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="No"
                      disabled={disableField}
                    />
                  </RadioGroup>
                )}
              ></Controller>
            </Grid>
            {hasEncumbrances ? (
              <Grid item xs={12} sm={12}>
                <FormInputText
                  name="encumbrances"
                  label="Encumbrances"
                  control={control}
                  required
                  multiline
                  minRows={6}
                  disabled={disableField}
                  error={errors.hasEncumbrances}
                />
              </Grid>
            ) : null}

            <Grid item xs={12} sm={12} lg={12}>
              <Typography variant="body1">
                Has the Property been subject to a Residential Tenancy Agreement
                or Rooming Accommodation Agreement at any time within the period
                of 12 months before the Contract Date.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
              <Controller
                name="hasRtaAgreement"
                control={control}
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    defaultValue={field.value ? "true" : "false"}
                    onChange={({ target }) =>
                      field.onChange(target.value === "true")
                    }
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Yes"
                      disabled={disableField}
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="No"
                      disabled={disableField}
                    />
                  </RadioGroup>
                )}
              ></Controller>
            </Grid>
            {hasRtaAgreement ? (
              <Fragment>
                <Grid item xs={12} sm={12}>
                  <Typography variant="body1">

                  If Yes, the day of the last rent increase for each residential
                  premises comprising the Property is
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormInputText
                    name="lastRentIncreaseDate"
                    label="Last Rent Increase Date"
                    control={control}
                    required
                    multiline
                    minRows={4}
                    disabled={disableField}
                    error={errors.lastRentIncreaseDate}
                  />
                </Grid>
              </Fragment>
            ) : null}
            <Grid item xs={12} sm={12}>
              <Typography variant="h5" color={theme.palette.text.secondary}>
                Tenancies
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
              <Typography variant="body1">Is the Property tenanted?</Typography>
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
              <Controller
                control={control}
                name="hasTenant"
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    defaultValue={field.value ? "true" : "false"}
                    onChange={({ target }) =>
                      field.onChange(target.value === "true")
                    }
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes"
                      disabled={disableField}
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                      disabled={disableField}
                    />
                  </RadioGroup>
                )}
              />
            </Grid>
            {hasTenant ? (
              <Fragment>
                <Grid item xs={12} sm={12}>
                  <FormInputText
                    control={control}
                    name="tenancyTenantName"
                    label="Tenants Name"
                    required={contract?.hasTenant}
                    disabled={disableField}
                    error={errors.tenancyTenantName}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormInputText
                    control={control}
                    name="tenancyTermAndOptions"
                    label="Terms & Options"
                    multiline
                    minRows={4}
                    required={contract?.hasTenant}
                    disabled={disableField}
                    error={errors.tenancyTermAndOptions}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <FormInputDatePicker
                    control={control}
                    name="tenancyTermStart"
                    label="Tenancy Term Start"
                    required={contract?.hasTenant}
                    disabled={disableField}
                    error={errors.tenancyTermStart}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <FormInputDatePicker
                    control={control}
                    name="tenancyTermEnd"
                    label="Tenancy Term End"
                    required={contract?.hasTenant}
                    disabled={disableField}
                    error={errors.tenancyTermEnd}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <FormInputNumeric
                    control={control}
                    name="tenancyRent"
                    label="Rent"
                    required={contract?.hasTenant}
                    disabled={disableField}
                    error={errors.tenancyRent}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <FormInputNumeric
                    control={control}
                    name="tenancyBond"
                    label="Bond"
                    required={contract?.hasTenant}
                    disabled={disableField}
                    error={errors.tenancyBond}
                  />
                </Grid>
              </Fragment>
            ) : null}
          </Grid>
        </form>
        {hasTenant ? <ManagingAgent /> : null}
</Paper>
  )
}

const ManagingAgent = () => {
  const participants = useAppSelector(selectParticipants)
  const contract = useAppSelector(selectContract)
  const dispatch = useAppDispatch()
  const params = useParams()
  const workspaceId = Number(params.workspaceId)
  const theme = useTheme()

  const filteredParticipants = participants.filter(
    participant => participant.role === ParticipantRoleEnum.TenancyAgent,
  )

  const managingAgent =
    filteredParticipants.length > 0
      ? filteredParticipants[0]
      : {
          role: ParticipantRoleEnum.TenancyAgent,
          participantType: ParticipantParticipantTypeEnum.Organisation,
        }

  const {
    control,
    watch,
    reset,
    setValue,
    formState: { errors, isDirty },
  } = useForm<Participant>({
    mode: "onChange",
    defaultValues: managingAgent,
    resolver: zodResolver(participantSchema),
  })

  useEffect(() => {
    if (managingAgent.id === undefined && contract!.hasTenant) {
      dispatch(
        postParticipant({
          participant: {
            role: ParticipantRoleEnum.TenancyAgent,
          },
          workspaceId: workspaceId,
        }),
      )
      console.log("managing agent added")
    }
  }, [managingAgent, contract!.hasTenant])

  const formValues = watch()

  const debouncedSave = React.useCallback(
    debounce(async (data: Participant) => {
      dispatch(
        putParticipant({
          workspaceId: workspaceId,
          participant: data,
        }),
      )
      reset(data, { keepErrors: true })
    }, 1000),
    [dispatch, workspaceId],
  )

  useEffect(() => {
    if (isDirty) {
      debouncedSave(formValues)
    }
  }, [formValues, isDirty])

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item>
        <Grid component="form" container spacing={gridSpacing}>
          <Grid item xs={12} sm={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            ></Box>
            <Divider />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Typography variant="h6" color={theme.palette.text.secondary}>
              Managing Agent
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <ParticipantNameForm
              control={control}
              participantType={ParticipantParticipantTypeEnum.Organisation}
              errors={errors}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Typography variant="h6" color={theme.palette.text.secondary}>
              Contact
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormInputText
              name="phone"
              control={control}
              label="Phone"
              error={errors.phone}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <FormInputText
              name="email"
              control={control}
              label="Email"
              error={errors.email}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Typography variant="h6" color={theme.palette.text.secondary}>
              Address
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <AddressForm
              control={control}
              address={managingAgent}
              setValue={setValue}
              errors={errors}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Encumbrances
