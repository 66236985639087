import { Box, Chip, lighten } from "@mui/material"
import { ParticipantRoleEnum } from "../landconnex-api-client"

const RoleChip = ({ role }: { role: ParticipantRoleEnum }) => {
  const ParticipantChip = ({
    role,
    color,
  }: {
    role: string
    color: string
  }) => {
    return (
      <Box>
        <Chip
          label={role}
          size="small"
          variant="filled"
          sx={{
            minWidth: 90,
            textTransform: "uppercase",
            fontSize: 12,
            fontWeight: 500,
            color: color,
            borderColor: color,
            background: lighten(color, 0.9),
          }}
        />
      </Box>
    )
  }
  switch (role) {
    case ParticipantRoleEnum.Seller:
      return <ParticipantChip role="Seller" color="rgb(33, 150, 243)" />
    case ParticipantRoleEnum.Buyer:
      return <ParticipantChip role="Buyer" color="rgb(255, 193, 7)" />
    case ParticipantRoleEnum.SellerAgent:
      return <ParticipantChip role="Agent" color="rgb(0, 200, 83)" />
    case ParticipantRoleEnum.SellerSolicitor:
      return <ParticipantChip role="Solicitor" color="rgb(103, 58, 183)" />

    case ParticipantRoleEnum.BuyerSolicitor:
      return (
        <ParticipantChip role="Buyer Solicitor" color="rgb(103, 58, 183)" />
      )
    case ParticipantRoleEnum.Licencee:
      return <Chip label="Licencee" />

    case ParticipantRoleEnum.TenancyAgent:
      return <ParticipantChip role="Tenancy Agent" color="#ED71B5" />
  }
}

export default RoleChip
