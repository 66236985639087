import {  memo, useState } from 'react';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useMenu } from '../../app/features/menu/useMenu';
import { NavItem } from './NavItem';
import NavGroup from './NavGroup';
import MenuItem from '../../app/features/menu/menu-item';

const MenuList = () => {

  

  const {isDrawerOpen, menuItems} = useMenu();
  
  const drawerOpen = isDrawerOpen;

  const [selectedID, setSelectedID] = useState('');

  const lastItem: MenuItem | null = null;
  

  let lastItemIndex = menuItems.length - 1;
  let remItems: Array<MenuItem> = [];
  let lastItemId = ""

  if (lastItem && lastItem < menuItems.length) {
    lastItemId = menuItems[lastItem - 1].id;
    lastItemIndex = lastItem - 1;
    const foo = menuItems.slice(lastItem - 1, menuItems.length)
    
    remItems = foo == undefined ? [] : foo;
  }

  const navItems = menuItems.slice(0, lastItemIndex + 1).map((item, index) => {
    switch (item.type) {
      case 'group':
        if (item.url && item.id !== lastItemId) {
          return (
            <List key={item.id}>
              <NavItem item={item} level={1} isParents setSelectedID={() => setSelectedID('')} />
              {index !== 0 && <Divider sx={{ py: 0.5 }} />}
            </List>
          );
        }

        return (
          <NavGroup
            key={item.id}
            setSelectedID={setSelectedID}
            selectedID={selectedID}
            item={item}
            lastItem={lastItem}
            remItems={remItems}
            lastItemId={lastItemId ?? undefined}
          />
        );
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <Box {...(drawerOpen && { sx: { mt: 1.5 } })}>{navItems}</Box>;
}

export default memo(MenuList);
