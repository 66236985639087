import { Alert, Button } from "@mui/material"
import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material"
import Grid from "@mui/material/Grid2"
import { appConfig, gridSpacing } from "../../../constants"
import { Controller, useForm } from "react-hook-form"
import Typography from "@mui/material/Typography"
import { styled, TextField } from "@mui/material"
import {
  selectTeamStatus,
  TeamStatus,
  uploadTeamLogo,
} from "../../../app/slices/team-slice"
import { updateTeam } from "../../../app/slices/team-slice"
import { TeamCompanyTypeEnum } from "../../../landconnex-api-client"
import { Team } from "../../../landconnex-api-client"
import { teamSchema } from "../../../utils/validation-schemas"
import { zodResolver } from "@hookform/resolvers/zod"
import { useCallback, useState } from "react"
import { selectTeam } from "../../../app/slices/team-slice"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { Delete, Image } from "@mui/icons-material"

const Branding = () => {
  const imageStore = appConfig.imageBucket
  const dispatch = useAppDispatch()
  const [dragActive, setDragActive] = useState(false)
  const [uploadError, setUploadError] = useState<string | null>(null)
  const [isUploading, setIsUploading] = useState(false)
  const status = useAppSelector(selectTeamStatus)
  const team = useAppSelector(selectTeam)
  const validateFile = (file: File): string | null => {
    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      return "Please upload a PNG or JPEG image"
    }
    if (file.size > MAX_FILE_SIZE) {
      return "File size must be less than 5MB"
    }
    return null
  }

  const teamForm = useForm<Team>({
    defaultValues:
      team == null
        ? {
            abn: "",
            acn: "",
            companyType: TeamCompanyTypeEnum.RealEstateAgency,
            name: "",
            primaryColour: "",
            secondaryColour: "",
            websiteUrl: "",
            primaryContact: {
              name: {
                firstName: "",
                lastName: "",
                middleName: "",
                organisationName: "",
              },
              email: "",
              phone: "",
              mobilePhone: "",
              address: {
                streetAddress1: "",
                streetAddress2: "",
                locality: "",
                stateOrTerritory: "",
                postCode: "",
                country: "",
              },
            },
          }
        : team,
    mode: "onChange",
    resolver: zodResolver(teamSchema),
  })
  const {
    reset,
    handleSubmit,
    control,
    formState: { isValid },
  } = teamForm

  const onSubmit = (data: Team) => {
    if (isValid) {
      dispatch(updateTeam(data))
      reset(data)
    }
  }
  const handleLogoUpload = async (file: File) => {
    const error = validateFile(file)
    if (error) {
      setUploadError(error)
      return
    }

    setUploadError(null)
    setIsUploading(true)
    try {
      await dispatch(uploadTeamLogo(file))
      // Force reload the image by updating the timestamp
      const img = document.querySelector("#team-logo") as HTMLImageElement
      if (img) {
        img.src = `${imageStore}/logos/${team?.id}.png?${Date.now()}`
      }
    } catch (error) {
      setUploadError("Failed to upload logo. Please try again.")
      console.error("Failed to upload logo:", error)
    } finally {
      setIsUploading(false)
    }
  }
  const DropZone = styled(Box)(({ theme }) => ({
    width: "100%",
    height: 200,
    border: `2px dashed ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    transition: "border-color 0.2s ease",
    backgroundColor: theme.palette.background.default,
    "&:hover": {
      borderColor: theme.palette.primary.main,
    },
  }))

  const LogoPreview = styled(Box)(({ theme }) => ({
    width: "100%",
    height: 200,
    borderRadius: theme.shape.borderRadius,
    overflow: "hidden",
    position: "relative",
    backgroundColor: theme.palette.background.default,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }))

  const PreviewImage = styled("img")({
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
  })

  const handleDrop = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleLogoUpload(e.dataTransfer.files[0])
    }
  }, [])

  const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(true)
  }, [])

  const handleDragLeave = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
  }, [])

  const handleFileChange: React.ChangeEventHandler<
    HTMLInputElement
  > = event => {
    const file = event.target.files?.[0]
    if (file) {
      handleLogoUpload(file)
    }
  }

  const handleRemoveLogo = async () => {
    try {
      // await dispatch(updateTeam({ ...formData, logo: "" })).unwrap()
      //setFormData(prev => ({ ...prev, logo: "" }))
      setUploadError(null)
    } catch (error) {
      setUploadError("Failed to remove logo")
      console.error("Failed to remove logo:", error)
    }
  }
  const MAX_FILE_SIZE = 5 * 1024 * 1024 // 5MB
  const ALLOWED_FILE_TYPES = ["image/jpeg", "image/png"]

  return (
    <Grid component="form" container spacing={gridSpacing} mb={gridSpacing}>
      <Grid size={12}>
        <Typography variant="h5" gutterBottom>
          Branding
        </Typography>

        <Grid container spacing={1}>
          <Grid size={{ xs: 12, md: 6 }}>
            <Typography variant="subtitle2" gutterBottom>
              Primary Color
            </Typography>

            <Controller
              control={control}
              name="primaryColour"
              render={({ field }) => (
                <TextField
                  fullWidth
                  {...field}
                  value={field.value}
                  size="small"
                  type="color"
                  sx={{
                    "& input": {
                      height: "80px",
                      cursor: "pointer",
                    },
                  }}
                />
              )}
            />
          </Grid>

          <Grid size={{ xs: 12, md: 6 }}>
            <Typography variant="subtitle2" gutterBottom>
              Secondary Color
            </Typography>
            <Controller
              control={control}
              name="secondaryColour"
              render={({ field }) => (
                <TextField
                  fullWidth
                  {...field}
                  value={field.value}
                  size="small"
                  type="color"
                  sx={{
                    "& input": {
                      height: "80px",
                      cursor: "pointer",
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid size={12}>
            <Typography variant="subtitle2" gutterBottom>
              Company Logo
            </Typography>
            <Typography variant="caption" color="textSecondary" paragraph>
              Upload a PNG or JPEG image (max 5MB)
            </Typography>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <DropZone
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              sx={{
                borderColor: dragActive ? "primary.main" : "divider",
              }}
            >
              <input
                type="file"
                accept="image/png,image/jpeg"
                style={{ display: "none" }}
                onChange={handleFileChange}
                id="logo-upload"
              />
              <label
                htmlFor="logo-upload"
                style={{ width: "100%", height: "100%", cursor: "pointer" }}
              >
                <Box
                  height="100%"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  {isUploading ? (
                    <CircularProgress size={40} />
                  ) : (
                    <>
                      <Image
                        sx={{
                          fontSize: 40,
                          color: "text.secondary",
                          mb: 1,
                        }}
                      />
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        align="center"
                      >
                        Drag and drop your logo here
                        <br />
                        or click to select a file
                      </Typography>
                    </>
                  )}
                </Box>
              </label>
            </DropZone>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <LogoPreview>
              <PreviewImage
                id="team-logo"
                src={`${imageStore}/logos/${team?.id}.png?${Date.now()}`}
                alt="Company Logo"
              />
              {isUploading && (
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  right={0}
                  bottom={0}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  bgcolor="rgba(255, 255, 255, 0.8)"
                >
                  <CircularProgress />
                </Box>
              )}
              <Box
                position="absolute"
                top={8}
                right={8}
                sx={{
                  opacity: 0,
                  transition: "0.2s",
                  "&:hover": { opacity: 1 },
                }}
              >
                <Tooltip title="Remove logo">
                  <IconButton
                    onClick={handleRemoveLogo}
                    size="small"
                    sx={{
                      bgcolor: "background.paper",
                      "&:hover": { bgcolor: "error.light", color: "white" },
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              </Box>
            </LogoPreview>

            {uploadError && (
              <Alert severity="error" sx={{ mt: 1 }}>
                {uploadError}
              </Alert>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={1} my={1}>
          <Grid size={12}>
            <Box width={120} mx="auto" mt="30px">
              <Button
                size="large"
                variant="contained"
                onClick={() => {
                  handleSubmit(data => {
                    if (isValid) {
                      onSubmit(data)
                    }
                  })()
                }}
                disabled={status === TeamStatus.loading}
              >
                Save
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Branding
