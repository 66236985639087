import { FC, useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { withAuthenticationRequired } from "react-oidc-context"
import RedirectToLogin from "../../components/redirect-to-login"
import {
  getWorkspaces,
  selectWorkspacesStatus,
  WorkspacesStatus,
} from "../../app/slices/workspaces-slice"
import {
  initialise,
  selectSessionStatus,
  SessionStatus,
} from "../../app/slices/session-slice"
import WorkspacesLarge from "./components/workspaces-large"
import WorkspacesSmallMedium from "./components/workspaces-small-medium"
import MainCard from "../../components/cards/MainCard"
// import { getRoles } from "../../app/authorisation"

const WorkspacesPage: FC = () => {
  // const [ setIsCardView] = useState(true)

  const workspacesStatus = useAppSelector(selectWorkspacesStatus)
  const dispatch = useAppDispatch()
  // const navigate = useNavigate()
  // const [ setShowModal] = useState(false)
  // const teamName = useAppSelector(selectTeamName)
  const sessionStatus = useAppSelector(selectSessionStatus)
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"))
  const isMedium = useMediaQuery(theme.breakpoints.between("sm", "md"))

  // const roles = useAppSelector(getRoles)

  useEffect(() => {
    if (sessionStatus == SessionStatus.initial) {
      dispatch(initialise())
    }
  }, [sessionStatus, dispatch])
  useEffect(() => {
    if (workspacesStatus === WorkspacesStatus.initial) {
      dispatch(getWorkspaces())
    }
  }, [workspacesStatus, dispatch])

  // const handleViewToggle = () => {
  //   setIsCardView(prev => !prev)
  // }

  // const handleCreateWorkspace = () => {
  //   if (teamName == undefined) {
  //     setShowModal(true)
  //   } else {
  //     navigate("/create-workspace")
  //   }
  // }

  // return teamName ? (
  return (
    <MainCard title="Property Workspaces">
      {isSmall || isMedium ? <WorkspacesSmallMedium /> : <WorkspacesLarge />}
    </MainCard>
  )
}

export default withAuthenticationRequired(WorkspacesPage, {
  OnRedirecting: () => <RedirectToLogin />,
  signinRedirectArgs: { redirect_uri: window.location.href },
})
