import { RootState } from "../store"
import { Participant } from "../../landconnex-api-client"
import { createAppSlice } from "../createAppSlice"
import { IdTokenClaims, User } from "oidc-client-ts"
import { appConfig } from "../../constants"

export enum SessionStatus {
  initial,
  loading,
  ready,
}

export interface JWTPayload {
  sub: string // Subject (user ID)
  exp: number // Expiration time
  iat: number // Issued at
  email: string
  name: string
  roles: string[]
  // Add any other JWT claims you expect
}

export interface SessionState {
  status: SessionStatus
  details?: Participant
  modalOpen: boolean

  /**
   * true if the user can create new workspaces; false otherwise.
   */
  isTeamAdministrator: boolean
  isDashboardViewer: boolean
  isWorkspaceCreator: boolean
  isWorkspaceReader: boolean
  isWorkspaceAdministrator: boolean
  isGroupAdministrator: boolean
  isAgent: boolean
  isAgentAdministrator: boolean
  teamName: string | undefined
  /**
   * JWT token payload information
   */
  tokenInfo?: JWTPayload
}

const initialState: SessionState = {
  status: SessionStatus.initial,
  modalOpen: false,
  isTeamAdministrator: false,
  isDashboardViewer: false,
  isWorkspaceCreator: false,
  isWorkspaceReader: false,
  isWorkspaceAdministrator: false,
  isGroupAdministrator: false,
  isAgent: false,
  isAgentAdministrator: false,
  tokenInfo: undefined,
  teamName: undefined,
}

const parseJWT = (token: string): JWTPayload => {
  const base64Url = token.split(".")[1]
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
  return JSON.parse(window.atob(base64))
}

interface LandConnexIdTokenClaims extends IdTokenClaims {
  roles: string[]
  groups: string[]
}

export const sessionSlice = createAppSlice({
  name: "session",
  initialState,
  reducers: create => ({
    initialise: create.reducer(state => {
      const oidcStorage = sessionStorage.getItem(
        `oidc.user:${appConfig.authority}:${appConfig.clientId}`,
      )
      if (oidcStorage) {
        const user = User.fromStorageString(oidcStorage)
        const profile = user.profile as LandConnexIdTokenClaims
        state.tokenInfo = parseJWT(user.access_token)

        state.isTeamAdministrator = profile.roles.includes("team_administrator")
        state.isAgent = profile.roles.includes("agent")
        state.isAgentAdministrator = profile.roles.includes("agent_administrator")
        state.isWorkspaceCreator = profile.roles.includes("workspace_creator")
        state.isWorkspaceReader = profile.roles.includes("workspace_reader")
        state.isDashboardViewer = profile.roles.includes("dashboard_viewer")
        state.isWorkspaceAdministrator = profile.roles.includes(
          "workspace_administrator",
        )
        state.isGroupAdministrator = profile.roles.includes(
          "group_administrator",
        )
        if (profile.groups && profile.groups.length > 0) {
          state.teamName = profile.groups[0]
        }
        state.status = SessionStatus.ready
      }
    }),
    setTokenInfo: (state, action: { payload: string }) => {
      try {
        state.tokenInfo = parseJWT(action.payload)
      } catch (error) {
        console.error("Failed to parse JWT token:", error)
        state.tokenInfo = undefined
      }
    },
    setSession: (state, action: { payload: Participant }) => {
      state.details = action.payload
    },
    toggleModal: state => {
      state.modalOpen = !state.modalOpen
    },
  }),

  selectors: {
    selectTeamName: state => state.teamName,
    selectIsWorkspaceCreator: state => state.isWorkspaceCreator,
    selectIsWorkspaceAdministrator: state => state.isWorkspaceAdministrator,
    selectIsGroupAdministrator: state => state.isGroupAdministrator,
    selectIsTeamAdministrator: state => state.isTeamAdministrator,
    selectIsAgent: state => state.isAgent,
    selectIsAgentAdministrator: state => state.isAgentAdministrator,
    selectDetails: state => state.details,
    selectSessionStatus: state => state.status,
    selectTokenInfo: state => state.tokenInfo,
    selectIsWorkspaceReader: state => state.isWorkspaceReader,
    selectIsDashboardViewer: state => state.isDashboardViewer,
  },
})

export const { setTokenInfo, toggleModal, initialise } = sessionSlice.actions
export const {
  selectTeamName,
  selectIsWorkspaceCreator,
  selectIsWorkspaceAdministrator,
  selectIsWorkspaceReader,
  selectIsDashboardViewer,
  selectIsGroupAdministrator,
  selectIsTeamAdministrator,
  selectIsAgent,
  selectIsAgentAdministrator,
  selectDetails,
  selectSessionStatus,
  selectTokenInfo,
} = sessionSlice.selectors
export default sessionSlice.reducer
export const sessionState = (state: RootState) => state.session
