import { teamMembersApi, teamsApi } from "../../api"
import { createAppSlice } from "../../app/createAppSlice"
import {
  Team,
  TeamInvitation,
  User,
} from "../../landconnex-api-client"

export enum SettingsStatus {
  initial,
  loading,
  ready,
  error,
}

interface SettingsState {
  status: SettingsStatus
  team: Team | null
  members: Array<User>
  teamInvitations: Array<TeamInvitation>
}

const initialState: SettingsState = {
  status: SettingsStatus.initial,
  members: [],
  teamInvitations: [],
  team: null,
}

export const settingsSlice = createAppSlice({
  name: "settings",
  initialState,
  reducers: create => ({
    getTeam: create.asyncThunk(
      async () => {
        const result = await teamsApi.getTeam()
        return result.data
      },
      {
        pending: state => {
          state.status = SettingsStatus.loading
        },
        fulfilled: (state, action) => {
          state.status = SettingsStatus.ready
          state.team = action.payload
        },
        rejected: state => {
          state.status = SettingsStatus.error
        },
      },
    ),
    getTeamMembers: create.asyncThunk(
      async () => {
        const response = await teamMembersApi.getUsers()

        return response.data
      },
      {
        pending: state => {
          state.status = SettingsStatus.loading
        },
        fulfilled: (state, action) => {
          state.status = SettingsStatus.ready
          state.members = action.payload.users!
        },
        rejected: state => {
          state.status = SettingsStatus.error
        },
      },
    ),
    getTeamInvitations: create.asyncThunk(
      async () => {
        const response = await teamMembersApi.getTeamInvitations()

        return response.data.items
      },
      {
        pending: state => {
          state.status = SettingsStatus.loading
        },
        fulfilled: (state, action) => {
          state.status = SettingsStatus.ready
          state.teamInvitations = action.payload
        },
        rejected: state => {
          state.status = SettingsStatus.error
        },
      },
    ),
    cancelInvitation: create.asyncThunk<void, number>(
      async invitationId => {
        await teamMembersApi.cancelInvitation(invitationId)
      },
      {
        pending: state => {
          state.status = SettingsStatus.loading
        },
        fulfilled: state => {
          state.status = SettingsStatus.ready
        },
        rejected: state => {
          state.status = SettingsStatus.error
        },
      },
    ),
    inviteUserToTeam: create.asyncThunk<void, string>(
      async emailAddress => {
        await teamMembersApi.inviteUserToTeam(emailAddress)
      },
      {
        pending: state => {
          state.status = SettingsStatus.loading
        },
        fulfilled: state => {
          state.status = SettingsStatus.ready
        },
        rejected: state => {
          state.status = SettingsStatus.error
        },
      },
    ),
    removeUserFromTeam: create.asyncThunk<void, string>(
      async sub => {
        await teamMembersApi.removeUserFromTeam(sub)
      },
      {
        pending: state => {
          state.status = SettingsStatus.loading
        },
        fulfilled: state => {
          state.status = SettingsStatus.ready
        },
        rejected: state => {
          state.status = SettingsStatus.error
        },
      },
    ),
  }),

  selectors: {
    selectTeam: settings => settings.team,
    selectStatus: settings => settings.status,
    selectTeamMembers: settings => settings.members,
    selectTeamInvitations: settings => settings.teamInvitations,
  },
})

export const {
  getTeam,
  getTeamMembers,
  getTeamInvitations,
  cancelInvitation,
  inviteUserToTeam,
  removeUserFromTeam,
} = settingsSlice.actions

export const {
  selectTeam,
  selectStatus,
  selectTeamInvitations,
  selectTeamMembers,
} = settingsSlice.selectors
